import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import axios from 'axios';

import { IAgreementDTO, ICompanyDTO, ILocationDTO } from '../typescript/interfaces';
import { Method } from '../typescript/types';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL ?? '';

const getAxiosConfig = <T>(
  method: Method,
  token: string | GetTokenSilentlyVerboseResponse | undefined,
  path: string,
  data?: T,
) => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  url: `${REACT_APP_API_URL}/${path}`,
  data,
});

interface IDTO {
  token: string | GetTokenSilentlyVerboseResponse | undefined;
}

//****************************/
// Company
//****************************/

export const getCompanies = async (token: string): Promise<ICompanyDTO[]> => {
  const response = await axios(getAxiosConfig('GET', token, 'company'));
  return response.data;
};

export const getCompany = async (token: string, id: string): Promise<ICompanyDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `company/${id}`));
  return response.data;
};

interface IAddCompany extends IDTO {
  company: ICompanyDTO;
}

export const addCompany = async ({ token, company }: IAddCompany) => {
  const response = await axios(getAxiosConfig('POST', token, 'company', company));
  return response.data;
};

export interface IUpdateCompany extends IDTO {
  company: ICompanyDTO;
}

export const updateCompany = async ({ token, company }: IUpdateCompany): Promise<ICompanyDTO> => {
  const response = await axios(getAxiosConfig('PUT', token, `company/${company.id}`, company));
  return response.data;
};

export interface IDeleteCompany extends IDTO {
  companyId: string;
}

export const deleteCompany = async ({ token, companyId }: IDeleteCompany): Promise<ICompanyDTO> => {
  const response = await axios(getAxiosConfig('DELETE', token, `company/${companyId}`));
  return response.data;
};

//****************************/
// Organization
//****************************/

interface IDeleteOrganization extends IDTO {
  ids: string[];
  companyId: string;
}

export interface IUpdateOrganization extends IDTO {
  agreements: IAgreementDTO[];
  companyId: string;
}

export const deleteOrganization = async ({ token, ids, companyId }: IDeleteOrganization) => {
  const deletePromises = ids.map((id: string) =>
    axios(getAxiosConfig('DELETE', token, `company/${companyId}/agreements/${id}`)),
  );
  const results = await Promise.all(deletePromises);
  return results.map((result) => result.data);
};

export const updateOrganization = async ({ token, agreements, companyId }: IUpdateOrganization) => {
  const updatePromises = agreements.map((agreement: IAgreementDTO) =>
    axios(
      getAxiosConfig('PUT', token, `company/${companyId}/agreements/${agreement.id}`, agreement),
    ),
  );
  const results = await Promise.all(updatePromises);
  return results.map((result) => result.data);
};

//****************************/
// Location
//****************************/

export interface IUpdateLocation extends IDTO {
  location: ILocationDTO;
  companyId: string;
}

interface IDeleteLocation extends IDTO {
  id: string;
  companyId: string;
}

export const addLocation = async ({ token, location, companyId }: IUpdateLocation) => {
  const response = await axios(
    getAxiosConfig('POST', token, `company/${companyId}/locations`, location),
  );
  return response.data;
};

export const updateLocation = async ({ token, location, companyId }: IUpdateLocation) => {
  const response = await axios(
    getAxiosConfig('PUT', token, `company/${companyId}/locations/${location.id}`, location),
  );
  return response.data;
};

export const deleteLocation = async ({ token, id, companyId }: IDeleteLocation) => {
  const response = await axios(
    getAxiosConfig('DELETE', token, `company/${companyId}/locations/${id}`),
  );
  return response.data;
};

//****************************/
// Agreement
//****************************/
export interface IUpdateAgreement extends IDTO {
  agreement: IAgreementDTO;
  companyId: string;
}

interface IDeleteAgreement extends IDTO {
  id: string;
  companyId: string;
}

export const addAgreement = async ({ token, agreement, companyId }: IUpdateAgreement) => {
  const newAgreement = { externalId: agreement.externalId };
  const response = await axios(
    getAxiosConfig('POST', token, `company/${companyId}/agreements`, newAgreement),
  );
  return response.data;
};

export const updateAgreement = async ({ token, agreement, companyId }: IUpdateAgreement) => {
  const response = await axios(
    getAxiosConfig('PUT', token, `company/${companyId}/agreements/${agreement.id}`, agreement),
  );
  return response.data;
};

export const deleteAgreement = async ({ token, id, companyId }: IDeleteAgreement) => {
  const response = await axios(
    getAxiosConfig('DELETE', token, `company/${companyId}/agreements/${id}`),
  );
  return response.data;
};
