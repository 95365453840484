import styled from 'styled-components';

import { Button, FileList, RadioButtons, SmallTabs, CommunicationLog } from '../..';
import { IDetailView } from '../../../typescript/interfaces';
import { getIsChangesAllowed } from '../../../utils';
import { Fields } from '../../UI/Fields/Fields';
import { Table } from '../../UI/Table/Table';
import { FormFields } from '../FormFields';
import { GridItem } from '../Griditem';
import { BottomButtonsWrapper } from '../styles';

interface IProps {
  viewModel: IDetailView | null;
}

export const AdvancedLayoutGrid = ({ viewModel }: IProps) => {
  if (!viewModel) {
    return null;
  }
  const {
    companyId,
    docLinks,
    fields,
    formFields,
    orgid,
    radioButtons,
    tableData,
    warning,
    wonum,
    workorderid,
  } = viewModel;
  const { isChangesAllowed } = getIsChangesAllowed(parseInt(workorderid ?? '0'));
  const submitDisabled =
    warning?.hasWarning || !radioButtons?.some((button) => button.checked) || !isChangesAllowed;

  const buttonText = () => {
    if (!isChangesAllowed) {
      return 'Changes not allowed';
    }
    return warning?.hasWarning ? warning?.message ?? 'Add a comment' : 'Save and close';
  };

  const tabs = [
    {
      label: <p>Resource table</p>,
      content: <Table tableData={tableData} heading="Resources" defaultOrder="asc" />,
    },
    {
      label: <p>Communication log</p>,
      content: <CommunicationLog wonum={wonum} orgid={orgid} companyId={companyId} />,
    },
  ];

  return (
    <Container>
      <Fields fieldItems={fields} />
      <GridItem colSpan={'1/-1'}>
        <FoldoutArea>
          {docLinks && docLinks.length > 0 && (
            <FileList
              workorderid={workorderid}
              docLinks={docLinks}
              orgid={orgid}
              companyId={companyId}
            />
          )}
        </FoldoutArea>
      </GridItem>
      <InputAndTabs>
        <FormFields formFields={formFields} />
        <Tabs>
          <SmallTabs tabs={tabs} />
        </Tabs>
      </InputAndTabs>
      <BottomButtonsWrapper>
        <RadioButtons radioButtons={radioButtons} radioButtonGroupName={radioButtons[0].name} />
        <Button type="submit" disabled={submitDisabled}>
          {buttonText()}
        </Button>
      </BottomButtonsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 0;
  flex: 1;
  flex-direction: column;
`;

const InputAndTabs = styled.div`
  display: grid;
  column-gap: calc(4 * ${({ theme }) => theme.spacings.medium});
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'form tabs tabs';
  padding-top: ${({ theme }) => theme.spacings.medium};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-areas:
      'tabs tabs tabs'
      'form form form';
  }
`;

const Tabs = styled.div`
  grid-area: tabs;
`;

const FoldoutArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;
