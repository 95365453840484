export const getNumber = (value: unknown) => {
  if (typeof value === 'number') return value;
  if (typeof value !== 'string') return 0;
  if (value === '') return 0;

  const parsed = parseFloat(value);
  if (isNaN(parsed)) return 0;

  return parsed;
};
