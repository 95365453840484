import { ITableData, ITableHeaderCell, ITableRow } from '../../../typescript/interfaces';
import { ResourceTypes, WorkOrder } from '../../../typescript/types';
import { addThousandSeperator } from '../../../utils';
import { cell } from '../../UI';
import { getFillerRows } from '../getFillerRows';
import { getFixedPriceRow } from '../getFixedPrice';
import { getListPrice } from '../getListPrice';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'resourceType',
    label: 'Resource type',
  },
  {
    id: 'Description',
    label: 'Description',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
  {
    id: 'unitPrice',
    label: 'Unit price',
    align: 'right',
  },
  {
    id: 'cost',
    label: 'Amount',
    align: 'right',
  },
];

const getTableRows = (workOrder: WorkOrder, minRows: number): ITableRow[] => {
  const matusetransRows: ITableRow[] = workOrder?.matusetrans
    ? workOrder.matusetrans.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Material }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.positivequantity }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const servrectransRows = workOrder?.servrectrans
    ? workOrder.servrectrans.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Service }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.quantity }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const tooltransRows = workOrder?.tooltrans
    ? workOrder.tooltrans.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Tools }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.toolqty }),
            cell({ value: '' }, { align: 'right' }), // Maximo buggar
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const pluspgbtransRows = workOrder?.pluspgbtrans
    ? workOrder.pluspgbtrans.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.FeesAndCharges }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: '1' }),
            cell({ value: getListPrice(v.lineprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.lineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const labtransRows = workOrder?.labtrans
    ? workOrder.labtrans.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Labor }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.regularhrs }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];

  let tableRows = [
    matusetransRows,
    servrectransRows,
    tooltransRows,
    pluspgbtransRows,
    labtransRows,
  ].flat();

  if (tableRows.length < minRows) {
    tableRows = [...tableRows, ...getFillerRows(tableRows.length, minRows)];
  }

  return tableRows;
};

/**
 * @param {WorkOrder} workOrder
 * @param {number} minRows - minimum number of rows to display in the table
 * @returns {IEnhancedTableData}
 */
export const getCompletedWorkDetailTable = (workOrder: WorkOrder, minRows: number): ITableData => {
  const { pluspquotetype } = workOrder;

  return {
    headerCells,
    rows:
      pluspquotetype === 'FIXED' ? getFixedPriceRow(workOrder) : getTableRows(workOrder, minRows),
    tableFooter: {
      label: 'Total Amount',
      value: addThousandSeperator(workOrder.totalAmount),
    },
  };
};
