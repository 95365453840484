import {
  ICompletedWorkUpdateableFields,
  IFieldItem,
  IProposedWorkUpdateableFields,
} from '../../typescript/interfaces';

type FieldsToUpdate = IProposedWorkUpdateableFields | ICompletedWorkUpdateableFields;

export const getMappedFormFields = (formFields: IFieldItem[], fieldsToUpdate: FieldsToUpdate) => {
  const mappedFormFields = formFields.map((field: IFieldItem) => {
    if (field.name in fieldsToUpdate) {
      const content = fieldsToUpdate[field.name as keyof FieldsToUpdate];
      return { ...field, content };
    }
    return field;
  });

  return mappedFormFields;
};
