import styled from 'styled-components';

import { getStatusText } from '../../utils';
import { Status } from '../UI';

interface IProps {
  name?: string;
  date?: string;
  status?: string;
  comment?: string;
  selfAlign?: string;
}

export const Log = ({ name, date, status, comment, selfAlign }: IProps) => {
  const statusText = getStatusText(status ?? '');
  const statusElm =
    status && status.length > 0 ? (
      <StatusContainer>
        <Status status={status} />
        <p>{statusText}</p>
      </StatusContainer>
    ) : null;

  return (
    <LogContainer $selfAlign={selfAlign}>
      <NameAndDate>
        <Name>{name}</Name>
        <Date>{date}</Date>
      </NameAndDate>
      {statusElm}
      <Comment>{comment}</Comment>
    </LogContainer>
  );
};

const LogContainer = styled.div<{ $selfAlign: string | undefined }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.large};
  box-shadow: ${({ theme }) => theme.shadows.small};
  flex-basis: auto;
  align-self: ${({ $selfAlign }) => ($selfAlign === 'right' ? 'flex-end' : 'flex-start')};
  background: ${({ $selfAlign, theme }) =>
    $selfAlign === 'right' ? theme.colors.background.log : ''};
  max-width: 70%;
  min-width: 65%;

  @media (max-width: 320px) {
    max-width: 288px;
  }
`;

const NameAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xsmall};
  /* gap: ${({ theme }) => theme.spacings.xxlarge}; */
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xsmall};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

const Name = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-right: ${({ theme }) => theme.spacings.xxlarge};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacings.xsmall};
  }
`;

const Date = styled.div`
  color: ${({ theme }) => theme.colors.text.navigation};
`;

const Comment = styled.p`
  white-space: pre-line;
  word-break: break-word;
`;
