import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ILocationDTO } from 'typescript/interfaces';

import { Button, Icon, Input, Spinner } from './../../../../components';
import { useCompanyService } from './../../../../hooks/useCompanyService';

interface IFormData {
  location: string;
  locationIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  companyId: string;
  location: ILocationDTO;
}

export const EditLocation = ({ companyId, location }: IProps) => {
  const { updateLocation, deleteLocation, isUpdateCompanyLoading, isDeleteCompanyLoading } =
    useCompanyService();
  const { t } = useTranslation();
  const isLoading = isUpdateCompanyLoading || isDeleteCompanyLoading;
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    location: location?.name ?? '',
    locationIsValid: location?.name ? true : undefined,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid =
      formData.locationIsValid === true && formData.isFormChanged === true && !isLoading;
    setIsFormValid(isFormValid);
  }, [formData]);

  const removeLocation = (companyId: string, locationId: string) => {
    const isConfirmed = window.confirm(`${t('general.confirmAction')}`);

    if (!isConfirmed) {
      return;
    }

    deleteLocation(locationId, companyId);
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    companyId: string,
    location: ILocationDTO,
  ) => {
    e.preventDefault();
    const { location: name } = formData;
    const updatedLocation = { ...location, name };
    updateLocation(updatedLocation, companyId);
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = !!value;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}IsValid`]: isValid,
      isFormChanged: true,
    });
  };

  if (isLoading) {
    return <Spinner size={20} />;
  }

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, companyId, location)}
      onChange={(e) => handleFormChange(e)}>
      <Input
        name="location"
        value={formData.location}
        warning={formData.locationIsValid === false}
        placeholder={
          formData.locationIsValid === false
            ? t('admin.company.locations.locationNameIsRequired') ?? ''
            : t('admin.company.locations.locationName') ?? ''
        }
      />
      <ButtonContainer>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          onClick={() => removeLocation(companyId, location.id)}>
          <Icon icon="delete" />
          {`${t('admin.company.locations.removeLocation')}`}
        </Button>
        <Button size="small" type="submit" disabled={!isFormValid}>
          {`${t('general.saveChange')}`}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
