import { IDetailView, IFieldItem, IRadioButton } from '../../../typescript/interfaces';
import { Page, PLUSPESTAT, WorkOrder } from '../../../typescript/types';
import { getProposedWorkDetailTable } from './getProposedWorkDetailTable';
import { getProposedWorkFields } from './getProposedWorkFields';

const radioButtons: IRadioButton[] = [
  { value: PLUSPESTAT.REJECTED, label: 'Reject', name: 'pluspeststat' },
  { value: PLUSPESTAT.ACCEPTED, label: 'Approve', name: 'pluspeststat' },
];

const getProposedWorkForm = (workOrder: WorkOrder): IFieldItem[] => {
  return [
    {
      title: 'Purchase order',
      placeholder: '',
      content: workOrder.pluspcustponum,
      fieldVariant: 'inputField',
      name: 'pluspcustponum',
      maxLength: 25,
    },
    {
      title: 'Reference',
      placeholder: '',
      content: workOrder.pluspcostcenter,
      fieldVariant: 'inputField',
      name: 'pluspcostcenter',
      maxLength: 37,
    },
    {
      title: 'Comment',
      content: '',
      fieldVariant: 'textAreaField',
      name: 'comment',
      warning: false,
      warningMessage: 'Please add a comment',
      style: { flex: '1', gridRow: 'span 2' },
      textAreaProps: {
        rows: 13,
      },
    },
  ];
};

export const getProposedWorkDetailView = (
  workOrder: WorkOrder,
  application: Page,
  companyId: string,
): IDetailView => {
  return {
    workorderid: workOrder.workorderid,
    wonum: workOrder.wonum,
    orgid: workOrder.siteid.substring(0, 4),
    fields: getProposedWorkFields(workOrder),
    docLinks: workOrder.doclinks,
    formFields: getProposedWorkForm(workOrder),
    tableData: getProposedWorkDetailTable(workOrder, 6),
    radioButtons,
    companyId,
  };
};
