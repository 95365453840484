import { IHistoryDTO } from 'typescript/interfaces';

import { PLUSPESTAT, Pluspwopricetotals, Pluspcustpriceest, WorkOrder } from '../typescript/types';
import { addThousandSeperator } from './addThousandSeperator';

interface ITotals extends Omit<Pluspwopricetotals, 'wospecialprice'> {}

// If "completed work", the cost is calculated from all properties in pluspwopricetotals
export const getCompletedWorkCosts = (pluspwopricetotals: Pluspwopricetotals): number => {
  // We want to remove wospecialprice when we calculate total price
  const { wospecialprice, ...totals } = pluspwopricetotals;

  return (Object.keys(totals) as Array<keyof ITotals>).reduce(
    (acc, curr) => (acc += totals[curr]),
    0,
  );
};

// Return priceest with status pending or an empty priceest object
export const getPluspcustpriceest = (workOrder: WorkOrder): Pluspcustpriceest => {
  return (
    workOrder.pluspcustpricest?.find((quote) => {
      return quote.pluspeststat === PLUSPESTAT.PENDING;
    }) ?? ({} as Pluspcustpriceest)
  );
};

// If ""proposed work",", the cost is calculated from the first price estimate with status "PENDING"
const getProposedWorkCost = (workOrder: WorkOrder): string => {
  return workOrder ? getPluspcustpriceest(workOrder).plusppriceest ?? '0' : '0';
};

/**
 * Returns the cost of a work order based on its type and data.
 * @param {IHistoryDTO} row - The work order data.
 * @returns The cost of the work order as a string with thousand separators, or an empty string if the cost cannot be determined.
 */
export const getAmount = (row: IHistoryDTO) => {
  const { source, sourceData } = row;
  const workorderType = source?.toLowerCase().includes('completed') ? 'completed' : 'proposed';

  if (workorderType === 'completed') {
    if (sourceData.pluspquotetype === 'FIXED') {
      return addThousandSeperator(sourceData.pluspwopricetotals?.wospecialprice ?? 0);
    }

    if (!sourceData.pluspwopricetotals) {
      return '';
    }

    return addThousandSeperator(getCompletedWorkCosts(sourceData.pluspwopricetotals));
  }

  if (workorderType === 'proposed') {
    return addThousandSeperator(getProposedWorkCost(sourceData));
  }

  return '0';
};
