export enum Pages {
  PROPOSED = 'PROPOSED',
  COMPLETED = 'COMPLETED',
  HISTORY = 'HISTORY',
  COMPANIES = 'COMPANIES',
  WELCOME = 'WELCOME',
}

export enum SubPages {
  SYSTEM = 'SYSTEM',
  WORKORDER = 'WORKORDER',
}
