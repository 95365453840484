import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Fields, Table } from '../../components';
import { IDetailView, ITableData } from '../../typescript/interfaces';

interface IProps {
  viewModel: Partial<IDetailView> | undefined;
}

export const HistoryTableRowDetailsView = ({ viewModel }: IProps) => {
  const { t } = useTranslation();
  if (!viewModel) {
    return null;
  }

  const { fields = [], tableData = {} as ITableData, customerComment } = viewModel;
  return (
    <Container>
      <Fields fieldItems={fields} />
      <Table tableData={tableData} heading="Resources" size="small" />
      {customerComment && (
        <CustomerComment>
          <p>{t('history.workorder.customerComment')}:</p>
          {customerComment}
        </CustomerComment>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: ${({ theme }) => theme.spacings.large};
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.navigation};

  .fields {
    > div {
      background: none;
    }
    .fieldItem {
      padding-top: ${({ theme }) => theme.spacings.xsmall};

      p:first-child {
        font-size: ${({ theme }) => theme.fonts.size.lable};
      }
      p:last-child {
        font-size: ${({ theme }) => theme.fonts.size.small};
      }
    }

    .status {
      font-size: ${({ theme }) => theme.fonts.size.small};
    }
  }

  table {
    th,
    tr.MuiTableRow-root {
      background: none;
    }
    th,
    td {
      color: ${({ theme }) => theme.colors.text.navigation};
    }
  }

  @media screen and (max-width: 1070px) {
    .MuiTableContainer-root .MuiTableHead-root {
      display: none;
    }
  }
`;

const CustomerComment = styled.div`
  background: ${({ theme }) => theme.colors.background.body};
  padding: ${({ theme }) => theme.spacings.large};
  margin-top: ${({ theme }) => theme.spacings.large};
  max-width: 100%;
  overflow: hidden;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.text.body};
  border: 1px solid ${({ theme }) => theme.colors.border.default};

  p {
    color: ${({ theme }) => theme.colors.text.navigation};
    font-size: ${({ theme }) => theme.fonts.size.small};
    margin-bottom: ${({ theme }) => theme.spacings.xsmall};
  }
`;
