import MuiModal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModal } from '../../utils/context/ModalContext';
import { Button, Heading, Icon } from '../UI';

export const Modal = () => {
  const { modalContent, closeModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!modalContent);
  }, [modalContent]);

  if (!modalContent || !modalContent.content) {
    setIsOpen(false);
    return <></>;
  }

  const { title, content, props } = modalContent;
  const { overflow, displayCloseButton, width } = {
    ...{ overflow: 'auto', displayCloseButton: true, width: '80vw' },
    ...(props ?? {}),
  };

  return (
    <StyledModal open={isOpen} onClose={() => setIsOpen(false)}>
      <Wrapper $overflow={overflow} $width={width}>
        <ContentHeader>
          <Title variant="h4" tag="h1">
            {title}
          </Title>
          {displayCloseButton && (
            <Button variant="tertiary" onClick={() => closeModal()}>
              <Icon icon="multiply" />
            </Button>
          )}
        </ContentHeader>
        {content}
      </Wrapper>
    </StyledModal>
  );
};

const StyledModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 100vh;
`;

const Wrapper = styled.div<{ $overflow: string; $width: string }>`
  max-width: ${({ $width }) => $width};
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.background.body};
  padding: ${({ theme }) => theme.spacings.large};
  border-radius: ${({ theme }) => theme.radius.default};
  max-height: calc(100% - ${({ theme }) => theme.spacings.large});
  overflow-y: ${({ $overflow }) => $overflow};
  overflow-x: ${({ $overflow }) => $overflow};

  button {
    align-self: flex-end;
  }

  :focus {
    outline: none;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

const Title = styled(Heading)`
  margin: 0;
`;
