import { Root, Indicator } from '@radix-ui/react-checkbox';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onClick'> {
  id?: string;
  checked?: boolean;
  label?: string;
  value: string;
  onClick?: (checked: boolean, value?: string) => void;
}

export const Checkbox = ({ id, label, value, checked, onClick }: IProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked, setIsChecked]);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    setIsChecked(!isChecked);
    if (onClick) {
      onClick(!isChecked, value);
    }
  };

  return (
    <Container>
      <Root
        className="checkbox-root"
        checked={isChecked}
        onClick={(e) => handleOnClick(e, value)}
        id={id}
        value={value}>
        <Indicator className="checkbox-indicator" style={{ position: 'relative' }}>
          <Icon icon="check" />
        </Indicator>
      </Root>
      {id && label && <label htmlFor={id}>{label}</label>}
    </Container>
  );
};

const Container = styled.div`
  .checkbox-root {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: ${({ theme }) => theme.spacings.xsmall};
    background: ${({ theme }) => theme.colors.background.body};
    border: 1px solid ${({ theme }) => theme.colors.border.default};
    cursor: pointer;

    &[checked='true'],
    &:hover {
      border-color: ${({ theme }) => theme.colors.border.cta};
    }

    .checkbox-indicator {
      border: none;
      position: relative;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: ${({ theme }) => theme.colors.background.cta};
      }
    }
  }
`;
