import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SmallTabs } from '../../components';
import { usePermissions } from '../../hooks';
import { SearchWorkorderHistoryPage } from './SearchWorkorderHistoryPage';
import { SystemHistoryPage } from './SystemHistoryPage';
import { WorkorderHistoryPage } from './WorkorderHistoryPage';

const tabs = (
  t: TFunction<'translation', undefined, 'translation'>,
  hasPermissionsToViewWorkOrderHistory: boolean,
  hasPermissionsToViewSystemHistory: boolean,
) => {
  let tabs = [];

  if (hasPermissionsToViewWorkOrderHistory) {
    tabs.push({
      label: <p>{t('history.workorder.tabTitle')}</p>,
      content: <WorkorderHistoryPage />,
    });
  }

  if (hasPermissionsToViewSystemHistory) {
    tabs.push({
      label: <p>{t('history.system.tabTitle')}</p>,
      content: <SystemHistoryPage />,
    });
  }

  if (hasPermissionsToViewWorkOrderHistory) {
    tabs.push({
      label: <p>{t('history.searchworkorder.tabTitle')}</p>,
      content: <SearchWorkorderHistoryPage />,
    });
  }

  return tabs;
};

export const HistoryPage = () => {
  const { t } = useTranslation();
  const {
    hasPermissionsToViewCompanyWorklog,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
    hasPermissionToViewCustomerAdminForCompany,
  } = usePermissions();

  return (
    <Container>
      <TabsContainer>
        <SmallTabs
          tabs={tabs(
            t,
            hasPermissionsToViewCompanyWorklog ||
              hasPermissionsToViewCompletedWorkorderForCompany ||
              hasPermissionsToViewProposedWorkorderForCompany,
            hasPermissionToViewCustomerAdminForCompany,
          )}
        />
      </TabsContainer>
    </Container>
  );
};

const Container = styled.div``;

const TabsContainer = styled.div``;
