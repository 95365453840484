import toast from 'react-hot-toast';
import styled from 'styled-components';

import { ActionButton } from '../../../../components';
import { useUserService } from '../../../../hooks';

interface ResendMessagesBoxProps {
  userId: string;
  companyId: string;
  weeklyReminderEnabled: boolean;
}

export const ResendMessagesBox = ({
  userId,
  companyId,
  weeklyReminderEnabled,
}: ResendMessagesBoxProps) => {
  const { sendWeeklyReminderEmail, sendWelcomeEmail } = useUserService(undefined, companyId);

  const doSendWelcomeEmail = async () => {
    try {
      await sendWelcomeEmail(userId);
      toast.success('Welcome email sent');
    } catch {
      toast.error('Error occurred while sending welcome email');
    }
  };

  const doSendWeeklyReminderEmail = async () => {
    try {
      await sendWeeklyReminderEmail(userId);
      toast.success('Weekly reminder sent');
    } catch {
      toast.error('Error occurred while sending weekly reminder');
    }
  };

  return (
    <Container>
      <ActionList>
        <ActionItem>
          <div>
            <h3>Resend welcome email</h3>
            <p>Did the user not get the welcome email? Don't fear, you can send it again here.</p>
          </div>
          <ActionButton action={doSendWelcomeEmail} variant="secondary">
            Send welcome email
          </ActionButton>
        </ActionItem>
        {weeklyReminderEnabled && (
          <ActionItem>
            <div>
              <h3>Resend weekly reminder</h3>
              <p>
                The weekly reminder is sent automatically every week. If you want you can manually
                send another one here.
              </p>
            </div>
            <ActionButton action={doSendWeeklyReminderEmail} variant="secondary">
              Send weekly reminder
            </ActionButton>
          </ActionItem>
        )}
      </ActionList>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  padding: ${({ theme }) => theme.spacings.medium};
  margin-top: 1rem;
`;

const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  padding-bottom: ${({ theme }) => theme.spacings.medium};

  &:last-child {
    border-bottom: none;
  }
`;
