import { TableBody as MuiTableBody } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { TableRow, TableCell } from '../';
import { HeaderLabelCell } from '../';
import { ITableHeaderCell, ITableRow } from '../../../typescript/interfaces';

interface IProps {
  rows: ITableRow[];
  headerCells?: ITableHeaderCell[];
  dense: boolean;
  rowsPerPage?: number;
  tablePage?: number;
  handleRowClick?: (id: string) => void;
  hasExpandableRows?: boolean;
  keepSelectedOnTop?: boolean;
}

export const TableBody = ({
  rows,
  headerCells,
  dense,
  rowsPerPage,
  tablePage,
  handleRowClick,
  hasExpandableRows = false,
  keepSelectedOnTop,
}: IProps) => {
  if (!rows || rows.length === 0) {
    return (
      <TB>
        <TableRow>
          <TableCell dense={dense}>No data</TableCell>
        </TableRow>
      </TB>
    );
  }

  let tableRows = getTableBody({
    rows,
    headerCells,
    dense,
    rowsPerPage,
    tablePage,
    handleRowClick,
    hasExpandableRows,
  });

  if (keepSelectedOnTop) {
    tableRows = [
      ...tableRows.filter((row) => row.props.selected),
      ...tableRows.filter((row) => !row.props.selected),
    ];
  }

  return <TB>{tableRows}</TB>;
};

const getTableBody = ({
  rows,
  headerCells,
  dense,
  rowsPerPage,
  tablePage,
  handleRowClick,
  hasExpandableRows = false,
}: IProps) => {
  if (rowsPerPage !== undefined && tablePage !== undefined) {
    const handleClick = (id: string | undefined) => {
      if (handleRowClick && id !== undefined) {
        handleRowClick(id);
      }
    };

    const sliceStart = tablePage * rowsPerPage;
    // Because we hide the details-row only half of the rowsPerPage is visible. So we need to double it.
    const sliceEnd = tablePage * rowsPerPage + (hasExpandableRows ? rowsPerPage * 2 : rowsPerPage);

    return rows.slice(sliceStart, sliceEnd).map((row, index) => {
      const { cells, ...rest } = row;
      return (
        <TableRow
          key={`row${index}`}
          onClick={() => handleClick(row.id)}
          {...rest}
          tableHasExpandalbeRows={hasExpandableRows}>
          {cells.map((cell, index) => {
            const { value, ...rest } = cell;
            return (
              <React.Fragment key={index}>
                {headerCells && <HeaderLabelCell headerCells={headerCells} index={index} />}
                <TableCell dense={dense} {...rest}>
                  {rest.children || value}
                </TableCell>
              </React.Fragment>
            );
          })}
        </TableRow>
      );
    });
  }

  return rows.map((row, index) => {
    const { cells, ...rest } = row;
    return (
      <TableRow key={`row${index}`} {...rest}>
        {cells.map((cell, index) => {
          const { value, ...rest } = cell;
          return (
            <React.Fragment key={index}>
              {headerCells && <HeaderLabelCell headerCells={headerCells} index={index} />}
              <TableCell dense={dense} {...rest}>
                {value}
              </TableCell>
            </React.Fragment>
          );
        })}
      </TableRow>
    );
  });
};

const TB = styled(MuiTableBody)`
  &.MuiTableBody-root {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: flex;
      flex-direction: column;
    }
  }
`;
