import axios, { AxiosResponse } from 'axios';
import { Notification, FullNotification, CreateNotification } from 'typescript/types/Notification';

const API_APP_API_URL = process.env.REACT_APP_API_URL ?? '';

export const getNotifications = async (token: string) => {
  const response: AxiosResponse<{ notifications: Notification[] }> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${API_APP_API_URL}/notifications`,
  });

  return response;
};

export const getAllNotifications = async (token: string) => {
  const response: AxiosResponse<{ notifications: FullNotification[] }> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${API_APP_API_URL}/notifications/all`,
  });

  return response;
};

export const createNotification = async (token: string, message: CreateNotification) => {
  const response: AxiosResponse<never> = await axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${API_APP_API_URL}/notifications`,
    data: message,
  });

  return response;
};

export const updateNotification = async (
  token: string,
  id: string,
  message: CreateNotification,
) => {
  const response: AxiosResponse<never> = await axios({
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${API_APP_API_URL}/notifications/${id}`,
    data: message,
  });

  return response;
};

export const deleteNotification = async (token: string, id: string) => {
  const response: AxiosResponse<never> = await axios({
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${API_APP_API_URL}/notifications/${id}`,
  });

  return response;
};
