import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Icon, SmallTabs, Spinner } from '../../../../components';
import { FieldItem } from '../../../../components/UI/FieldItem/FieldItem';
import { useCompanyService, usePermissions } from '../../../../hooks';
import { IFieldItem } from '../../../../typescript/interfaces';
import { useModal } from '../../../../utils/context';
import { UserListPage } from '../../UserAdminPage/UserListPage/UserListPage';
import { ICompanyDTO } from './../../../../typescript/interfaces/ICompanyDTO';
import { EditCompany } from './EditCompany';
import { Locations } from './Locations';
import { Organizations } from './Organizations';

const tabs = (
  company: ICompanyDTO,
  tabTitle1: string,
  tabTitle2: string,
  tabTitle3: string,
  setOptionalComponent?: Dispatch<SetStateAction<ReactNode>>,
) => [
  {
    label: <p>{tabTitle1}</p>,
    content: <UserListPage company={company} setOptionalComponent={setOptionalComponent} />,
  },
  {
    label: <p>{tabTitle2}</p>,
    content: <Organizations company={company} setOptionalComponent={setOptionalComponent} />,
  },
  {
    label: <p>{tabTitle3}</p>,
    content: <Locations company={company} setOptionalComponent={setOptionalComponent} />,
  },
];

export const EditCompanyPage = () => {
  const { selectedCompany } = usePermissions();
  const { company, isCompanyLoading } = useCompanyService(selectedCompany?.id);
  const { t } = useTranslation();
  const { setModalContent } = useModal();
  const [optionalComponent, setOptionalComponent] = useState<null | ReactNode>(null);

  const fieldItem: IFieldItem = {
    title: 'Name',
    content: company?.name || '',
    name: 'selected_company',
    fieldVariant: 'textField',
  };

  const editCompany = (company: ICompanyDTO) => {
    setModalContent({
      title: `${t('admin.company.editCompany')} ${company.name}`,
      content: <EditCompany company={company} />,
      props: {
        width: '800px',
      },
    });
  };

  return isCompanyLoading ? (
    <SpinnerContainer>
      <Spinner size={60} />
    </SpinnerContainer>
  ) : (
    <Container>
      <CompanyDetails>
        <SelectedCompany fieldItem={fieldItem} />
        {company && (
          <Button
            type="button"
            variant="tertiary"
            size="small"
            onClick={(e) => editCompany(company)}
            tooltip={`${t('admin.company.editCompany')}`}>
            <Icon icon="edit" />
          </Button>
        )}
      </CompanyDetails>

      <TabsContainer>
        <SmallTabs
          tabs={tabs(
            company || ({} as ICompanyDTO),
            t('admin.company.users.tabTitle'),
            t('admin.company.organization.tabTitle'),
            t('admin.company.locations.tabTitle'),
            setOptionalComponent,
          )}
          optionalComponent={optionalComponent}
        />
      </TabsContainer>
    </Container>
  );
};

const Container = styled.div``;

const TabsContainer = styled.div``;

const CompanyDetails = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  display: flex;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacings.xxlarge};
`;

const SelectedCompany = styled(FieldItem)`
  border-top: 0;
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacings.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  justify-content: space-between;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
