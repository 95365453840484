import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { useTokenQuery } from '.';
import { Pages } from '../typescript/enums';
import { ITableData } from '../typescript/interfaces/ITableData';
import { Page } from '../typescript/types';
import { getCommunicationLog } from '../utils';

const API_APP_API_URL = process.env.REACT_APP_API_URL ?? '';

const request = async (
  wonum: string,
  orgid: string,
  companyId: string,
  searchText: 'PA-WAPPR' | 'PA-COMP',
  token: string,
) => {
  const data: AxiosResponse<ITableData> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [getCommunicationLog],
    url: `${API_APP_API_URL}/company/${companyId}/worklogs/wonumber/${wonum}/orgid/${orgid}/searchtext/${searchText}`,
  });
  return data;
};

interface IWorkOrderHistory {
  wonum: string;
  orgid: string;
  companyId: string;
  currentPage: Page;
  fetchDate?: string;
}

export const useGetCommunicationLog = ({
  wonum,
  orgid,
  companyId,
  currentPage,
  fetchDate,
}: IWorkOrderHistory) => {
  const { data: token } = useTokenQuery();
  const searchText = currentPage === Pages.PROPOSED ? 'PA-WAPPR' : 'PA-COMP';

  return useQuery<AxiosResponse<ITableData> | null, Error>(
    ['workorder-history', wonum, orgid, searchText, fetchDate],
    () => request(wonum, orgid, companyId, searchText, token as string),
    {
      enabled: wonum.length !== 0 && orgid.length !== 0,
      refetchOnWindowFocus: false,
    },
  );
};
