import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { Providers } from './components';
import { setupBrowserMock } from './mocks/browser';

setupBrowserMock();

ReactDOM.render(
  <BrowserRouter>
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>,
  document.getElementById('root'),
);
