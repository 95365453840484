import { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

import { FieldChange } from '../../typescript/types';
import { FieldBase } from '../FieldBase/FieldBase';
import { Spinner } from '../Spinner/Spinner';

interface BaseProps {
  name: string;
  warning?: boolean;
}

interface IControlledProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>, BaseProps {
  value: string;
  onChange: (event: FieldChange) => void;
}

interface IUncontrolledProps extends HTMLAttributes<HTMLInputElement>, BaseProps {
  value?: string;
}

type InputProps = (IControlledProps | IUncontrolledProps) & {
  disabled?: boolean;
  loading?: boolean;
  type?: string;
};

export const Input = ({
  value,
  onChange,
  name,
  loading = false,
  warning = false,
  disabled = false,
  ...rest
}: InputProps) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    if (value !== undefined && value !== controlledValue) {
      setControlledValue(value);
    }
  }, [controlledValue, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setControlledValue(newValue);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <InputContainer>
      <StyledInput
        value={onChange ? controlledValue : undefined}
        defaultValue={onChange ? rest.defaultValue : value}
        name={name}
        onChange={onChange ? handleChange : undefined}
        $warning={warning}
        disabled={disabled}
        {...rest}
      />

      {loading && (
        <LoadingIndicator>
          <Spinner size="24px" />
        </LoadingIndicator>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input<{ $warning?: boolean; disabled: boolean }>`
  font-family: ${({ theme }) => theme.fonts.family};
  ${FieldBase}

  ${({ $warning, theme }) =>
    $warning &&
    `
    border: 1px solid ${theme.colors.border.fieldError};
    ::placeholder {
      color: ${theme.colors.text.error};
      opacity: 1;
    };
    :focus {
      outline: 1px solid ${theme.colors.border.fieldError};
    }
  `}  
  
  ${({ disabled, theme }) =>
    disabled &&
    `
    border: 1px solid ${theme.colors.border.default};
    ::placeholder {
      color: ${theme.colors.text.disabled};
      opacity: 1;
    };

    cursor: default;
  `}
`;

const LoadingIndicator = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
