import { useState } from 'react';
import { useQuery } from 'react-query';
import { IHistoryDTO } from 'typescript/interfaces';

import { getSystemHistory as getSystemHistoryAPI } from '../api';
import { getFormattedDate } from '../utils';
import { usePermissions } from './usePermissions';
import usePrevious from './usePrevious';
import { useTokenQuery } from './useTokenQuery';

export const pageSize = 250;

const transformWorkorderHistory = (data: IHistoryDTO[]) => {
  return data.map((history) => {
    const { created, ...rest } = history;
    const localeDate = getFormattedDate(created);

    return {
      ...rest,
      created: localeDate,
    } as IHistoryDTO;
  });
};

export const useGetSystemHistory = () => {
  const { data: token } = useTokenQuery();
  const { selectedCompany } = usePermissions();
  const companyId = selectedCompany?.id ?? '';
  const [skip, setSkip] = useState(0);
  const [fetchedData, setFetchedData] = useState<IHistoryDTO[]>([] as IHistoryDTO[]);
  const previousFetchedData = usePrevious(fetchedData);
  const previousCompanyId = usePrevious(selectedCompany?.id);

  const setNextPage = () => {
    setSkip(skip + pageSize);
  };

  const history = useQuery(
    ['system-history', companyId, skip],
    async () => {
      const res = await getSystemHistoryAPI(token as string, companyId, pageSize, skip);
      const previousRes =
        previousCompanyId === selectedCompany?.id ? previousFetchedData ?? [] : [];

      const currentRes = [...previousRes];
      res.forEach((x) => {
        if (!currentRes.find((y) => y.id === x.id)) {
          currentRes.push(x);
        }
      });

      setFetchedData(currentRes);

      return currentRes;
    },
    {
      select: (data) => {
        if (!data) {
          return data;
        }
        return transformWorkorderHistory(data);
      },
      cacheTime: 0, // We disable cahche for this query because invalidation is not working
    },
  );

  return { setNextPage, history };
};
