import { t } from 'i18next';
import { useMemo, useState } from 'react';

import { Filter, Table } from '../../../../components';
import { filterTableRows } from '../../../../utils';
import { AddAgreementButton } from './AddAgreementButton';
import { getTableData } from './getOrganizationTableData';
import { IOrganizationGroup } from './Organizations';

interface IProps {
  group: IOrganizationGroup;
  companyId: string;
}

export const OrganizationGroup = ({ group, companyId }: IProps) => {
  // Filter
  const inputContainerTooltip = `Filter agreements based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".`;
  const inputPlaceHolder = t('admin.company.organization.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const data = getTableData(companyId, group.organization, group.agreements);

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  return (
    <>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
        optionalHeaderComponent={
          <AddAgreementButton companyId={companyId} org={group.organization} />
        }
      />
      <Table tableData={tableData} enableSorting={true} defaultSortProperty="externalId" />
    </>
  );
};
