import { IFieldItem } from '../../../typescript/interfaces';
import { WorkOrder } from '../../../typescript/types';
import { getPluspcustpriceest, getStatusText } from '../../../utils';

export const getProposedWorkFields = (workOrder: WorkOrder): IFieldItem[] => {
  const { pluspestremark, pluspeststat, createdate } = getPluspcustpriceest(workOrder);
  const status = getStatusText(pluspeststat);
  return [
    {
      title: 'Work Order',
      content: workOrder.wonum,
      name: 'wonum',
    },
    {
      title: 'Description',
      content: `${workOrder.description ?? ''}
      ${workOrder.description_longdescription ?? ''}`,
      name: 'description',
      boldContent: false,
      style: { gridColumn: 'span 2', maxHeight: '167px', overflowY: 'scroll' },
    },
    {
      title: 'Quote Remark',
      content: pluspestremark,
      name: 'pluspestremark',
    },
    {
      title: 'Location',
      content: workOrder.location_description,
      name: 'location_description',
    },
    {
      title: 'Customer',
      content: workOrder.pluspcustomer_name,
      name: 'pluspcustomer_name',
    },
    {
      title: 'Service',
      content: workOrder.commodity,
      name: 'commodity',
    },
    {
      title: 'Reported By',
      content: workOrder.reportedby,
      name: 'reportedby',
    },
    {
      title: 'Report Date',
      content: workOrder.reportdate,
      name: 'reportdate',
    },
    {
      title: 'Quote Date',
      content: createdate ?? '',
      name: 'createdate',
    },
    {
      title: 'Agreement',
      content: workOrder.pluspagreement,
      name: 'pluspagreement',
    },
    {
      title: 'Current Status',
      content: status,
      name: 'pluspeststat',
      status,
    },
  ];
};
