import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Loader } from '..';
import { updateCompletedWorkOrder } from '../../api/completedWork';
import { useCompletedWorkOrderQuery, useSuccess, useTokenQuery } from '../../hooks';
import { ErrorPage, InvalidPage } from '../../pages/';
import { Pages } from '../../typescript/enums';
import {
  ICompletedWorkUpdateableFields,
  IDetailView,
  IRadioButton,
  IWarning,
} from '../../typescript/interfaces';
import { IUpdateCompletedWorkOrder } from '../../typescript/interfaces/IUpdateCompletedWorkOrder';
import { Pluspnextbillstat } from '../../typescript/types';
import { PLUSPNEXTBILLSTAT } from '../../typescript/types/Pluspnextbillstat';
import { AdvancedLayoutGrid } from './AdvancedLayoutGrid/AdvancedLayoutGrid';
import { getDetailViewModel } from './getDetailViewModel';
import { getMappedFormFields } from './getMappedFormFields';
import { Form } from './styles';

export const CompletedWorkDetailedView = () => {
  const fields = useMemo(() => {
    return {} as ICompletedWorkUpdateableFields;
  }, []);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<ICompletedWorkUpdateableFields>(fields);
  const [detailViewModel, setDetailViewModel] = useState<IDetailView | null>(null);
  const [radioButtons, setRadioButtons] = useState<IRadioButton[] | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const selectedWorkOrder = parseInt(params.id ?? '0');
  const org = params.org ?? '';
  const companyId = params.companyId ?? '';
  const [setSuccess] = useSuccess();

  const {
    data: workOrder,
    isLoading: workOrderIsLoading,
    isError: workOrderIsError,
    error: workOrderError,
  } = useCompletedWorkOrderQuery(selectedWorkOrder, org, companyId);

  const isNotFound = workOrderIsError && workOrderError.response?.status === 404;

  const {
    mutate,
    isLoading: updateIsLoading,
    isError: updateIsError,
    error: updateError,
  } = useMutation(updateCompletedWorkOrder);

  const { data: token, isLoading: tokenIsLoading } = useTokenQuery();
  const { user } = useAuth0();

  const viewModel = useMemo(() => {
    if (!detailViewModel) return null;

    const { pluspnextbillstat, comment } = fieldsToUpdate;
    const warning: IWarning = {
      hasWarning: pluspnextbillstat === PLUSPNEXTBILLSTAT.REJECT && !comment,
      message: 'Add a comment',
    };

    let { formFields } = detailViewModel;
    let commentIndex = formFields.findIndex((field) => field.name === 'comment');
    if (commentIndex) {
      formFields[commentIndex].warning = warning.hasWarning;
    }

    const mappedFormFields = getMappedFormFields(formFields, fieldsToUpdate);

    return {
      ...detailViewModel,
      formFields: mappedFormFields,
      warning,
      radioButtons: radioButtons ?? detailViewModel.radioButtons,
    };
  }, [detailViewModel, fieldsToUpdate, radioButtons]);

  useEffect(() => {
    let isMounted = true;

    if (!workOrder) return;
    setFieldsToUpdate({
      ...fields,
      pluspnextbillstat: '',
      pluspcostcenter: workOrder?.pluspcostcenter,
      pluspcustponum: workOrder?.pluspcustponum,
    });

    return () => {
      isMounted = false;
    };
  }, [fields, workOrder]);

  useEffect(() => {
    let isMounted = true;

    if (!workOrder) {
      return;
    }
    setDetailViewModel(getDetailViewModel(workOrder, Pages.COMPLETED, companyId));

    return () => {
      isMounted = false;
    };
  }, [workOrder]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const updatedCompletedWorkOrder: IUpdateCompletedWorkOrder = {
      companyId,
      id: selectedWorkOrder,
      org,
      fields: fieldsToUpdate,
      token,
      user,
    };

    mutate(updatedCompletedWorkOrder, {
      onSuccess: () => {
        setFieldsToUpdate({} as ICompletedWorkUpdateableFields);
        setSuccess('Completed Work Order updated successfully');
        navigate(`/company/${companyId}/completed-work`, { state: Pages.COMPLETED });
        queryClient.resetQueries(['completed-workorders']);
      },
    });
  };

  const handleReset = (event: React.FormEvent) => {
    event.preventDefault();
    setFieldsToUpdate({} as ICompletedWorkUpdateableFields);
    navigate(`/company/${companyId}/completed-work`, { state: Pages.COMPLETED });
  };

  const getPluspnextbillstat = (val: string): Pluspnextbillstat => {
    return Object.values(PLUSPNEXTBILLSTAT).includes(val) ? (val as Pluspnextbillstat) : '';
  };

  const updateRadioButtons = (value: string) => {
    if (!detailViewModel) return;

    const { radioButtons } = detailViewModel;
    const updatedRadioButtons: IRadioButton[] = radioButtons.map((radioButton: IRadioButton) => {
      return { ...radioButton, checked: radioButton.value === value };
    });

    setRadioButtons(updatedRadioButtons);
  };

  const handleChange = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (!event) return;

    let { name, value } = event.target as HTMLInputElement;

    if (name === 'pluspnextbillstat') {
      value = getPluspnextbillstat(value);
      updateRadioButtons(value);
    }

    setFieldsToUpdate({
      ...fieldsToUpdate,
      [name]: value,
    });
  };

  if (workOrderIsLoading || updateIsLoading || tokenIsLoading) {
    return <Loader></Loader>;
  } else if (isNotFound) {
    return (
      <InvalidPage
        header="Workorder could not be found"
        text="Your workorder might already have been processed"
        link={<Link to={`/company/${companyId}/completed-work`}>Go back</Link>}
      />
    );
  } else if (workOrderIsError) {
    return <ErrorPage error={workOrderError} />;
  } else if (updateIsError) {
    return <ErrorPage error={updateError} />;
  } else if (!workOrder) {
    return <ErrorPage error={'No workorder found'} />;
  } else {
    return (
      <Form onSubmit={handleSubmit} onReset={handleReset} onChange={handleChange}>
        <AdvancedLayoutGrid viewModel={viewModel} />
      </Form>
    );
  }
};
