export const downloadHelper = (obj: Blob, fileName: string): void => {
  if (obj) {
    const url = window.URL.createObjectURL(obj);
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', `${fileName ?? 'file'}`);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } else {
    throw new Error("Can't download file");
  }
};
