import styled from 'styled-components';

import { IFieldItem } from '../../../typescript/interfaces';
import { FieldChange } from '../../../typescript/types';
import { GridItem } from '../../DetailView/Griditem';
import { Input } from '../../Input/Input';
import { FieldTitle } from './FieldTitle';

interface IProps {
  fieldItem: IFieldItem;
  handleChange?: (event: FieldChange) => void;
}

export const InputField = ({ fieldItem, handleChange, ...rest }: IProps) => {
  const { title, content, placeholder, name, maxLength } = fieldItem;
  const passThroughProps = {
    maxLength,
    warning: fieldItem.warning ?? false,
  };

  const changeHandler = (event: FieldChange) => {
    if (handleChange) {
      handleChange(event);
    }
  };
  const Title = () => (title ? <FieldTitle title={title} /> : null);

  return (
    <Wrapper className="fieldItem" {...rest}>
      <Title />
      <Input
        value={content}
        onChange={changeHandler}
        name={name ?? 'input-undefined'}
        placeholder={placeholder ? `${placeholder}:` : ''}
        {...passThroughProps}
      />
      {maxLength && (
        <MaxLengthMessageContainer>{maxLength} characters max</MaxLengthMessageContainer>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const MaxLengthMessageContainer = styled.div`
  color: gray;
  font-size: 0.8rem;
  text-align: right;
  margin-top: -2px;
`;
