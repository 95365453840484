import { ITableRow } from '../../typescript/interfaces';
import { ResourceTypes, WorkOrder } from '../../typescript/types';
import { addThousandSeperator } from '../../utils';
import { cell } from '../UI';
import { getFillerRows } from './getFillerRows';

export const getFixedPriceRow = (workOrder: WorkOrder): ITableRow[] => {
  const {
    pluspwopricetotals: { wospecialprice },
  } = workOrder;

  const tableRow: ITableRow = {
    cells: [
      cell({ value: ResourceTypes.FixedPrice }),
      cell({ value: '' }),
      cell({ value: '1' }),
      cell(
        { value: wospecialprice ? addThousandSeperator(wospecialprice) : 'Missing cost' },
        { align: 'right' },
      ),
      cell(
        { value: wospecialprice ? addThousandSeperator(wospecialprice) : 'Missing cost' },
        { align: 'right' },
      ),
    ],
  };

  return [tableRow, ...getFillerRows(1)];
};
