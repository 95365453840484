import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

export const GridBaseStyle = () =>
  css`
    display: grid;
    grid-template-columns: 4fr 16fr;
    gap: 1rem;
    padding-top: 0;
  `;

export const FieldBaseStyle = () => css`
  padding: ${({ theme }) => theme.spacings.xsmall};
  overflow: hidden;
`;

export const AdvancedLayoutGrid = styled.div`
  ${GridBaseStyle}
`;

export const SimpleLayoutGrid = styled.div`
  ${GridBaseStyle}
  grid-template-rows: repeat(8, 32px);
`;

export const GridCol1 = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 1rem;
`;

export const GridCol2 = styled(GridCol1)`
  grid-template-columns: repeat(12, 1fr);
`;

export const GridItem = styled.div<{ rowSpan?: number; columnSpan?: number }>`
  display: flex;

  ${({ columnSpan }) => (columnSpan ? `grid-column: span ${columnSpan};` : '')}
  ${({ rowSpan }) => (rowSpan ? `grid-row: span ${rowSpan};` : '')}

  > div {
    flex: 1;
  }
`;

export const TextWrapper = styled.div`
  ${FieldBaseStyle}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

export const InputWrapper = styled(TextWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const Title = styled.p`
  white-space: nowrap;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.text.label};
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

interface IContent extends HTMLAttributes<HTMLParagraphElement> {
  scrollable?: boolean;
  developing?: boolean;
}

export const Content = styled.p<IContent>`
  ${({ developing }) =>
    developing &&
    `text-decoration: line-through;
     text-decoration-color: red;
     text-decoration-thickness: 5px;
  `}
  ${({ scrollable }) =>
    !scrollable &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
    ${({ scrollable }) =>
    scrollable &&
    `overflow-y: auto;
    padding-right: 0.5rem;;
    scrollbar-color: #999 #f2f2f2;
    scrollbar-width: thin;

    white-space: pre-wrap;

    &::-webkit-scrollbar {
      width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track { 
    background: #f2f2f2;
    border-radius: 6px;
  }
  ;`};
`;

export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.7fr 0.8fr;
  grid-template-rows: 1.4fr repeat(5, 1fr);
  //first row
  div:nth-child(-n + 4) {
    background-color: #a3c2c2;
  }
  //2
  div:nth-child(n + 5):nth-child(-n + 8) {
    background-color: ${({ theme }) => theme.colors.background.disabled};
  }
  //4
  div:nth-child(n + 13):nth-child(-n + 16) {
    background-color: ${({ theme }) => theme.colors.background.disabled};
  }
  //6
  div:nth-child(n + 21):nth-child(-n + 24) {
    background-color: ${({ theme }) => theme.colors.background.disabled};
  }
`;

export const TableItem = styled.div<{
  rowSpan: number;
  columnSpan: number;
}>`
  padding: 8px;
  width: 100%;
  align-self: stretch;
  grid-column: ${(props) => `span ${props.columnSpan}`};
  grid-row: ${(props) => `span ${props.rowSpan}`};
`;

export const SelectWrapper = styled.div`
  ${FieldBaseStyle}
  display: flex;
  border-color: ${({ theme }) => theme.colors.border.cta};
  height: 34px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
`;

export const Select = styled.select`
  height: 32px;
  width: 100%;
  border: none;
  font-size: 16px;
  margin-left: 16px;
  margin-top: -8px;
  outline: none;
`;

export const AttachementWrapper = styled.div`
  ${FieldBaseStyle}
  border-color: ${({ theme }) => theme.colors.border.cta};
  background-color: var(--attachement-bg);
  height: 100%;
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-end;
  margin: ${({ theme }) => theme.spacings.xlarge} 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const Attachement = styled.a`
  /* all: unset; */
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
`;
