import i18n from 'i18next';

import { Checkbox } from '../../../../../components';
import {
  IAgreementDTO,
  ITableData,
  ITableHeaderCell,
  ITableRow,
} from '../../../../../typescript/interfaces';

export const getHeaderCells = (
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
): ITableHeaderCell[] => {
  const { t } = i18n;
  return [
    {
      id: 'select',
      label: <Checkbox value="selectAll" checked={isAllSelected} onClick={handleIsAllSelected} />,
      sx: { width: '30px' },
      sortable: false,
    },
    {
      id: 'externalId',
      label: t('admin.user.agreements.externalId'),
    },
  ];
};

export const getRows = (
  userAgreements: IAgreementDTO[],
  selectableAgreements: IAgreementDTO[],
  handleAgreementClick: (checked: boolean, value?: string) => void,
): ITableRow[] => {
  return selectableAgreements.map((agreement: IAgreementDTO) => {
    const isChecked = userAgreements.some((userAgreement) => userAgreement.id === agreement.id);

    return {
      id: agreement.id,
      hover: false,
      cells: [
        {
          id: 'id',
          value: (
            <Checkbox value={agreement.id} checked={isChecked} onClick={handleAgreementClick} />
          ),
          sx: { width: '30px' },
        },
        {
          id: 'externalId',
          value: agreement.externalId,
        },
      ],
      selected: isChecked,
    };
  });
};

export const getTableData = (
  userAgreements: IAgreementDTO[],
  selectableAgreements: IAgreementDTO[],
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
  handleAgreementClick: (checked: boolean, value?: string) => void,
): ITableData => {
  return {
    headerCells: getHeaderCells(isAllSelected, handleIsAllSelected),
    rows: getRows(userAgreements, selectableAgreements, handleAgreementClick),
  };
};
