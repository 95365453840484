import styled from 'styled-components';

import { Heading } from '../../components/UI';
import { getErrorMessage } from '../../utils';

interface IProps {
  error: unknown;
  heading?: string;
}

export const ErrorPage = ({ error, heading = 'Looks like an error occured' }: IProps) => {
  const message = getErrorMessage(error);

  return (
    <Container>
      <Heading>{heading}</Heading>
      <Text>{message}</Text>
    </Container>
  );
};

export const Text = styled.p`
  font-size: 2rem;
  line-height: 2.2rem;
`;

export const Container = styled.div`
  color: #424242;
  display: flex;
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
