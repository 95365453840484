import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface IProps {
  path: string;
  disabled?: boolean;
  isActive?: boolean;
  children: React.ReactNode;
  navigateOptions?: NavigateOptions;
}

export const Link = ({ path, isActive, disabled = false, children, navigateOptions }: IProps) => {
  const location = useLocation();
  const active = isActive !== undefined ? isActive : location.pathname.includes(path);
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return;
    navigate(`/${path}/`, navigateOptions);
  };

  return (
    <Container onClick={handleClick} $isActive={active} $disabled={disabled}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ $isActive: boolean; $disabled: boolean }>`
  cursor: ${({ $isActive, $disabled }) => ($isActive || $disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      transition: ${({ theme }) => theme.transitions.fast};
      fill: ${({ theme, $isActive }) =>
        $isActive ? theme.colors.background.cta : theme.colors.text.disabled};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme, $disabled }) => ($disabled ? '' : theme.colors.background.cta)};
      }
    }
  }
`;
