import { AxiosResponse } from 'axios';
import { UseQueryResult } from 'react-query/types/react/types';
import styled from 'styled-components';

import { Spinner } from '../..';
import { usePermissions } from '../../../hooks';
import { WorkOrder } from '../../../typescript/types';

interface IProps {
  isSelected: boolean;
  query: (companyId: string) => UseQueryResult<AxiosResponse<WorkOrder[]>, unknown>;
}

export const TabIcon = ({ isSelected, query }: IProps) => {
  const { selectedCompany } = usePermissions();
  const companyId = selectedCompany?.id || '';
  const { data: result, isLoading, isError } = query(companyId);
  const numberOfWorkorders = result && !isError ? result.data.length.toString() : '0';

  return (
    <Circle isSelected={isSelected}>
      {isLoading ? (
        <Spinner size={20} variantType={isSelected ? 'onPrimary' : 'default'} />
      ) : (
        <div className="numberWrapper">{numberOfWorkorders}</div>
      )}
    </Circle>
  );
};

interface ICircle {
  isSelected: boolean;
}

export const Circle = styled.span<ICircle>`
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 1rem;
  border: 1px solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.border.cta : theme.colors.text.disabled};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.background.primary : 'none'};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.background.onPrimary : theme.colors.text.disabled};
  line-height: 0;

  .numberWrapper {
    position: relative;
    top: 1px;
  }
`;
