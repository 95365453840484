import { css } from 'styled-components';

export const FieldBase = () => css`
  background: ${({ theme }) => theme.colors.background.body};
  border-color: ${({ theme }) => theme.colors.border.field};
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.colors.border.cta};
  cursor: text;
  font-size: ${({ theme }) => theme.fonts.size.body};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacings.small};
  text-overflow: ellipsis;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text.field};
    font-size: ${({ theme }) => theme.fonts.size.small};
  }
`;
