import { IServiceDTO, IUserDTO } from '../../../../../typescript/interfaces';

export const updateUserServices = (
  userServices: IServiceDTO[],
  selectableServices: IServiceDTO[],
  id: string,
  checked: boolean,
): IServiceDTO[] => {
  const service = selectableServices.find((service: IServiceDTO) => service.id === id);

  let updatedServices = userServices;

  // if there is a service and it is checked, add it to the users assigned services
  if (service && checked) {
    updatedServices = [...updatedServices, service];
  }
  // if there is a service and it is not checked, remove it from the users assigned services
  if (service && !checked) {
    updatedServices = updatedServices.filter((serv: IServiceDTO) => serv.id !== service.id);
  }

  return updatedServices;
};
