import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { Loader } from '../../components';
import { useAppState, usePermissions, useUserService } from '../../hooks';
import { InvalidPage } from '../InvalidPage/InvalidPage';

export const MainPage = () => {
  const { t } = useTranslation();
  const { me } = useUserService();
  const {
    appState: { hasBeenOnOnboardingPage },
  } = useAppState();
  const {
    selectedCompany,
    permissionsContextIsLoading,
    hasPermissionsToViewProposedWorkorderForCompany,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewCompanyWorklog,
  } = usePermissions();
  const { isLoading: authIsLoading } = useAuth0();

  const NoPermissions = () => {
    const hasNoPermissionsText = `${t('hasNoPermissions.missingPermissions')} ${t(
      'hasNoPermissions.contactSupport',
    )}`;
    return <InvalidPage header={`${t('hasNoPermissions.header')}`} text={hasNoPermissionsText} />;
  };

  if (permissionsContextIsLoading || authIsLoading) {
    return <Loader />;
  }

  // If the user has not onboarded, redirect to welcome page
  if (me?.onboard === false) {
    return <Navigate to="/onboard" />;
  }

  // If the use har been onboarder but has no permissions, redirect to welcome page
  if (me?.onboard === true && hasBeenOnOnboardingPage) {
    return <Navigate to="/welcome" />;
  }

  // If user has read permissions on proposed work, redirect to proposed work
  if (hasPermissionsToViewProposedWorkorderForCompany) {
    return <Navigate to={`/company/${selectedCompany?.id}/proposed-work`} />;
  }

  // If user has read permissions on completed work, redirect to completed work
  if (hasPermissionsToViewCompletedWorkorderForCompany) {
    return <Navigate to={`/company/${selectedCompany?.id}/completed-work`} />;
  }

  // If user has permissions to read completed work orders, proposed work orders or company worklog, redirect to history
  if (
    hasPermissionsToViewCompanyWorklog ||
    hasPermissionsToViewCompletedWorkorderForCompany ||
    hasPermissionsToViewProposedWorkorderForCompany
  ) {
    return <Navigate to={`/company/${selectedCompany?.id}/history`} />;
  }

  return <NoPermissions />;
};
