import * as Tabs from '@radix-ui/react-tabs';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

interface TabObject {
  label: React.ReactNode;
  content: React.ReactNode;
}

interface IProps {
  tabs: TabObject | TabObject[];
  optionalComponent?: ReactNode;
  optionalActiveTab?: string;
}

export const SmallTabs = ({ tabs, optionalComponent, optionalActiveTab }: IProps) => {
  const [activeTab, setActiveTab] = useState<string>('0');
  const tabArr = !Array.isArray(tabs) ? [tabs] : tabs;

  const tabList = tabArr.map((tab, index) => (
    <TabTrigger key={index} value={index.toString()}>
      {tab.label}
    </TabTrigger>
  ));

  const tabContent = tabArr.map((tab, index) => {
    const { content, ...rest } = tab;
    return (
      <TabsContent key={index} value={index.toString()} {...rest}>
        {tab.content}
      </TabsContent>
    );
  });

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (optionalActiveTab) {
      setActiveTab(optionalActiveTab);
    }
  }, [optionalActiveTab]);

  return (
    <TabsRoot className="TabsRoot" value={activeTab} onValueChange={handleTabChange}>
      <TabsListContainer>
        <TabsList className="TabsList" aria-label="Tabs">
          {tabList}
        </TabsList>
        {optionalComponent && optionalComponent}
      </TabsListContainer>

      {tabContent}
    </TabsRoot>
  );
};

const TabsListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const TabsRoot = styled(Tabs.Root)`
  height: 100%;
  display: flex;
  flex-flow: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  }
`;
const TabsList = styled(Tabs.List)`
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  flex: 1;
`;
const TabTrigger = styled(Tabs.Trigger)`
  background: ${({ theme }) => theme.colors.background.body};
  border: 1px solid transparent;
  padding: ${({ theme }) => theme.spacings.medium} ${({ theme }) => theme.spacings.xlarge};
  border-top-left-radius: ${({ theme }) => theme.radius.default};
  border-top-right-radius: ${({ theme }) => theme.radius.default};
  font-size: ${({ theme }) => theme.fonts.size.preamble};
  font-family: ${({ theme }) => theme.fonts.family};
  position: relative;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.fast};

  &:focus {
    outline: none;
  }

  &[aria-selected='true'] {
    border-top-color: ${({ theme }) => theme.colors.border.default};
    border-left-color: ${({ theme }) => theme.colors.border.default};
    border-right-color: ${({ theme }) => theme.colors.border.default};
    cursor: default;
  }

  &[aria-selected='false'] {
    color: ${({ theme }) => theme.colors.text.navigation};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};

    &:hover {
      color: ${({ theme }) => theme.colors.text.body};
    }

    &::after {
      content: '';
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${({ theme }) => theme.colors.border.default};
      width: 100vw;
    }
  }

  @media screen and (max-width: 415px) {
    padding: ${({ theme }) => theme.spacings.small};
  }

  @media screen and (max-width: 331px) {
    font-size: ${({ theme }) => theme.fonts.size.body};
  }
`;

const TabsContent = styled(Tabs.Content)`
  display: flex;
  flex-flow: column;

  &[data-state='active'] {
    flex: 1;

    > div:first-child {
      height: 100%;
    }
  }

  &:focus {
    outline: none;
  }
`;
