import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IDetailView, IHistoryDTO } from '../../typescript/interfaces';
import { WorkOrder } from '../../typescript/types';
import { getHistoryTableRowDetailsViewModel } from './getHistoryTableRowDetailsViewModel';
import { HistoryTableRowDetailsView } from './HistoryTableRowDetailsView';

export interface IUpdatedWorkorder extends WorkOrder {
  type: 'completed' | 'proposed';
}

interface IProps {
  row: IHistoryDTO;
}

export const WorkOrderHistoryTableRowDetails = ({ row }: IProps) => {
  const [viewModel, setViewModel] = useState<Partial<IDetailView>>();
  const { t } = useTranslation();

  useEffect(() => {
    setViewModel(getHistoryTableRowDetailsViewModel(row));
  }, [row]);

  return viewModel ? (
    <HistoryTableRowDetailsView viewModel={viewModel} />
  ) : (
    <p>{t('history.workorder.noHistoryDetailsFound')}</p>
  );
};
