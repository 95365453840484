import { AxiosResponse } from 'axios';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';

import { Loader } from '..';
import { usePermissions } from '../../hooks';
import { ErrorPage } from '../../pages/ErrorPage/ErrorPage';
import { WorkOrder } from '../../typescript/types';
import { QuickResponseProvider } from '../../utils/context/QuickResponseContext';
import { EnhancedTable, PartialCsvExportConfig } from '../EnhancedTable/EnhancedTable';

interface IProps {
  getWorkOrdersQuery: (companyId?: string) => UseQueryResult<AxiosResponse<WorkOrder[]>, unknown>;
  csvExportConfig: PartialCsvExportConfig;
}

export const WorkOrders = ({ getWorkOrdersQuery, csvExportConfig }: IProps) => {
  const { selectedCompany } = usePermissions();
  const { data: result, isLoading, error } = getWorkOrdersQuery(selectedCompany?.id);
  const workOrders = result?.data;

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (workOrders && workOrders.length > 0) {
    return (
      <>
        <Container>
          <QuickResponseProvider>
            <EnhancedTable workOrders={workOrders} csvExportConfig={csvExportConfig} />
          </QuickResponseProvider>
        </Container>
      </>
    );
  }

  return (
    <Container>
      <NoWorkOrdersText>You have no more work orders! 🎉</NoWorkOrdersText>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const NoWorkOrdersText = styled.p`
  font-size: 3rem;
  color: #424242;
  text-align: center;
`;
