import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Clock = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm2.293 15.707a1 1 0 0 0 1.414-1.414L13 11.586V6a1 1 0 1 0-2 0v6a1 1 0 0 0 .293.707l3 3Z"
      fill="currentColor"
    />
  </Svg>
);
