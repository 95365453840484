import { IDetailView, IHistoryDTO } from 'typescript/interfaces';

import { getCompletedWorkDetailTable } from '../../components/DetailView/CompletedWork/getCompletedWorkDetailTable';
import { getCompletedWorkFields } from '../../components/DetailView/CompletedWork/getCompletedWorkFields';
import { getProposedWorkDetailTable } from '../../components/DetailView/ProposedWork/getProposedWorkDetailTable';
import { getProposedWorkFields } from '../../components/DetailView/ProposedWork/getProposedWorkFields';
import { Pages } from '../../typescript/enums';

export const getHistoryTableRowDetailsViewModel = (
  historyObj: IHistoryDTO,
): Partial<IDetailView> => {
  const { source, sourceData, updateData } = historyObj;

  if (!sourceData) {
    return {};
  }

  const page = source === 'Workorder_Completed' ? Pages.COMPLETED : Pages.PROPOSED;
  const fields =
    page === Pages.COMPLETED
      ? getCompletedWorkFields(sourceData)
      : getProposedWorkFields(sourceData);
  const tableData =
    page === Pages.COMPLETED
      ? getCompletedWorkDetailTable(sourceData, 6)
      : getProposedWorkDetailTable(sourceData, 6);
  const customerComment =
    updateData?.worklog[0]?.description_longdescription.split('Customer Comment:')[1]?.trim() ?? '';

  return {
    fields,
    tableData,
    customerComment,
  };
};
