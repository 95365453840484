import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import axios from 'axios';

import { IServiceDTO } from '../typescript/interfaces';
import { Method } from '../typescript/types';

const API_URL = process.env.REACT_APP_API_URL ?? '';

const getAxiosConfig = <T>(
  method: Method,
  token: string | GetTokenSilentlyVerboseResponse | undefined,
  path: string,
  data?: T,
) => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  url: `${API_URL}/${path}`,
  data,
});

interface IDTO {
  token: string | GetTokenSilentlyVerboseResponse | undefined;
}

export interface IUpdateService extends IDTO {
  service: IServiceDTO;
}

export const getServices = async (token: string): Promise<IServiceDTO[]> => {
  const response = await axios(getAxiosConfig('GET', token, 'services'));
  return response.data;
};

export const addService = async ({ token, service }: IUpdateService) => {
  const response = await axios(getAxiosConfig('POST', token, `services`, service));
  return response.data;
};

export const updateService = async ({ token, service }: IUpdateService) => {
  const response = await axios(getAxiosConfig('PUT', token, `services/${service.id}`, service));
  return response.data;
};
