import { IAgreementDTO } from '../../../../../typescript/interfaces';

export const updateUserAgreements = (
  currentUserAgreements: IAgreementDTO[],
  selectableAgreements: IAgreementDTO[],
  id: string,
  checked: boolean,
): IAgreementDTO[] => {
  const agreement = selectableAgreements.find((agreement: IAgreementDTO) => agreement.id === id);

  let updatedAgreements = [...currentUserAgreements];

  // if there is a agreement and it is checked, add it to the user agreementIds
  if (agreement && checked) {
    updatedAgreements = [...updatedAgreements, agreement];
  }

  // if there is a agreement and it is not checked, remove it from the user agreementIds
  if (agreement && !checked) {
    updatedAgreements = updatedAgreements.filter((agr: IAgreementDTO) => agr.id !== agreement?.id);
  }

  return updatedAgreements;
};
