import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableContainerProps,
} from '@mui/material';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Heading } from '..';
import { ITableData, ITableRow } from '../../../typescript/interfaces';
import { Order } from '../../../typescript/types';
import { getStringOrNumber } from '../../../utils';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';

export type TableSize = 'small' | 'medium';

interface IProps {
  tableData: ITableData;
  dense?: boolean;
  size?: TableSize;
  heading?: string;
  rowsPerPage?: number;
  tablePage?: number;
  handleRowClick?: (id: string) => void;
  hideLabelInCell?: boolean;
  enableSorting?: boolean;
  sortedRows?: ITableRow[];
  hasExpandableRows?: boolean;
  keepSelectedOnTop?: boolean;
  defaultSortProperty?: string;
  defaultOrder?: Order;
}

export const Table = ({
  tableData,
  dense = false,
  size,
  heading = '',
  rowsPerPage,
  tablePage,
  handleRowClick,
  hideLabelInCell = false,
  enableSorting,
  hasExpandableRows = false,
  keepSelectedOnTop = true,
  defaultSortProperty = '',
  defaultOrder = 'desc',
}: IProps) => {
  const { headerCells, rows, tableFooter } = tableData;

  const getLowerCase = (val: string | number) => {
    if (typeof val === 'string') {
      return val.toLowerCase();
    }

    return val;
  };

  // Sorting
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<string>(defaultSortProperty);

  const tableRows = useMemo(() => {
    if (!rows) {
      return [];
    }

    const sortedRows = [...rows].sort((a: ITableRow, b: ITableRow) => {
      const valA = getLowerCase(
        getStringOrNumber(a.cells.find((cell) => cell.id === orderBy)?.value),
      );
      const valB = getLowerCase(
        getStringOrNumber(b.cells.find((cell) => cell.id === orderBy)?.value),
      );

      if (valB < valA) {
        return -1;
      }
      if (valB > valA) {
        return 1;
      }
      return 0;
    });

    return order === 'asc' ? sortedRows : sortedRows.reverse();
  }, [order, orderBy, rows]);

  const onRequestSort = !enableSorting
    ? undefined
    : (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

  return (
    <Wrapper>
      <TableContainer $tableSize={size}>
        <Heading tag="h2" variant="preamble">
          {heading}
        </Heading>
        <MuiTable size={'small'} stickyHeader>
          <TableHead
            headerCells={headerCells}
            dense={dense}
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
          />
          <TableBody
            rows={tableRows}
            dense={dense}
            headerCells={hideLabelInCell ? undefined : headerCells}
            rowsPerPage={rowsPerPage}
            tablePage={tablePage}
            handleRowClick={handleRowClick}
            hasExpandableRows={hasExpandableRows}
            keepSelectedOnTop={keepSelectedOnTop}
          />
          {tableFooter && <TableFooter tableFooter={tableFooter} size={size} />}
        </MuiTable>
      </TableContainer>
    </Wrapper>
  );
};

interface ITableContainerProps extends TableContainerProps {
  $tableSize?: TableSize;
}

const TableContainer = styled(MuiTableContainer)<ITableContainerProps>`
  &.MuiTableContainer-root {
    h2 {
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
        display: none;
      }
    }

    table {
      thead {
        tr {
          th {
            font-size: ${({ theme, $tableSize }) =>
              $tableSize === 'small' ? theme.fonts.size.small : ''};
            padding: ${({ theme, $tableSize }) =>
              $tableSize === 'small' ? theme.spacings.small : ''};
            z-index: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: ${({ theme, $tableSize }) =>
            $tableSize === 'small' ? theme.fonts.size.small : ''};
          padding: ${({ theme, $tableSize }) =>
            $tableSize === 'small' ? theme.spacings.small : ''};
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    border-top: 1px solid ${({ theme }) => theme.colors.border.default};
    margin-top: ${({ theme }) => theme.spacings.medium};
  }
`;
