import * as React from 'react';

import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const DownChevron = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.293 6.293a1 1 0 0 0 0 1.414l9.859 9.859a1.2 1.2 0 0 0 1.697 0l9.858-9.859a1 1 0 0 0-1.414-1.414L12 15.586 2.707 6.293a1 1 0 0 0-1.414 0Z"
      fill="currentColor"
    />
  </Svg>
);
