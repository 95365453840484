import styled from 'styled-components';

import { IFieldItem } from '../../../typescript/interfaces';
import { FieldChange } from '../../../typescript/types';
import { GridItem } from '../../DetailView/Griditem';
import { Input } from '../../Input/Input';
import { FieldTitle } from './FieldTitle';

interface IProps {
  fieldItem: IFieldItem;
  handleChange?: (event: FieldChange) => void;
}

export const DateField = ({ fieldItem, handleChange, ...rest }: IProps) => {
  const { title, content, placeholder, name } = fieldItem;
  const passThroughProps = {
    warning: fieldItem.warning ?? false,
  };

  const changeHandler = (event: FieldChange) => {
    if (handleChange) {
      handleChange(event);
    }
  };
  const Title = () => (title ? <FieldTitle title={title} /> : null);

  return (
    <Wrapper className="fieldItem" {...rest}>
      <Title />
      <Input
        type="date"
        value={content}
        onChange={changeHandler}
        name={name ?? 'input-undefined'}
        placeholder={placeholder ? `${placeholder}:` : ''}
        {...passThroughProps}
      />
    </Wrapper>
  );
};

export const Wrapper = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;
