import { tryParseValue } from './tryParseValue';

const formatAndRoundNumber = (value: number) => {
  const formated = Intl.NumberFormat('sv-SE', { minimumFractionDigits: 2 }).format(value);
  return formated;
};

export const addThousandSeperator = (value: string | number): string => {
  switch (typeof value) {
    case 'string':
      const parsed = tryParseValue(value);
      return formatAndRoundNumber(parsed);
    case 'number':
      return formatAndRoundNumber(value);
    default:
      return '0';
  }
};
