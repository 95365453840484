import * as React from 'react';

import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Check = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.749 5.334a1 1 0 0 1 0 1.415L9.89 18.607a1.2 1.2 0 0 1-1.697 0L2.334 12.75a1 1 0 1 1 1.415-1.415l5.292 5.293L20.334 5.334a1 1 0 0 1 1.415 0Z"
      fill="currentColor"
    />
  </Svg>
);
