import { Navigate } from 'react-router-dom';

interface IProtectedRouteProps {
  hasPermissions: boolean;
  onboard?: boolean;
  children: JSX.Element;
}

export const ProtectedRoute = ({ hasPermissions, onboard, children }: IProtectedRouteProps) => {
  if (onboard === false) {
    return <Navigate to="/onboard" />;
  }

  if (!hasPermissions) {
    return <Navigate to="/" />;
  }

  return children;
};
