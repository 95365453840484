import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { WorkOrder } from 'typescript/types';

import { Loader } from '../../components/Loader/Loader';
import { AlertBox, Button } from '../../components/UI';
import { addThousandSeperator } from '../../utils';
import { useModal } from '../../utils/context';
import { IQuickResponseContext } from '../../utils/context/QuickResponseContext';

type ApprovalState = 'REVIEW' | 'LOADING' | 'COMPLETED';

interface IProps {
  quickResponseContext: IQuickResponseContext;
}

interface WorkOrderWithApproveStatus extends WorkOrder {
  successful?: boolean;
  errorMessage?: string;
}

export const QuickResponseApprove = ({
  quickResponseContext: { includedWorkOrders, approveAllWorkOrders },
}: IProps) => {
  const { closeModal } = useModal();

  const [currentState, setCurrentState] = useState<ApprovalState>('REVIEW');
  const [rows, setRows] = useState<WorkOrderWithApproveStatus[]>(includedWorkOrders);

  const handleApprove = async () => {
    setCurrentState('LOADING');

    const result = await approveAllWorkOrders();
    setRows((rows) =>
      rows.map((row) => ({
        ...row,
        ...(result[row.workorderid] ?? {}),
      })),
    );

    setCurrentState('COMPLETED');
  };

  const allWasSuccessful = rows.every((x) => x.successful === true);

  return (
    <OuterContainer>
      {currentState === 'LOADING' && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}

      <ConfirmationText>Do you want to approve all the work orders listed below?</ConfirmationText>

      <WorkOrderListBox>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Work order</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Est. Amout</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((x) => (
              <Fragment key={x.workorderid}>
                <StatusTableRow key={x.workorderid} $successful={x.successful}>
                  <TableCell>{x.wonum}</TableCell>
                  <TableCell>{x.description}</TableCell>
                  <TableCell>{addThousandSeperator(x.totalAmount)}</TableCell>
                </StatusTableRow>

                {x.successful === false && x.errorMessage && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <AlertBox severity="error">
                        Error while approving this work order, error was: {x.errorMessage}
                      </AlertBox>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </WorkOrderListBox>

      {currentState === 'COMPLETED' && (
        <AlertBox severity={allWasSuccessful ? 'info' : 'error'}>
          {allWasSuccessful
            ? 'All workorders were approved successfully'
            : 'We encountered some issues while approving your workorders. Workorders highlighted in red are not yet approved. Please try again later.'}
        </AlertBox>
      )}

      <ButtonContainer>
        {currentState !== 'COMPLETED' ? (
          <>
            <Button variant="primary" onClick={handleApprove} loading={currentState === 'LOADING'}>
              I Approve
            </Button>
            <Button variant="secondary" onClick={closeModal} disabled={currentState === 'LOADING'}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button variant="primary" onClick={closeModal}>
              Close
            </Button>
          </>
        )}
      </ButtonContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
`;

const ConfirmationText = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.h4};
  margin-top: ${({ theme }) => theme.spacings.small};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorkOrderListBox = styled.div`
  height: 500px;
  border: 1px solid ${({ theme }) => theme.colors.border.field};
  padding: ${({ theme }) => theme.spacings.xsmall};
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => theme.spacings.small};
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const StatusTableRow = styled((props) => <TableRow {...props} />)`
  background-color: ${({ $successful, theme }) =>
    $successful !== undefined
      ? $successful === true
        ? theme.colors.background.success
        : theme.colors.background.error
      : 'inherit'};
`;
