import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getUserByEmail } from '../api';
import { useDebouncedState } from './useDebouncedState';
import { useTokenQuery } from './useTokenQuery';

export const useSearchUser = (companyId: string, initialEmail?: string) => {
  const { data: token, isLoading: tokenIsLoading } = useTokenQuery();
  const { currentState: email, setState: setSearchEmail } = useDebouncedState({
    initialState: initialEmail ?? '',
    debounceTime: 500,
  });

  const emailPseudoValid = useMemo(
    () => !!email && email.length > 3 && email.indexOf('@') !== -1,
    [email],
  );

  const {
    data: userSearchResult,
    isLoading: userSearchLoading,
    error: userSearchError,
  } = useQuery(
    ['userByEmail', email],
    () => getUserByEmail(token!.toString(), email ?? '', companyId).catch(() => {}),
    {
      enabled: !!token && !tokenIsLoading && emailPseudoValid,
      retry: false,
    },
  );

  return {
    setSearchEmail,
    userSearchResult: emailPseudoValid ? userSearchResult : null,
    userSearchLoading,
    userSearchError,
  };
};
