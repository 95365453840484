import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useState, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Loader } from '..';
import { updateProposedWorkOrder } from '../../api/proposedWork';
import { useProposedWorkOrderQuery, usePermissions, useTokenQuery } from '../../hooks';
import { ErrorPage, InvalidPage } from '../../pages/';
import { Pages } from '../../typescript/enums';
import { IDetailView, IProposedWorkUpdateableFields, IWarning } from '../../typescript/interfaces';
import { IUpdateProposedWorkOrder } from '../../typescript/interfaces/IUpdateProposedWorkOrder';
import { Pluspcustpriceest, Pluspestat, PLUSPESTAT, WorkOrder } from '../../typescript/types';
import { useSuccess } from './../../hooks/useSuccess';
import { IRadioButton } from './../../typescript/interfaces/IRadioButton';
import { AdvancedLayoutGrid } from './AdvancedLayoutGrid/AdvancedLayoutGrid';
import { getDetailViewModel } from './getDetailViewModel';
import { getMappedFormFields } from './getMappedFormFields';
import { Form } from './styles';

export const getPluspcustpricest = (workOrder: WorkOrder): Pluspcustpriceest => {
  const plupCustPriceEst = workOrder.pluspcustpricest?.find(
    (pluspcustpriceest: Pluspcustpriceest) => {
      return pluspcustpriceest.pluspeststat === PLUSPESTAT.PENDING;
    },
  );

  return plupCustPriceEst || ({} as Pluspcustpriceest);
};

export const ProposedWorkDetailedView = () => {
  const fields = useMemo(() => {
    return {} as IProposedWorkUpdateableFields;
  }, []);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<IProposedWorkUpdateableFields>(fields);
  const [detailViewModel, setDetailViewModel] = useState<IDetailView | null>(null);
  const [radioButtons, setRadioButtons] = useState<IRadioButton[] | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id, org = '', companyId = '' } = useParams();
  const selectedWorkOrder = Number(id) ?? 0;
  const { selectedCompany } = usePermissions();
  const [setSuccess] = useSuccess();

  const {
    data: workOrder,
    isLoading: workOrderIsLoading,
    isError: workOrderIsError,
    error: workOrderError,
  } = useProposedWorkOrderQuery(selectedWorkOrder, org, selectedCompany?.id ?? '');

  const isNotFound = workOrderIsError && workOrderError.response?.status === 404;

  const {
    mutate,
    isLoading: updateIsLoading,
    isError: updateIsError,
    error: updateError,
  } = useMutation(updateProposedWorkOrder);

  const { data: token, isLoading: tokenIsLoading } = useTokenQuery();
  const { user } = useAuth0();

  const viewModel = useMemo(() => {
    if (!detailViewModel) return null;

    const { pluspeststat, comment } = fieldsToUpdate;
    const warning: IWarning = {
      hasWarning: pluspeststat === PLUSPESTAT.REJECTED && !comment,
      message: 'Add a comment',
    };

    let { formFields } = detailViewModel;
    let commentIndex = formFields.findIndex((field) => field.name === 'comment');
    if (commentIndex) {
      formFields[commentIndex].warning = warning.hasWarning;
    }

    const mappedFormFields = getMappedFormFields(formFields, fieldsToUpdate);

    return {
      ...detailViewModel,
      formFields: mappedFormFields,
      warning,
      radioButtons: radioButtons ?? detailViewModel.radioButtons,
    };
  }, [detailViewModel, fieldsToUpdate, radioButtons]);

  useEffect(() => {
    let isMounted = true;
    if (!workOrder) return;

    const { worklogid, pluspestremark } = getPluspcustpricest(workOrder);

    setFieldsToUpdate({
      ...fields,
      pluspcostcenter: workOrder?.pluspcostcenter,
      pluspcustponum: workOrder?.pluspcustponum,
      pluspestremark: pluspestremark,
      worklogid: worklogid ?? '',
      pluspeststat: '',
    });

    return () => {
      isMounted = false;
    };
  }, [fields, workOrder]);

  useEffect(() => {
    let isMounted = true;
    if (!workOrder) {
      return;
    }
    setDetailViewModel(getDetailViewModel(workOrder, Pages.PROPOSED, companyId));

    return () => {
      isMounted = false;
    };
  }, [workOrder]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const updatedProposedWorkOrder: IUpdateProposedWorkOrder = {
      companyId: selectedCompany?.id ?? '',
      id: selectedWorkOrder,
      org,
      fields: fieldsToUpdate,
      token,
      user,
    };

    mutate(updatedProposedWorkOrder, {
      onSuccess: () => {
        setFieldsToUpdate({} as IProposedWorkUpdateableFields);
        setSuccess('Proposed work order updated successfully');
        navigate(`/company/${companyId}/proposed-work`, { state: Pages.PROPOSED });
        queryClient.resetQueries(['proposed-workorders']);
      },
    });
  };

  const handleReset = (event: React.FormEvent) => {
    event.preventDefault();
    setFieldsToUpdate({} as IProposedWorkUpdateableFields);
    navigate(`/company/${companyId}/proposed-work`, { state: Pages.PROPOSED });
  };

  const getPluspeststat = (val: string): Pluspestat => {
    if (Object.values(PLUSPESTAT).includes(val)) {
      return val as Pluspestat;
    }

    return '';
  };

  const updateRadioButtons = (value: string) => {
    if (!detailViewModel) return;

    const { radioButtons } = detailViewModel;
    const updatedRadioButtons: IRadioButton[] = radioButtons.map((radioButton: IRadioButton) => {
      return { ...radioButton, checked: radioButton.value === value };
    });

    setRadioButtons(updatedRadioButtons);
  };

  const handleChange = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (!event) return;

    let { name, value } = event.target as HTMLInputElement;

    if (name === 'pluspeststat') {
      value = getPluspeststat(value);
      updateRadioButtons(value);
    }

    setFieldsToUpdate({
      ...fieldsToUpdate,
      [name]: value,
    });
  };

  if (workOrderIsLoading || updateIsLoading || tokenIsLoading) {
    return <Loader></Loader>;
  } else if (isNotFound) {
    return (
      <InvalidPage
        header="Workorder could not be found"
        text="Your workorder might already have been processed"
        link={<Link to={`/company/${companyId}/proposed-work`}>Go back</Link>}
      />
    );
  } else if (workOrderIsError) {
    return <ErrorPage error={workOrderError} />;
  } else if (updateIsError) {
    return <ErrorPage error={updateError} />;
  } else if (!workOrder) {
    return <ErrorPage error={'No workorder found'} />;
  } else {
    return (
      <Form onSubmit={handleSubmit} onReset={handleReset} onChange={handleChange}>
        <AdvancedLayoutGrid viewModel={viewModel} />
      </Form>
    );
  }
};
