import { useQuery } from 'react-query';

import { getNotifications } from '../api/notifications';
import { useTokenQuery } from './useTokenQuery';

export const useNotifications = () => {
  const { data: token } = useTokenQuery();

  return useQuery(
    ['notifications'],
    () => getNotifications(token as string).then((x) => x.data.notifications),
    {
      enabled: !!token,
      refetchInterval: 1800 * 1000,
    },
  );
};
