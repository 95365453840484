import styled from 'styled-components';

import { ITableFooter } from '../../../typescript/interfaces/';
import { TableCell, TableRow, TableSize } from './';

interface IProps {
  tableFooter: ITableFooter;
  colSpan?: number;
  size?: TableSize;
}

export const TableFooter = ({ tableFooter, colSpan = 5, size }: IProps) => {
  const { label, value } = tableFooter;
  return (
    <Footer $tableSize={size}>
      <TableRow>
        <TableCell colSpan={colSpan - 1}>{label}:</TableCell>
        <TableCell>{value}</TableCell>
      </TableRow>
    </Footer>
  );
};

const Footer = styled.tfoot<{ $tableSize?: TableSize }>`
  tr.MuiTableRow-root {
    background: ${({ theme }) => theme.colors.background.body};

    td {
      border-top: 1px solid ${({ theme }) => theme.colors.border.default};
      border-bottom: 0;
      text-align: right;
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      white-space: nowrap;
      font-size: ${({ theme, $tableSize }) =>
        $tableSize === 'small' ? theme.fonts.size.small : theme.fonts.size.preamble};

      &:nth-child(2) {
        padding-left: 0;
      }

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      td {
        border-top: none;

        &:nth-child(1) {
          flex: 1 1 0;
        }

        &:nth-child(2) {
          flex: 0 1 auto;
        }
      }
    }
  }
`;
