import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { FieldBase } from '../';
import { FieldChange } from '../../typescript/types';

export interface IProps extends Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  maxLength?: number;
  name?: string;
  onChange: (event: FieldChange) => void;
  warning?: boolean;
}

export const TextArea = ({ onChange, warning = false, ...props }: IProps) => {
  return <StyledTextArea onChange={onChange} warning={warning} {...props} />;
};

export interface IStyledTextArea extends Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  onChange: (event: FieldChange) => void;
  warning?: boolean;
}

const StyledTextArea = styled.textarea<IStyledTextArea>`
  all: unset;
  ${FieldBase}
  border-color: ${({ theme, warning }) =>
    warning ? theme.colors.border.fieldError : theme.colors.border.cta};
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: pre-line;
  background: ${({ theme, warning }) =>
    warning ? theme.colors.background.error : theme.colors.background.body};
  flex: 1;

  ::placeholder {
    font-weight: ${({ theme, warning }) =>
      warning ? theme.fonts.weight.bold : theme.fonts.weight.regular};
    opacity: 1;
    color: ${({ theme, warning }) =>
      warning ? theme.colors.text.onPrimary : theme.colors.text.field};
  }
`;
