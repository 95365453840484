import styled from 'styled-components';

import { Icon } from '../../../components/UI';

export const NonLinkHeaderIcon = styled(Icon)`
  cursor: pointer;

  path {
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme }) => theme.colors.text.disabled};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.background.cta};
    }
  }
`;
