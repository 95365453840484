import { ISpacings } from '../../../typescript/interfaces';

export const spacings: ISpacings = {
  xxsmall: '0.25rem',
  xsmall: '0.5rem',
  small: '0.75rem',
  medium: '1rem',
  large: '1.5rem',
  xlarge: '2rem',
  xxlarge: '3rem',
};
