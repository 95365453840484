import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, List, MenuItem, ListItemIcon, ListSubheader } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import { CompanySelect } from '../../components/UI/CompanySelect/CompanySelect';
import { usePermissions } from '../../hooks';
import { CustomerAdmin } from './AdminArea/CustomerAdmin';
import { History } from './AdminArea/History';
import { SystemAdminMenu } from './AdminArea/SystemAdminDropdown';
import { WorkOrder } from './AdminArea/WorkOrder';
import { HeaderLinkRenderProvider } from './Header';
import { UserAreaMenu } from './UserArea/UserArea';

export const MobileMenu = () => {
  const { hasPermissionToUpdateCompany } = usePermissions();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <MenuButtonContainer>
        <MenuIcon onClick={() => setOpen(true)} />
      </MenuButtonContainer>

      <HeaderLinkRenderProvider
        value={{
          renderDivider: (text) => <ListSubheader>{text}</ListSubheader>,
          renderItem: ({ icon, Link, text }) => (
            <MenuItem>
              <Link>
                <ListItemIcon>{icon}</ListItemIcon>
                {text}
              </Link>
            </MenuItem>
          ),
        }}>
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <Box width={250} onClick={handleClose}>
            <List>
              <MenuItem>
                <CompanySelectContainer
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <CompanySelect />
                </CompanySelectContainer>
              </MenuItem>

              <WorkOrder />
              <History />
              <CustomerAdmin />

              {hasPermissionToUpdateCompany && (
                <>
                  <ListSubheader>System admin area</ListSubheader>
                  <SystemAdminMenu withIcons={true} />
                </>
              )}

              <ListSubheader>User area</ListSubheader>
              <UserAreaMenu handleClose={handleClose} />
            </List>
          </Box>
        </Drawer>
      </HeaderLinkRenderProvider>
    </>
  );
};

const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  grid-row: 1;
  grid-column: 4;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`;

const CompanySelectContainer = styled.div`
  width: 100%;
`;
