import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAppState, usePermissions } from '../../../hooks';
import { Crumb, ICrumb } from './Crumb';

const getCrumbs = (paths: string[]): ICrumb[] => {
  const filteredPaths = paths.filter((path) => path !== '');

  const crumbs = filteredPaths.map((path, index) => {
    if (index === 0 || index === paths.length - 1) {
      return { pathName: path };
    }

    return { pathName: path, path: `/${paths.slice(0, index + 1).join('/')}` };
  });

  return crumbs;
};

const replacePathNameInCrumb = (
  crumbs: ICrumb[],
  crumbToReplace: string,
  pathName?: string,
): ICrumb[] => {
  const crumbIndex = crumbs.findIndex((crumb) => crumb.pathName?.includes(crumbToReplace)) + 1;

  if (!crumbs[crumbIndex]) {
    return crumbs;
  }

  crumbs[crumbIndex] = {
    pathName: pathName ?? '',
    path: crumbs[crumbIndex].path,
  };
  return crumbs;
};

export const Breadcrumbs = () => {
  const location = useLocation();
  const { selectedCompany } = usePermissions();
  const { appState } = useAppState();
  const { currentPage } = appState;
  const paths = location.pathname.split('/').filter((path) => path !== '');
  const hasWorkordersInPath = paths.includes('proposed-work') || paths.includes('completed-work');

  if (hasWorkordersInPath) {
    return null;
  }

  let crumbs = getCrumbs(paths);

  // Check if there is a company crumb and replace pathName in it.
  if (paths.includes('company')) {
    crumbs = replacePathNameInCrumb(crumbs, 'company', selectedCompany?.name ?? '');
  }

  // Check if there is a user crumb and replace pathName in it.
  if (paths.includes('users')) {
    crumbs = replacePathNameInCrumb(crumbs, 'users', appState[currentPage].selectedUser?.name);
  }

  // If we are on history page, just show "History".
  if (paths.includes('history')) {
    crumbs = [{ path: '', pathName: 'History' }];
  }

  return (
    <Container id="BreadCrumbs">
      <Wrapper>
        {crumbs.map(({ path, pathName }: ICrumb, index) => {
          return <Crumb path={path} pathName={pathName} key={index} />;
        })}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.border.default};
  padding: ${({ theme }) => theme.spacings.medium} ${({ theme }) => theme.spacings.xlarge};
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;
