import { Pages } from '../../typescript/enums';
import { IEnhancedTableData } from '../../typescript/interfaces';
import { Page, WorkOrder } from '../../typescript/types';
import { addThousandSeperator, getPluspcustpriceest } from '../../utils';
import { createTableRows } from './createTableRows';

/**
 * Returns an array of table rows based on the provided work orders and application type.
 * @param workOrders An array of WorkOrder objects.
 * @param application The type of application (Pages.PROPOSED or Pages.COMPLETED).
 * @returns An array of IEnhancedTableData objects representing the table rows.
 */
export const getTableRows = (workOrders: WorkOrder[], application: Page) => {
  let rows: IEnhancedTableData[] = [];
  if (application === Pages.PROPOSED) {
    rows = workOrders.map((workOrder: WorkOrder) => {
      const {
        wonum,
        description,
        description_longdescription,
        location_description,
        pluspcustponum,
        workorderid,
        pluspcostcenter,
        organization,
      } = workOrder;

      const { pluspestremark, pluspeststat } = getPluspcustpriceest(workOrder);

      // Work order, Description, Location, Quote remark, Est. Amount, Purchase order, Reference, Response
      return createTableRows(
        wonum,
        description.concat(' ', description_longdescription),
        location_description,
        pluspestremark,
        addThousandSeperator(workOrder.totalAmount),
        pluspcustponum,
        pluspcostcenter,
        pluspeststat,
        Number(workorderid),
        organization,
      );
    });
  }

  if (application === Pages.COMPLETED) {
    rows = workOrders.map((workOrder: WorkOrder) => {
      const {
        wonum,
        description = '',
        description_longdescription = '',
        location_description,
        remarkdesc = '',
        failureremark_description_longdescription = '',
        pluspcustponum,
        workorderid,
        pluspcostcenter,
        pluspnextbillstat,
        organization,
      } = workOrder;

      // Work order, Description, Location, Action reporting, Amount, Purchase order, Reference, Response
      return createTableRows(
        wonum,
        description.concat(' ', description_longdescription),
        location_description,
        remarkdesc.concat(' ', failureremark_description_longdescription),
        addThousandSeperator(workOrder.totalAmount),
        pluspcustponum,
        pluspcostcenter,
        pluspnextbillstat,
        Number(workorderid),
        organization,
      );
    });
  }

  return rows;
};
