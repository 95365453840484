import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Filter, Spinner, Table } from '../../../../../components';
import { useCompanyService } from '../../../../../hooks';
import { IAgreementDTO, IUserDTO } from '../../../../../typescript/interfaces';
import { filterTableRows } from '../../../../../utils';
import { getTableData } from './getAgreementTableData';
import { updateUserAgreements } from './updateUserAgreements';

interface IProps {
  user: IUserDTO;
  companyId: string;
  handleUpdateUser: (updatedUser: IUserDTO) => void;
}
export const AgreementsContent = ({ user, companyId, handleUpdateUser }: IProps) => {
  const { company, isCompanyLoading, error } = useCompanyService(companyId);
  const companyAgreements = company?.agreements ?? [];
  const selectableAgreements = getSelectableAgreements(companyAgreements);

  // Filter
  const inputContainerTooltip = `Filter agreements based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
      `;
  const inputPlaceHolder = t('admin.user.agreements.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const updateUser = (updatedAgreements: IAgreementDTO[]) => {
    handleUpdateUser({ ...user, assignedAgreements: updatedAgreements });
  };

  // "selectAll" checkbox
  const isAllSelected = user.assignedAgreements.length === selectableAgreements.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedAgreements = checked ? selectableAgreements : [];
    updateUser(updatedAgreements);
  };

  // "agreement" checkboxes
  const handleAgreementClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedAgreements = updateUserAgreements(
        user.assignedAgreements,
        selectableAgreements,
        value,
        checked,
      );
      updateUser(updatedAgreements);
    }
  };

  const data = getTableData(
    user.assignedAgreements,
    selectableAgreements,
    isAllSelected,
    handleIsAllSelected,
    handleAgreementClick,
  );

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  if (error) {
    return <p>{error.message}</p>;
  }

  if (isCompanyLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
      />
      <Table tableData={tableData} enableSorting={true} defaultSortProperty="externalId" />
    </>
  );
};

export const getSelectableAgreements = (companyAgreements: IAgreementDTO[]) => {
  if (!companyAgreements) {
    return [];
  }

  return companyAgreements.filter(({ externalId }) => {
    if (!externalId) {
      return false;
    }

    const [, agr] = externalId.split('_');
    return !!agr; // filter out agreements that are organizations
  });
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
