import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { getErrorMessage } from '../utils/getErrorMessage';

interface IErrorType {
  optionalMessage?: string;
  error: unknown;
}

interface IErrorValidationPropertyItem {
  PropertyName: string;
  ErrorMessage: string;
}

interface IErrorValidationType {
  message?: string;
  errors?: IErrorValidationPropertyItem[];
}

const getValidateSubErrorString = (errors: IErrorValidationPropertyItem[]) =>
  errors.reduce((acc, cur) => `${acc}\n${cur.ErrorMessage}`, '');

const getValidationErrorMessage = (error: IErrorValidationType) =>
  `${error.message}${error.errors ? getValidateSubErrorString(error.errors) : ''}`;

export const useError = () => {
  const [error, setError] = useState<IErrorType | undefined | unknown>();

  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (error) {
      if ((error as IErrorType).error) {
        if (((error as IErrorType).error as IErrorValidationType).message) {
          setErrorMessage(
            `${
              (error as IErrorType).optionalMessage
                ? `${(error as IErrorType).optionalMessage} `
                : ''
            }${getValidationErrorMessage((error as IErrorType).error as IErrorValidationType)}`,
          );
        } else {
          setErrorMessage(
            `${
              (error as IErrorType).optionalMessage
                ? `${(error as IErrorType).optionalMessage} `
                : ''
            } ${getErrorMessage((error as IErrorType).error)}`,
          );
        }
      } else {
        setErrorMessage(getErrorMessage(error));
      }
    }
  }, [error]);

  return [setError];
};
