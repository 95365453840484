import { SVGProps } from 'react';

import { IconTypes } from './icons/icons';

interface IProps extends SVGProps<SVGSVGElement> {
  icon: keyof typeof IconTypes;
}

/**
 * Renders an icon component based on the provided icon prop.
 * @param {string} icon - The name of the icon to render.
 * @param {Object} rest - SVGProps to pass to the SVG element.
 * @returns {JSX.Element} The rendered icon component.
 */
export const Icon = ({ icon, ...rest }: IProps) => {
  const IconType = IconTypes[icon];
  return <IconType className="icon" {...rest} />;
};
