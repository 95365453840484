import { CircularProgressProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import styled from 'styled-components';

const DefaultSpinner = styled(CircularProgress)`
  circle {
    stroke: ${({ theme }) => theme.colors.background.cta};
  }
`;

const OnPrimary = styled(CircularProgress)`
  circle {
    stroke: ${({ theme }) => theme.colors.background.onPrimary};
  }
`;
type VariantType = 'default' | 'onPrimary';

const SpinnerVariants = {
  default: DefaultSpinner,
  onPrimary: OnPrimary,
};

interface IProps extends CircularProgressProps {
  variantType?: VariantType;
}

export const Spinner = ({ variantType: variant = 'default', ...rest }: IProps) => {
  const Variant = SpinnerVariants[variant];
  return <Variant {...rest} />;
};
