import i18n from 'i18next';

import { Checkbox } from '../../../../../components/UI/Checkbox/Checkbox';
import {
  IRoleDTO,
  ITableData,
  ITableHeaderCell,
  ITableRow,
} from '../../../../../typescript/interfaces';

export const getHeaderCells = (
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
): ITableHeaderCell[] => {
  const { t } = i18n;
  return [
    {
      id: 'select',
      label: <Checkbox value="selectAll" checked={isAllSelected} onClick={handleIsAllSelected} />,
      sx: { width: '30px' },
      sortable: false,
    },
    {
      id: 'role',
      label: t('admin.user.roles.role'),
    },
  ];
};
const getRows = (
  userRoles: IRoleDTO[],
  selectableRoles: IRoleDTO[],
  handleRoleClick: (checked: boolean, value?: string) => void,
): ITableRow[] => {
  const rows: ITableRow[] = selectableRoles.map((role: IRoleDTO) => {
    const isChecked = userRoles.some((userRole) => userRole.id === role.id);
    return {
      id: role.id,
      hover: false,
      cells: [
        {
          id: 'id',
          value: <Checkbox value={role.id} checked={isChecked} onClick={handleRoleClick} />,
          sx: { width: '30px' },
        },
        {
          id: 'name',
          value: role.name,
        },
      ],
      selected: isChecked,
    };
  });

  return rows;
};

export const getTableData = (
  userRoles: IRoleDTO[],
  selectableRoles: IRoleDTO[],
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
  handleRoleClick: (checked: boolean, value?: string) => void,
): ITableData => {
  return {
    headerCells: getHeaderCells(isAllSelected, handleIsAllSelected),
    rows: getRows(userRoles, selectableRoles, handleRoleClick),
  };
};
