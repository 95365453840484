import styled from 'styled-components/macro';

export const Text = styled.p`
  font-size: 2rem;
  color: #424242;
  text-align: center;
  line-height: 2.2rem;
`;

export const Header = styled.h1`
  font-size: 4rem;
  color: #424242;
  text-align: center;
`;

export const FlexContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LinkContainer = styled.div`
  margin-top: 2rem;

  a {
    font-size: 2rem;
  }
`;
