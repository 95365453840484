import i18n from 'i18next';

import { IHistoryDTO, ITableData, ITableHeaderCell, ITableRow } from '../../typescript/interfaces';
import { getFormattedDate } from '../../utils';

const headerCells = (): ITableHeaderCell[] => {
  const { t } = i18n;
  return [
    {
      id: 'name',
      label: t('history.system.table.name'),
    },
    {
      id: 'comment',
      label: t('history.system.table.comment'),
    },
    {
      id: 'date',
      label: t('history.system.table.date'),
    },
  ];
};

const getRows = (data: IHistoryDTO[]): ITableRow[] => {
  if (!data) {
    return [];
  }

  return data.map((row: IHistoryDTO) => {
    const name = row.user ? `${row.user.givenName ?? ''} ${row.user.familyName ?? ''}` : '';
    return {
      id: row.id,
      cells: [
        {
          id: 'name',
          value: name,
        },
        {
          id: 'comment',
          value: row.comment,
          showTooltip: true,
        },
        {
          id: 'date',
          value: getFormattedDate(row.created),
          showTooltip: true,
        },
      ],
    };
  });
};

export const getServiceHistoryTableData = (data: IHistoryDTO[], admin = false): ITableData => {
  return {
    headerCells: headerCells(),
    rows: getRows(data),
  };
};
