import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ISettingsDTO } from 'typescript/interfaces';

import { Button, Input, LanguageSelect, Spinner } from '../../components';
import { useUserService } from '../../hooks';

interface IFormData {
  notifyUser: boolean;
  givenName: string;
  givenNameIsValid: boolean | undefined;
  familyName: string;
  familyNameIsValid: boolean | undefined;
  language: string;
  languageIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  buttonText?: string;
  isButtonDisabled?: boolean;
}

export const UserSettings = ({ buttonText, isButtonDisabled }: IProps) => {
  const { t } = useTranslation();
  const { updateSettings, settings, isSettingsLoading } = useUserService();
  const [formData, setFormData] = useState<IFormData>({
    notifyUser: settings?.notifyUser ?? false,
    givenName: settings?.givenName ?? '',
    givenNameIsValid: undefined,
    familyName: settings?.familyName ?? '',
    familyNameIsValid: undefined,
    language: settings?.language ?? '',
    languageIsValid: undefined,
    isFormChanged: false,
  });

  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(isButtonDisabled);

  useEffect(() => {
    if (isButtonDisabled !== undefined) {
      setIsBtnDisabled(isButtonDisabled && isSettingsLoading);
    } else {
      setIsBtnDisabled(
        !formData.familyNameIsValid ||
          !formData.givenNameIsValid ||
          !formData.languageIsValid ||
          !formData.isFormChanged ||
          isSettingsLoading,
      );
    }
  }, [
    formData.familyNameIsValid,
    formData.givenNameIsValid,
    formData.isFormChanged,
    formData.languageIsValid,
    isButtonDisabled,
    isSettingsLoading,
    setIsBtnDisabled,
  ]);

  useEffect(() => {
    setFormData({
      notifyUser: settings?.notifyUser ?? false,
      givenName: settings?.givenName ?? '',
      givenNameIsValid: !!settings?.givenName,
      familyName: settings?.familyName ?? '',
      familyNameIsValid: !!settings?.familyName,
      language: settings?.language ?? '',
      languageIsValid: !!settings?.language,
      isFormChanged: false,
    });
  }, [settings]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormData({
      ...formData,
      givenNameIsValid: !!formData.givenName,
      familyNameIsValid: !!formData.familyName,
    });

    if (!formData.givenNameIsValid || !formData.familyNameIsValid || !formData.languageIsValid) {
      return;
    }

    const updatedSettings: ISettingsDTO = {
      notifyUser: formData.notifyUser,
      givenName: formData.givenName,
      familyName: formData.familyName,
      language: formData.language,
    };

    updateSettings(updatedSettings);
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value, [`${name}IsValid`]: !!value, isFormChanged: true });
  };

  const handleLanguageChange = (value: string) => {
    setFormData({ ...formData, language: value, languageIsValid: !!value, isFormChanged: true });
  };

  if (isSettingsLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleFormChange(e)}>
      <label htmlFor="givenName">{`${t('userSettings.name')}`}</label>
      <Input
        name="givenName"
        warning={formData.givenNameIsValid === false}
        placeholder={
          formData.givenNameIsValid === false ? t('userSettings.nameIsRequired') ?? '' : ''
        }
        value={formData.givenName}
      />
      <label htmlFor="familyName">{`${t('userSettings.surname')}`}</label>
      <Input
        name="familyName"
        warning={formData.familyNameIsValid === false}
        placeholder={
          formData.familyNameIsValid === false ? t('userSettings.surnameIsRequired') ?? '' : ''
        }
        value={formData.familyName}
      />
      {formData.languageIsValid === false && (
        <Warning>{t('userSettings.languageIsRequired')}</Warning>
      )}
      <LanguageSelect onChange={handleLanguageChange} defaultValue={formData.language} />
      <Button type="submit" disabled={isBtnDisabled}>
        {buttonText ?? t('general.saveChange')}
      </Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;

const Warning = styled.p`
  color: ${({ theme }) => theme.colors.text.error};
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
