import { useEffect, useRef, useState } from 'react';

export interface UseDebouncedStateOptions<T> {
  initialState?: T;
  debounceTime?: number;
  onStateCommit?: (state: T) => void;
  onStateRetired?: (state: T | null) => void;
}

export const useDebouncedState = <T>(options: UseDebouncedStateOptions<T>) => {
  const combinedOptions = {
    ...{
      debounceTime: 1000,
      initialState: null,
    },
    ...options,
  };

  const timeoutRef = useRef<NodeJS.Timeout>();
  const [pendingState, setPendingState] = useState<T | null>(combinedOptions.initialState);
  const [currentState, setCurrentState] = useState<T | null>(combinedOptions.initialState);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const setState = (state: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      combinedOptions.onStateRetired?.(pendingState);
    }

    setPendingState(state);

    timeoutRef.current = setTimeout(() => {
      setCurrentState(state);
      combinedOptions.onStateCommit?.(state);
    }, combinedOptions.debounceTime);
  };

  return {
    currentState,
    pendingState,
    setState,
  };
};
