import { Pluspestat, Pluspnextbillstat } from '.';

export interface WorkOrder {
  workorderid: string;
  actfinish: string;
  commodity: string;
  description: string;
  description_longdescription: string;
  doclinks: Doclink[];
  failureremark_description_longdescription: string;
  labtrans: Labtrans[];
  location: string;
  location_description: string;
  matusetrans: Matusetrans[];
  organization: string;
  pluspagreement: string;
  pluspagreement_description: string;
  pluspcostcenter: string;
  pluspcrhrs: string;
  pluspcustomer_name: string;
  pluspcustponum: string;
  pluspcustpricest?: Pluspcustpriceest[];
  pluspgbtrans: Pluspgbtrans[];
  pluspnextbillstat: Pluspnextbillstat;
  pluspquotetype: string;
  plusptotaloutstanding: string;
  pluspwopricetotals: Pluspwopricetotals;
  pluspwpgenbill: Pluspwpgenbill[];
  remarkdesc: string;
  reportdate: string;
  reportedby: string;
  servrectrans: Servrectrans[];
  siteid: string;
  status: string;
  status_description: string;
  tooltrans: any[];
  wonum: string;
  workOrderHistory: History[];
  wplabor: Wplabor[];
  wpmaterial: Wpmaterial[];
  wpservice: Wpservice[];
  wptool: Wptool[];
  totalAmount: number;
}

export interface History {
  description_longdescription: string;
  person_displayname: string;
  createdate: string;
  href: string;
}

export interface ResponseInfo {
  href: string;
  totalCount: number;
}

export interface Matusetrans {
  plusplineprice: string;
  plusplistprice: string;
  positivequantity: string;
  description: string;
}

export interface Pluspcustpriceest {
  createdate?: string;
  pluspestremark: string;
  pluspeststat: Pluspestat;
  plusppriceest: string;
  worklogid?: string;
}

// Totalprice for each "resource type"
export interface Pluspwopricetotals {
  feebillpr: number;
  labbillpr: number;
  matbillpr: number;
  srvbillpr: number;
  toolbillpr: number;
  wospecialprice?: number;
}

//Material
export interface Matusetrans {
  description: string;
  plusplineprice: string;
  positivequantity: string;
}

//Service
export interface Servrectrans {
  description: string;
  plusplineprice: string;
  plusplistprice: string;
  quantity: string;
}

//Tools
export interface Tooltrans {
  description: string;
  plusplineprice: string;
  plusplistprice: string;
  toolqty: string;
}

//Fees and charges
export interface Pluspgbtrans {
  description: string;
  lineprice: string;
}

//Labor
export interface Labtrans {
  c_knowntocust: string;
  craft: string;
  craftskill_description: string;
  description: string;
  plusplineprice: string;
  plusplistprice: string;
  regularhrs: string;
}

export enum ResourceTypes {
  FeesAndCharges = 'Fees and charges',
  FixedPrice = 'Fixed price',
  Labor = 'Labor',
  Material = 'Material',
  Service = 'Service',
  Tools = 'Tools',
}
export interface Doclink {
  c_clientviewable: string;
  doclinksid: string;
  urlname: string;
  urltype: string;
}

export interface Wplabor {
  c_knowntocust: string;
  craft: string;
  craftskill_description: string;
  laborhrs: string;
  plusplineprice: string;
  quantity: string;
  skilllevel: string;
  totalhours: string;
  plusplistprice: string;
}

export interface Wpmaterial {
  description: string;
  itemqty: string;
  plusplineprice: string;
  plusplistprice: string;
}

export interface Wpservice {
  description: string;
  itemqty: string;
  plusplineprice: string;
  plusplistprice: string;
}

export interface Wptool {
  description: string;
  itemqty: string;
  plusplineprice: string;
}

export interface Pluspwpgenbill {
  description: string;
  lineprice: string;
}
