import { Tooltip } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  status: string;
}

const getStatusColor = (status: string) => {
  const formattedStatus = status.toLowerCase().replace(/\s/g, '');
  switch (formattedStatus) {
    case 'approved':
      return 'success';
    case 'approve':
      return 'success';
    case 'disputed':
      return 'warning';
    case 'dispute':
      return 'warning';
    case 'rejected':
      return 'error';
    case 'reject':
      return 'error';
    default:
      return 'disabled';
  }
};

export const Status: FC<IProps> = ({ status, children }) => {
  const themeColor = getStatusColor(status);
  return (
    <Container className="status">
      <Tooltip title={status}>
        <StatusContainer>
          <StatusIcon $themeColor={themeColor} />
        </StatusContainer>
      </Tooltip>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xsmall};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.preamble};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.body};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: 50%;
  overflow: hidden;
`;

const StatusIcon = styled.div<{ $themeColor: string }>`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin: 5px;
  background: ${({ theme, $themeColor }) =>
    theme.colors.background[$themeColor as keyof typeof theme.colors.background]};
  display: inline-block;
  overflow: hidden;
`;
