import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Tooltip } from '@mui/material';
import { RefObject } from 'react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  value: string;
  tooltip: string;
  ref?: RefObject<HTMLButtonElement>;
}

export const FilterButton = ({ value, tooltip, ...rest }: IProps) => {
  const Icon = value ? ClearIcon : FilterListIcon;

  if (!value) {
    return (
      <Button disabled {...rest}>
        <Icon />
      </Button>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <span>
        <Button {...rest}>
          <Icon />
        </Button>
      </span>
    </Tooltip>
  );
};

const Button = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.background.cta};

  &:disabled {
    cursor: default;
  }
`;
