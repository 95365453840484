import InfoIcon from '@mui/icons-material/Info';
import { IconButton as MuiIconButton, Tooltip as MuiTooltip } from '@mui/material';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Position } from '../../../typescript/types';
import { Input } from '../../Input/Input';
import { FilterButton } from './FilterButton';
import { FilterWordsList } from './FilterWordsList';

interface IProps {
  filterWords: string[];
  handleChange: (value: string) => void;
  filterButtonTooltip?: string;
  inputContainerTooltip?: string;
  inputName?: string;
  inputPlaceholder?: string;
  isVisible?: boolean;
  value: string;
  optionalHeaderComponent?: JSX.Element;
  optionalHeaderComponentPosition?: Position;
}

export const Filter = ({
  filterWords,
  handleChange,
  filterButtonTooltip = 'Clear all filters',
  inputContainerTooltip = '',
  inputName = 'Filter',
  inputPlaceholder = '',
  isVisible = true,
  value = '',
  optionalHeaderComponent,
  optionalHeaderComponentPosition = 'right',
}: IProps) => {
  return (
    <>
      {isVisible && (
        <Container id="filter">
          <FilterHeader className="filterHeader" $position={optionalHeaderComponentPosition}>
            <InputContainer className="inputContainer">
              {inputContainerTooltip && (
                <Tooltip title={inputContainerTooltip}>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Input
                value={value}
                placeholder={inputPlaceholder}
                name={inputName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
              />
              <FilterButton
                value={value}
                tooltip={filterButtonTooltip}
                onClick={(e) => handleChange('')}
              />
            </InputContainer>
            {optionalHeaderComponent && (
              <OptionalContainer>{optionalHeaderComponent}</OptionalContainer>
            )}
          </FilterHeader>
          <FilterWordsList
            words={filterWords}
            onChange={(changedQuery) => handleChange(changedQuery)}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  padding-bottom: ${({ theme }) => theme.spacings.large};
`;

const FilterHeader = styled.div<{ $position: Position }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
  flex-flow: ${({ $position }) => ($position === 'left' ? 'row-reverse' : 'row')};
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;

  input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${({ theme }) =>
      `${theme.spacings.small} 3rem ${theme.spacings.small} calc(3rem - 4px)`};

    ::placeholder {
      opacity: 1;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 53%;
    transform: translateY(-50%);
    padding: ${({ theme }) => theme.spacings.small};
    cursor: pointer;
  }
`;

const Tooltip = styled(MuiTooltip)`
  position: absolute;
  left: 0;

  svg {
    color: ${({ theme }) => theme.colors.background.onPrimary};
    background: ${({ theme }) => theme.colors.background.primary};
    border-radius: 50px;
  }
`;

const OptionalContainer = styled.div`
  flex: 0;
`;

const IconButton = styled(MuiIconButton)`
  width: fit-content;
  margin-right: -2px;
`;
