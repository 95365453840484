import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export const useSuccess = () => {
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (success) {
      toast.success(success);
    }
  }, [success]);

  return [setSuccess];
};
