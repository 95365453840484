import { IUserDTO } from '../../../../../typescript/interfaces';
import { ServicesContent } from './ServicesContent';

interface IProps {
  handleUpdateUser: (updatedUser: IUserDTO) => void;
  user?: IUserDTO;
}

export const Services = ({ user, handleUpdateUser }: IProps) => {
  if (!user) {
    return null;
  }

  return <ServicesContent user={user} handleUpdateUser={handleUpdateUser} />;
};
