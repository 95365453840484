import { FC } from 'react';
import styled from 'styled-components/macro';

export const PageTitle: FC = ({ children }) => {
  return <H1>{children}</H1>;
};

const H1 = styled.h1`
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 400;
`;
