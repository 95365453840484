import { ITableData, ITableHeaderCell, ITableRow } from '../../../typescript/interfaces';
import { ResourceTypes, WorkOrder, Wplabor } from '../../../typescript/types';
import { addThousandSeperator } from '../../../utils';
import { cell } from '../../UI';
import { getFillerRows } from '../getFillerRows';
import { getFixedPriceRow } from '../getFixedPrice';
import { getListPrice } from '../getListPrice';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'resourceType',
    label: 'Type',
  },
  {
    id: 'resource',
    label: 'Resource',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
  {
    id: 'unitPrice',
    label: 'Unit price',
    align: 'right',
  },
  {
    id: 'cost',
    label: 'Amount',
    align: 'right',
  },
];

const getDescription = (wpLabor: Wplabor) => {
  const { c_knowntocust, craft, craftskill_description } = wpLabor;

  if (c_knowntocust !== '') {
    return c_knowntocust;
  }
  if (craft !== '' && craftskill_description !== '') {
    return `${craft} ${craftskill_description}`;
  }

  return craft !== '' ? craft : craftskill_description;
};

const getTableRows = (workOrder: WorkOrder, minRows: number): ITableRow[] => {
  const wplaborValues = workOrder?.wplabor
    ? workOrder.wplabor.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Labor }),
            cell({ value: getDescription(v), showTooltip: true }),
            cell({ value: v.totalhours }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const wpmaterialValues = workOrder?.wpmaterial
    ? workOrder.wpmaterial.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Material }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.itemqty }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }), // Maximo buggar
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const wpserviceValues = workOrder?.wpservice
    ? workOrder.wpservice.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Service }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.itemqty }),
            cell({ value: getListPrice(v.plusplistprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const wptoolValues = workOrder?.wptool
    ? workOrder.wptool.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.Tools }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: v.itemqty }),
            cell({ value: '' }, { align: 'right' }), // Maximo buggar
            cell({ value: addThousandSeperator(v.plusplineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];
  const pluspwpgenbillValues = workOrder.pluspwpgenbill
    ? workOrder.pluspwpgenbill.map((v) => {
        return {
          cells: [
            cell({ value: ResourceTypes.FeesAndCharges }),
            cell({ value: v.description, showTooltip: true }),
            cell({ value: '1' }),
            cell({ value: addThousandSeperator(v.lineprice) }, { align: 'right' }),
            cell({ value: addThousandSeperator(v.lineprice) }, { align: 'right' }),
          ],
        };
      })
    : [];

  let tableRows = [
    wplaborValues,
    wpmaterialValues,
    wpserviceValues,
    wptoolValues,
    pluspwpgenbillValues,
  ].flat();

  if (tableRows.length < minRows) {
    tableRows = [...tableRows, ...getFillerRows(tableRows.length, minRows)];
  }

  return tableRows;
};

/**
 * @param {WorkOrder} workOrder
 * @param {number} minRows - minimum number of rows to display in the table
 * @returns {ITableData}
 */
export const getProposedWorkDetailTable = (workOrder: WorkOrder, minRows: number): ITableData => {
  const { pluspquotetype } = workOrder;

  return {
    headerCells,
    rows:
      pluspquotetype === 'Fixed Price'
        ? getFixedPriceRow(workOrder)
        : getTableRows(workOrder, minRows),
    tableFooter: {
      label: 'Total Est. Amount',
      value: addThousandSeperator(workOrder.totalAmount),
    },
  };
};
