import { Auth0Provider } from '@auth0/auth0-react';
import { FC } from 'react';

const IPA_AUTH0_DOMAIN = process.env.REACT_APP_IPA_AUTH0_DOMAIN ?? '';
const IPA_AUTH0_CLIENT_ID = process.env.REACT_APP_IPA_AUTH0_CLIENT_ID ?? '';
const IPA_AUTH0_AUDIENCE = process.env.REACT_APP_IPA_AUTH0_AUDIENCE ?? '';
const IPA_AUTH_REDIRECTURI = process.env.REACT_APP_IPA_AUTH_REDIRECTURI ?? '';

const scope = `read:workorder
read:workorder-completedwork
read:workorder-proposedwork
update:workorder
update:workorder-completedwork
update:workorder-proposedwork
InvoicePreApprover
`;

export const AuthProvider: FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={IPA_AUTH0_DOMAIN}
      clientId={IPA_AUTH0_CLIENT_ID}
      redirectUri={IPA_AUTH_REDIRECTURI}
      audience={IPA_AUTH0_AUDIENCE}
      scope={scope}>
      {children}
    </Auth0Provider>
  );
};
