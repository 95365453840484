import { ILocationDTO, IUserDTO } from '../../../../../typescript/interfaces';

export const updateUserLocations = (
  userLocations: ILocationDTO[],
  selectableLocations: ILocationDTO[],
  id: string,
  checked: boolean,
  user: IUserDTO,
): ILocationDTO[] => {
  const location = selectableLocations.find((location: ILocationDTO) => location.id === id);

  let updatedLocations = userLocations;

  // if there is a location and it is checked, add it to the users assigned locations
  if (location && checked) {
    updatedLocations = [...updatedLocations, location];
  }

  // if there is a location and it is not checked, remove it from the users assigned locations
  if (location && !checked) {
    updatedLocations = updatedLocations.filter((loc: ILocationDTO) => loc.id !== location.id);
  }

  return updatedLocations;
};
