import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { FC } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import { useTheme } from '../../hooks';
import { GlobalStyles } from '../../utils/theme/GlobalStyles';

const getMuiTheme = (theme: DefaultTheme) => {
  return createTheme({
    typography: {
      fontFamily: theme.fonts.family,
    },
    palette: {
      primary: {
        main: theme.colors.background.cta,
      },
      secondary: {
        main: theme.colors.background.field,
      },
    },
  });
};

export const ThemeProviders: FC = ({ children }) => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={getMuiTheme(theme)}>
        <GlobalStyles />
        {children}
      </MuiThemeProvider>
    </ThemeProvider>
  );
};
