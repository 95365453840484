import styled from 'styled-components';
import { IFieldItem } from 'typescript/interfaces';

import { Status } from '..';
import { GridItem } from '../../DetailView/Griditem';
import { FieldTitle } from './FieldTitle';

interface IProps {
  fieldItem: IFieldItem;
}

export const TextField = ({ fieldItem, ...rest }: IProps) => {
  const { title, content, status, boldContent = true } = fieldItem;
  const Title = () => (title ? <FieldTitle title={title} /> : null);

  //TODO: Fix bold content. Description should not be bold.

  return (
    <Wrapper className="fieldItem" style={fieldItem.style} $boldContent={boldContent} {...rest}>
      <Title />
      {status ? <Status status={status}>{content}</Status> : <p>{content}</p>}
    </Wrapper>
  );
};

export const Wrapper = styled(GridItem)<{ $boldContent: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  padding-top: ${({ theme }) => theme.spacings.medium};

  p {
    white-space: pre-line;

    &:last-child {
      font-size: ${({ theme }) => theme.fonts.size.preamble};
      font-weight: ${({ theme, $boldContent }) =>
        $boldContent ? theme.fonts.weight.medium : theme.fonts.weight.regular};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: grid;
    grid-template-columns: 120px 1fr;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    box-shadow: none;
    outline: none;
  }
`;
