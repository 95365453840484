import styled from 'styled-components';

interface ITabLabel {
  text: string;
  mobileText: string;
}

export const TabLabel = ({ text, mobileText }: ITabLabel) => {
  return (
    <>
      <Label>{text}</Label>
      <MobileLabel>{mobileText}</MobileLabel>
    </>
  );
};

const Label = styled.span`
  display: none;
  text-transform: Capitalize;
  font-size: ${({ theme }) => theme.fonts.size.h4};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: inline;
  }
`;

const MobileLabel = styled(Label)`
  display: none;
  font-size: ${({ theme }) => theme.fonts.size.preamble};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: inline;
  }
`;
