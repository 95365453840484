import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Heading } from '../../components';
import { useAppState } from '../../hooks';

export const WelcomePage = () => {
  const { t } = useTranslation();
  const { appState, setAppState } = useAppState();

  setAppState({
    ...appState,
    hasBeenOnOnboardingPage: true,
  });

  return (
    <Wrapper>
      <Container>
        <Heading>👋 {t('welcomePage.title')}</Heading>
        <p>{t('welcomePage.paragraph')}</p>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 75%;

  form {
    margin: ${({ theme }) => theme.spacings.xlarge} 0;
  }

  p {
    line-height: 1.5;
  }
`;
