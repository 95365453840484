import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import axios from 'axios';

import { IRoleDTO } from '../typescript/interfaces';
import { Method } from '../typescript/types';

const API_URL = process.env.REACT_APP_API_URL ?? '';

const getAxiosConfig = <T>(
  method: Method,
  token: string | GetTokenSilentlyVerboseResponse | undefined,
  path: string,
  data?: T,
) => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  url: `${API_URL}/${path}`,
  data,
});

interface IDTO {
  token: string | GetTokenSilentlyVerboseResponse | undefined;
}

export interface IUpdateRole extends IDTO {
  role: IRoleDTO;
}

export const getRolesByCompany = async (token: string, companyId: string): Promise<IRoleDTO[]> => {
  const response = await axios(getAxiosConfig('GET', token, `company/${companyId}/roles/`));
  return response.data;
};

export const getRole = async (token: string, id: string): Promise<IRoleDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `roles/${id}`));
  return response.data;
};

export const getRolesByCompanyAndUser = async (
  token: string,
  companyId: string,
  userId: string,
): Promise<IRoleDTO[]> => {
  const response = await axios(
    getAxiosConfig('GET', token, `company/${companyId}/user/${userId}/roles`),
  );
  return response.data;
};

export const addRole = async ({ token, role }: IUpdateRole) => {
  const response = await axios(getAxiosConfig('POST', token, `roles`, role));
  return response.data;
};

export const updateRole = async ({ token, role }: IUpdateRole) => {
  const response = await axios(getAxiosConfig('PUT', token, `roles/${role.id}`, role));
  return response.data;
};

interface IDeleteRole extends IDTO {
  id: string;
}

export const deleteRole = async ({ token, id }: IDeleteRole) => {
  const response = await axios(getAxiosConfig('DELETE', token, `roles/${id}`));
  return response.data;
};
