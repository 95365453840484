import { User } from '@auth0/auth0-spa-js';
import styled from 'styled-components';

interface IProps {
  user?: User;
}

export const ButtonContent = ({ user }: IProps) => {
  if (!user) return <></>;

  return (
    <Wrapper>
      {user?.picture && (
        <Avatar>
          <img src={user?.picture} alt={user?.name ?? 'User'} />
        </Avatar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Avatar = styled.div`
  border-radius: 2rem;
  overflow: hidden;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
  transition: border 0.2s ease-in-out;

  img {
    max-width: 100%;
  }

  &:hover {
    border: 4px solid rgb(221, 134, 51);
  }
`;
