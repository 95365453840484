import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Accordion, Button, Icon } from '../../../../components';
import { useModal } from '../../../../utils/context';
import { AddAgreementButton } from './AddAgreementButton';
import { EditOrganization } from './EditOrganization';
import { OrganizationGroup } from './OrganizationGroup';
import { IOrganizationGroup } from './Organizations';

export interface IProps {
  companyId: string;
  groups: IOrganizationGroup[];
}

export const OrganizationGroups = ({ groups, companyId }: IProps) => {
  const { setModalContent } = useModal();
  const { t } = useTranslation();

  const editAgreement = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    organizationGroup: IOrganizationGroup,
    companyId: string,
  ) => {
    e.stopPropagation();
    setModalContent({
      title: 'Edit organization',
      content: <EditOrganization organizationGroup={organizationGroup} companyId={companyId} />,
    });
  };

  const summary = (group: IOrganizationGroup) => {
    return (
      <Summary>
        <Button
          type="button"
          variant="tertiary"
          size="small"
          onClick={(e) => editAgreement(e, group, companyId)}
          tooltip={`${t('admin.company.organization.editOrganization')}`}>
          <Icon icon="edit" />
        </Button>
        <p>{group.organization}</p>
      </Summary>
    );
  };

  return (
    <Container>
      {groups.map((group, index) => (
        <Accordion
          key={index}
          summary={summary(group)}
          details={
            <Details>
              {group?.agreements.length > 0 ? (
                <OrganizationGroup group={group} companyId={companyId} />
              ) : (
                <NoAgreementsFound>
                  <p>{t('admin.company.organization.noAgreementsFound')}</p>
                  <AddAgreementButton companyId={companyId} org={group.organization} />
                </NoAgreementsFound>
              )}
            </Details>
          }
          expanded={true}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.medium};
`;

const NoAgreementsFound = styled.div`
  display: flex;
  justify-content: space-between;
`;
