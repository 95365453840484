import { useSelect } from 'downshift';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

export interface ISelectItem {
  title: string;
  value: string;
}

interface IProps {
  label?: string;
  defaultTitle?: string;
  selectItems: ISelectItem[];
  disabled?: boolean;
  onChange?: (value: string) => void;
  initialSelectedItem?: ISelectItem;
}

export const Select = ({
  label,
  defaultTitle = '',
  selectItems,
  disabled = false,
  onChange,
  initialSelectedItem,
}: IProps) => {
  function Select() {
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect({
      items: selectItems,
      initialSelectedItem,
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onChange && onChange(selectedItem.value);
        }
      },
    });

    return (
      <Container>
        <div>
          {label && <label {...getLabelProps()}>{label}:</label>}
          <ToggleButton
            disabled={disabled}
            {...getToggleButtonProps()}
            $hasSelectedItem={selectedItem}
            $isOpen={disabled ? false : isOpen}>
            <span>{selectedItem ? selectedItem.title : defaultTitle}</span>
            <>
              {(disabled ? false : isOpen) ? (
                <Icon icon={'upChevron'} />
              ) : (
                <Icon icon={'downChevron'} />
              )}
            </>
          </ToggleButton>
        </div>
        <List {...getMenuProps()} $isOpen={isOpen} defaultValue={'apa'}>
          {selectItems
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((item, index) => (
              <ListItem
                key={index}
                $highlightedIndex={highlightedIndex === index}
                $isSelected={selectedItem === item}
                data-value={item.value}
                {...getItemProps({ item, index })}>
                {item.title}
              </ListItem>
            ))}
        </List>
      </Container>
    );
  }

  return <Select />;
};

const Container = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.size.body};

  label {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }
`;

const SELECT_WIDTH = '170px';

const ToggleButton = styled.div<{ $hasSelectedItem: boolean; $isOpen: boolean; disabled: boolean }>`
  padding: ${({ theme }) => theme.spacings.small};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.border.disabled : theme.colors.border.field};
  border-radius: ${({ theme, $isOpen }) =>
    $isOpen ? `${theme.radius.default} ${theme.radius.default} 0 0` : theme.radius.default};
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  justify-content: space-between;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, $hasSelectedItem, disabled }) =>
    disabled
      ? theme.colors.text.disabled
      : $hasSelectedItem
      ? theme.colors.text.body
      : theme.colors.text.label};

  white-space: nowrap;
  min-width: ${SELECT_WIDTH};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    min-width: 100%;
  }

  svg {
    path {
      fill: ${({ theme, $hasSelectedItem, disabled }) =>
        disabled
          ? theme.colors.text.disabled
          : $hasSelectedItem
          ? theme.colors.text.body
          : theme.colors.text.label};
    }
  }

  :focus {
    outline: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }
`;

export const List = styled.ul<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'collapse')};
  position: absolute;
  width: 100%;
  list-style: none;
  background: ${({ theme }) => theme.colors.background.body};
  z-index: 999;
  max-height: 20rem;
  overflow-y: auto;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.border.field};
  border-top: none;
  border-radius: 0 0 ${({ theme }) => theme.radius.default} ${({ theme }) => theme.radius.default};
  margin-top: 0;
  overflow-x: hidden;
  min-width: ${SELECT_WIDTH};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    min-width: 100%;
  }

  li {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ListItem = styled.li<{ $highligtedIndex: boolean; $isSelected: boolean }>`
  cursor: ${({ $isSelected }) => ($isSelected ? 'default' : 'pointer')};
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.background.cta : theme.colors.background.body};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.text.onCta : theme.colors.text.body};
  padding: ${({ theme }) => theme.spacings.small};

  &:hover {
    color: ${({ theme }) => theme.colors.text.onCta};
    background: ${({ theme }) => theme.colors.background.cta};
  }
`;
