import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';

interface IProps {
  words: string[];
  onChange: (changedFilterWords: string) => void;
}

export const FilterWordsList = ({ words, onChange }: IProps) => {
  const handleClick = (index: number) => {
    const changedQuery = words.filter((word, i) => i !== index);
    // Return the new filterWords as a string
    onChange(changedQuery.join(' '));
  };

  if (words.length === 0) {
    return null;
  }

  return (
    <Ul>
      {words.map((word: string, index: number) => (
        <li key={index}>
          <button onClick={() => handleClick(index)}>
            {word.replace(/"/g, '')}
            <ClearIcon fontSize="small" />
          </button>
        </li>
      ))}
    </Ul>
  );
};

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: ${({ theme }) => theme.spacings.medium};

  li > button {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.xxsmall};
    background-color: ${({ theme }) => theme.colors.background.tag};
    border-radius: ${({ theme }) => theme.radius.default};
    border: none;
    color: ${({ theme }) => theme.colors.text.tag};
    cursor: pointer;
    padding: ${({ theme }) => theme.spacings.xsmall};
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.background.tagHover};
    }

    svg {
      color: ${({ theme }) => theme.colors.text.tagIcon};
    }
  }
`;
