import styled from 'styled-components';

import { Spinner } from '..';

interface ILoaderProps {
  size?: string | number;
}

export const Loader = ({ size }: ILoaderProps) => {
  return (
    <Wrapper>
      <Spinner size={size ?? 120} />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;
