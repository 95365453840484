import { useLayoutEffect, useMemo, useRef } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type WhitelistedEnvironment = 'development' | 'test' | 'acceptance' | 'stage';

const getWhitelistedEnvironment = (): WhitelistedEnvironment | null => {
  const whiteListedEnv = ['development', 'test', 'acceptance', 'stage'];
  const env = process.env.REACT_APP_ENV ?? 'undefined';
  return whiteListedEnv.includes(env) ? (env as WhitelistedEnvironment) : null;
};

export const useWhitelistedEnvironment = () => useMemo(() => getWhitelistedEnvironment(), []);

export const EnvironmentBanner = () => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const env = useWhitelistedEnvironment();

  useLayoutEffect(() => {
    if (!env) return;
    document.title = `[${env}] ${document.title}`;

    const bannerHeight = bannerRef.current?.scrollHeight ?? 0;
    document.body.style.paddingTop = `${bannerHeight}px`;
  }, [env]);

  if (!env) return null;

  return (
    <EnvironmentBannerContainer ref={bannerRef} env={env}>
      <p>{env}</p>
    </EnvironmentBannerContainer>
  );
};

const getBackgroundColor = (env: WhitelistedEnvironment, theme: DefaultTheme) => {
  switch (env) {
    case 'development':
      return css`
        background: ${theme.colors.background.success};
      `;
    case 'acceptance':
    case 'test':
      return css`
        background: ${theme.colors.background.warning};
      `;
    case 'stage':
      return css`
        background: ${theme.colors.background.error};
      `;
    default:
      return css`
        background: #000000;
        color: #ffffff;
      `;
  }
};

const EnvironmentBannerContainer = styled.div<{ env: WhitelistedEnvironment }>`
  width: 100vw;
  padding: ${({ theme }) => theme.spacings.small};
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  ${({ env, theme }) => getBackgroundColor(env, theme)}

  p {
    animation: moveText 2s ease-out 0s 2 normal forwards;
  }

  @keyframes moveText {
    0%,
    65%,
    100% {
      transform: translateX(0);
    }

    70%,
    80%,
    90% {
      transform: translateX(-5px);
    }

    75%,
    85%,
    95% {
      transform: translateX(5px);
    }
  }
`;
