import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { IHistoryDTO } from 'typescript/interfaces';

import { getWorkorderHistory as getWorkorderHistoryAPI, IWorkOrderFilter } from '../api';
import { getFormattedDate } from '../utils';
import { usePermissions } from './usePermissions';
import { useTokenQuery } from './useTokenQuery';

const transformWorkorderHistory = (data: IHistoryDTO[]) => {
  return data.map((history) => {
    const { created, ...rest } = history;
    const localeDate = getFormattedDate(created);

    return {
      ...rest,
      created: localeDate,
    } as IHistoryDTO;
  });
};

export const useGetWorkorderHistory = (pageSize = 250) => {
  const { data: token, isLoading: isTokenLoading } = useTokenQuery();
  const { selectedCompany } = usePermissions();
  const [skip, setSkip] = useState(0);
  const [filter, setFilter] = useState<IWorkOrderFilter>({});

  const companyId = useMemo(() => {
    return selectedCompany?.id ?? '';
  }, [selectedCompany?.id]);

  const setNextPage = () => {
    setSkip(skip + pageSize);
  };

  const { data: workorderHistory, isLoading: isWorkOrderHistoryLoading } = useQuery(
    ['workorder-history', skip, companyId, filter],
    async () => {
      return await getWorkorderHistoryAPI(token as string, companyId, filter, pageSize, skip);
    },
    {
      select: (data) => {
        if (!data) {
          return data;
        }
        return transformWorkorderHistory(data);
      },
      cacheTime: 0, // We disable cache for this query because invalidation is not working
    },
  );

  const isLoading = isWorkOrderHistoryLoading || isTokenLoading;

  return {
    setNextPage,
    setFilter,
    filter,
    workorderHistory,
    isLoading,
  };
};
