import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';

import { updateCompletedWorkOrder } from '../../api/completedWork';
import { updateProposedWorkOrder } from '../../api/proposedWork';
import { getPluspcustpricest } from '../../components/DetailView/ProposedWorkDetailedView';
import { useAppState, useCompanyService, usePermissions, useTokenQuery } from '../../hooks';
import { Pages } from '../../typescript/enums';
import { PLUSPESTAT, PLUSPNEXTBILLSTAT, WorkOrder } from '../../typescript/types';

export interface IQuickResponseContext {
  isEnabled: boolean;
  includedWorkOrders: WorkOrder[];
  toggleIncludeWorkorder: (workOrder: WorkOrder) => void;
  includeWorkOrders: (workOrders: WorkOrder[]) => void;
  unIncludeAllWorkOrders: () => void;
  approveAllWorkOrders: () => Promise<ApproveAllWorkOrdersResponse>;
}

type ApproveAllWorkOrdersResponse = Record<
  string,
  {
    successful?: boolean;
    errorMessage?: string;
  }
>;

const initialState: IQuickResponseContext = {
  isEnabled: false,
  includedWorkOrders: [],
  toggleIncludeWorkorder: (_) => {},
  includeWorkOrders: (_) => {},
  unIncludeAllWorkOrders: () => {},
  approveAllWorkOrders: () => Promise.resolve({}),
};

export const QuickResponseContext = createContext<IQuickResponseContext>(initialState);
const { Provider } = QuickResponseContext;

export const QuickResponseProvider: FC = ({ children }) => {
  const queryClient = useQueryClient();
  const { data: token } = useTokenQuery();
  const { user } = useAuth0();
  const { appState } = useAppState();
  const { currentPage } = appState;

  const [includedWorkOrders, setIncludedWorkOrders] = useState<WorkOrder[]>([]);

  // Check if Quick Response is enabled for this company
  const { selectedCompany } = usePermissions();
  const { company } = useCompanyService(selectedCompany?.id);
  const isEnabled = company?.hasQuickResponse ?? false;

  const toggleIncludeWorkorder = (workOrder: WorkOrder) => {
    const existing = includedWorkOrders.find((x) => x.workorderid === workOrder.workorderid);
    if (existing) {
      setIncludedWorkOrders(
        includedWorkOrders.filter((x) => x.workorderid !== workOrder.workorderid),
      );
    } else {
      setIncludedWorkOrders([...includedWorkOrders, workOrder]);
    }
  };

  const includeWorkOrders = (workOrders: WorkOrder[]) => setIncludedWorkOrders(workOrders);
  const unIncludeAllWorkOrders = () => setIncludedWorkOrders([]);

  const approveAllWorkOrders = async () => {
    const result: ApproveAllWorkOrdersResponse = {};

    const promises = includedWorkOrders.map((x) => {
      const updateFunc =
        currentPage === Pages.COMPLETED ? updateCompletedWorkOrder : updateProposedWorkOrder;

      const { worklogid, pluspestremark } = getPluspcustpricest(x);

      const request = {
        id: parseInt(x.workorderid),
        companyId: selectedCompany!.id!,
        org: x.organization,
        token,
        user,
        fields:
          currentPage === Pages.COMPLETED
            ? {
                pluspcostcenter: x?.pluspcostcenter,
                pluspcustponum: x?.pluspcustponum,
                pluspnextbillstat: PLUSPNEXTBILLSTAT.APPROVE,
              }
            : {
                pluspcostcenter: x?.pluspcostcenter,
                pluspcustponum: x?.pluspcustponum,
                pluspestremark: pluspestremark,
                worklogid: worklogid ?? '',
                pluspeststat: PLUSPESTAT.ACCEPTED,
              },
      };

      return updateFunc(request as any)
        .then(() => {
          result[x.workorderid] = {
            successful: true,
          };
        })
        .catch((err: Error) => {
          result[x.workorderid] = {
            successful: false,
            errorMessage: err.message,
          };
        });
    });

    await Promise.allSettled(promises);

    // Only leave unsuccessful workorder approvals in the list
    setIncludedWorkOrders((x) => x.filter((y) => result[y.workorderid].successful === false));

    queryClient.invalidateQueries(['proposed-workorders']);
    queryClient.invalidateQueries(['completed-workorders']);

    return result;
  };

  return (
    <Provider
      value={{
        isEnabled,
        toggleIncludeWorkorder,
        includeWorkOrders,
        unIncludeAllWorkOrders,
        includedWorkOrders,
        approveAllWorkOrders,
      }}>
      {children}
    </Provider>
  );
};

export const useQuickResponse = () => useContext(QuickResponseContext);
