import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { WorkOrder } from 'typescript/types';

import { getCompletedWorkOrder } from '../api/completedWork';
import { usePermissions } from './usePermissions';
import { useTokenQuery } from './useTokenQuery';

export const useCompletedWorkOrderQuery = (id: number, org: string, companyId: string) => {
  const queryClient = useQueryClient();
  const { hasPermissionsToViewCompletedWorkorderForCompany, isPermissionsLoading } =
    usePermissions();
  const { data: token } = useTokenQuery();

  return useQuery<WorkOrder, AxiosError>(
    ['completed-workorders', id, org, companyId],
    () => getCompletedWorkOrder(id, org, companyId, token as string),
    {
      enabled: hasPermissionsToViewCompletedWorkorderForCompany && !isPermissionsLoading,
      retry: (count, error) => count < 5 && error.response?.status !== 404,
      onError: (error) => {
        if (error.response?.status === 404) {
          queryClient.invalidateQueries(['completed-workorders', companyId]);
        }
      },
    },
  );
};
