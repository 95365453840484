import { IUserDTO } from '../../../../../typescript/interfaces';
import { LocationsContent } from './LocationsContent';

interface IProps {
  handleUpdateUser: (updatedUser: IUserDTO) => void;
  companyId: string;
  user?: IUserDTO;
}

export const Locations = ({ handleUpdateUser, companyId, user }: IProps) => {
  if (!user || !companyId) {
    return null;
  }

  return <LocationsContent user={user} companyId={companyId} handleUpdateUser={handleUpdateUser} />;
};
