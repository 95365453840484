import { OpenInNew } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';

interface IProps {
  text: string;
  href: string;
  onClick: () => void;
}

export const ExternalLink = ({ text, href, onClick }: IProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <OpenInNew fontSize="small" />
      </ListItemIcon>
      <a href={href} target="_blank" rel="noreferrer">
        {text}
      </a>
    </MenuItem>
  );
};
