import { createContext } from 'react';
import styled from 'styled-components';

import { Logo } from '../Logo/Logo';
import { CompanySelect } from '../UI/CompanySelect/CompanySelect';
import { AdminArea } from './AdminArea/AdminArea';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { MobileMenu } from './MobileMenu';
import { Tabs } from './Tabs/Tabs';
import { UserArea } from './UserArea/UserArea';

export const Header = () => {
  return (
    <Container>
      <HeaderPanel>
        <HeaderLinkRenderProvider
          value={{
            renderDivider: () => <></>,
            renderItem: ({ icon, Link }) => <Link>{icon}</Link>,
          }}>
          <Logo />
          <Breadcrumbs />
          <Tabs />
          <DropdownContainer>
            <CompanySelect />
          </DropdownContainer>
          <ControlsContainer>
            <AdminArea />
            <UserArea />
          </ControlsContainer>
          <MobileMenu />
        </HeaderLinkRenderProvider>
      </HeaderPanel>
    </Container>
  );
};

const Container = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
`;

const HeaderPanel = styled.div`
  flex: 1;
  display: grid;
  column-gap: ${({ theme }) => theme.spacings.medium};
  grid-template-columns: 200px 1fr auto auto;
  grid-template-rows: auto auto auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    column-gap: 0;
    row-gap: ${({ theme }) => theme.spacings.xxsmall};
    margin-top: ${({ theme }) => theme.spacings.small};
  }

  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin: 0 ${({ theme }) => theme.spacings.xlarge};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 0 ${({ theme }) => theme.spacings.medium};
  }

  // Tabs
  .MuiTabs-root {
    grid-column: 2;

    @media screen and (max-width: 1250px) {
      grid-row: 3;
      grid-column: 1/5;
    }
  }

  #BreadCrumbs {
    grid-column: 2;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: none;
    }
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column: 3;
  gap: ${({ theme }) => theme.spacings.large};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 4;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`;

interface IHeaderLinkRenderContext {
  renderDivider: (text: string) => React.ReactNode;
  renderItem: React.FC<{
    icon: React.ReactNode;
    Link: React.FC;
    text?: string;
  }>;
}

const defaultValue: IHeaderLinkRenderContext = {
  renderDivider: (text) => <></>,
  renderItem: ({ children }) => <>{children}</>,
};

export const HeaderLinkRenderContext = createContext<IHeaderLinkRenderContext>(defaultValue);
export const HeaderLinkRenderProvider = HeaderLinkRenderContext.Provider;
