import { SearchOutlined, Warning } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Tooltip } from '@mui/material';
import { IWorkOrderFilter } from 'api';
import { ChangeEvent, FormEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ITableRow } from 'typescript/interfaces';
import { FieldChange } from 'typescript/types';

import { Spinner, FieldItem, Table, Button, TableToolbar } from '../../components';
import { useAppState, useGetWorkorderHistory, usePermissions } from '../../hooks';
import { CsvExportConfig, useCsvExport } from '../../hooks/useCsvExport';
import { getNumber } from '../../utils';
import { getWorkorderHistoryTableData } from './getWorkorderHistoryTableData';

const getWorkorderHistoryCsvExportConfig = (): CsvExportConfig<ITableRow> => ({
  headers: ['name', 'action', 'workorder', 'description', 'amount', 'date'],
  buildRow: (x) => {
    const row = Object.fromEntries(x.cells.map((c) => [c.id, c.value?.toString() ?? '']));
    return [row.name, row.action, row.workorder, row.description, row.amount, row.date];
  },
  buildFilename: () => 'workorder-history.csv',
});

export interface IWorkorderHistory {
  wonum: string;
  action: string;
  date: string;
  comment: string;
}

export const SearchWorkorderHistoryPage = () => {
  const { permissionsForSelectedCompany } = usePermissions();
  const [localFilter, setLocalFilter] = useState<IWorkOrderFilter>({});
  const { setFilter, workorderHistory = [], isLoading } = useGetWorkorderHistory();
  const [tablePage, setTablePage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const { appState, setAppState } = useAppState();
  const exportToCsv = useCsvExport(getWorkorderHistoryCsvExportConfig());

  const warnings = useMemo(() => {
    const warnings: Record<string, string> = {};

    if (localFilter.from && localFilter.to) {
      const dateFrom = new Date(localFilter.from);
      const dateTo = new Date(localFilter.to);

      if (dateFrom > dateTo) {
        warnings['from'] = "From date can't be later then to date";
      } else if (dateTo < dateFrom) {
        warnings['to'] = "To date can't be earlier then from date";
      }
    }

    return warnings;
  }, [localFilter]);

  const hasWarnings = useMemo(() => Object.keys(warnings).length > 0, [warnings]);

  const handleRowClick = (id: string) => {
    const {
      WORKORDER_HISTORY: { details },
    } = appState;
    const value = details.get(`${id}_details`);
    details.set(`${id}_details`, value === undefined ? true : !value); // If value is undefined, set it to true, otherwise toggle it
    setAppState({
      ...appState,
      WORKORDER_HISTORY: {
        details: new Map(details),
      },
    });
  };

  const handleChange = (e: FieldChange) => {
    setLocalFilter((x) => ({ ...x, [e.target.name]: e.target.value }));
  };

  const onSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (hasWarnings) return;

    setFilter(localFilter);
  };

  const handleIsOnEmptyPage = (isOnEmptyPage: boolean) => {
    if (!isOnEmptyPage) return;
    setTablePage(0);
  };

  return (
    <>
      <ExportRow>
        <Tooltip title="Export to CSV file">
          <IconButton
            onClick={() =>
              exportToCsv(
                getWorkorderHistoryTableData(workorderHistory).rows.filter(
                  (x) => !x.id?.includes('_details'),
                ),
              )
            }>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Tooltip>
      </ExportRow>
      <FilterContainer>
        <FilterBody onSubmit={onSearch}>
          {permissionsForSelectedCompany?.company.update && (
            <FieldItem
              fieldItem={{
                fieldVariant: 'inputField',
                title: t('history.searchworkorder.idFilter') as string,
                content: localFilter.id ?? '',
                name: 'id',
              }}
              handleChange={handleChange}
            />
          )}
          <FieldItem
            fieldItem={{
              fieldVariant: 'inputField',
              title: t('history.searchworkorder.workorderFilter') as string,
              content: localFilter.workorder ?? '',
              name: 'workorder',
            }}
            handleChange={handleChange}
          />
          <FieldItem
            fieldItem={{
              fieldVariant: 'inputField',
              title: t('history.searchworkorder.emailFilter') as string,
              content: localFilter.email ?? '',
              name: 'email',
            }}
            handleChange={handleChange}
          />
          <FieldItem
            fieldItem={{
              fieldVariant: 'dateField',
              title: t('history.searchworkorder.dateFromFilter') as string,
              content: localFilter.from ?? '',
              name: 'from',
              warning: !!warnings['from'],
            }}
            handleChange={handleChange}
          />
          <FieldItem
            fieldItem={{
              fieldVariant: 'dateField',
              title: t('history.searchworkorder.dateToFilter') as string,
              content: localFilter.to ?? '',
              name: 'to',
              warning: !!warnings['to'],
            }}
            handleChange={handleChange}
          />
          <Button type="submit" size="small" disabled={hasWarnings}>
            <SearchOutlined />
          </Button>
        </FilterBody>
        {hasWarnings && (
          <FilterWarningContainer>
            <Warning />
            <div>
              {Object.entries(warnings).map(([key, message]) => (
                <p key={key}>{message}</p>
              ))}
            </div>
          </FilterWarningContainer>
        )}
      </FilterContainer>

      {isLoading ? (
        <SpinnerContainer>
          <Spinner size={60} />
        </SpinnerContainer>
      ) : (
        <>
          <Table
            tableData={getWorkorderHistoryTableData(workorderHistory)}
            heading=""
            rowsPerPage={rowsPerPage}
            tablePage={tablePage}
            handleRowClick={handleRowClick}
            hideLabelInCell={false}
            enableSorting={false}
            hasExpandableRows={true}
            defaultSortProperty={''}
            defaultOrder="asc"
          />
          <TableToolbar
            count={workorderHistory?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={tablePage}
            onPageChange={(_, newPage: number) => setTablePage(newPage)}
            onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement>) =>
              setRowsPerPage(getNumber(e.target.value))
            }
            handleIsOnEmptyPage={(isOnEmptyPage) => handleIsOnEmptyPage(isOnEmptyPage)}
          />
        </>
      )}
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  padding-bottom: ${({ theme }) => theme.spacings.large};
`;

const FilterBody = styled.form`
  display: flex;
  flex-flow: 'row';
  align-items: flex-end;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacings.medium};

  & * {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  & button {
    flex: 0;
    min-width: auto;
  }

  @media screen and (max-width: 940px) {
    flex-direction: column;
    flex-flow: column;
    align-items: stretch;

    button {
      width: 100%;
      max-width: 400px;
      align-self: center;
    }
  }
`;

const FilterWarningContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.large};

  svg {
    color: ${({ theme }) => theme.colors.background.warning};
  }
`;

const ExportRow = styled.div`
  display: flex;
  justify-content: end;
`;
