import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Delete = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0a3 3 0 0 0-3 3v1H2a1 1 0 0 0 0 2h1c0 .02 0 .039.002.059l.889 15.117A3 3 0 0 0 6.886 24h10.228a3 3 0 0 0 2.995-2.824l.89-15.117L21 6h1a1 1 0 1 0 0-2h-6V3a3 3 0 0 0-3-3h-2Zm3 4V3a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v1h4ZM5.002 6l.886 15.059a1 1 0 0 0 .998.941h10.228a1 1 0 0 0 .998-.941L18.998 6H5.002ZM14 8a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Zm-3 1a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0V9Z"
      fill="currentColor"
    />
  </Svg>
);
