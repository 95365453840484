import { WorkOrders } from '..';
import { useCompletedWorkOrdersQuery } from '../../hooks';

export const CompletedWorkOrders = () => {
  return (
    <WorkOrders
      getWorkOrdersQuery={useCompletedWorkOrdersQuery}
      csvExportConfig={{
        buildFilename: () => `completed-workorders.csv`,
      }}
    />
  );
};
