import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Input, Spinner } from '../../../../components';
import { useCompanyService } from '../../../../hooks';

interface IFormData {
  location: string;
  locationIsValid: boolean | undefined;
  externalId: string;
  externalIdIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  companyId: string;
}

export const AddLocation = ({ companyId }: IProps) => {
  const { addLocation, isAddLocationLoading } = useCompanyService();
  const [isFormValid, setIsFormValid] = useState(false);
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IFormData>({
    location: '',
    locationIsValid: undefined,
    externalId: '',
    externalIdIsValid: undefined,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid =
      formData.locationIsValid === true &&
      formData.externalIdIsValid === true &&
      formData.isFormChanged === true;
    setIsFormValid(isFormValid);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, cId: string) => {
    e.preventDefault();
    const name = e.currentTarget.location.value;
    const externalId = e.currentTarget.externalId.value;
    addLocation(companyId, name, externalId);
  };

  if (isAddLocationLoading) {
    return <Spinner size={20} />;
  }

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = !!value;

    setFormData({ ...formData, [name]: value, [`${name}IsValid`]: isValid, isFormChanged: true });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e, companyId)} onChange={(e) => handleFormChange(e)}>
      <label htmlFor="location">{`${t('admin.company.locations.locationName')}`}</label>
      <Input
        name="location"
        warning={formData.locationIsValid === false}
        defaultValue={formData.location ?? ''}
        placeholder={
          formData.locationIsValid === false
            ? t('admin.company.locations.locationNameIsRequired') ?? ''
            : ''
        }
      />
      <label htmlFor="externalId">{`${t('admin.company.locations.externalId')}`}</label>
      <Input
        name="externalId"
        defaultValue={formData.externalId ?? ''}
        warning={formData.externalIdIsValid === false}
        placeholder={
          formData.externalIdIsValid === false
            ? t('admin.company.locations.externalIdIsRequired') ?? ''
            : ''
        }
      />
      <Button type="submit" disabled={!isFormValid}>{`${t(
        'admin.company.locations.addLocation',
      )}`}</Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;
