import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAdminNotifications } from '../../../hooks/useAdminNotifications';
import { CreateNotification, MessageLevelMapping } from '../../../typescript/types/Notification';
import { Button } from '../../UI';

interface EditingNotification extends CreateNotification {
  id?: string;
}

export const AdminNotifications = () => {
  const [editingRow, setEditingRow] = useState<EditingNotification>();
  const {
    informationMessages,
    createInformationMessage,
    updateInformationMessage,
    deleteInformationMessage,
  } = useAdminNotifications();

  const deleteAction = async (id: string) => {
    if (window.confirm('Do you want to delete this message?')) {
      await deleteInformationMessage(id);
    }
  };

  const onRowSave = async (editingRow: EditingNotification) => {
    if (!editingRow) {
      return;
    }

    try {
      if (editingRow.id) {
        await updateInformationMessage(editingRow.id, editingRow);
      } else {
        await createInformationMessage(editingRow);
      }

      setEditingRow(undefined);
    } catch {}
  };

  return (
    <Container>
      <Button
        onClick={() => setEditingRow({ level: 2, message: '', from: '', to: '' })}
        size="small"
        disabled={!!editingRow}>
        Add new notification
      </Button>

      {editingRow !== undefined && (
        <EditingNotificationForm
          row={editingRow}
          onSave={onRowSave}
          onCancel={() => setEditingRow(undefined)}
        />
      )}

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '30px' }}>Level</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {informationMessages?.map((x) => (
            <TableRow key={x.id}>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                }}>
                {MessageLevelMapping[x.level]}
              </TableCell>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  width: '45%',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                }}>
                {x.message}
              </TableCell>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                }}>
                {x.from}
              </TableCell>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                }}>
                {x.to}
              </TableCell>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  paddingLeft: '0.5rem',
                  paddingRight: '0',
                  minWidth: '150px',
                }}
                align="right">
                {editingRow?.id === x.id ? (
                  <IconButton onClick={() => setEditingRow(undefined)}>
                    <CancelIcon color="primary" />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      onClick={() => setEditingRow({ ...x })}
                      disabled={!!editingRow}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="warning"
                      onClick={() => deleteAction(x.id)}
                      disabled={!!editingRow}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    align-self: end;
  }
`;

const EditingNotificationForm = ({
  row,
  onSave,
  onCancel,
}: {
  row: EditingNotification;
  onSave: (editingRow: EditingNotification) => void;
  onCancel: () => void;
}) => {
  const [localEditingRow, setLocalEditingRow] = useState<EditingNotification>(row);

  const convertToDateTimeLocalString = (timeString: string) => {
    const date = new Date(timeString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    setLocalEditingRow({
      ...localEditingRow,
      from: row.from ? convertToDateTimeLocalString(row.from) : '',
      to: row.to ? convertToDateTimeLocalString(row.to) : '',
    });
  }, [row]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave(localEditingRow);
      }}>
      <Paper sx={{ p: 2, marginTop: 2, marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              select
              value={localEditingRow.level}
              label="Level"
              fullWidth
              onChange={(e) =>
                setLocalEditingRow({ ...localEditingRow, level: parseInt(e.target.value) })
              }>
              {Object.entries(MessageLevelMapping)
                // .filter(([_, name]) => name !== 'Debug')
                .map(([value, name]) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={localEditingRow.from}
              fullWidth
              required
              label="Date from"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setLocalEditingRow({ ...localEditingRow, from: e.target.value })}
              type="datetime-local"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={localEditingRow.to}
              fullWidth
              required
              label="Date to"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setLocalEditingRow({ ...localEditingRow, to: e.target.value })}
              type="datetime-local"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={2}
              fullWidth
              label="Message"
              value={localEditingRow.message ?? ''}
              required
              onChange={(e) => setLocalEditingRow({ ...localEditingRow, message: e.target.value })}
            />
          </Grid>
          <Grid item container xs={12} gap={1} justifyContent="end">
            <Grid xs={1}>
              <Button size="small" variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid xs={1}>
              <Button size="small" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
