import React, { createContext, useContext, useEffect, useReducer } from 'react';

export enum WizardActionType {
  CurrentStep = 'CURRENT_STEP',
  ValidSteps = 'VALID_STEPS',
  Progress = 'PROGRESS',
  StepState = 'STEP_STATE',
}

export interface IWizardAction {
  type: WizardActionType | string;
  payload: any;
}

interface IStepState {
  [key: string]: any;
}

export interface IWizardState {
  currentStep: number;
  validSteps: Map<number, boolean>;
  totalSteps: number;
  progress: number;
  steps: Map<number, IStepState>;
}

interface WizardContextType {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isStepValid: (step: number) => boolean;
  setStepValidity: (step: number, isValid: boolean) => void;
  handleFinish: (formData: any) => void;
  state: IWizardState;
  dispatch: React.Dispatch<any>;
}

const WizardContext = createContext<WizardContextType | undefined>(undefined);

interface IWizardProviderProps {
  steps: { label: string; content: JSX.Element }[];
  initialFormData?: any;
  onFinish?: (data: IWizardState) => void;
  customReducer?: (state: IWizardState, action: IWizardAction) => IWizardState;
}

export const WizardProvider: React.FC<IWizardProviderProps> = ({
  steps,
  onFinish,
  customReducer,
  children,
}) => {
  const reducer = (state: IWizardState, action: IWizardAction) => {
    state = customReducer?.(state, action) ?? state;

    switch (action.type) {
      case WizardActionType.CurrentStep:
        return {
          ...state,
          currentStep: action.payload,
        };
      case WizardActionType.ValidSteps:
        const validSteps = state.validSteps.set(action.payload.step, action.payload);
        return {
          ...state,
          validSteps,
        };
      case WizardActionType.Progress:
        return {
          ...state,
          progress: action.payload,
        };
      case WizardActionType.StepState:
        const steps = state.steps.set(action.payload.step, action.payload.state);
        return {
          ...state,
          steps,
        };
      default:
        return state;
    }
  };

  const initialState = {
    currentStep: 0,
    validSteps: new Map<number, boolean>(),
    totalSteps: steps.length,
    progress: 0,
    steps: new Map<number, any>(),
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const goToNextStep = () => {
    dispatch({ type: WizardActionType.CurrentStep, payload: state.currentStep + 1 });
  };

  const goToPreviousStep = () => {
    dispatch({ type: WizardActionType.CurrentStep, payload: state.currentStep - 1 });
  };

  const isStepValid = (step?: number) => {
    return state.validSteps.get(step ?? state.currentStep) ?? false;
  };

  useEffect(() => {
    dispatch({
      type: WizardActionType.Progress,
      payload: ((state.currentStep + 1) / steps.length) * 100,
    });
  }, [state.currentStep, steps.length]);

  const setStepValidity = (step: number, isValid: boolean) => {
    const validSteps = state.validSteps.set(step, isValid);
    dispatch({ type: WizardActionType.ValidSteps, payload: validSteps });
  };

  // This is what should be called when the user clicks the "Finish" button.
  const handleFinish = () => {
    if (onFinish && state.validSteps.size === steps.length) {
      onFinish(state);
    }
  };

  return (
    <WizardContext.Provider
      value={{
        goToNextStep,
        goToPreviousStep,
        isStepValid,
        setStepValidity,
        handleFinish,
        state,
        dispatch,
      }}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizardContext = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error('useWizardContext must be used within a Wizard component');
  }

  return context;
};
