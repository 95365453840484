import { useAuth0 } from '@auth0/auth0-react';
import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMe, getUserPermissions as getUserPermissionsAPI } from '../../api';
import { ICompanyDTO, IPermissionItemDTO, IUserPermissionsDTO } from '../../typescript/interfaces';

export interface IPermissionsContext {
  allPermissions: IUserPermission[] | null;
  reloadAllPermissions: () => Promise<void>;
  hasPermissionToUpdateCompany: boolean;
  hasPermissionToViewCustomerAdminForCompany: boolean;
  hasPermissionsToViewCompanyWorklog: boolean;
  hasPermissionsToViewCompletedWorkorderForCompany: boolean;
  hasPermissionsToViewProposedWorkorderForCompany: boolean;
  hasPermissionsToViewWorkorderForCompany: boolean;
  hasAdminPermission: boolean;
  isPermissionsLoading: boolean;
  permissionsContextIsLoading: boolean;
  permissionsError: Error | null;
  permissionsForSelectedCompany: IUserPermission | null | undefined;
  selectedCompany?: Partial<ICompanyDTO> | null;
  updateSelectedCompany: (company: Partial<ICompanyDTO>) => void;
}

export const PermissionsContext = createContext<IPermissionsContext>({} as IPermissionsContext);

const { Provider } = PermissionsContext;

export interface IUserApplicationPermission {
  read: boolean;
  update: boolean;
}

export interface IUserPermission {
  completedWork: IUserApplicationPermission;
  proposedWork: IUserApplicationPermission;
  worklog?: {
    read: boolean;
  };
  company: IUserApplicationPermission;
  isAdmin: boolean;
  companyId: string;
  companyName: string;
}

const initialState: IUserPermission = {
  completedWork: { read: false, update: false },
  proposedWork: { read: false, update: false },
  worklog: { read: false },
  company: { read: false, update: false },
  isAdmin: false,
  companyId: '',
  companyName: '',
};

const parseCompanyIdFromPath = (pathname: string) => {
  const regex = /company\/([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/i;
  const match = pathname.match(regex);
  const companyId = match ? match[1] : null;

  return companyId;
};

const loadStoredCompany = (): Partial<ICompanyDTO> | null => {
  const storedCompany = localStorage.getItem('selectedCompany');
  return storedCompany ? JSON.parse(storedCompany) : null;
};

const hasAnyReadRights = (permission: IUserPermission) =>
  permission.completedWork.read || permission.proposedWork.read || permission.worklog?.read;

/**
 * Checks if the user has permissions to access a specific company.
 * Access means that it is included in the permissions and that at very least have read access to something
 * @param allPermissions - An array of all the user's permissions.
 * @param companyId - The ID of the company to check permissions for.
 * @returns A boolean indicating whether the user has permissions to access the company.
 */
const hasUserCompanyPermissions = (
  allPermissions: IUserPermission[] | null,
  companyId?: string,
) => {
  if (!allPermissions || !companyId) {
    return false;
  }

  const permissionsForCompany = allPermissions.find(
    (permission) => permission.companyId === companyId && hasAnyReadRights(permission),
  );

  return !!permissionsForCompany;
};

// Get permissions for given company
const getCompanyPermissions = (
  userPermissions: IUserPermissionsDTO,
  companyId: string,
): IUserPermission => {
  // Get permission for given company
  const permissionsForCompany = userPermissions.permissions.find(
    (userPermission: any) => userPermission.companyId === companyId,
  );
  if (!permissionsForCompany) {
    return initialState;
  }

  const permissions = {
    completedWork: {
      read: permissionsForCompany.readCompleted,
      update: permissionsForCompany.writeCompleted,
    },
    proposedWork: {
      read: permissionsForCompany.readProposed,
      update: permissionsForCompany.writeProposed,
    },
    company: {
      read: permissionsForCompany.readCompany,
      update: permissionsForCompany.writeCompany,
    },
    worklog: {
      read: permissionsForCompany.readWorklog,
    },
    companyId: permissionsForCompany.companyId,
    companyName: permissionsForCompany.companyName,
    isAdmin: permissionsForCompany.isAdmin,
  } as IUserPermission;

  return permissions;
};

const getUserPermissionsForAllCompanies = (
  userPermissions: IUserPermissionsDTO,
): IUserPermission[] => {
  return userPermissions.permissions.map((userPermission: IPermissionItemDTO) =>
    getCompanyPermissions(userPermissions, userPermission.companyId),
  );
};

export const PermissionsProvider: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState<Error | null>(null);
  const [userPermissions, setUserPermissions] = useState<IUserPermissionsDTO>();
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
  const [initialCompanySelectionLoading, setInitialCompanySelectionLoading] = useState(true);
  const [allPermissions, setAllPermissions] = useState<IUserPermission[]>();

  const [selectedCompany, setSelectedCompany] = useState<Partial<ICompanyDTO> | null | undefined>();
  const { pathname } = useLocation();

  const fetchPermissionsAndSetSelectedCompany = useCallback(async () => {
    let allPermissions: IUserPermission[] = [];

    try {
      const token = await getAccessTokenSilently();

      // Call me before doing anything else so the backend has a change of looking us up in auth0
      await getMe(token);

      const permissions = await getUserPermissionsAPI(token);
      setUserPermissions(permissions);
      setIsPermissionsLoading(false);

      allPermissions = getUserPermissionsForAllCompanies(permissions);
      setAllPermissions(allPermissions);
    } catch (error) {
      console.error('An error occurred while obtaining permissions', error);
      setError(error as Error);
      setIsPermissionsLoading(false);

      return;
    }

    // Try to find a company to set as selected
    let candidateCompany: Partial<ICompanyDTO> | null = null;
    const companyIdFromPath = parseCompanyIdFromPath(pathname);
    const storedSelectedCompany = loadStoredCompany();

    //It can be the last company, lets start with that
    if (storedSelectedCompany?.id) {
      candidateCompany = storedSelectedCompany;
    }

    //There was a companyId provided in the URL, fallback to this
    if (companyIdFromPath) {
      candidateCompany = { id: companyIdFromPath };
    }

    //Check if we have access to the candidate company
    if (candidateCompany) {
      const doesUserHavePermissionsForCompany = hasUserCompanyPermissions(
        allPermissions,
        candidateCompany.id,
      );

      if (doesUserHavePermissionsForCompany) {
        //... we do, mark it as selected and exit
        const company = allPermissions.find((x) => x.companyId === candidateCompany?.id);
        setSelectedCompany({
          id: company?.companyId,
          name: company?.companyName,
        });
        setInitialCompanySelectionLoading(false);

        return;
      }
    }

    //We stil don't have a company, pick the first one from the ones we do have access to
    const firstCompanyWithReadPermissions = allPermissions?.find(hasAnyReadRights);

    if (firstCompanyWithReadPermissions) {
      setSelectedCompany({
        id: firstCompanyWithReadPermissions.companyId,
        name: firstCompanyWithReadPermissions.companyName,
      });
      setInitialCompanySelectionLoading(false);

      return;
    }

    //..if we get here we don't have permissions to any company :/
    setError(new Error('No permission to any company'));
    setInitialCompanySelectionLoading(false);
  }, [getAccessTokenSilently, pathname]);

  useEffect(() => {
    fetchPermissionsAndSetSelectedCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  // Update local storage if the selected company ever changes
  useEffect(() => {
    if (selectedCompany?.id) {
      localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    }
  }, [selectedCompany]);

  const updateSelectedCompany = useCallback(
    (updatedCompany?: Partial<ICompanyDTO>) => {
      if (updatedCompany?.id) {
        setSelectedCompany(updatedCompany);
      }
    },
    [setSelectedCompany],
  );

  const permissionsForSelectedCompany = useMemo(() => {
    if (isPermissionsLoading || userPermissions === undefined) {
      return;
    }

    if (!allPermissions || !selectedCompany?.id) {
      return null;
    }

    const permissions = allPermissions.find(
      (permission) => permission.companyId === selectedCompany?.id,
    );

    return permissions ? permissions : initialState;
  }, [allPermissions, selectedCompany?.id, isPermissionsLoading, userPermissions]);

  const permissionsContextIsLoading = useMemo(() => {
    return (
      isPermissionsLoading ||
      initialCompanySelectionLoading ||
      userPermissions === undefined ||
      permissionsForSelectedCompany === undefined
    );
  }, [
    isPermissionsLoading,
    initialCompanySelectionLoading,
    userPermissions,
    permissionsForSelectedCompany,
  ]);

  const hasPermissionToUpdateCompany = permissionsForSelectedCompany?.company?.update ?? false;
  const hasPermissionToViewCustomerAdminForCompany =
    permissionsForSelectedCompany?.company.read ?? false;
  const hasPermissionsToViewWorkorderForCompany =
    (permissionsForSelectedCompany?.completedWork.read ?? false) ||
    (permissionsForSelectedCompany?.proposedWork.read ?? false);
  const hasPermissionsToViewProposedWorkorderForCompany =
    permissionsForSelectedCompany?.proposedWork.read ?? false;
  const hasPermissionsToViewCompletedWorkorderForCompany =
    permissionsForSelectedCompany?.completedWork.read ?? false;
  const hasPermissionsToViewCompanyWorklog = permissionsForSelectedCompany?.worklog?.read ?? false;
  const hasAdminPermission = permissionsForSelectedCompany?.isAdmin ?? false;

  return (
    <Provider
      value={{
        allPermissions: allPermissions ?? [],
        reloadAllPermissions: fetchPermissionsAndSetSelectedCompany,
        hasPermissionToViewCustomerAdminForCompany,
        hasPermissionToUpdateCompany,
        hasPermissionsToViewCompanyWorklog,
        hasPermissionsToViewCompletedWorkorderForCompany,
        hasPermissionsToViewProposedWorkorderForCompany,
        hasPermissionsToViewWorkorderForCompany,
        hasAdminPermission,
        isPermissionsLoading,
        permissionsContextIsLoading,
        permissionsError: error,
        permissionsForSelectedCompany,
        selectedCompany,
        updateSelectedCompany,
      }}>
      {children}
    </Provider>
  );
};
