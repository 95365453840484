import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Button, Menu as MuiMenu, Tooltip } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import { ButtonContent } from './ButtonContent';
import { ExternalLink } from './ExtarnalLink';
import { Logout } from './Logout';
import { Permissions } from './Permissions';
import { Settings } from './Settings';

const BUILD_VERSION = process.env.REACT_APP_BUILD;

export const UserArea = () => {
  const { user, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    padding: '0',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  };

  return (
    <>
      {isAuthenticated && (
        <Container>
          <Tooltip title="Account details">
            <Button
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disableRipple
              sx={styles}>
              <ButtonContent user={user} />
            </Button>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <UserAreaMenu handleClose={handleClose} />
          </Menu>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Menu = styled(MuiMenu)`
  .MuiPaper-root {
    &::before {
      right: 19px;
    }
  }
`;

const BuildVersion = styled.div`
  color: ${({ theme }) => theme.colors.text.label};
  padding: 0 ${({ theme }) => theme.spacings.medium};
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

export const UserAreaMenu = ({ handleClose }: { handleClose: () => void }) => (
  <>
    <Permissions onClick={handleClose} />
    <Settings onClick={handleClose} />
    <Divider />
    <ExternalLink
      text="Terms of use"
      href="https://id.coor.com/terms-of-use/"
      onClick={handleClose}
    />
    <ExternalLink
      text="Privacy policy"
      href="https://id.coor.com/privacy-policy/"
      onClick={handleClose}
    />
    <Divider />
    <Logout onClick={handleClose} />
    <Divider />
    <BuildVersion>{BUILD_VERSION}</BuildVersion>
  </>
);
