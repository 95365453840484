import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Filter, Spinner, Table } from '../../../../../components';
import { useServiceService } from '../../../../../hooks/useServiceService';
import { IServiceDTO, IUserDTO } from '../../../../../typescript/interfaces';
import { filterTableRows } from '../../../../../utils';
import { getTableData } from './getServicesTableData';
import { updateUserServices } from './updateUserServices';

interface IProps {
  user: IUserDTO;
  handleUpdateUser: (updatedUser: IUserDTO) => void;
}
export const ServicesContent = ({ user, handleUpdateUser }: IProps) => {
  const { t } = useTranslation();
  const [userServices, setUserServices] = useState(user.assignedServices ?? []);
  const { services: selectableServices = [], isServicesLoading } = useServiceService();

  // Filter
  const inputContainerTooltip = `Filter services based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
    `;
  const inputPlaceHolder = t('admin.user.services.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const updateUser = (updatedServices: IServiceDTO[]) => {
    const updatedUser = { ...user, assignedServices: updatedServices };
    handleUpdateUser(updatedUser);
  };

  // "selectAll" checkbox
  const isAllSelected = userServices.length === selectableServices.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedServices = checked ? selectableServices : [];
    setUserServices(updatedServices);
    updateUser(updatedServices);
  };

  // "location" checkboxes
  const handleServicesClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedServices = updateUserServices(userServices, selectableServices, value, checked);
      setUserServices(updatedServices);
      updateUser(updatedServices);
    }
  };

  const data = getTableData(
    userServices,
    selectableServices,
    isAllSelected,
    handleIsAllSelected,
    handleServicesClick,
  );

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  if (isServicesLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
      />
      <Table tableData={tableData} enableSorting={true} defaultSortProperty="externalId" />
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
