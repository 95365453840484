import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ICompanyDTO } from 'typescript/interfaces';

import { Button, Spinner, TableWithFilter } from '../../../../components';
import { useModal } from '../../../../utils/context';
import { useAppState, useCompanyService } from './../../../../hooks';
import { AddUser } from './AddUser/AddUser';
import { getUserListData } from './getUserListData';

interface IProps {
  company: ICompanyDTO;
  setOptionalComponent?: Dispatch<SetStateAction<ReactNode>>;
}

export const UserListPage = ({ company, setOptionalComponent }: IProps) => {
  const companyId = company?.id || '';
  const { isCompanyLoading } = useCompanyService();
  const users = company?.users || [];
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  const handleRowClick = (id: string) => {
    const user = users?.find((user) => user.id === id);
    const { currentPage } = appState;
    setAppState({
      ...appState,
      [currentPage]: {
        ...appState[currentPage],
        selectedUser: { id, name: `${user?.givenName ?? ''} ${user?.familyName ?? ''}` },
      },
    });
    navigate(`/company/${companyId}/users/${id}`);
  };

  const addAUser = (company: ICompanyDTO) => {
    setModalContent({
      title: `${t('admin.user.addUserModal.title')}`,
      content: <AddUser company={company} />,
    });
  };

  const AddUserButton = () => (
    <Button onClick={() => addAUser(company)}>{t('admin.company.users.addUser')}</Button>
  );

  useEffect(() => {
    if (!setOptionalComponent) {
      return;
    }

    setOptionalComponent(AddUserButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOptionalComponent]);

  return isCompanyLoading ? (
    <SpinnerContainer>
      <Spinner size={60} />
    </SpinnerContainer>
  ) : (
    <>
      <TableWithFilter
        data={users ?? []}
        getTableData={getUserListData}
        handleRowClick={handleRowClick}
        filterPlaceholder="Filter users"
        enableSorting={true}
        defaultSortProperty="name"
      />
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
