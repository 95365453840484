import styled from 'styled-components';

import { IRadioButton } from '../../typescript/interfaces';
import { RadioButton } from './RadioButton';

interface IProps {
  radioButtons: IRadioButton[];
  radioButtonGroupName: string;
}

export const RadioButtons = ({ radioButtons, radioButtonGroupName }: IProps) => {
  return (
    <Wrapper>
      <div>
        {radioButtons.length > 0 &&
          radioButtons.map(({ value, label }, index) => {
            return (
              <RadioButton value={value} label={label} name={radioButtonGroupName} key={index} />
            );
          })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  > div {
    display: flex;
    gap: ${({ theme }) => theme.spacings.xsmall};
    padding: ${({ theme }) => theme.spacings.xxsmall};
    border-style: solid;
    border-width: 3px;
    border-color: #e0ded8;
    border-radius: 50px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 560px) {
      flex-direction: column;
      border-radius: 36px;
    }

    label {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex: 1;
      }
    }
  }
`;
