import { IRoleDTO } from '../../../../../typescript/interfaces';

export const updateUserRoles = (
  userRoles: IRoleDTO[],
  selectableRoles: IRoleDTO[],
  id: string,
  checked: boolean,
): IRoleDTO[] => {
  const role = selectableRoles.find((role: IRoleDTO) => role.id === id);

  let updatedRoles = [...userRoles];

  // if there is a role and it is checked, add it to the user locations
  if (role && checked) {
    updatedRoles = [...updatedRoles, role];
  }
  // if there is a role and it is not checked, remove it from the user locations
  if (role && !checked) {
    updatedRoles = updatedRoles.filter((r: IRoleDTO) => r.id !== role?.id);
  }

  return updatedRoles;
};
