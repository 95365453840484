import { useState } from 'react';

import { Table } from '../../../../../components';
import { IRoleDTO } from '../../../../../typescript/interfaces';
import { getTableData } from './getRolesTableData';
import { updateUserRoles } from './updateUserRoles';

interface IProps {
  roles: IRoleDTO[];
  userRoles?: IRoleDTO[];
  handleUpdateRoles: (updatedRoles: IRoleDTO[]) => void;
}

export const RolesContent = ({ roles, userRoles, handleUpdateRoles }: IProps) => {
  const [updatedUserRoles, setUpdatedUserRoles] = useState<IRoleDTO[] | undefined>(userRoles);

  // "selectAll" checkbox
  const isAllSelected = updatedUserRoles?.length === roles.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedRoles = checked ? roles : [];
    setUpdatedUserRoles(updatedRoles);
    handleUpdateRoles(updatedRoles);
  };

  // "role" checkboxes
  const handleRoleClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedRoles = updateUserRoles(updatedUserRoles ?? [], roles, value, checked);
      setUpdatedUserRoles(updatedRoles);
      handleUpdateRoles(updatedRoles);
    }
  };

  const tableData = getTableData(
    updatedUserRoles ?? [],
    roles,
    isAllSelected,
    handleIsAllSelected,
    handleRoleClick,
  );

  return (
    <Table
      tableData={tableData}
      enableSorting={true}
      defaultSortProperty="name"
      keepSelectedOnTop={false}
    />
  );
};
