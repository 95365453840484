import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../../components';
import { useModal } from '../../../../utils/context';
import { IAgreementDTO } from './../../../../typescript/interfaces/';
import { EditAgreement } from './EditAgreement';

interface IProps {
  companyId: string;
  org: string;
  agreement: IAgreementDTO;
}

export const EditAgreementButton = ({ companyId, org, agreement }: IProps) => {
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  const editAgreement = (companyId: string, org: string, agreement: IAgreementDTO) => {
    setModalContent({
      title: `Edit agreement ${agreement.externalId}`,
      content: <EditAgreement companyId={companyId} org={org} agreement={agreement} />,
    });
  };

  return (
    <Button
      variant="tertiary"
      size="small"
      onClick={() => editAgreement(companyId, org, agreement)}
      tooltip={`${t('admin.company.organization.editAgreement')}`}>
      <Icon icon="edit" />
    </Button>
  );
};
