import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Wrench = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.957 2a6.037 6.037 0 0 0-5.96 7.027c.162.98-.017 2.157-.86 2.999l-6.841 6.831a1.007 1.007 0 0 0 0 1.426l1.423 1.42a1.014 1.014 0 0 0 1.432 0l6.867-6.856c.835-.834 2-1.015 2.973-.86a6.037 6.037 0 0 0 7-6.284l-1.247 1.249a3 3 0 0 1-4.243 0l-1.437-1.437a3 3 0 0 1 0-4.243l1.261-1.261A6.165 6.165 0 0 0 15.957 2ZM7.915 8.032C7.915 3.595 11.517 0 15.957 0a8.04 8.04 0 0 1 2.719.47 1 1 0 0 1 .37 1.65l-2.568 2.566a1 1 0 0 0 0 1.415l1.437 1.436a1 1 0 0 0 1.414 0l2.561-2.56a1 1 0 0 1 1.65.372c.298.84.46 1.744.46 2.683 0 4.438-3.602 8.032-8.043 8.032-.436 0-.864-.035-1.282-.101-.545-.088-.99.047-1.244.3l-6.867 6.856a3.014 3.014 0 0 1-4.258 0L.883 21.7a3.007 3.007 0 0 1 0-4.257l6.841-6.831c.256-.256.39-.706.3-1.257a8.07 8.07 0 0 1-.11-1.322Z"
      fill="currentColor"
    />
  </Svg>
);
