import { withAuthenticationRequired } from '@auth0/auth0-react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { Toaster } from 'react-hot-toast';
import { initReactI18next } from 'react-i18next';
import styled from 'styled-components';

import da from './assets/locales/da.json';
import en from './assets/locales/en.json';
import sv from './assets/locales/sv.json';
import { CheckingAuth, EnvironmentBanner, Header, Routes, InformationBanner } from './components';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator', 'cookie', 'querystring', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    },
    load: 'languageOnly',
    resources: {
      da: { translation: da },
      en: { translation: en },
      sv: { translation: sv },
    },
    backend: {
      loadPath: './locales/{{lng}}.json',
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v4',
  });

const AppRoot = () => {
  return (
    <>
      <EnvironmentBanner />
      <InformationBanner />
      <Header />
      <Main>
        <Routes />
      </Main>
      <Toaster />
    </>
  );
};
export const App = withAuthenticationRequired(AppRoot, {
  onRedirecting: () => <CheckingAuth />,
});

const Main = styled.main`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacings.medium};
  padding-bottom: ${({ theme }) => theme.spacings.xlarge};
  max-width: ${({ theme }) => theme.breakpoints.xl};
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.spacings.xlarge};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding: 0;
  }
`;
