import styled from 'styled-components';

import { TableCell } from '../..';
import { IHeadCell } from '../../../typescript/interfaces';
import { ITableHeaderCell } from '../../../typescript/interfaces/IlTableHeaderCell';

interface IProps {
  headerCells: ITableHeaderCell[] | IHeadCell[];
  index: number;
  dense?: boolean;
  hiddenInXs?: boolean;
}

export const HeaderLabelCell = ({ headerCells, index, hiddenInXs }: IProps) => {
  if (headerCells.length === 0) {
    return null;
  }
  const label = headerCells[index].label;
  if (!label) {
    return null;
  }

  return (
    <LabelCell id={headerCells[index].id}>
      <HeaderLabel $hiddenInXs={hiddenInXs ?? false}>{label}:</HeaderLabel>
    </LabelCell>
  );
};

const LabelCell = styled((props) => <TableCell {...props} />)`
  &.MuiTableCell-root {
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: none;
    }
  }
`;

const HeaderLabel = styled.span<{ $hiddenInXs: boolean }>`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.label};
  font-size: ${({ theme }) => theme.fonts.size.small};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: ${({ $hiddenInXs }) => ($hiddenInXs ? 'none' : 'unset')};
  }
`;
