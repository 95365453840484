import i18n from 'i18next';

import { Status } from '../../components';
import { IHistoryDTO, ITableData, ITableHeaderCell, ITableRow } from '../../typescript/interfaces';
import { getAmount, getFormattedDate, getStatusText } from '../../utils';
import { WorkOrderHistoryRowDetailsView } from './WorkOrderHistoryRowDetailsView';
import { WorkorderTypeIndicator } from './WorkorderTypeIndicator';

const headerCells = (): ITableHeaderCell[] => {
  const { t } = i18n;
  return [
    {
      id: 'name',
      label: t('history.workorder.table.name'),
    },
    {
      id: 'action',
      label: t('history.workorder.table.action'),
    },
    {
      id: 'workorder',
      label: t('history.workorder.table.workorder'),
    },
    {
      id: 'description',
      label: t('history.workorder.table.description'),
    },
    {
      id: 'amount',
      label: t('history.workorder.table.amount'),
    },
    {
      id: 'date',
      label: t('history.workorder.table.date'),
    },
    {
      id: 'purchaseOrder',
      label: t('Purchase order'),
    },
    {
      id: 'reference',
      label: t('Reference'),
    },
  ];
};

const getRows = (data: IHistoryDTO[]): ITableRow[] => {
  if (!data) {
    return [];
  }

  const rowAndDetails = data.map((row: IHistoryDTO) => {
    const { user, action, created, sourceData, source } = row;
    const amount = getAmount(row);
    const { description, wonum, pluspcustponum, pluspcostcenter } = sourceData ?? {
      description: '',
      wonum: '',
      pluspcustponum: '',
      pluspcostcenter: '',
    };
    const status = getStatusText(action);

    return [
      {
        id: row.id,
        cells: [
          {
            id: 'name',
            value: user?.givenName + ' ' + user?.familyName,
            children: <WorkorderTypeIndicator source={source} user={user} />,
          },
          {
            id: 'action',
            value: status,
            children: <Status status={status}>{status}</Status>,
          },
          {
            id: 'workorder',
            value: wonum,
            showTooltip: true,
          },
          {
            id: 'description',
            value: description,
            showTooltip: true,
          },
          {
            id: 'amount',
            value: amount,
            showTooltip: true,
          },
          {
            id: 'date',
            value: getFormattedDate(created),
            showTooltip: true,
          },
          {
            id: 'purchaseOrder',
            value: pluspcustponum,
            showTooltip: true,
          },
          {
            id: 'reference',
            value: pluspcostcenter,
            showTooltip: true,
          },
        ],
        sx: { cursor: 'pointer' },
      },
      {
        id: row.id + '_details',
        cells: [
          {
            id: 'details',
            value: <WorkOrderHistoryRowDetailsView row={row} />,
            colSpan: 6,
            className: 'details',
          },
        ],
        className: 'details',
      },
    ];
  });

  return rowAndDetails.flatMap((x) => x);
};

export const getWorkorderHistoryTableData = (data: IHistoryDTO[]): ITableData => {
  const tableData = {
    headerCells: headerCells(),
    rows: getRows(data),
  };

  return tableData;
};
