import MuiTableRow, { TableRowProps } from '@mui/material/TableRow';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface IProps extends TableRowProps {
  tableHasExpandalbeRows?: boolean;
}

export const TableRow: FC<IProps> = ({ children, tableHasExpandalbeRows = false, ...rest }) => {
  const { onClick } = rest;
  return (
    <StyledTableRow
      {...rest}
      $selectable={!!onClick}
      $tableHasExpandalbeRows={tableHasExpandalbeRows}>
      {children}
    </StyledTableRow>
  );
};

interface IStyledTableRow extends IProps {
  $selectable: boolean;
  $tableHasExpandalbeRows?: boolean;
}

const StyledTableRow = styled(MuiTableRow)<IStyledTableRow>`
  cursor: ${({ hover }) => (hover ? 'pointer' : 'unset')};

  &.MuiTableRow-root {
    background: ${({ theme }) => theme.colors.background.tableRow};
        &:nth-child(even) {
          background: ${({ theme }) => theme.colors.background.body};
        }

    ${({ $tableHasExpandalbeRows }) =>
      $tableHasExpandalbeRows &&
      css`
        background: ${({ theme }) => theme.colors.background.body};
        &:nth-child(4n + 1),
        &:nth-child(4n + 2) {
          background: ${({ theme }) => theme.colors.background.tableRow};
        }
      `}}

    ${({ hover, $selectable }) =>
      hover &&
      $selectable &&
      css`
        &:hover {
          td {
            background: ${({ theme }) => theme.colors.background.tableRowHover};
            color: ${({ theme }) => theme.colors.text.tableRowHover};

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
              background: none;
            }
          }
        }
      `}

    &.filler {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        display: none;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: grid;
      grid-template-columns: 120px 1fr;
      gap: ${({ theme }) => theme.spacings.xsmall};
      padding: ${({ theme }) => theme.spacings.small};
      border-bottom: 1px solid ${({ theme }) => theme.colors.background.tag};
    }
  }
`;
