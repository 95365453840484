import { IFieldItem } from '../../../typescript/interfaces';
import { Pluspcustpriceest, PLUSPESTAT, WorkOrder } from '../../../typescript/types';
import { addThousandSeperator } from './../../../utils/addThousandSeperator';
import { getStatusText } from './../../../utils/getStatusText';

const getEstimatedQuote = (workOrder: WorkOrder) => {
  let cache = '';
  const memoize = (workOrder: WorkOrder) => {
    if (cache) {
      return cache;
    }

    const { pluspcustpricest } = workOrder;
    const acceptedEst =
      pluspcustpricest?.find((pricest) => pricest.pluspeststat === PLUSPESTAT.ACCEPTED) ??
      ({} as Pluspcustpriceest);

    cache =
      !acceptedEst.plusppriceest || acceptedEst.plusppriceest === '0'
        ? ''
        : addThousandSeperator(acceptedEst.plusppriceest);

    return cache;
  };

  return memoize(workOrder);
};
export const getCompletedWorkFields = (workOrder: WorkOrder): IFieldItem[] => {
  const status = getStatusText(workOrder.pluspnextbillstat);
  return [
    {
      title: 'Work order',
      content: workOrder.wonum,
      name: 'wonum',
    },
    {
      title: 'Description',
      content: `${workOrder.description ?? ''}
      ${workOrder.description_longdescription ?? ''}`,
      name: 'description',
      boldContent: false,
      style: { gridColumn: 'span 2', maxHeight: '167px', overflowY: 'scroll' },
    },
    {
      title: 'Action reporting',
      content: `${workOrder.remarkdesc ?? ''}
      ${workOrder.failureremark_description_longdescription ?? ''}`,
      name: 'remarkdesc',
      style: { maxHeight: '167px', overflowY: 'scroll' },
    },
    {
      title: 'Location',
      content: workOrder.location_description,
      name: 'location_description',
    },
    {
      title: 'Customer',
      content: workOrder.pluspcustomer_name,
      name: 'pluspcustomer_name',
    },
    {
      title: 'Service',
      content: workOrder.commodity,
      name: 'commodity',
    },
    {
      title: 'Reported by',
      content: workOrder.reportedby,
      name: 'reportedby',
    },
    {
      title: 'Report date',
      content: workOrder.reportdate,
      name: 'reportdate',
    },
    {
      title: 'Finish date',
      content: workOrder.actfinish,
      name: 'actfinish',
    },
    {
      title: 'Agreement',
      content: workOrder.pluspagreement,
      name: 'pluspagreement',
    },
    {
      title: 'Current status',
      content: status,
      name: 'pluspnextbillstat',
      status,
    },
    {
      title: 'Estimated amount',
      content: getEstimatedQuote(workOrder) ?? '',
      style: {
        display: `${getEstimatedQuote(workOrder) ? 'flex' : 'none'}`,
      },
      name: 'pluspestpriceest',
    },
  ];
};
