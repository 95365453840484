import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ICrumb {
  path?: string;
  pathName: string;
}

export const Crumb = ({ path, pathName }: ICrumb) => {
  const crumb = path ? <Link to={path}>{pathName}</Link> : <p>{pathName}</p>;
  return <Container>{crumb}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  &:not(:last-child) {
    display: flex;

    &::after {
      content: '>';
      margin-left: ${({ theme }) => theme.spacings.medium};
      color: ${({ theme }) => theme.colors.text.disabled};
    }
  }

  &:last-child {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  a::first-letter,
  p::first-letter {
    text-transform: capitalize;
  }
`;
