import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spinner } from '../../../components';
import { usePermissions, useUserService } from '../../../hooks';
import { PermissionsList } from './PermissionsList';

export const UserPermissions = () => {
  const { t } = useTranslation();
  const { selectedCompany } = usePermissions();
  const { me, isMeLoading } = useUserService();
  const { user, isUserLoading, error } = useUserService(me?.id, selectedCompany?.id);

  if (isUserLoading || isMeLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  // If there is an error or no user, we pressume that the user has no permissions
  if (error || !user) {
    return (
      <Wrapper>
        <p>{t('userArea.permissions.noPermissionsFound')}</p>
      </Wrapper>
    );
  }

  const { assignedAgreements = [], assignedLocations = [], assignedServices = [] } = user;

  return (
    <Wrapper>
      <PermissionsList
        permissions={assignedAgreements}
        title={t('userArea.agreementsList.title')}
        noPermissionsText={t('userArea.agreementsList.noAgreementsFound')}
      />
      <PermissionsList
        permissions={assignedLocations}
        title={t('userArea.locationsList.title')}
        noPermissionsText={t('userArea.locationsList.noLocationsFound')}
      />
      <PermissionsList
        permissions={assignedServices}
        title={t('userArea.servicesList.title')}
        noPermissionsText={t('userArea.servicesList.noServicesFound')}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
