import { TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';
import styled from 'styled-components';

import { TableCell } from '..';
import { ITableCell, ITableHeaderCell } from '../../../typescript/interfaces';
import { Order } from '../../../typescript/types';

type RequestSortType = (property: string) => void;

interface IProps {
  headerCells: ITableHeaderCell[];
  dense?: boolean;
  onRequestSort?: RequestSortType;
  order: Order;
  orderBy: string;
}

const getSortableHeaderCells = (
  headerCells: ITableHeaderCell[],
  dense = false,
  order: Order,
  orderBy: string,
  onRequestSort: RequestSortType,
) => {
  return headerCells.map((headerCell, index) => {
    const { align, padding, sortDirection, label, id, sortable } = headerCell;
    const createSortHandler = (property: string) => {
      onRequestSort(property);
    };

    const direction = orderBy === id ? order : 'asc';
    const active = orderBy === id;

    return (
      <TableCell
        key={index}
        align={align}
        padding={padding}
        sortDirection={sortDirection}
        variant="head"
        dense={dense}>
        {sortable !== false ? (
          <TableSortLabel
            onClick={() => createSortHandler(id)}
            direction={direction}
            active={active}>
            {label}
          </TableSortLabel>
        ) : (
          label
        )}
      </TableCell>
    );
  });
};

const getUnsortableHeaderCells = (headerCells: ITableHeaderCell[], dense = false) => {
  return headerCells.map((headerCell, index) => {
    const { align, padding, sortDirection, label } = headerCell;
    return (
      <TableCell
        key={index}
        align={align}
        padding={padding}
        sortDirection={sortDirection}
        variant="head"
        dense={dense}>
        {label}
      </TableCell>
    );
  });
};

const getHeaderCells = (
  headerCells: ITableHeaderCell[],
  dense = false,
  order: Order,
  orderBy: string,
  onRequestSort?: RequestSortType,
): JSX.Element[] => {
  if (!headerCells || headerCells.length === 0) {
    return [];
  }

  if (onRequestSort) {
    return getSortableHeaderCells(headerCells, dense, order, orderBy, onRequestSort);
  }

  return getUnsortableHeaderCells(headerCells, dense);
};

export const TableHead = ({ headerCells, dense, order, orderBy, onRequestSort }: IProps) => {
  if (!headerCells || headerCells.length === 0) {
    return null;
  }
  return (
    <StyledTableHead>
      <TableRow>{getHeaderCells(headerCells, dense, order, orderBy, onRequestSort)}</TableRow>
    </StyledTableHead>
  );
};

const StyledTableHead = styled(MuiTableHead)`
  &.MuiTableHead-root {
    th {
      border-bottom: 1px solid ${({ theme }) => theme.colors.background.tag};
      padding-top: ${({ theme }) => theme.spacings.medium};
      padding-bottom: ${({ theme }) => theme.spacings.medium};

      span {
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
        font-size: ${({ theme }) => theme.fonts.size.preamble};
        color: ${({ theme }) => theme.colors.text.body};
      }
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: none;
    }
  }
`;
