import styled from 'styled-components';
import { IRoleDTO } from 'typescript/interfaces';

import { Spinner, Table, WizardActionType, useWizardContext } from '../../../../../components';
import { useRoleService } from '../../../../../hooks';
import { getTableData } from '../../EditUserPage/Roles/getRolesTableData';
import { updateUserRoles } from '../../EditUserPage/Roles/updateUserRoles';

interface IProps {
  companyId: string;
}

export const AddRoles = ({ companyId }: IProps) => {
  const { setStepValidity, state, dispatch } = useWizardContext();
  const { currentStep, steps } = state;
  const currentStepState = steps.get(currentStep) ?? {};
  const roles: IRoleDTO[] = currentStepState?.roles ?? [];

  const {
    rolesByCompany: selectableRoles = [],
    rolesByCompanyIsLoading: selectableRolesIsLoading,
  } = useRoleService(companyId);

  // "selectAll" checkbox
  const isAllSelected = roles.length === selectableRoles.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedRoles = checked ? selectableRoles : [];
    updateStepState(updatedRoles);
  };

  // "role" checkboxes
  const handleRoleClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedRoles = updateUserRoles(roles, selectableRoles, value, checked);
      updateStepState(updatedRoles);
    }
  };

  const updateStepState = (roles: IRoleDTO[]) => {
    dispatch({
      type: WizardActionType.StepState,
      payload: { step: currentStep, state: { roles } },
    });
    setStepValidity(currentStep, roles.length > 0);
  };

  const tableData = getTableData(
    roles,
    selectableRoles,
    isAllSelected,
    handleIsAllSelected,
    handleRoleClick,
  );

  if (selectableRolesIsLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <TableWrapper>
      <Table tableData={tableData} enableSorting={true} keepSelectedOnTop={false} />
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  max-height: 509px;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
