import { useAuth0 } from '@auth0/auth0-react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';

interface IProps {
  text?: string;
  onClick: () => void;
}

export const Logout = ({ text = 'Logout', onClick }: IProps) => {
  const { logout } = useAuth0();
  const IPA_AUTH_REDIRECTURI = process.env.REACT_APP_IPA_AUTH_REDIRECTURI ?? '';
  const handleClose = () => {
    onClick();
    // Clear permissions from local storage
    localStorage.removeItem('permissions');
    // Clear selected company from local storage
    localStorage.removeItem('selectedCompany');
    // Log local storage out
    logout({ returnTo: IPA_AUTH_REDIRECTURI });
  };

  return (
    <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      {text}
    </MenuItem>
  );
};
