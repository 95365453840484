import { useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '../../components/Spinner/Spinner';
import { useAppState, useGetCommunicationLog } from '../../hooks';
import { LogList } from './LogList';

interface IProps {
  wonum: string;
  orgid: string;
  companyId: string;
}

export const CommunicationLog = ({ wonum, orgid, companyId }: IProps) => {
  const [fetchDate] = useState<string>(Date.now().toString());

  const {
    appState: { currentPage },
  } = useAppState();
  const { data: result, isLoading } = useGetCommunicationLog({
    wonum,
    orgid,
    companyId,
    currentPage,
    fetchDate,
  });

  const tableData = result?.data;

  const details =
    tableData && tableData.rows.length > 0 ? (
      <LogList data={tableData} />
    ) : (
      <NoHistory>No history found for this work order</NoHistory>
    );

  return isLoading ? (
    <SpinnerContainer>
      <Spinner size={60} />
    </SpinnerContainer>
  ) : (
    <Container>{details}</Container>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: calc(2 * ${({ theme }) => theme.spacings.large});

  > div:first-child {
    flex: 1;
  }
`;

const NoHistory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
