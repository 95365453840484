import styled from 'styled-components';

interface IProps {
  title: string;
}

export const FieldTitle = ({ title }: IProps) => {
  return <Title>{title}:</Title>;
};

export const Title = styled.p`
  white-space: nowrap;
  margin-right: 0.5rem;
`;
