import styled from 'styled-components';

import { IFieldItem } from '../../../typescript/interfaces';
import { FieldItem } from '../FieldItem/FieldItem';

interface IProps {
  fieldItems: IFieldItem[];
}

export const Fields = ({ fieldItems }: IProps) => {
  if (!fieldItems.length) {
    return null;
  }

  const secondCol = [...fieldItems];
  const firstCol = secondCol.splice(0, 2);

  return (
    <Container className="fields">
      <SubCol>
        {firstCol.length &&
          firstCol.map((fieldItem: IFieldItem, index: number) => (
            <FieldItem fieldItem={fieldItem} key={index} />
          ))}
      </SubCol>
      <SubCol>
        {secondCol.length &&
          secondCol.map((fieldItem: IFieldItem, index: number) => (
            <FieldItem fieldItem={fieldItem} key={index} />
          ))}
      </SubCol>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: calc(4 * ${({ theme }) => theme.spacings.medium});
  grid-template-areas: 'firstCol secondCol secondCol';

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-areas:
      'firstCol firstCol firstCol'
      'secondCol secondCol secondCol';
  }
`;

const SubCol = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(267px, 1fr));
  row-gap: ${({ theme }) => theme.spacings.medium};
  column-gap: calc(4 * ${({ theme }) => theme.spacings.medium});
  background-color: ${({ theme }) => theme.colors.background.body};
  padding-bottom: ${({ theme }) => theme.spacings.xlarge};

  &:first-of-type {
    grid-area: firstCol;
    z-index: 1;

    div {
      border: none;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    }
  }

  &:last-of-type {
    grid-area: secondCol;
    background: none;
    padding-top: ${({ theme }) => theme.spacings.medium};

    > div:first-child {
      border-top: none;
    }

    @media screen and (min-width: 624px) {
      > div:nth-child(-n + 2) {
        border-top: none;
      }
    }

    @media screen and (min-width: 952px) {
      > div:nth-child(-n + 3) {
        border-top: none;
      }
    }

    @media screen and (min-width: 1281px) {
      padding-top: 0;
      > div:nth-child(-n + 3) {
        border-top: 1px solid ${({ theme }) => theme.colors.border.default};
      }

      > div:nth-child(-n + 2) {
        border-top: none;
      }
    }

    @media screen and (min-width: 1476px) {
      padding-top: 0;
      > div:nth-child(-n + 3) {
        border-top: 0;
      }
    }
  }
`;
