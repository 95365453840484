import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';

import {
  getRolesByCompany as getRolesByCompanyAPI,
  getRolesByCompanyAndUser as getRolesByCompanyAndUserAPI,
} from '../api';
import { useError } from './useError';
import { useTokenQuery } from './useTokenQuery';

export const useRoleService = (companyId?: string, userId?: string) => {
  const { isAuthenticated, isLoading: authIsLoading } = useAuth0();
  const { data: token } = useTokenQuery();

  const [setError] = useError();

  // Get roles
  const { data: rolesByCompany, isLoading: rolesByCompanyIsLoading } = useQuery(
    ['roles'],
    () => getRolesByCompanyAPI(token as string, companyId ?? ''),
    {
      enabled: !!token && !!isAuthenticated && !authIsLoading && !!companyId,
      onError: (error) => {
        setError(error);
      },
    },
  );

  // Get roles by company and user
  const { data: rolesByCompanyAndUser, isLoading: rolesByCompanyAndUserIsLoading } = useQuery(
    ['roles', userId, companyId],
    () => getRolesByCompanyAndUserAPI(token as string, companyId ?? '', userId ?? ''),
    {
      enabled: !!token && !!isAuthenticated && !authIsLoading && !!companyId && !!userId,
      onError: (error) => {
        setError(error);
      },
    },
  );

  return {
    rolesByCompany,
    rolesByCompanyIsLoading,
    rolesByCompanyAndUser,
    rolesByCompanyAndUserIsLoading,
  };
};
