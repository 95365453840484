import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { usePermissions } from '../../../hooks';
import { Icon } from '../../UI';
import { HeaderLinkRenderContext } from '../Header';
import { Link } from './Link';

export const CustomerAdmin = () => {
  const { renderItem } = useContext(HeaderLinkRenderContext);
  const { pathname } = useLocation();
  const { hasPermissionToViewCustomerAdminForCompany, selectedCompany } = usePermissions();

  // If there is no company that has company.read permission, don't show the icon
  if (!hasPermissionToViewCustomerAdminForCompany) {
    return null;
  }

  const blackListedPaths = ['history', 'completed-work', 'proposed-work'];
  const pathsIncludesBlacklistedPath = pathname
    .split('/')
    .filter((path) => path !== '')
    .some((path) => blackListedPaths.includes(path));

  return renderItem({
    icon: <Icon icon="company" />,
    text: 'Company',
    Link: ({ children }) => (
      <Link path={`company/${selectedCompany?.id}`} isActive={!pathsIncludesBlacklistedPath}>
        {children}
      </Link>
    ),
  });
};
