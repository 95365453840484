import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { CompletedWorkOrder, Loader, ProposedWorkOrder } from '..';
import { usePermissions, useUserService } from '../../hooks';
import {
  HistoryPage,
  ErrorPage,
  MainPage,
  EditUserPage,
  CompanyPage,
  EditCompanyPage,
  OnboardPage,
  WelcomePage,
} from '../../pages';
import { CompletedWorkOrders } from '../CompletedWorkOrders/CompletedWorkOrders';
import { ProposedWorkOrders } from '../ProposedWorkOrders/ProposedWorkOrders';
import { ProtectedRoute } from './ProtectedRoute';

export const Routes = () => {
  const { isLoading: authIsLoading } = useAuth0();
  const {
    hasPermissionToViewCustomerAdminForCompany,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
    permissionsError,
    permissionsContextIsLoading,
  } = usePermissions();

  const { me, isMeLoading, isError } = useUserService(
    undefined,
    undefined,
    permissionsContextIsLoading,
  );

  const env = process.env.REACT_APP_ENV;
  const isDevEnv = env === 'development' || env === 'mock';

  if (!isDevEnv && (permissionsError !== null || isError)) {
    const errorMessage = permissionsError?.message ?? 'An error occurred while fetching user';
    return <ErrorPage heading="An error occurred 😬" error={errorMessage} />;
  }

  if (isMeLoading || permissionsContextIsLoading || authIsLoading) {
    return <Loader />;
  }

  return (
    <ReactRoutes>
      {/* Proposed work */}
      <Route
        path="/company/:companyId/proposed-work/:id/:org"
        element={
          <ProtectedRoute
            hasPermissions={hasPermissionsToViewProposedWorkorderForCompany}
            onboard={me?.onboard}>
            <ProposedWorkOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/company/:companyId/proposed-work"
        element={
          <ProtectedRoute
            hasPermissions={hasPermissionsToViewProposedWorkorderForCompany}
            onboard={me?.onboard}>
            <ProposedWorkOrders />
          </ProtectedRoute>
        }
      />

      {/* Completed work */}
      <Route
        path="/company/:companyId/completed-work/:id/:org"
        element={
          <ProtectedRoute
            hasPermissions={hasPermissionsToViewCompletedWorkorderForCompany}
            onboard={me?.onboard}>
            <CompletedWorkOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/company/:companyId/completed-work"
        element={
          <ProtectedRoute
            hasPermissions={hasPermissionsToViewCompletedWorkorderForCompany}
            onboard={me?.onboard}>
            <CompletedWorkOrders />
          </ProtectedRoute>
        }
      />

      {/* Audit log */}
      <Route
        path="/company/:companyId/history"
        element={
          <ProtectedRoute
            hasPermissions={
              hasPermissionsToViewProposedWorkorderForCompany ||
              hasPermissionsToViewCompletedWorkorderForCompany ||
              hasPermissionToViewCustomerAdminForCompany
            }
            onboard={me?.onboard}>
            <HistoryPage />
          </ProtectedRoute>
        }
      />

      {/* Company admin */}
      <Route
        path="/company"
        element={
          <ProtectedRoute
            hasPermissions={hasPermissionToViewCustomerAdminForCompany}
            onboard={me?.onboard}>
            <CompanyPage />
          </ProtectedRoute>
        }>
        {/* Company */}
        <Route path=":companyId" element={<EditCompanyPage />} />
        <Route path=":companyId/users" element={<EditCompanyPage />} />
        <Route path=":companyId/users/:userId" element={<EditUserPage />} />
      </Route>

      {/* Onboard */}
      <Route path="/onboard" element={<OnboardPage />} />

      {/* Welcome */}
      <Route path="/welcome" element={<WelcomePage />} />

      {/* Main page */}
      <Route index element={<MainPage />} />
    </ReactRoutes>
  );
};
