import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components';
import { useModal } from '../../../../utils/context';
import { AddAgreement } from './AddAgreement';

interface IProps {
  companyId: string;
  org: string;
}

export const AddAgreementButton = ({ companyId, org }: IProps) => {
  const { t } = useTranslation();
  const { setModalContent } = useModal();
  const addAgreement = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    companyId: string,
    org: string,
  ) => {
    e.preventDefault();
    setModalContent({
      title: 'Add agreement',
      content: <AddAgreement companyId={companyId} org={org} />,
    });
  };

  return (
    <Button onClick={(e) => addAgreement(e, companyId, org)} variant="secondary" size="small">
      {t('admin.company.organization.addAgreement')}
    </Button>
  );
};
