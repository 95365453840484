import { FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Icon, Input, Spinner } from './../../../../components';
import { useCompanyService, usePermissions } from './../../../../hooks';
import { ICompanyDTO } from './../../../../typescript/interfaces/ICompanyDTO';

interface IFormData {
  companyName: string;
  companyNameIsValid: boolean | undefined;
  hasQuickResponse: boolean;
  isFormChanged: boolean;
}

interface IProps {
  company: ICompanyDTO;
}

export const EditCompany = ({ company }: IProps) => {
  const { t } = useTranslation();
  const { hasAdminPermission } = usePermissions();
  const { updateCompany, deleteCompany, isUpdateCompanyLoading, isDeleteCompanyLoading } =
    useCompanyService();
  const isLoading = isUpdateCompanyLoading || isDeleteCompanyLoading;
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    companyName: company?.name ?? '',
    companyNameIsValid: !!company?.name,
    hasQuickResponse: company?.hasQuickResponse ?? false,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid =
      formData.companyNameIsValid === true && formData.isFormChanged === true && !isLoading;
    setIsFormValid(isFormValid);
  }, [formData]);

  const removeCompany = (companyId: string) => {
    const isConfirmed = window.confirm(`${t('general.confirmAction')}`);

    if (!isConfirmed) {
      return;
    }

    deleteCompany(companyId);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, company: ICompanyDTO) => {
    e.preventDefault();
    const name = e.currentTarget.companyName.value;
    const hasQuickResponse =
      e.currentTarget.hasQuickResponse?.checked ?? company.hasQuickResponse ?? false;
    const updatedCompany = { ...company, name, hasQuickResponse };
    updateCompany(updatedCompany);
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value, checked } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = name === 'hasQuickResponse' ? true : !!value;

    setFormData({
      ...formData,
      [name]: name === 'hasQuickResponse' ? checked : value,
      [`${name}IsValid`]: isValid,

      isFormChanged: true,
    });
  };

  if (isLoading) {
    return <Spinner size={20} />;
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e, company)} onChange={(e) => handleFormChange(e)}>
      <Input
        name="companyName"
        value={formData.companyName}
        warning={formData.companyNameIsValid === false}
        placeholder={
          formData.companyNameIsValid === false ? t('admin.company.companyIsRequired') ?? '' : ''
        }
      />
      {hasAdminPermission && (
        <FormControlLabel
          control={<Switch name="hasQuickResponse" checked={formData.hasQuickResponse} />}
          label="Has Quick Response"
        />
      )}
      <ButtonContainer>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          onClick={() => removeCompany(company.id)}>
          <Icon icon="delete" />
          {`${t('admin.company.removeCompany')}`}
        </Button>
        <Button size="small" type="submit" disabled={!isFormValid}>
          Save changes
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
