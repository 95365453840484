import styled from 'styled-components';

import { CustomerAdmin } from './CustomerAdmin';
import { History } from './History';
import { SystemAdminDropdown } from './SystemAdminDropdown';
import { WorkOrder } from './WorkOrder';

export const AdminArea = () => {
  return (
    <Container>
      <Buttons>
        <WorkOrder />
        <History />
        <CustomerAdmin />
        <SystemAdminDropdown />
      </Buttons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.large};
`;

const Buttons = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.border.default};
  border-left: 1px solid ${({ theme }) => theme.colors.border.default};
  display: flex;
  gap: ${({ theme }) => theme.spacings.large};
  padding: ${({ theme }) => theme.spacings.large};
  font-size: ${({ theme }) => theme.fonts.size.h3};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    border-left: none;
  }
`;
