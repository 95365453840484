import { useParams } from 'react-router-dom';

import { CompletedWorkDetailedView } from '../../components/DetailView/CompletedWorkDetailedView';
import { InvalidPage } from '../../pages/InvalidPage/InvalidPage';

export const CompletedWorkOrder = () => {
  const { id } = useParams();
  const workOrderId = Number(id);

  if (!id) {
    return <InvalidPage header="Missing workorder" text={`No workorder with id: ${id} found.`} />;
  }

  if (isNaN(workOrderId)) {
    return <InvalidPage header="Wrong format" text={`Wrong format on workorder id`} />;
  }
  return <CompletedWorkDetailedView />;
};
