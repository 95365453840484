import styled from 'styled-components';
import { IFieldItem } from 'typescript/interfaces';
import { FieldChange } from 'typescript/types';

import { TextArea } from '../..';
import { GridItem } from '../../DetailView/Griditem';
import { FieldTitle } from './FieldTitle';

interface IProps {
  fieldItem: IFieldItem;
  handleChange?: (event: FieldChange) => void;
}

export const TextAreaField = ({ fieldItem, handleChange, ...rest }: IProps) => {
  const { title, placeholder, warning, warningMessage, name, textAreaProps } = fieldItem;
  const changeHandler = (event: FieldChange) => {
    if (handleChange) {
      handleChange(event);
    }
  };
  const placeHolderText = warning ? warningMessage : placeholder ? placeholder : '';
  const Title = () => (title ? <FieldTitle title={title} /> : null);
  const style = { ...fieldItem?.style, flex: '1' };

  return (
    <Wrapper className="fieldItem" style={style} {...rest}>
      <Title />
      <TextArea
        maxLength={400}
        onChange={changeHandler}
        name={name}
        placeholder={placeHolderText}
        warning={warning}
        {...textAreaProps}
      />
    </Wrapper>
  );
};

export const Wrapper = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
  flex: 1;
`;
