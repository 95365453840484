import { WorkOrder } from 'typescript/types';

export const mockCompletedWorkOrders: Partial<WorkOrder>[] = [
  {
    workorderid: '12564470',
    wonum: 'M10004528',
    siteid: 'SE02AR01',
    pluspcustomer_name: 'ICA FASTIGHETER AB',
    pluspagreement: 'A16121',
    status: '',
    pluspquotetype: '',
    commodity: 'S100',
    reportdate: '2021-12-29',
    actfinish: '2021-12-30',
    description: 'Kallhäll - Klädförrådet - Kontorsyta - Arbetsplats - Annat',
    description_longdescription:
      'Dörren till klädförrådet har slutat fungera. Korten läses inte av, Vi bytte batterierna, men den fungerar  iallafall inte. det är flera anställda, som har behörigheten som sagt till att deras kort inte funkar. Jag har tagit bort behörigheten och lagt till den... men nej :( Vi behöver IO-nummer till Larmtronic så de får komma och kolla på dörren. // rapporterat av Anna Coor',
    location: '15032',
    location_description: 'ICA KALLHÄLL',
    reportedby: 'Extrakonto Reception Kallhäll (reckal) (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: 'se info:',
    failureremark_description_longdescription:
      'Larmtronic kom och löste problemet. Det var dublett på behörigheten.// Anna  Avslutar ordern. 30/12-21',
    pluspwopricetotals: {
      labbillpr: 2847,
      matbillpr: 0,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: 'Mediateknik',
        craft: 'TECHOTHE',
        regularhrs: '3',
        plusplineprice: '2847',
        plusplistprice: '949',
      },
    ],
    servrectrans: [
      {
        description: 'Larmtronic Västerås AB - 112413',
        quantity: '1',
        plusplineprice: '0',
        plusplistprice: '0',
      },
    ],
  },

  {
    workorderid: '12656849',
    wonum: 'M10074685',
    siteid: 'SE02AR04',
    pluspcustomer_name: 'ICA Sverige AB',
    pluspagreement: 'A16147',
    status: '',
    pluspquotetype: '',
    commodity: 'F120',
    reportdate: '2022-01-15',
    actfinish: '2022-01-15',
    description:
      'SKADEGÖRELSE - JOUR - Helsingborg - Port 10.14 - Lageryta - Portar/Dörrar/Bommar -',
    description_longdescription:
      'JOUR 2022-01-15 12:27 Port 10.14 (tror han) porten i anslutning till frysen (den högra) mellan stora utlstningen och fryslagret sluss med 2 portar (den högra). Porten går ej att få ned. Har hängt sig/fastnat i ett läge. Jourperson Kim kontaktades 12:32',
    location: '15029',
    location_description: 'ICA HELSINGBORG',
    reportedby: 'Michel Cavalli (l00mci) (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: 'Åtgärd',
    failureremark_description_longdescription:
      'Skadegörelse. Port påkörd. Tagit fram stege och lagt rätt duken i skenan. Bottenbalk krökt.   Funktionskontroll OK  Åtgärdat kl 14:00   Port ska bytas så vidtar inte fler åtgärder',
    pluspwopricetotals: {
      labbillpr: 2444.6,
      matbillpr: 0,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: 'Porttekniker',
        craft: 'TECHOP',
        regularhrs: '2',
        plusplineprice: '2444.6',
        plusplistprice: '1222.3',
      },
    ],
    servrectrans: [],
  },

  {
    workorderid: '12831329',
    wonum: 'M10209277',
    siteid: 'SE02AR04',
    pluspcustomer_name: 'ICA Sverige AB',
    pluspagreement: 'A16147',
    status: '',
    pluspquotetype: '',
    commodity: 'F120',
    reportdate: '2022-02-21',
    actfinish: '2022-02-22',
    description:
      'LSW // DAGJOUR Helsingborg - SKADEGÖRELS E - Port 54A - Lageryta - Portar/D#246;rr...',
    description_longdescription:
      'DAGJOUR 2022-02-21 11:34 ICA Personal ringer ang Port 54A som har fastnat i öppet lägge; detta pga någon har ställt en sopkvast under porten, och när man har kört ner den så har sopkvasten fastnat mellan porten och väggen. Servicecentret kontaktade dagjouren (Linn) kl 11:35.',
    location: '15029-00-1001',
    location_description: 'RUM 1001 Kolonial utl./PLAN 1/HUS B101',
    reportedby: 'Emilie Wadenhed (h20elo) (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: '',
    failureremark_description_longdescription:
      'Hej.  Har stängt av portskåpet. Frikopplat porten. Fått upp den så att kvasten som satt fast kom loss. Drog ner porten till stängt läge tog bort frikopplingen. Startade portskåpet. Återställde porten.    Mvh Linn på coor.',
    pluspwopricetotals: {
      labbillpr: 1438,
      matbillpr: 0,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'JANITOR',
        regularhrs: '1',
        plusplineprice: '420',
        plusplistprice: '420',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'JANITOR',
        regularhrs: '1',
        plusplineprice: '420',
        plusplistprice: '420',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOP',
        regularhrs: '2',
        plusplineprice: '1022',
        plusplistprice: '511',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'JANITOR',
        regularhrs: '-2',
        plusplineprice: '-840',
        plusplistprice: '-420',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: 'Porttekniker',
        craft: 'TECHOP',
        regularhrs: '2',
        plusplineprice: '1438',
        plusplistprice: '719',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOP',
        regularhrs: '-2',
        plusplineprice: '-1022',
        plusplistprice: '-511',
      },
    ],
    servrectrans: [],
  },

  {
    workorderid: '12924047',
    wonum: 'M10281712',
    siteid: 'SE02AR01',
    pluspcustomer_name: 'ICA Sverige AB',
    pluspagreement: 'A16147',
    status: '',
    pluspquotetype: '',
    commodity: 'F120',
    reportdate: '2022-03-10',
    actfinish: '2022-03-22',
    description:
      'SKADEGÖRELSE - Årsta - Utlastningen Port 26 Lageryta - Portar/Dörrar/Bommar - Kan inte öppnas/stänga',
    description_longdescription:
      'Lageryta - Portar/Dörrar/Bommar - Kan inte öppnas/stänga. Port 26, lastbryggan hamnar snett vid anvädning.',
    location: '17353-01',
    location_description: 'ICA 3952-DE Stockholm SP Årsta',
    reportedby: 'Roger Fajersson (h03rfn) (EXT)',
    pluspcostcenter: '3952',
    pluspcustponum: 'H03rfn',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: 'Åtgärd',
    failureremark_description_longdescription:
      'Felsökning. Kontaktar ue för åtgärd. (Hörmann)  Förser Hörmann med dokumentation för Heta arbeten.  Svetsning samt skärning av brygga som var inbacka. Byte av bummper svatsplatta och plåtar.  Kontrollerad. Klar.',
    pluspwopricetotals: {
      labbillpr: 1632,
      matbillpr: 29205,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [
      {
        plusplineprice: '29205',
        positivequantity: '1',
        description: 'Hörmann Svenska AB - C13-643356/0502119556',
        plusplistprice: '29205',
      },
    ],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOPAS',
        regularhrs: '1',
        plusplineprice: '544',
        plusplistprice: '544',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOPAS',
        regularhrs: '1',
        plusplineprice: '544',
        plusplistprice: '544',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOPAS',
        regularhrs: '1',
        plusplineprice: '544',
        plusplistprice: '544',
      },
    ],
    servrectrans: [
      {
        description: 'Hörmann Svenska AB - C13-643356/0502119556',
        quantity: '1',
        plusplineprice: '0',
        plusplistprice: '0',
      },
    ],
  },

  {
    workorderid: '12925078',
    wonum: 'M10282460',
    siteid: 'SE02AR01',
    pluspcustomer_name: 'ICA Sverige AB',
    pluspagreement: 'A16147',
    status: '',
    pluspquotetype: '',
    commodity: 'F130',
    reportdate: '2022-03-10',
    actfinish: '2022-03-18',
    description: 'SKADEGÖRELSE - Viby - Dagvattenbrunn påkörd, sönder',
    description_longdescription: '',
    location: '33734',
    location_description: 'ICA VIBY',
    reportedby: 'Kerollos Iskarous',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: 'Åtgärd',
    failureremark_description_longdescription:
      'Skadegörelse är anmält. /KI  Cubile AB har varit på plats och satt en ny dagvattebrun. /KI',
    pluspwopricetotals: {
      labbillpr: 511,
      matbillpr: 6661.6,
      srvbillpr: 2213.61,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [
      {
        plusplineprice: '6661.6',
        positivequantity: '1',
        description: 'Cubile Utemiljö AB - 19298',
        plusplistprice: '6661.6',
      },
    ],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOP',
        regularhrs: '1',
        plusplineprice: '511',
        plusplistprice: '511',
      },
    ],
    servrectrans: [
      {
        description: 'Cubile Utemiljö AB - 19298',
        quantity: '1',
        plusplineprice: '2213.61',
        plusplistprice: '2213.61',
      },
    ],
  },

  {
    workorderid: '12926884',
    wonum: 'M10284021',
    siteid: 'SE02AR01',
    pluspcustomer_name: 'ICA Sverige AB',
    pluspagreement: 'A16147',
    status: '',
    pluspquotetype: '',
    commodity: 'F170',
    reportdate: '2022-03-11',
    actfinish: '2022-03-17',
    description: 'SKADEGÖRELSE - Hacksta - 1-2 Hissen i frysen Kontorsyta - Övrigt -',
    description_longdescription:
      'Hissen i frysen är ur funktion, en medarbetare blev inlåst tills vakten kunde släppa ut hen.',
    location: '15034',
    location_description: 'ICA VÄSTERÅS',
    reportedby: 'Mattias Lundgren (p02msl) (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'DISPUTE',
    remarkdesc: 'Åtgärd',
    failureremark_description_longdescription:
      'Hissens dörr på våning 2 blivit trasig (sparkat eller annan inverkan)) så delar såsom kugghjul och annat har lossnat. Kone: Hissen stog på över plan ena dörrbladet på korgen hadde spårat ur, fick plocka hela dörrbladet för att byta dörrstyrningar. Funktionkontroll.',
    pluspwopricetotals: {
      labbillpr: 4221,
      matbillpr: 3601.65,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 0,
    },
    doclinks: [],
    matusetrans: [
      {
        plusplineprice: '3601.65',
        positivequantity: '1',
        description: 'KONE AB - 170195423',
        plusplistprice: '3601.65',
      },
    ],
    tooltrans: [],
    pluspgbtrans: [],
    labtrans: [
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: '',
        craft: 'TECHOP',
        regularhrs: '1',
        plusplineprice: '511',
        plusplistprice: '511',
      },
      {
        description: '',
        c_knowntocust: '',
        craftskill_description: 'Hiss',
        craft: 'TECHOP',
        regularhrs: '3.5',
        plusplineprice: '3710',
        plusplistprice: '3710',
      },
    ],
    servrectrans: [
      {
        description: 'KONE AB - 170195423',
        quantity: '1',
        plusplineprice: '0',
        plusplistprice: '0',
      },
    ],
  },

  {
    workorderid: '12955485',
    wonum: 'M10305087',
    siteid: 'SE02AR04',
    pluspcustomer_name: 'ICA FASTIGHETER AB',
    pluspagreement: 'A16121',
    status: '',
    pluspquotetype: 'FIXED',
    commodity: 'F130',
    reportdate: '2022-03-16',
    actfinish: '2022-06-14',
    description: 'KO // Helsingborg - Servicegenomgång på all fastinstallerad köksutrustning',
    description_longdescription: 'Andreas Dallby har hand om ärendet.',
    location: '15029',
    location_description: 'ICA HELSINGBORG',
    reportedby: 'Karl Pulkert',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspnextbillstat: 'WAPPR',
    remarkdesc: '',
    failureremark_description_longdescription:
      'En överenskommelse mellan Magnus N (ICA) & Andreas D (COOR) innan Andreas avslutade sin tjänst.  Faktureras och vid frågor eller felaktigheter får Magnus N ta kontakt med Kim O (COOR) eller Karl P (COOR).    Denna service genomgången gjorde för att få en inventering av all utrustning ICA HBG',
    pluspwopricetotals: {
      labbillpr: 0,
      matbillpr: 0,
      srvbillpr: 0,
      toolbillpr: 0,
      feebillpr: 0,
      wospecialprice: 120000,
    },
    doclinks: [],
    matusetrans: [],
    tooltrans: [],
    pluspgbtrans: [
      {
        lineprice: '-20000',
        description: 'Avgår garantibelopp',
      },
    ],
    labtrans: [],
    servrectrans: [
      {
        description: 'HNA - 59927',
        quantity: '1',
        plusplineprice: '155907.9',
        plusplistprice: '155907.9',
      },
    ],
  },
];
