import { Box, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import styled from 'styled-components';

import { IHeadCell, IEnhancedTableData } from '../../typescript/interfaces';
import { Order } from '../../typescript/types';

interface IProps {
  headCells: IHeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IEnhancedTableData) => void;
  order: Order;
  orderBy: string;
  dense?: boolean;
}

export const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  dense = false,
}: IProps) => {
  const createSortHandler =
    (property: keyof IEnhancedTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: IHeadCell) => (
          <TableHeadCell
            key={headCell.id}
            align={headCell.id === 'cost' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            $dense={dense}>
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableHead = styled(MuiTableHead)`
  &.MuiTableHead-root {
    padding-bottom: ${({ theme }) => theme.spacings.large};
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: none;
    }
  }
`;

const TableHeadCell = styled(TableCell)<{ $dense: boolean }>`
  &.MuiTableCell-root {
    padding: ${({ theme, $dense }) =>
      $dense
        ? `${theme.spacings.xxsmall} ${theme.spacings.xsmall}`
        : `${theme.spacings.medium} ${theme.spacings.medium}`};
    span {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      font-size: ${({ theme }) => theme.fonts.size.preamble};
      white-space: nowrap;
    }
  }
`;
