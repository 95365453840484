import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { IRadioButton } from '../../typescript/interfaces';

export interface IProps extends IRadioButton, HTMLAttributes<HTMLInputElement> {
  name: string;
}

export const RadioButton = ({ label, value, name, ...rest }: IProps) => {
  const state = getState(value);
  const textState = `on${state.charAt(0).toUpperCase() + state.slice(1)}`;
  return (
    <StyledRadioButton>
      <StyledInput
        type="radio"
        name={name}
        value={value}
        $state={state}
        $textState={textState}
        {...rest}
      />
      <P $state={state}>{label}</P>
    </StyledRadioButton>
  );
};

const getState = (value: string) => {
  if (value === 'ACCEPTED' || value === 'APPROVED') {
    return 'success';
  }
  if (value === 'REJECTED' || value === 'REJECT') {
    return 'error';
  }
  if (value === 'DISPUTE') {
    return 'warning';
  } else {
    return '';
  }
};

const StyledRadioButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
`;

const P = styled.p<{ $state: string }>`
  transition: outline-color 0.15s ease-in-out, color 0.15s ease-in-out;
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  letter-spacing: -0.015rem;
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xlarge}`};
  border-radius: 30px;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: -3px;
  outline-color: transparent;
  flex: 1;
  text-align: center;

  &:hover {
    outline-color: ${({ theme, $state }) =>
      theme.colors.background[$state as keyof typeof theme.colors.background]};
    color: ${({ theme, $state }) =>
      theme.colors.background[$state as keyof typeof theme.colors.background]};
    ${({ $state }) => $state !== '' && css``}
  }
`;

const StyledInput = styled.input<{ $state: string; $textState: string }>`
  display: none;
  appearance: none;

  &:checked + p {
    outline: none;
    ${({ $state, $textState }) =>
      $state !== '' &&
      css`
        color: ${({ theme }) => theme.colors.text[$textState as keyof typeof theme.colors.text]};
        background: ${({ theme }) =>
          theme.colors.background[$state as keyof typeof theme.colors.background]};
      `}
  }
`;
