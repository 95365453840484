import { IBreakpoints } from '../../../typescript/interfaces';

export const breakpoints: IBreakpoints = {
  // mobile
  xs: '576px',
  // tablet
  sm: '768px',
  // laptop
  md: '1024px',
  // desktop
  lg: '1280px',
  // large desktop
  xl: '1920px',
  // large desktop
  xxl: '1980px',
};
