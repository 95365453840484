import {
  IAgreementDTO,
  ITableData,
  ITableHeaderCell,
  ITableRow,
} from '../../../../typescript/interfaces';
import { EditAgreementButton } from './EditAgreementButton';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'agreement',
    label: 'Agreement',
  },
  {
    id: 'remove',
    label: '',
  },
];
const getRows = (companyId: string, org: string, agreements: IAgreementDTO[]): ITableRow[] => {
  const rows = agreements.map((agreement: IAgreementDTO) => {
    return {
      id: agreement.id,
      hover: false,
      cells: [
        {
          id: 'externalId',
          value: agreement.externalId,
        },
        {
          id: 'remove',
          value: <EditAgreementButton companyId={companyId} org={org} agreement={agreement} />,
          sx: { width: '40px', textAlign: 'right' },
        },
      ],
    };
  });

  return rows;
};
export const getTableData = (
  companyId: string,
  org: string,
  agreements: IAgreementDTO[],
): ITableData => {
  return {
    headerCells: headerCells,
    rows: getRows(companyId, org, agreements),
  };
};
