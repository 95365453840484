export type MessageLevel = 'Debug' | 'Information' | 'Warning' | 'Error';

export const MessageLevelMapping: Record<number, MessageLevel> = {
  1: 'Debug',
  2: 'Information',
  3: 'Warning',
  4: 'Error',
};

export interface Notification {
  level: MessageLevel;
  message: string;
}

export interface FullNotification extends CreateNotification {
  id: string;
}

export interface CreateNotification {
  level: number;
  message: string;
  from: string;
  to: string;
}
