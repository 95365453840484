import styled from 'styled-components';

import { IAgreementDTO, ILocationDTO, IServiceDTO } from '../../../typescript/interfaces';
import { Heading } from '../../UI';

type Permission = IAgreementDTO | ILocationDTO | IServiceDTO;
type Permissions = Permission[];

interface IProps {
  permissions: Permissions;
  title: string;
  noPermissionsText: string;
}

export const PermissionsList = ({ permissions, title, noPermissionsText }: IProps) => {
  return (
    <Wrapper>
      <Heading tag="h3">{title}</Heading>
      {permissions.length > 0 ? (
        permissions.map((permission: Permission, index: number) => (
          <Permission key={index}>{permission.name ?? ''}</Permission>
        ))
      ) : (
        <p>{noPermissionsText}</p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Permission = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
`;
