import { IHeadCell } from '../../typescript/interfaces';

export const getCompletedWorkheadCells = (extraCells: IHeadCell[]) => {
  const cells: IHeadCell[] = [
    ...extraCells,
    {
      id: 'workOrder',
      numeric: false,
      disablePadding: false,
      label: 'Work order',
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'location',
      numeric: true,
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'actionReporting',
      numeric: true,
      disablePadding: false,
      label: 'Action reporting',
    },
    {
      id: 'cost',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'purchaseOrder',
      numeric: true,
      disablePadding: false,
      label: 'Purchase order',
    },
    {
      id: 'reference',
      numeric: true,
      disablePadding: false,
      label: 'Reference',
    },
    {
      id: 'response',
      numeric: true,
      disablePadding: false,
      label: 'Response',
    },
  ];

  return cells;
};
