import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Edit = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.136 2.006a3.435 3.435 0 0 1 4.858 4.858L8.474 20.4a3 3 0 0 1-1.092.697L2.343 22.94a1 1 0 0 1-1.282-1.284l1.85-5.033a3 3 0 0 1 .695-1.087l13.53-13.529ZM20.58 3.42c-.56-.56-1.47-.56-2.03 0l-1.11 1.11 2.03 2.031 1.11-1.11c.56-.56.56-1.47 0-2.03Zm-2.523 4.556-2.032-2.031L5.971 15.999l2.038 2.037 10.048-10.06ZM6.452 19.308l-1.753-1.753-1.016 2.765 2.769-1.012Z"
      fill="currentColor"
    />
  </Svg>
);
