import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Multiply = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.364 22.778a1 1 0 0 0 1.414-1.415L13.414 12l9.365-9.364a1 1 0 0 0-1.415-1.415L12 10.586 2.636 1.22a1 1 0 0 0-1.414 1.415L10.586 12l-9.364 9.363a1 1 0 1 0 1.414 1.415L12 13.414l9.364 9.364Z"
      fill="currentColor"
    />
  </Svg>
);
