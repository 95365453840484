import { IAgreementDTO } from '../../../../typescript/interfaces/';
import { IOrganizationGroup } from './Organizations';

export const getOrganizationGroups = (agreements: IAgreementDTO[]): IOrganizationGroup[] => {
  const agreementMap: Map<string, Set<{ id: string; externalId: string }>> = new Map();
  const orgs: Map<string, string> = new Map();

  for (const agreement of agreements) {
    // If the agreement does not have an externalId, skip it
    if (!agreement.externalId) {
      continue;
    }

    const [org, agr] = agreement.externalId.split('_');

    // If agr is undefined, it means that the agreement is an organization
    if (!agr) {
      orgs.set(org, agreement.id);
    }

    // If the organization is not in the map, add it
    if (!agreementMap.has(org)) {
      agreementMap.set(org, new Set());
    }

    // If agr is defined, it means that the agreement is an agreement
    if (agr) {
      agreementMap.get(org)?.add({ id: agreement.id, externalId: agr });
    }
  }

  // Groups agreements by organization and sorts them alphabetically by name.
  const grouped = Array.from(agreementMap, ([org, agreementsMap]) => {
    const agreements = Array.from(agreementsMap).sort((a, b) => {
      const externalIdA = a.externalId.toUpperCase();
      const externalIdB = b.externalId.toUpperCase();
      if (externalIdA < externalIdB) {
        return -1;
      }
      if (externalIdA > externalIdB) {
        return 1;
      }
      return 0;
    });

    return {
      organization: org,
      organizationId: orgs.get(org) || '',
      agreements,
    };
  });

  const sorted = grouped.sort((a, b) => {
    const externalIdA = a.organization.toUpperCase();
    const externalIdB = b.organization.toUpperCase();
    if (externalIdA < externalIdB) {
      return -1;
    }
    if (externalIdA > externalIdB) {
      return 1;
    }
    return 0;
  });

  return sorted;
};
