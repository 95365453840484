import { CSSProperties } from 'react';

import { IFieldItem } from '../../../typescript/interfaces';
import { FieldChange } from '../../../typescript/types';
import { DateField } from './DateField';
import { InputField } from './InputField';
import { TextAreaField } from './TextAreaField';
import { TextField } from './TextField';

export type FieldVariantType = 'textField' | 'inputField' | 'textAreaField' | 'dateField';

const Variant = {
  textField: TextField,
  inputField: InputField,
  textAreaField: TextAreaField,
  dateField: DateField,
};

interface IProps {
  fieldItem: IFieldItem;
  handleChange?: (event: FieldChange) => void;
  style?: CSSProperties;
}

export const FieldItem = ({ fieldItem, ...rest }: IProps) => {
  const { fieldVariant = 'textField' } = fieldItem;
  const FieldVariant = Variant[fieldVariant];

  return <FieldVariant fieldItem={fieldItem} {...rest} />;
};
