import { IEnhancedTableData } from 'typescript/interfaces/';

export const createTableRows = (
  workOrder: string,
  description: string,
  location: string,
  actionReporting: string,
  cost: string,
  purchaseOrder: string,
  reference: string,
  response: string,
  id: number,
  organization: string,
): IEnhancedTableData => {
  return {
    workOrder,
    description,
    location,
    actionReporting,
    cost,
    purchaseOrder,
    reference,
    response,
    id,
    organization,
    quickResponse: '',
  };
};
