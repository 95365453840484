import { ListItemIcon, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon, UserSettings } from '../../../components';
import { useModal } from '../../../utils/context';

interface IProps {
  onClick: () => void;
}

export const Settings = ({ onClick }: IProps) => {
  const { t } = useTranslation();
  const { setModalContent } = useModal();
  const handleClick = () => {
    onClick();
    setModalContent({ title: t('userArea.settings'), content: <UserSettings /> });
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <Icon icon="settings" />
      </ListItemIcon>
      {t('userArea.settings')}
    </MenuItem>
  );
};
