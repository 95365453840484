import { useState } from 'react';

import { Button, IButtonProps } from './Button';

interface IActionButtonProps<T> extends Omit<IButtonProps, 'onClick'> {
  action: () => Promise<T>;
}

export const ActionButton = <T extends unknown>({ action, ...rest }: IActionButtonProps<T>) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    action().finally(() => setLoading(false));
  };

  return <Button loading={loading} onClick={onClick} {...rest} />;
};
