import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Table, Button, Filter } from '../../../../components';
import {
  ILocationDTO,
  ITableData,
  ITableHeaderCell,
  ITableRow,
} from '../../../../typescript/interfaces';
import { filterTableRows } from '../../../../utils';
import { useModal } from '../../../../utils/context';
import { ICompanyDTO } from './../../../../typescript/interfaces/ICompanyDTO';
import { AddLocation } from './AddLocation';
import { EditLocationButton } from './EditLocationButton';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'edit',
    label: '',
    sx: { width: '30px' },
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'externalId',
    label: 'External ID',
  },
];

const getRows = (locations: ILocationDTO[], companyId: string): ITableRow[] => {
  return locations.map((location: ILocationDTO) => {
    return {
      id: location.id,
      hover: false,
      cells: [
        {
          id: 'edit',
          value: <EditLocationButton location={location} companyId={companyId} />,
          sx: { width: '30px' },
        },
        {
          id: 'name',
          value: location.name,
        },
        {
          id: 'externalId',
          value: location.externalId,
        },
      ],
    };
  });
};

const getTableData = (locations: ILocationDTO[], companyId: string): ITableData => {
  return {
    headerCells,
    rows: getRows(locations, companyId),
  };
};

interface IProps {
  company: ICompanyDTO;
  setOptionalComponent?: Dispatch<SetStateAction<ReactNode>>;
}

export const Locations = ({ company, setOptionalComponent }: IProps) => {
  const { locations = [], id: companyId } = company;
  const data = getTableData(locations, companyId);
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  // Filter
  const inputContainerTooltip = `Filter locations on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
      `;
  const inputPlaceHolder = t('admin.company.locations.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  const addLocation = () => {
    setModalContent({
      title: `${t('admin.company.locations.addLocation')}`,
      content: <AddLocation companyId={company.id} />,
    });
  };

  const AddLocationButton = () => (
    <Button onClick={() => addLocation()}>{t('admin.company.locations.addLocation')}</Button>
  );

  useEffect(() => {
    if (!setOptionalComponent) {
      return;
    }

    setOptionalComponent(AddLocationButton);
  }, [setOptionalComponent]);

  return (
    <Container>
      {locations.length > 0 ? (
        <>
          <Filter
            filterWords={filterWords}
            handleChange={(query) => setQuery(query)}
            inputContainerTooltip={inputContainerTooltip}
            inputPlaceholder={inputPlaceHolder}
            value={query}
          />
          <Table tableData={tableData} enableSorting={true} defaultSortProperty="name" />
        </>
      ) : (
        <p>No locations</p>
      )}
    </Container>
  );
};

const Container = styled.div``;
