import { TablePagination } from '@mui/material';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

interface IProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleIsOnEmptyPage: (isOnEmptyPage: boolean) => void;
}

export const TableToolbar = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  handleIsOnEmptyPage,
}: IProps) => {
  const numberOfPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);
  const isOnEmptyPage = useMemo(
    () => page > numberOfPages - 1 && page !== 0,
    [page, numberOfPages],
  );
  const paginationPage = isOnEmptyPage ? 0 : page;

  useEffect(() => {
    if (!isOnEmptyPage) return;
    handleIsOnEmptyPage(isOnEmptyPage);
  }, [handleIsOnEmptyPage, isOnEmptyPage, page]);

  return (
    <Toolbar>
      <Pagination
        rowsPerPageOptions={[
          { label: '10', value: 10 },
          { label: '50', value: 50 },
          { label: 'All', value: Number.MAX_VALUE },
        ]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={paginationPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        showFirstButton
        showLastButton
        labelRowsPerPage="Results per page"
        disabled={count === 0}
      />
    </Toolbar>
  );
};

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-flow: column-reverse;

    label {
      justify-content: center;
    }

    .MuiToolbar-root {
      padding: 0;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
`;

const Pagination = styled((props) => <TablePagination {...props} />)`
  &.MuiTablePagination-root {
    .MuiTablePagination-selectLabel {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      font-size: ${({ theme }) => theme.fonts.size.body};
    }

    .MuiInputBase-root {
      border: 1px solid ${({ theme }) => theme.colors.border.input};
      border-radius: ${({ theme }) => theme.radius.default};
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
      font-size: ${({ theme }) => theme.fonts.size.body};
    }

    .MuiTablePagination-displayedRows {
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
      font-size: ${({ theme }) => theme.fonts.size.body};
    }

    .MuiTablePagination-actions {
    }
    button {
      svg {
        color: ${({ theme }) => theme.colors.background.primary};
      }

      &.Mui-disabled {
        svg {
          color: ${({ theme }) => theme.colors.text.disabled};
        }
      }
    }
  }
`;
