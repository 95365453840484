import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Chat = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3h-8.519a1 1 0 0 0-.455.11L6.6 23.88a1.1 1.1 0 0 1-1.6-.979V21H3a3 3 0 0 1-3-3V6Zm3-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2.9A1.1 1.1 0 0 1 7 20.1v1.33l4.117-2.102A3 3 0 0 1 12.48 19H21a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H3Zm3 9a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm1-5a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H7Z"
      fill="currentColor"
    />
  </Svg>
);
