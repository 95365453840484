import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ITableData, ITableRow } from '../../typescript/interfaces';
import { Log } from './Log';

interface IProps {
  data: ITableData;
}

export const LogList = ({ data }: IProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollTop = elementRef.current.scrollHeight;
    }
  }, []);

  const { rows } = data;
  const logs = rows.map((row: ITableRow, index: number) => {
    const name = row.cells[0].value as string;
    const date = row.cells[1].value as string;
    const status = row.cells[2].value as string;
    const comment = row.cells[3].value as string;

    return (
      <Log
        name={name}
        date={date}
        status={status}
        comment={comment}
        selfAlign={row.selfAlign}
        key={index}
      />
    );
  });

  return <LogListContainer ref={elementRef}>{logs}</LogListContainer>;
};

const LogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  align-items: flex-start;
  max-height: 428px;
  overflow-y: auto;
`;
