import { ITypeScale } from '../../../typescript/interfaces';

export const typeScale: ITypeScale = {
  baseFontSize: '16px',
  mobileBaseFontSize: '20px',
  h1: '3.75rem',
  h2: '2.5rem',
  h3: '1.625rem',
  h4: '1.25rem',
  body: '1rem',
  preamble: '1.125rem',
  lable: '0.75rem',
  small: '0.875rem',
};
