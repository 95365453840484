import { parseValue } from './tryParseValue';

export const getStringOrNumber = (val: unknown): string | number => {
  const parsed = parseValue(val);

  if (isNaN(parsed) && typeof val === 'string') {
    return val;
  }

  if (typeof parsed === 'number') {
    return parsed;
  }

  return 0;
};
