import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon, SmallTabs, Spinner } from '../../../../components';
import { FieldItem } from '../../../../components/UI/FieldItem/FieldItem';
import { useRoleService, useUserService } from '../../../../hooks';
import {
  IAgreementDTO,
  ILocationDTO,
  IRoleDTO,
  IUpdateUserDTO,
  IUserDTO,
} from '../../../../typescript/interfaces';
import { useModal } from '../../../../utils/context';
import { Agreements } from './Agreements/Agreements';
import { EditUser } from './EditUser';
import { Locations } from './Locations/Locations';
import { Roles } from './Roles/Roles';
import { Services } from './Services/Services';

const tabs = (
  companyId: string,
  handleUpdateUser: (updatedUser: IUserDTO) => void,
  handleUpdateRoles: (updatedRoles: IRoleDTO[]) => void,
  roles?: IRoleDTO[],
  userRoles?: IRoleDTO[],
  user?: IUserDTO,
) => [
  {
    label: <p>Agreements</p>,
    content: <Agreements user={user} companyId={companyId} handleUpdateUser={handleUpdateUser} />,
  },
  {
    label: <p>Locations</p>,
    content: <Locations user={user} companyId={companyId} handleUpdateUser={handleUpdateUser} />,
  },
  {
    label: <p>Services</p>,
    content: <Services user={user} handleUpdateUser={handleUpdateUser} />,
  },
  {
    label: <p>Roles</p>,
    content: <Roles roles={roles} userRoles={userRoles} handleUpdateRoles={handleUpdateRoles} />,
  },
];

export type Inputs = {
  agreements: IAgreementDTO[];
  company: string;
  locations: ILocationDTO[];
  roles: IRoleDTO[];
  services: string[];
};

export const EditUserPage = () => {
  const { userId, companyId = '' } = useParams();
  const { user, isUserLoading, error, updateUser, isUpdateUserLoading } = useUserService(
    userId,
    companyId,
  );
  const {
    rolesByCompany,
    rolesByCompanyIsLoading,
    rolesByCompanyAndUser,
    rolesByCompanyAndUserIsLoading,
  } = useRoleService(companyId, userId);
  const [updatedRoles, setUpdatedRoles] = useState<IRoleDTO[] | []>(rolesByCompanyAndUser ?? []);
  const [updatedUser, setUpdatedUser] = useState<IUserDTO | undefined>(user);
  useUserService();
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  const isLoading =
    isUserLoading ||
    isUpdateUserLoading ||
    rolesByCompanyIsLoading ||
    rolesByCompanyAndUserIsLoading;

  useEffect(() => {
    if (!user) {
      return;
    }
    setUpdatedUser({ ...user });
  }, [user]);

  useEffect(() => {
    if (!rolesByCompanyAndUser || rolesByCompanyAndUserIsLoading) {
      return;
    }
    setUpdatedRoles(rolesByCompanyAndUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesByCompanyAndUser]);

  const fieldItem = {
    title: 'Selected user',
    content: `${user?.givenName || ''} ${user?.familyName || ''}`,
    name: 'selected_user',
  };

  const editUser = (user: IUserDTO, companyId: string) => {
    setModalContent({
      title: `${t('admin.user.editUser')} ${user.givenName} ${user.familyName}`,
      content: (
        <EditUser
          user={user}
          companyId={companyId}
          userRoles={updatedRoles?.map((role) => role.id)}
        />
      ),
    });
  };

  const handleUpdateUser = (updatedUser: IUserDTO) => {
    setUpdatedUser(updatedUser);
  };

  const handleUpdateRoles = (updatedRoles: IRoleDTO[]) => {
    setUpdatedRoles(updatedRoles);
  };

  const submitUpdatedUser = (
    companyId: string,
    updatedRoles: IRoleDTO[],
    updatedUser?: IUserDTO,
  ) => {
    if (!updatedUser) {
      return;
    }

    // Convert updatedUser to IUpdatedUserDTO
    const updatedUserDTO: IUpdateUserDTO = {
      id: updatedUser.id,
      email: updatedUser.email,
      givenName: updatedUser.givenName,
      familyName: updatedUser.familyName,
      companyId,
      language: updatedUser.language,
      notify: updatedUser.notifyUser,
      roles: updatedRoles?.map((role) => role.id) ?? [],
      assignedAgreements: updatedUser.assignedAgreements?.map((agreement) => agreement.id) ?? [],
      assignedLocations: updatedUser.assignedLocations?.map((location) => location.id) ?? [],
      assignedServices: updatedUser.assignedServices?.map((service) => service.id) ?? [],
    };

    updateUser(updatedUserDTO);
  };

  if (error) {
    return <p>{error.message}</p>;
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return user ? (
    <>
      <UserDetails>
        <SelectedUser fieldItem={fieldItem} />
        <Button
          type="button"
          variant="tertiary"
          size="small"
          onClick={(e) => editUser(user, companyId)}
          tooltip={`${t('admin.user.editUser')}`}>
          <Icon icon="edit" />
        </Button>
      </UserDetails>

      <TabsContainer>
        <SmallTabs
          tabs={tabs(
            companyId,
            handleUpdateUser,
            handleUpdateRoles,
            rolesByCompany,
            updatedRoles,
            updatedUser,
          )}
          optionalComponent={
            <Button
              onClick={(e) => submitUpdatedUser(companyId, updatedRoles, updatedUser)}
              disabled={!updatedUser || isUpdateUserLoading}>{`${t('general.saveChange')}`}</Button>
          }
        />
      </TabsContainer>
    </>
  ) : (
    <p>No user found</p>
  );
};

const TabsContainer = styled.div``;

const UserDetails = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  display: flex;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacings.xxlarge};
`;

const SelectedUser = styled(FieldItem)`
  border-top: 0;
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacings.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  justify-content: space-between;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
