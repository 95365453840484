import { useEffect, useMemo } from 'react';

import { Filter } from '../../components';
import { useAppState } from '../../hooks/useAppState';
import { Page, Position } from '../../typescript/types';
import { filterRows } from '../../utils/filterRows';

interface IProps<T> {
  page: Page;
  fieldPlaceHolder?: string;
  filterOptionsLabel?: string;
  rows: Array<T>;
  handleFilterChange: <T>(filtered: T[]) => void;
  optionalHeaderComponent?: JSX.Element;
  optionalHeaderComponentPosition?: Position;
}

export const EnhancedFilter = <T,>({
  rows,
  handleFilterChange,
  optionalHeaderComponent,
  optionalHeaderComponentPosition,
}: IProps<T>) => {
  const { appState, setAppState } = useAppState();
  const currentPage = appState.currentPage;
  const query = appState[currentPage].filter.query;

  const inputContainerTooltip = `Filter work orders based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
  `;
  //Regex to split on words in quotes and on spaces, but not on spaces in quotes
  const filterWords = useMemo(() => query.match(/"[^"]+"|[^ ]+/g) ?? [], [query]);

  const filteredRows = useMemo(() => {
    return filterRows(rows, filterWords);
  }, [filterWords, rows]);

  useEffect(() => {
    handleFilterChange(filteredRows);
  }, [filteredRows, handleFilterChange]);

  const handleChange = (changedQuery: string) => {
    setAppState({
      [appState.currentPage]: {
        ...appState[appState.currentPage],
        filter: { query: changedQuery },
      },
    });
  };

  return (
    <Filter
      filterWords={filterWords}
      handleChange={(query: string) => handleChange(query)}
      inputContainerTooltip={inputContainerTooltip}
      inputPlaceholder="Filter work orders"
      isVisible={rows.length > 0}
      value={query}
      optionalHeaderComponent={optionalHeaderComponent}
      optionalHeaderComponentPosition={optionalHeaderComponentPosition}
    />
  );
};
