import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Filter,
  Spinner,
  Table,
  WizardActionType,
  useWizardContext,
} from '../../../../../components';
import { useServiceService } from '../../../../../hooks/useServiceService';
import { IServiceDTO } from '../../../../../typescript/interfaces';
import { filterTableRows } from '../../../../../utils';
import { getTableData } from '../../EditUserPage/Services/getServicesTableData';

const updateUserServices = (
  services: IServiceDTO[],
  selectableServices: IServiceDTO[],
  checked: boolean,
  value?: string,
) => {
  const service = selectableServices.find((service: IServiceDTO) => service.id === value);

  if (!service) {
    return services;
  }

  // If there is a service and it is checked, add it to the users assigned services
  if (checked) {
    return [...services, service];
  } else {
    return services.filter((userService: IServiceDTO) => userService.id !== value);
  }
};

export const AddServices = () => {
  const { services: selectableServices = [], isServicesLoading } = useServiceService();
  const { t } = useTranslation();
  const { setStepValidity, state, dispatch } = useWizardContext();
  const { currentStep, steps } = state;
  const currentStepState = steps.get(currentStep) ?? {};
  const assignedServices: IServiceDTO[] = currentStepState?.assignedServices ?? [];

  // We set the step to valid by default, because the user can skip this step
  useEffect(() => {
    setStepValidity(currentStep, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter
  const inputContainerTooltip = `Filter services based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
    `;
  const inputPlaceHolder = t('admin.user.services.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  // "selectAll" checkbox
  const isAllSelected = assignedServices.length === selectableServices.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedServices = checked ? selectableServices : [];
    updateStepState(updatedServices);
  };

  // "services" checkboxes
  const handleServicesClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedServices = updateUserServices(
        assignedServices,
        selectableServices,
        checked,
        value,
      );
      updateStepState(updatedServices);
    }
  };

  const updateStepState = (assignedServices: IServiceDTO[]) => {
    dispatch({
      type: WizardActionType.StepState,
      payload: { step: currentStep, state: { assignedServices } },
    });
  };

  const data = getTableData(
    assignedServices,
    selectableServices,
    isAllSelected,
    handleIsAllSelected,
    handleServicesClick,
  );

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  if (isServicesLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <Container>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
      />
      <TableWrapper>
        <Table tableData={tableData} enableSorting={true} />
      </TableWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const TableWrapper = styled.div`
  max-height: 509px;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
