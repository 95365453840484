import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  AlertBox,
  Input,
  LanguageSelect,
  Switch,
  WizardActionType,
  useWizardContext,
} from '../../../../../components';
import { useSearchUser } from '../../../../../hooks';
import { ICompanyDTO } from '../../../../../typescript/interfaces';
import { validateEmail } from '../../../../../utils/validateEmail';

export interface IFormData {
  givenName: string;
  givenNameIsValid: boolean | undefined;
  familyName: string;
  familyNameIsValid: boolean | undefined;
  email: string;
  emailIsValid: boolean | undefined;
  company: ICompanyDTO;
  companyId: string;
  notifyUser: boolean;
  language: string;
  languageIsValid: boolean | undefined;
  isPrefilled?: boolean;
}

interface IProps {
  company: ICompanyDTO;
}

export const AddProfile = ({ company }: IProps) => {
  const { t } = useTranslation();
  const { setStepValidity, dispatch, state } = useWizardContext();
  const [isFormValid, setIsFormValid] = useState(false);
  const { steps, currentStep } = state;
  const currentStepState = steps.get(currentStep) ?? {};
  const { setSearchEmail, userSearchLoading, userSearchResult } = useSearchUser(
    company.id,
    currentStepState.email,
  );
  const [formData, setFormData] = useState<IFormData>({
    givenName: currentStepState.givenName ?? '',
    givenNameIsValid: currentStepState.givenNameIsValid,
    familyName: currentStepState.familyName ?? '',
    familyNameIsValid: currentStepState.familyNameIsValid,
    email: currentStepState.email ?? '',
    emailIsValid: currentStepState.emailIsValid,
    company: company,
    companyId: company.id,
    notifyUser: currentStepState.notifyUser ?? true,
    language: currentStepState.language ?? '',
    languageIsValid: currentStepState.languageIsValid,
    isPrefilled: currentStepState.isPrefilled,
  });
  const previousFormData = useRef<IFormData>();

  const getIsChangedElementValid = (name: string, value: string) => {
    if (name === 'email') {
      return validateEmail(value);
    }

    return value !== '';
  };

  useEffect(() => {
    const isFormValid =
      formData.givenNameIsValid === true &&
      formData.familyNameIsValid === true &&
      formData.emailIsValid === true &&
      formData.languageIsValid === true;

    setIsFormValid(isFormValid);
  }, [formData]);

  useEffect(() => {
    setStepValidity(currentStep, isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isFormValid]);

  const isEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isFormValid) {
      return;
    }

    if (!previousFormData.current || !isEqual(previousFormData.current, formData)) {
      const updatedFormData = {
        givenName: formData.givenName,
        givenNameIsValid: formData.givenNameIsValid,
        familyName: formData.familyName,
        familyNameIsValid: formData.familyNameIsValid,
        email: formData.email,
        emailIsValid: formData.emailIsValid,
        company: formData.company,
        companyId: formData.companyId,
        notifyUser: formData.notifyUser,
        language: formData.language,
        languageIsValid: formData.languageIsValid,
        isPrefilled: formData.isPrefilled,
      };

      dispatch({
        type: WizardActionType.StepState,
        payload: { step: currentStep, state: updatedFormData },
      });

      setStepValidity(currentStep, true);
    }

    previousFormData.current = formData;
  }, [currentStep, isFormValid, dispatch, formData, setStepValidity, state.currentStep]);

  useEffect(() => {
    // This will prevent some strange state glitches when moving between steps
    if (!formData.email && formData.isPrefilled) {
      return;
    }

    if (userSearchResult) {
      setFormData((previous) => ({
        ...previous,
        email: userSearchResult.email,
        emailIsValid: true,
        givenName: userSearchResult.givenName,
        givenNameIsValid: true,
        familyName: userSearchResult.familyName,
        familyNameIsValid: true,
        language: userSearchResult.language,
        languageIsValid: true,
        notifyUser: userSearchResult.notifyUser,
        isPrefilled: true,
      }));

      dispatch({ type: 'PREFILL_USER', payload: userSearchResult });
    } else if (formData.isPrefilled) {
      setFormData((previous) => ({
        ...previous,
        givenName: '',
        givenNameIsValid: undefined,
        familyName: '',
        familyNameIsValid: undefined,
        email: currentStepState.email ?? '',
        emailIsValid: currentStepState.emailIsValid,
        notifyUser: currentStepState.notifyUser ?? true,
        language: currentStepState.language ?? '',
        languageIsValid: currentStepState.languageIsValid,
        isPrefilled: false,
      }));

      dispatch({ type: 'PREFILL_USER', payload: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearchResult, setFormData]);

  const handleFormChange = (e: FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    const isValid = getIsChangedElementValid(name, value);

    if (name === 'email') {
      setSearchEmail(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      [`${name}IsValid`]: isValid,
    }));
  };

  const handleNotifyUserChange = (notifyUser: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      notifyUser,
    }));
  };

  const handleLanguageChange = (language: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      language,
      languageIsValid: language !== '',
    }));
  };

  return (
    <Form onChange={(e) => handleFormChange(e)}>
      <label htmlFor="email">{`${t('admin.user.email')}`}</label>
      <Input
        name="email"
        warning={formData.emailIsValid === false}
        placeholder={formData.emailIsValid === false ? t('admin.user.emailIsRequired') ?? '' : ''}
        value={formData.email}
        loading={userSearchLoading}
        data-1p-ignore
      />

      {formData.isPrefilled && <AlertBox severity="info">{t('admin.user.dataPrefilled')}</AlertBox>}

      <label htmlFor="givenName">{`${t('admin.user.name')}`}</label>
      <Input
        name="givenName"
        warning={formData.givenNameIsValid === false}
        placeholder={
          formData.givenNameIsValid === false ? t('admin.user.nameIsRequired') ?? '' : ''
        }
        value={formData.givenName ?? ''}
        disabled={formData.isPrefilled}
        onChange={() => {}}
      />
      <label htmlFor="familyName">{`${t('admin.user.surname')}`}</label>
      <Input
        name="familyName"
        warning={formData.familyNameIsValid === false}
        placeholder={
          formData.familyNameIsValid === false ? t('admin.user.surnameIsRequired') ?? '' : ''
        }
        value={formData.familyName ?? ''}
        disabled={formData.isPrefilled}
        onChange={() => {}}
      />
      <label htmlFor="notifyUser">{`${t('userSettings.notifyUser')}`}</label>
      <Switch
        name="notifyUser"
        onCheckedChange={handleNotifyUserChange}
        defaultChecked={formData.notifyUser}
        disabled={formData.isPrefilled}
      />
      <LanguageSelect
        onChange={handleLanguageChange}
        defaultValue={formData.language}
        disabled={formData.isPrefilled}
      />
      <label htmlFor="company">{`${t('admin.user.company')}`}</label>
      <Input name="company" value={company.name} disabled />
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;
