import { useQuery } from 'react-query';

import { useTokenQuery, usePermissions } from '.';
import { getCompletedWorkOrders } from '../api/completedWork';

export const useCompletedWorkOrdersQuery = (companyId?: string) => {
  const { hasPermissionsToViewCompletedWorkorderForCompany, isPermissionsLoading } =
    usePermissions();
  const { data: token } = useTokenQuery();

  return useQuery(
    ['completed-workorders', companyId],
    () => getCompletedWorkOrders(token as string, companyId),
    {
      enabled:
        hasPermissionsToViewCompletedWorkorderForCompany && !isPermissionsLoading && !!companyId,
    },
  );
};
