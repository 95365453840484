// Mock data to be used for when the API is not available
import { WorkOrder } from 'typescript/types';

export const mockProposedWorkOrders: Partial<WorkOrder>[] = [
  {
    workorderid: '13332134',
    wonum: 'M2582925',
    siteid: 'DK03AR02',
    pluspagreement_description: 'KEFM_KEFM_Holmens kanal 20',
    pluspcustomer_name: 'Klima-, Energi-, og Forsyningsministeriet (Dep)',
    pluspagreement: 'U71105',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'A310',
    reportdate: '2022-05-18',
    actfinish: '',
    description: 'Rengøring i kantine efter fest',
    description_longdescription:
      'Hej,     Det bedes om ekstra rengøring efter fest på Holmens Kanal 20.     Festen bliver afholdt i kantinen, fredag den 20. maj 2022.   - - - - - - - - Coor vurderer opgaven til at være et tilkøb, ad-hoc opgave.   Idet prissætning af opgaven ikke er mulig på baggrund af oplysningerne fra indmelder, anbefaler vi valg af model 1C: Opgaven udføres i medgået tid og materialer.  Timepriser fastsættes jf. kontrakten ved bilag 16.1 og den samlede pris fremsendes når opgaven er udført    Så snart du har godkendt dette tilkøb, kan vi anmode kunden om også at godkende tilkøbet, og så  kan vi påbegynde planlægning og udførsel af opgaven.',
    location: '15617',
    location_description: 'KEFM_KEFM_Holmens kanal 20',
    reportedby: 'Mireille Enggaard (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [],
    pluspcustpricest: [
      {
        pluspestremark: '',
        plusppriceest: '0',
        pluspeststat: 'REJECTED',
        worklogid: '4236381',
        createdate: '2022-05-20',
      },
      {
        pluspestremark: 'Model 1C - ad-hoc',
        plusppriceest: '0',
        pluspeststat: 'PENDING',
        worklogid: '4265296',
        createdate: '2022-06-03',
      },
    ],
    wplabor: [],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Prismodel C faktureres i medgået tid og materialer',
        lineprice: '',
      },
    ],
  },

  {
    workorderid: '13445967',
    wonum: 'M2606979',
    siteid: 'DK03AR02',
    pluspagreement_description: 'UIM_UIM_Slotsholmsgade 2-12',
    pluspcustomer_name: 'Udlændinge- og Integrationsministeriet (Dep)',
    pluspagreement: 'U71116',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'F130',
    reportdate: '2022-06-07',
    actfinish: '',
    description: 'Tilbud på TVO',
    description_longdescription:
      'Der ønskes tilbud på følgende:    Eksisterende ældre analoge tvo anlæg nedtages og erstattes med et nyt system, med 2 nye netværkskameraer og ny skærm. Kameraer opsættes på samme position, som de eksisterende, og skal være af en kvalitet så identifikation er mulig.  NVR maskinen opsættes i aflåst sikkerhedslokale bagtil.   Eksisterende skærm udskiftes med ny 32” model.  _____________________________________________  Tilbud skal være incl. Nedtagning af eks. udstyr, Installering og montering, Installering og montering, Idriftsætning og aflevering    _____________________________________________  Udskiftning af 2 kameraer samt TVO optager. Opsætning af TVO skab, samt forlængelse af coax kabler, oplægning af kanal for installation.  ______________________________________________  1 stk. IP NVR optager inkl. 2 TB harddisk.  1 stk. Skab for TVO.  2 stk. 2MP Vari-focus Eyeball kameraer.  32” HD Skærm inkl. Vægbeslag.  Coax til HDMI-convertere.  Coax til IP-convertere.    ______________________________________________    Forhold uden for tilbud:  - 230V til centraludstyret.  - Netværk til kontrolcentral.  - Rør og dåser for skjult installation.  - PC-udstyr.',
    location: '15684',
    location_description: 'UIM_UIM_Slotsholmsgade 2-12',
    reportedby: 'klc@uim.dk (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [
      {
        urltype: 'FILE',
        doclinksid: '1879375',
        urlname: 'M2606979UIM_UIM_Slotsholmsgade2-12_2608.pdf',
        c_clientviewable: 'true',
      },
    ],
    pluspcustpricest: [
      {
        pluspestremark: '',
        plusppriceest: '23937.35',
        pluspeststat: 'REJECTED',
        worklogid: '4347670',
        createdate: '2022-07-14',
      },
      {
        pluspestremark: 'Tilkøb',
        plusppriceest: '32754.7',
        pluspeststat: 'REJECTED',
        worklogid: '4415964',
        createdate: '2022-08-25',
      },
      {
        pluspestremark: 'Tilkøb 3A -',
        plusppriceest: '37774.7',
        pluspeststat: 'PENDING',
        worklogid: '4420673',
        createdate: '2022-08-26',
      },
    ],
    wplabor: [
      {
        c_knowntocust: 'Projektleder, hverdag 07.00-17.00',
        craftskill_description: '',
        quantity: '1',
        laborhrs: '7',
        craft: 'PROJMANG',
        skilllevel: 'STANDARD',
        plusplineprice: '6132.7',
        totalhours: '7',
        plusplistprice: '876.1',
      },
    ],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Indkøbt tjeneste Tekniker priser 16 timer a 600,00',
        lineprice: '9600',
      },
      {
        description: '2 overvågningskamaraer FLEXIDOME a 4025',
        lineprice: '8050',
      },
      {
        description: 'Video RecorderDDN-2516-200N00 Recorder 16ch,',
        lineprice: '3684',
      },
      {
        description: '1 stk div.  Coax til DVI og IP converter, konnekto',
        lineprice: '3375',
      },
      {
        description: 'TV 32 tommer inkl. Vægbeslag',
        lineprice: '3144',
      },
      {
        description: '1 stk.  skab for TVO',
        lineprice: '2572',
      },
      {
        description: 'Fee på materialeomkostninger 4%',
        lineprice: '1217',
      },
    ],
  },
  {
    workorderid: '13445967',
    wonum: 'M2606980',
    siteid: 'DK03AR02',
    pluspagreement_description: 'UIM_UIM_Slotsholmsgade 2-12',
    pluspcustomer_name: 'Udlændinge- og Integrationsministeriet (Dep)',
    pluspagreement: 'U71116',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'F130',
    reportdate: '2022-06-07',
    actfinish: '',
    description: 'Tilbud på TVO',
    description_longdescription:
      'Der ønskes tilbud på følgende:    Eksisterende ældre analoge tvo anlæg nedtages og erstattes med et nyt system, med 2 nye netværkskameraer og ny skærm. Kameraer opsættes på samme position, som de eksisterende, og skal være af en kvalitet så identifikation er mulig.  NVR maskinen opsættes i aflåst sikkerhedslokale bagtil.   Eksisterende skærm udskiftes med ny 32” model.  _____________________________________________  Tilbud skal være incl. Nedtagning af eks. udstyr, Installering og montering, Installering og montering, Idriftsætning og aflevering    _____________________________________________  Udskiftning af 2 kameraer samt TVO optager. Opsætning af TVO skab, samt forlængelse af coax kabler, oplægning af kanal for installation.  ______________________________________________  1 stk. IP NVR optager inkl. 2 TB harddisk.  1 stk. Skab for TVO.  2 stk. 2MP Vari-focus Eyeball kameraer.  32” HD Skærm inkl. Vægbeslag.  Coax til HDMI-convertere.  Coax til IP-convertere.    ______________________________________________    Forhold uden for tilbud:  - 230V til centraludstyret.  - Netværk til kontrolcentral.  - Rør og dåser for skjult installation.  - PC-udstyr.',
    location: '15684',
    location_description: 'UIM_UIM_Slotsholmsgade 2-12',
    reportedby: 'klc@uim.dk (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [
      {
        urltype: 'FILE',
        doclinksid: '1879375',
        urlname: 'M2606979UIM_UIM_Slotsholmsgade2-12_2608.pdf',
        c_clientviewable: 'true',
      },
    ],
    pluspcustpricest: [
      {
        pluspestremark: '',
        plusppriceest: '23937.35',
        pluspeststat: 'REJECTED',
        worklogid: '4347670',
        createdate: '2022-07-14',
      },
      {
        pluspestremark: 'Tilkøb',
        plusppriceest: '32754.7',
        pluspeststat: 'REJECTED',
        worklogid: '4415964',
        createdate: '2022-08-25',
      },
      {
        pluspestremark: 'Tilkøb 3A -',
        plusppriceest: '37774.7',
        pluspeststat: 'PENDING',
        worklogid: '4420673',
        createdate: '2022-08-26',
      },
    ],
    wplabor: [
      {
        c_knowntocust: 'Projektleder, hverdag 07.00-17.00',
        craftskill_description: '',
        quantity: '1',
        laborhrs: '7',
        craft: 'PROJMANG',
        skilllevel: 'STANDARD',
        plusplineprice: '6132.7',
        totalhours: '7',
        plusplistprice: '876.1',
      },
    ],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Indkøbt tjeneste Tekniker priser 16 timer a 600,00',
        lineprice: '9600',
      },
      {
        description: '2 overvågningskamaraer FLEXIDOME a 4025',
        lineprice: '8050',
      },
      {
        description: 'Video RecorderDDN-2516-200N00 Recorder 16ch,',
        lineprice: '3684',
      },
      {
        description: '1 stk div.  Coax til DVI og IP converter, konnekto',
        lineprice: '3375',
      },
      {
        description: 'TV 32 tommer inkl. Vægbeslag',
        lineprice: '3144',
      },
      {
        description: '1 stk.  skab for TVO',
        lineprice: '2572',
      },
      {
        description: 'Fee på materialeomkostninger 4%',
        lineprice: '1217',
      },
    ],
  },

  {
    workorderid: '13470849',
    wonum: 'M2613010',
    siteid: 'DK03AR02',
    pluspagreement_description: 'KEFM_KEFM_Holmens kanal 20',
    pluspcustomer_name: 'Klima-, Energi-, og Forsyningsministeriet (Dep)',
    pluspagreement: 'U71105',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'U131',
    reportdate: '2022-06-13',
    actfinish: '',
    description: 'Bestilling af planter til Ministerens kontor',
    description_longdescription:
      'Ministeren ønsker 6 planter til hans kontor.  Skal aftales med indmelder hvilke planter ministeren ønsker',
    location: '15617',
    location_description: 'KEFM_KEFM_Holmens kanal 20',
    reportedby: 'MIreille Enggaard Kempf (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [],
    pluspcustpricest: [
      {
        pluspestremark: 'tilkøb planter til ministers kontor',
        plusppriceest: '0',
        pluspeststat: 'PENDING',
        worklogid: '4283196',
        createdate: '2022-06-13',
      },
    ],
    wplabor: [],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Prismodel C faktureres i medgået tid og materialer',
        lineprice: '',
      },
    ],
  },

  {
    workorderid: '13470945',
    wonum: 'M2613039',
    siteid: 'DK03AR02',
    pluspagreement_description: 'KEFM_KEFM_Holmens kanal 20',
    pluspcustomer_name: 'Klima-, Energi-, og Forsyningsministeriet (Dep)',
    pluspagreement: 'U71105',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'A310',
    reportdate: '2022-06-13',
    actfinish: '',
    description: 'Rengøring af tagterrasse pga mågeklatter',
    description_longdescription:
      'Opgave oprettet på vegne af KFEM (MIreille) af BYGST FML Ditte Gudnitz    Der er et mågepar der har taget ophold på tagterrassen, og der er mågeklatter udover det hele.     BYGST er ejendomsejer, så BYGST kontakter Skadedyrsbekæmper for at få skræmt mågerne væk    Der er behov for ekstra rengøring estimeret ca. 15 minutter pr dag i en periode indtil Mågerne er væk. Der skal fjernes mågeklatter fra det ramte område.     Faktura for ekstra rengøring skal fordeles på FBE_Holmens Kanal 20 mellem KFEM og BM   - - - - - - - - Coor vurderer opgaven til at være et tilkøb, ad-hoc opgave.   Idet prissætning af opgaven ikke er mulig på baggrund af oplysningerne fra indmelder, anbefaler vi valg af model 1C: Opgaven udføres i medgået tid og materialer.  Timepriser fastsættes jf. kontrakten ved bilag 16.1 og den samlede pris fremsendes når opgaven er udført    Så snart du har godkendt dette tilkøb, kan vi anmode kunden om også at godkende tilkøbet, og så kan vi påbegynde planlægning og udførsel af opgaven.',
    location: '15617',
    location_description: 'KEFM_KEFM_Holmens kanal 20',
    reportedby: 'MIreille Enggaard Kempf (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [],
    pluspcustpricest: [
      {
        pluspestremark: 'Model 1C',
        plusppriceest: '0',
        pluspeststat: 'PENDING',
        worklogid: '4294592',
        createdate: '2022-06-17',
      },
    ],
    wplabor: [],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Prismodel C faktureres i medgået tid og materialer',
        lineprice: '0',
      },
    ],
  },

  {
    workorderid: '13640863',
    wonum: 'M2644799',
    siteid: 'DK03AR02',
    pluspagreement_description: 'UIM_UIM_Slotsholmsgade 2-12',
    pluspcustomer_name: 'Udlændinge- og Integrationsministeriet (Dep)',
    pluspagreement: 'U71116',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'U140',
    reportdate: '2022-07-15',
    actfinish: '',
    description: 'Reetablering af mødelokale A',
    description_longdescription:
      'Fredag d. 16. juni skal vi have reetableret mødesal A    Eksisterende skriveborde der pt. er placeret i mødesal A flyttes til:   Kulturministeriet, Nybrogade 2, 1203 København K.  Kontakt v. ankomst: Servicechef Jens Ejlersgård, tlf. 40 30 50 12    Kontorstole og skrivebordslamper flyttes til UIM´s kælder i SHG 12.    4 mødeborde og 17 mødestole flyttes fra UIM´s kælder i SHG 12 til mødesal A  Opstilling som på vedhæftede foto.    oprindelig ordre M2610060 - dette er en kopi af denne da WO skal vendes til en tilkøbs sag. Flytning mellem lokationer og forskellige ministerier.   IO 2754-31149202 IO til Bryde & Sønner A/S  skal flyttes til denne opgave.',
    location: '15684',
    location_description: 'UIM_UIM_Slotsholmsgade 2-12',
    reportedby: 'Heidi Stoltenberg',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [],
    pluspcustpricest: [
      {
        pluspestremark: 'Bestillingsydelse - Flytning mellem lokationer',
        plusppriceest: '1696.24',
        pluspeststat: 'PENDING',
        worklogid: '4349180',
        createdate: '2022-07-15',
      },
    ],
    wplabor: [],

    wpmaterial: [],

    wpservice: [
      {
        description: 'Bestillingsydelse: Over 10 arbejdspladser og behov for flyttekordinator',
        itemqty: '14',
        plusplineprice: '1696.24',
        plusplistprice: '121.16',
      },
    ],

    wptool: [],

    pluspwpgenbill: [],
  },

  {
    workorderid: '13692640',
    wonum: 'M2657681',
    siteid: 'DK03AR02',
    pluspagreement_description: 'UIM_HJEMST_Adelgade 13',
    pluspcustomer_name: 'Hjemrejsestyrelsen',
    pluspagreement: 'U71125',
    status: 'WAPPR',
    status_description: 'Väntar på godkännande',
    commodity: 'F130',
    reportdate: '2022-07-28',
    actfinish: '',
    description: 'Vindues folie - frostet',
    description_longdescription:
      'Det skal måle ca. 45,5 X 100cm. Det må gerne være magen til det, som er brugt andre steder i huset.',
    location: '15692',
    location_description: 'UIM_HJEMST_Adelgade 13',
    reportedby: 'Line Bundgaard (EXT)',
    pluspcostcenter: '',
    pluspcustponum: '',
    pluspcrhrs: '',
    doclinks: [],
    pluspcustpricest: [
      {
        pluspestremark: 'Frostet vinduesfolie',
        plusppriceest: '2283',
        pluspeststat: 'PENDING',
        worklogid: '4477932',
        createdate: '2022-09-20',
      },
    ],
    wplabor: [],

    wpmaterial: [],

    wpservice: [],

    wptool: [],

    pluspwpgenbill: [
      {
        description: 'Materialer',
        lineprice: '450',
      },
      {
        description: 'Fee på materialeomkostninger 4%',
        lineprice: '18',
      },
      {
        description: 'Tekniker x 3 á 550kr.',
        lineprice: '1650',
      },
      {
        description: 'Projektledelse 10% af projektomkostning',
        lineprice: '165',
      },
    ],
  },
];
