import { IRoleDTO } from '../../../../../typescript/interfaces';
import { RolesContent } from './RolesContent';

interface IProps {
  handleUpdateRoles: (updatedRoles: IRoleDTO[]) => void;
  roles?: IRoleDTO[];
  userRoles?: IRoleDTO[];
}

export const Roles = ({ handleUpdateRoles, roles, userRoles }: IProps) => {
  if (!roles?.length) {
    return null;
  }

  return <RolesContent roles={roles} userRoles={userRoles} handleUpdateRoles={handleUpdateRoles} />;
};
