import i18n from 'i18next';

import { Checkbox } from '../../../../../components';
import { IServiceDTO, ITableRow } from '../../../../../typescript/interfaces';

const getHeaderCells = (
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
) => {
  const { t } = i18n;
  return [
    {
      id: 'select',
      label: <Checkbox value="selectAll" checked={isAllSelected} onClick={handleIsAllSelected} />,
      sx: { width: '30px' },
      sortable: false,
    },
    {
      id: 'externalId',
      label: t('admin.user.services.externalId'),
    },
    {
      id: 'name',
      label: t('admin.user.services.name'),
    },
  ];
};
const getRows = (
  userServices: IServiceDTO[],
  selectableServices: IServiceDTO[],
  handleLocationClick: (checked: boolean, id?: string) => void,
): ITableRow[] => {
  const { t } = i18n;
  return selectableServices.map((selectableService: IServiceDTO) => {
    const { id, name, externalId } = selectableService;

    // Check if user has this service assigned
    const isChecked = userServices.some((service) => service.id === id);

    return {
      id: id,
      hover: false,
      cells: [
        {
          id: 'id',
          value: <Checkbox value={id} checked={isChecked} onClick={handleLocationClick} />,
          sx: { width: '30px' },
        },
        {
          id: 'externalId',
          value: !!externalId ? externalId : t('general.unspecified'),
        },
        {
          id: 'name',
          value: name,
        },
      ],
      selected: isChecked,
    };
  });
};

export const getTableData = (
  userServices: IServiceDTO[],
  selectableServices: IServiceDTO[],
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
  handleLocationClick: (checked: boolean, value?: string) => void,
) => {
  return {
    headerCells: getHeaderCells(isAllSelected, handleIsAllSelected),
    rows: getRows(userServices, selectableServices, handleLocationClick),
  };
};
