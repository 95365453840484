import { Component, ErrorInfo } from 'react';

interface IErrorBoundary {
  onError: (error: Error, errorInfo: ErrorInfo) => JSX.Element;
}

export class ErrorBoundary extends Component<IErrorBoundary> {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error: error, errorInfo: info });
  }

  render() {
    if (this.state.hasError && this.state.error && this.state.errorInfo) {
      const { onError } = this.props;
      const { error, errorInfo } = this.state;

      return onError(error, errorInfo);
    }

    return <>{this.props.children}</>;
  }
}
