import { t } from 'i18next';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { Filter, Spinner, Table } from '../../../../../components';
import { useCompanyService } from '../../../../../hooks';
import { ILocationDTO, IUserDTO } from '../../../../../typescript/interfaces';
import { filterTableRows } from '../../../../../utils';
import { getTableData } from './getLocationTableData';
import { updateUserLocations } from './updateUserLocations';

interface IProps {
  user: IUserDTO;
  companyId: string;
  handleUpdateUser: (updatedUser: IUserDTO) => void;
}

export const LocationsContent = ({ user, companyId, handleUpdateUser }: IProps) => {
  const { company, isCompanyLoading, error } = useCompanyService(companyId);
  const companyLocations = company?.locations ?? [];
  const [userLocations, setUserLocations] = useState(user.assignedLocations);

  useEffect(() => {
    setUserLocations(user.assignedLocations);
  }, [user]);

  // Filter
  const inputContainerTooltip = `Filter locations based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
      `;
  const inputPlaceHolder = t('admin.user.locations.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const updateUser = (updatedLocations: ILocationDTO[]) => {
    const updatedUser: IUserDTO = { ...user, assignedLocations: updatedLocations };
    handleUpdateUser(updatedUser);
  };

  // "selectAll" checkbox
  const isAllSelected = userLocations.length === companyLocations.length;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedLocations = checked ? companyLocations : [];
    setUserLocations(updatedLocations);
    updateUser(updatedLocations);
  };

  // "location" checkboxes
  const handleLocationsClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedLocations = updateUserLocations(
        userLocations,
        companyLocations,
        value,
        checked,
        user,
      );
      setUserLocations(updatedLocations);
      updateUser(updatedLocations);
    }
  };

  const data = getTableData(
    userLocations,
    companyLocations,
    isAllSelected,
    handleIsAllSelected,
    handleLocationsClick,
  );

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  if (error) {
    return <p>{error}</p>;
  }

  if (isCompanyLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
      />
      <Table tableData={tableData} enableSorting={true} defaultSortProperty="externalId" />
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
