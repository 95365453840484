import { PLUSPESTAT, PLUSPNEXTBILLSTAT } from '../typescript/types';

export const getStatusText = (status: string | number) => {
  const noWhiteSpace = typeof status === 'string' ? status.replace(/\s/g, '') : status;

  switch (noWhiteSpace) {
    case 1:
      return 'Approved';
    case 2:
      return 'Rejected';
    case 3:
      return 'Disregarded';
    case 4:
      return 'Disputed';
    case 5:
      return 'Visited';
    case 6:
      return 'Created';
    case 7:
      return 'Accepted';
    case 99:
      return 'System';
    case PLUSPNEXTBILLSTAT.DISPUTE:
      return 'Disputed';
    case PLUSPNEXTBILLSTAT.WAPPR:
      return 'Waiting for approval';
    case PLUSPNEXTBILLSTAT.REJECT:
      return 'Rejected';
    case PLUSPNEXTBILLSTAT.APPROVE:
      return 'Approved';
    case PLUSPESTAT.ACCEPTED:
      return 'Approved';
    case PLUSPESTAT.PENDING:
      return 'Pending';
    case PLUSPESTAT.REJECTED:
      return 'Rejected';
    default:
      return '';
  }
};
