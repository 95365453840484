import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TableWithFilter, Spinner, AlertBox } from '../../components';
import { useAppState, useGetWorkorderHistory } from '../../hooks';
import { getWorkorderHistoryTableData } from './getWorkorderHistoryTableData';

const numRecords = 2000;

export interface IWorkorderHistory {
  wonum: string;
  action: string;
  date: string;
  comment: string;
}

export const WorkorderHistoryPage = () => {
  const { workorderHistory = [], isLoading } = useGetWorkorderHistory(numRecords);
  const { t } = useTranslation();
  const { appState, setAppState } = useAppState();

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  const handleRowClick = (id: string) => {
    const {
      WORKORDER_HISTORY: { details },
    } = appState;
    const value = details.get(`${id}_details`);
    details.set(`${id}_details`, value === undefined ? true : !value); // If value is undefined, set it to true, otherwise toggle it
    setAppState({
      ...appState,
      WORKORDER_HISTORY: {
        details: new Map(details),
      },
    });
  };

  return (
    <>
      <TableWithFilter
        data={workorderHistory}
        getTableData={getWorkorderHistoryTableData}
        filterPlaceholder={`${t('history.workorder.filterPlaceholder')}`}
        subPage="WORKORDER"
        hideLabelInCell={false}
        handleRowClick={handleRowClick}
        hasExpandableRows={true}
        defaultOrder="asc"
        csvExportConfig={{ buildFilename: () => 'workorder-history.csv' }}
      />

      <AlertBox severity="info">
        Showing and filtering within the first {numRecords} records, use the "Search workorder
        history" tab if you can't find what you are looking for
      </AlertBox>
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
