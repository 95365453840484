import { IUserDTO } from '../../../../../typescript/interfaces';
import { AgreementsContent } from './AgreementsContent';

interface IProps {
  handleUpdateUser: (updatedUser: IUserDTO) => void;
  companyId: string;
  user?: IUserDTO;
}

export const Agreements = ({ handleUpdateUser, companyId, user }: IProps) => {
  if (!user) {
    return null;
  }

  return (
    <AgreementsContent user={user} companyId={companyId} handleUpdateUser={handleUpdateUser} />
  );
};
