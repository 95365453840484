import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const getVariant = (variant?: keyof typeof Variant) => {
  if (!variant) {
    return null;
  }

  return Variant[variant];
};

const defaultHeading = css``;

const h1 = css`
  ${defaultHeading}
  font-size:  ${({ theme }) => theme.fonts.size.h1};
`;

const h2 = css`
  ${defaultHeading}
  font-size:  ${({ theme }) => theme.fonts.size.h2};
`;

const h3 = css`
  ${defaultHeading}
  font-size:  ${({ theme }) => theme.fonts.size.h3};
`;

const h4 = css`
  ${defaultHeading}
  font-size:  ${({ theme }) => theme.fonts.size.h4};
`;

const preamble = css`
  font-size: ${({ theme }) => theme.fonts.size.preamble};
`;

interface IHeadingProps {
  variant?: keyof typeof Variant;
}

const H1 = styled.h1<IHeadingProps>`
  ${h1}
  ${({ variant }) => getVariant(variant)}
`;

const H2 = styled.h2<IHeadingProps>`
  ${h2}
  ${({ variant }) => getVariant(variant)}
`;

const H3 = styled.h3<IHeadingProps>`
  ${h3}
  ${({ variant }) => getVariant(variant)}
`;

const H4 = styled.h4<IHeadingProps>`
  ${h4}
  ${({ variant }) => getVariant(variant)}
`;

const Tags = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
};

const Variant = {
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  preamble: preamble,
};

type Tag = 'h1' | 'h2' | 'h3' | 'h4';

interface IProps {
  variant?: keyof typeof Variant;
  tag?: Tag;
  children: ReactNode;
}

const styledHeading = ({ variant, tag = 'h1', children, ...rest }: IProps) => {
  const StyledHeading = Tags[tag];

  return (
    <StyledHeading variant={variant} {...rest}>
      {children}
    </StyledHeading>
  );
};

export const Heading = (props: IProps) => {
  return styledHeading(props);
};
