import { ITableRow } from '../typescript/interfaces';

interface IRow {
  id: string;
}

//regex select quotes
const quotes = /["]+/g;
//regex selects non breakin spaces and spaces.
const spaces = /(&nbsp;|\s)+/g;

//Replaces quotes and spaces from query and val with dashes to fix issue with being able to search for words with spaces.
const washString = (str: string) => {
  return str.toLowerCase().replace(quotes, '').replace(spaces, '-');
};

// Filter rows on list of words
export const filterRows = <T>(rows: T[], filterWords: string[]) => {
  if (!filterWords.length) return rows;
  const filteredRows = rows.filter((row) => {
    const { id, ...tableRow } = row as IRow;
    const filtered = filterWords.every((word) => {
      return Object.values(tableRow).some((value) => {
        if (typeof value !== 'string') {
          return false;
        }
        const formattedValue = washString(value);
        const formattedQuery = washString(word);

        return formattedValue.includes(formattedQuery);
      });
    });
    return filtered;
  });

  return filteredRows;
};

// Filter rows on list of words
export const filterTableRows = (
  rows: ITableRow[],
  filterWords: string[],
  hasExpandableRows = false,
) => {
  if (!filterWords.length) {
    return rows;
  }

  let filteredRows = [...rows];

  // For each word in filterWords, return rows that includes the word
  filterWords.forEach((word) => {
    // Reassign filteredRows to the result of the filter for each word
    filteredRows = filteredRows.filter((row) => {
      const { cells } = row;
      // Check if any of the cells values includes a word in filterWords
      const rowContainsWord = cells.some((cell) => {
        const { value } = cell;
        // If value is not a string, return false
        if (typeof value !== 'string') {
          console.log('Type of ', value, 'is not string');
          return false;
        }
        const formattedValue = washString(value);
        const formattedWord = washString(word);

        return formattedValue.includes(formattedWord);
      });

      return rowContainsWord ? row : null;
    });
  });

  //If table has expandable rows, also get the expandable row
  if (hasExpandableRows) {
    // Add the expandable rows to the filtered rows
    const rowsPlusExpandableRow = filteredRows.reduce((acc, row) => {
      const index = rows.findIndex((r) => r.id === row.id);
      const expandableRow = rows[index + 1];
      const rowPlusExpandable = [row, expandableRow];

      return [...acc, ...rowPlusExpandable];
    }, [] as ITableRow[]);

    return rowsPlusExpandableRow;
  }

  return filteredRows;
};
