import { t } from 'i18next';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { IAgreementDTO } from 'typescript/interfaces';

import {
  Filter,
  Spinner,
  Table,
  WizardActionType,
  useWizardContext,
} from '../../../../../components';
import { useCompanyService } from '../../../../../hooks';
import { filterTableRows } from '../../../../../utils';
import { getSelectableAgreements } from '../../EditUserPage/Agreements/AgreementsContent';
import { getTableData } from '../../EditUserPage/Agreements/getAgreementTableData';
import { updateUserAgreements } from '../../EditUserPage/Agreements/updateUserAgreements';

export const AddAgreements = () => {
  const { setStepValidity, dispatch, state } = useWizardContext();
  const { currentStep, steps } = state;
  const currentStepState = steps.get(currentStep) ?? {};
  const assignedAgreements: IAgreementDTO[] = currentStepState?.assignedAgreements ?? [];

  const stateCompany = state.steps.get(0)?.company;
  const { company, isCompanyLoading, error } = useCompanyService(stateCompany.id);
  const companyAgreements = company?.agreements ?? [];

  const selectableAgreements = getSelectableAgreements(companyAgreements);

  // Filter
  const inputContainerTooltip = `Filter agreements based on given words.
  
You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
      `;
  const inputPlaceHolder = t('admin.user.agreements.filterPlaceholder');
  const [query, setQuery] = useState('');
  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  // "selectAll" checkbox
  const isAllSelected =
    assignedAgreements.length === selectableAgreements.length && selectableAgreements.length > 0;
  const handleIsAllSelected = (checked: boolean) => {
    const updatedAgreements = checked ? selectableAgreements : [];
    updateStepState(updatedAgreements);
  };

  // "agreement" checkboxes
  const handleAgreementClick = (checked: boolean, value?: string) => {
    if (value) {
      const updatedAgreements = updateUserAgreements(
        assignedAgreements,
        selectableAgreements,
        value,
        checked,
      );
      updateStepState(updatedAgreements);
    }
  };

  // Update step state
  const updateStepState = (assignedAgreements: IAgreementDTO[]) => {
    dispatch({
      type: WizardActionType.StepState,
      payload: { step: currentStep, state: { assignedAgreements } },
    });
    setStepValidity(currentStep, assignedAgreements.length > 0);
  };

  const data = getTableData(
    assignedAgreements,
    selectableAgreements,
    isAllSelected,
    handleIsAllSelected,
    handleAgreementClick,
  );

  const tableData = useMemo(() => {
    return {
      ...data,
      rows: filterTableRows(data.rows, filterWords),
    };
  }, [data, filterWords]);

  if (error) {
    return <p>{error.message}</p>;
  }

  if (isCompanyLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <Filter
        filterWords={filterWords}
        handleChange={(query) => setQuery(query)}
        inputContainerTooltip={inputContainerTooltip}
        inputPlaceholder={inputPlaceHolder}
        value={query}
      />
      <TableWrapper>
        <Table tableData={tableData} enableSorting={true} />
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  max-height: 509px;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
