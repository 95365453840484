import { FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { usePermissions } from '../../../../hooks';
import { Button, Input, Spinner } from './../../../../components';
import { useCompanyService } from './../../../../hooks/useCompanyService';

interface IFormData {
  company: string;
  companyIsValid: boolean | undefined;
  hasQuickResponse: boolean;
  isFormChanged: boolean;
}

export const AddCompany = () => {
  const { hasAdminPermission } = usePermissions();
  const { addCompany, isAddCompanyLoading } = useCompanyService();
  const { t } = useTranslation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    company: '',
    companyIsValid: undefined,
    hasQuickResponse: false,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid =
      formData.companyIsValid === true && formData.isFormChanged === true && !isAddCompanyLoading;
    setIsFormValid(isFormValid);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addCompany(formData.company, formData.hasQuickResponse);
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value, checked } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = name === 'hasQuickResponse' ? true : !!value;

    setFormData({
      ...formData,
      [name]: name === 'hasQuickResponse' ? checked : value,
      [`${name}IsValid`]: isValid,
      isFormChanged: true,
    });
  };

  if (isAddCompanyLoading) {
    return <Spinner size={20} />;
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleFormChange(e)}>
      <label htmlFor="company">{t('admin.company.companyName')}</label>
      <Input
        name="company"
        value={formData.company}
        warning={formData.companyIsValid === false}
        placeholder={
          formData.companyIsValid === false ? t('admin.company.companyIsRequired') ?? '' : ''
        }
      />
      {hasAdminPermission && (
        <FormControlLabel
          control={<Switch name="hasQuickResponse" checked={formData.hasQuickResponse} />}
          label="Has Quick Response"
        />
      )}
      <Button type="submit" disabled={!isFormValid}>
        {t('admin.company.addCompany')}
      </Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;
