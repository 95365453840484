import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IHistoryDTO } from 'typescript/interfaces';

import { Accordion } from '../../components/UI';
import { useAppState } from '../../hooks';
import { WorkOrderHistoryTableRowDetails } from './WorkOrderHistoryTableRowDetails';

interface IProps {
  row: IHistoryDTO;
}

interface IAccordionState {
  animating: boolean;
  opened: boolean;
  closed: boolean;
}

export const WorkOrderHistoryRowDetailsView = ({ row }: IProps) => {
  const {
    appState: { WORKORDER_HISTORY },
  } = useAppState();
  const { details } = WORKORDER_HISTORY;
  const expanded = details.get(`${row.id}_details`);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isAnimationStart, setAnimationStart] = useState<boolean>(false);
  const [isAnimationEnd, setAnimationEnd] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accordionState, setAccordionState] = useState<IAccordionState>({
    animating: false,
    opened: false,
    closed: false,
  });

  useEffect(() => {
    setIsExpanded(expanded === undefined ? false : expanded);
  }, [expanded]);

  const transitionStart = () => {
    setAnimationEnd(false);
    setAnimationStart(true);
  };

  const transitionEnd = () => {
    setAnimationStart(false);
    setAnimationEnd(true);
  };

  useEffect(() => {
    setAccordionState({
      animating: isAnimationStart || isAnimationEnd,
      opened: isExpanded && !isAnimationStart && !isAnimationEnd,
      closed: !isExpanded && !isAnimationStart && !isAnimationEnd,
    });
  }, [isAnimationStart, isAnimationEnd, isExpanded]);

  useEffect(() => {
    if (accordionState.opened && !accordionState.animating) {
      setIsOpen(true);
    }
    if (accordionState.closed && !accordionState.animating) {
      setIsOpen(false);
    }
  }, [accordionState]);

  const transitionProps = {
    addEndListener: (node: HTMLElement, done: () => void) => {
      node.addEventListener('transitionstart', transitionStart, false);
      node.addEventListener('transitionend', transitionEnd, false);
    },
  };

  return (
    <>
      <StyledAccordion
        id={`${row.id}_details`}
        details={<WorkOrderHistoryTableRowDetails row={row} />}
        expanded={isExpanded}
        $isOpen={isOpen}
        TransitionProps={transitionProps}
      />
    </>
  );
};

const StyledAccordion = styled(Accordion)<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
`;
