import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spinner } from '../../../components/Spinner/Spinner';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Progress } from '../Progress/Progress';
import { IWizardStep } from './IWizardStep';
import { useWizardContext } from './WizardProvider';

interface IProps {
  steps: IWizardStep[];
  handleFinish: (arg: any) => void;
  loading?: boolean;
}

export const Wizard: React.FC<IProps> = ({ steps, handleFinish, loading }) => {
  const { goToNextStep, goToPreviousStep, isStepValid, state } = useWizardContext();
  const { currentStep, progress, totalSteps } = state;
  const [isFinished, setIsFinished] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsFinished(currentStep === totalSteps - 1);
  }, [currentStep, totalSteps]);

  const handleNextStep = () => {
    if (!isStepValid(currentStep)) {
      return;
    }

    if (isFinished) {
      handleFinish(state.steps);
    } else {
      goToNextStep();
    }
  };

  const handlePreviousStep = () => {
    goToPreviousStep();
  };

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <Container>
      <Heading tag="h2" variant="preamble">
        {steps[currentStep].label}
      </Heading>
      {steps[currentStep].content}
      <StepperFooter>
        <Button disabled={currentStep === 0} onClick={handlePreviousStep} variant="secondary">
          {t('general.previous')}
        </Button>
        <Progress progress={progress} />
        <Button disabled={!isStepValid(currentStep) || loading} onClick={handleNextStep}>
          {isFinished ? `${t('general.done')}! 👍` : t('general.next')}
        </Button>
      </StepperFooter>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.xlarge};
  flex: 1;
  overflow: auto;

  h2 {
    margin: 0;
  }
`;

const StepperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
