import { useContext } from 'react';

import { usePermissions } from '../../../hooks';
import { Icon } from '../../UI';
import { HeaderLinkRenderContext } from '../Header';
import { Link } from './Link';

export const History = () => {
  const { renderItem } = useContext(HeaderLinkRenderContext);
  const { selectedCompany } = usePermissions();

  return renderItem({
    icon: <Icon icon="clock" />,
    text: 'History',
    Link: ({ children }) => <Link path={`company/${selectedCompany?.id}/history`}>{children}</Link>,
  });
};
