import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Span } from '../../typescript/types/Span';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  colSpan?: Span;
  rowSpan?: Span;
}

export const GridItem: FC<IProps> = ({ colSpan, rowSpan, children, ...rest }) => {
  return (
    <Item $colSpan={colSpan} $rowSpan={rowSpan} {...rest}>
      {children}
    </Item>
  );
};

export const Item = styled.div<{ $colSpan?: Span; $rowSpan?: Span }>`
  display: flex;
  ${({ $colSpan }) => ($colSpan ? `grid-column: ${$colSpan};` : '')}
  ${({ $rowSpan }) => ($rowSpan ? `grid-row: ${$rowSpan};` : '')}
  /* padding-top: ${({ theme }) => theme.spacings.medium}; */

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  > div {
    flex: 1;
  }
`;
