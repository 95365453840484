import { Tabs as MuiTabs } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAppState,
  useProposedWorkOrdersQuery,
  useCompletedWorkOrdersQuery,
  usePermissions,
} from '../../../hooks';
import { Pages } from '../../../typescript/enums';
import { Page } from '../../../typescript/types';
import { Paths } from '../../../utils/context';
import { Tab } from './Tab';

export const Tabs = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [selectedPath, setSelectedPath] = useState<string>('/');
  const {
    permissionsError,
    selectedCompany,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
  } = usePermissions();
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();

  const hasTwoTabs = permissionsError
    ? false
    : hasPermissionsToViewProposedWorkorderForCompany &&
      hasPermissionsToViewCompletedWorkorderForCompany;

  useEffect(() => {
    // Get if the user is on proposed-work or completed-work
    const path = location.pathname.split('/').filter((path) => path.includes('-work'))[0];
    setSelectedPath(path);
  }, [selectedPath, location]);

  useEffect(() => {
    setValue(hasTwoTabs ? (selectedPath === 'proposed-work' ? 0 : 1) : 0);
  }, [hasTwoTabs, selectedPath]);

  const handleClick = (page: Page) => {
    setAppState({ ...appState, currentPage: page, currentWorkLogPage: page });
    navigate(`company/${selectedCompany?.id}/${Paths[page as keyof typeof Paths]}`);
  };

  if (permissionsError) {
    return null;
  }

  const isTabsVisible = selectedPath === 'proposed-work' || selectedPath === 'completed-work';

  if (!isTabsVisible) {
    return null;
  }

  return (
    <TabsContainer value={value} aria-label="Select between proposed-work and completed-work">
      {hasPermissionsToViewProposedWorkorderForCompany && (
        <Tab
          text="proposed work"
          mobileText="proposed"
          path="proposed-work"
          isSelected={selectedPath === 'proposed-work'}
          query={useProposedWorkOrdersQuery}
          onClick={() => handleClick(Pages.PROPOSED)}
        />
      )}
      {hasPermissionsToViewCompletedWorkorderForCompany && (
        <Tab
          text="completed work"
          mobileText="completed"
          path="completed-work"
          isSelected={selectedPath === 'completed-work'}
          query={useCompletedWorkOrdersQuery}
          onClick={() => handleClick(Pages.COMPLETED)}
        />
      )}
    </TabsContainer>
  );
};

const TabsContainer = styled(MuiTabs)`
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.background.primary};
    height: 5px;
  }

  .MuiTabs-flexContainer {
    gap: ${({ theme }) => `calc(6*${theme.spacings.medium})`};
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 1250px) {
      justify-content: space-around;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      gap: ${({ theme }) => theme.spacings.small};
    }
  }
`;
