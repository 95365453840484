import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import styled, { DefaultTheme, css } from 'styled-components';

type Severity = 'info' | 'warning' | 'error';

interface IProps {
  severity: Severity;
}

const colorsFromMessageLevel = (severity: Severity, theme: DefaultTheme) => {
  switch (severity) {
    case 'info':
      return css`
        color: ${theme.colors.background.info};
      `;
    case 'warning':
      return css`
        color: ${theme.colors.background.warning};
      `;
    case 'error':
      return css`
        color: ${theme.colors.background.error};
      `;
    default:
      return css`
        color: #000000;
      `;
  }
};

const iconFromMessageLevel = (severity: Severity) => {
  switch (severity) {
    case 'info':
      return <InfoIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'error':
      return <ErrorIcon />;
    default:
      return null;
  }
};

export const AlertBox: React.FC<IProps> = ({ children, severity }) => {
  return (
    <Container $severity={severity}>
      {iconFromMessageLevel(severity)}
      <div>{children}</div>
    </Container>
  );
};

const Container = styled.div<{ $severity: Severity }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.large};
  margin: ${({ theme }) => theme.spacings.small};
  padding: ${({ theme }) => theme.spacings.small};
  background: ${({ theme }) => theme.colors.background.informationBanner};
  color: ${({ theme }) => theme.colors.text.informationBanner};
  border-radius: ${({ theme }) => theme.radius.default};

  svg {
    font-size: 2em;
    ${({ $severity, theme }) => colorsFromMessageLevel($severity, theme)}
  }
`;
