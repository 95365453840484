import { useTranslation } from 'react-i18next';
import { ILocationDTO } from 'typescript/interfaces';

import { Button, Icon } from '../../../../components';
import { useModal } from '../../../../utils/context';
import { EditLocation } from './EditLocation';

interface IProps {
  companyId: string;
  location: ILocationDTO;
}

export const EditLocationButton = ({ companyId, location }: IProps) => {
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  const editLocation = (companyId: string, location: ILocationDTO) => {
    setModalContent({
      title: `${t('admin.company.locations.editLocation')} ${location.name}`,
      content: <EditLocation companyId={companyId} location={location} />,
    });
  };

  return (
    <Button
      variant="tertiary"
      size="small"
      onClick={() => editLocation(companyId, location)}
      tooltip={`${t('admin.genereal.saveChange')}`}>
      <Icon icon="edit" />
    </Button>
  );
};
