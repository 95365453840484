import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { MessageLevel } from 'typescript/types/Notification';

import { useNotifications } from '../../hooks/useNotifications';
import { Button, Icon } from '../UI';

const BannerContainer = styled.div`
  width: 100vw;
  padding: ${({ theme }) => theme.spacings.xsmall};
  background-color: ${({ theme }) => theme.colors.background.informationBanner};
  min-height: 100px;
  flex-shrink: 0;
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.border.default};
  justify-content: center;

  /* @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 0;
  } */
`;

const InnerContainer = styled.div`
  display: flex;
  max-width: 1000px;
`;

const MessageContainer = styled.ul`
  flex: 1;
  color: ${({ theme }) => theme.colors.text.informationBanner};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: ${({ theme }) => theme.spacings.small};
  }
`;

const Message = styled.li<{ $level: MessageLevel }>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    flex: 1;
    margin-left: 1.7em;
    white-space: pre-line;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin-left: ${({ theme }) => theme.spacings.small};
    }
  }

  svg {
    font-size: 2em;
    ${({ $level, theme }) => colorsFromMessageLevel($level, theme)}
  }
`;

const colorsFromMessageLevel = (level: MessageLevel, theme: DefaultTheme) => {
  switch (level) {
    case 'Debug':
    case 'Information':
      return css`
        color: ${theme.colors.background.info};
      `;
    case 'Warning':
      return css`
        color: ${theme.colors.background.warning};
      `;
    case 'Error':
      return css`
        color: ${theme.colors.background.error};
      `;
    default:
      return css`
        color: #000000;
      `;
  }
};

const iconFromMessageLevel = (level: MessageLevel) => {
  switch (level) {
    case 'Debug':
    case 'Information':
      return <InfoIcon />;
    case 'Warning':
      return <WarningIcon />;
    case 'Error':
      return <ReportIcon />;
    default:
      return null;
  }
};

export const InformationBanner = () => {
  const [open, setOpen] = useState(true);
  const { data: messages } = useNotifications();

  if (!open || !messages?.length) return null;

  return (
    <BannerContainer>
      <InnerContainer>
        <MessageContainer>
          {messages?.map((x, i) => (
            <Message key={i} $level={x.level}>
              {iconFromMessageLevel(x.level)}
              <p>{x.message}</p>
            </Message>
          ))}
        </MessageContainer>
        <Button variant="noBackground" onClick={() => setOpen(false)}>
          <Icon icon="multiply" />
        </Button>
      </InnerContainer>
    </BannerContainer>
  );
};
