import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Input, Spinner } from '../../../../components';
import { useCompanyService } from '../../../../hooks';

interface IFormData {
  organization: string;
  organizationIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  companyId: string;
}

export const AddOrganization = ({ companyId }: IProps) => {
  const { addAgreement: addOrganization, isAddAgreementLoading } = useCompanyService();
  const [isFormValid, setIsFormValid] = useState(false);
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IFormData>({
    organization: '',
    organizationIsValid: undefined,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid = formData.organizationIsValid === true && formData.isFormChanged === true;
    setIsFormValid(isFormValid);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const externalId = e.currentTarget.organization.value.toUpperCase();
    addOrganization(companyId, { id: '', externalId }, 'Organization added');
  };

  if (isAddAgreementLoading) {
    return <Spinner size={20} />;
  }

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = !!value;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}IsValid`]: isValid,
      isFormChanged: true,
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleFormChange(e)}>
      <label htmlFor="organization">Organization</label>
      <Input
        name="organization"
        warning={formData.organizationIsValid === false}
        defaultValue={formData.organization ?? ''}
        placeholder={
          formData.organizationIsValid === false
            ? t('admin.company.organization.organizationIsRequired') ?? ''
            : ''
        }
      />
      <Button type="submit" disabled={!isFormValid}>
        Add organization
      </Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;
