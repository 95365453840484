import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getAllNotifications,
  createNotification as createNotificationAPI,
  updateNotification as updateNotificationAPI,
  deleteNotification as deleteNotificationAPI,
} from '../api/notifications';
import { CreateNotification } from '../typescript/types/Notification';
import { useError } from './useError';
import { useTokenQuery } from './useTokenQuery';

export const useAdminNotifications = () => {
  const { data: token } = useTokenQuery();
  const [setError] = useError();
  const queryClient = useQueryClient();

  const { data: notifications, isLoading: notificationsLoading } = useQuery(
    ['all-notifications'],
    () => getAllNotifications(token as string).then((x) => x.data.notifications),
    {
      enabled: !!token,
      refetchInterval: 1800 * 1000,
    },
  );

  const { mutateAsync: createMutation } = useMutation((message: CreateNotification) =>
    createNotificationAPI(token as string, message),
  );

  const createInformationMessage = (message: CreateNotification) =>
    createMutation(message, {
      onSuccess: () => {
        queryClient.invalidateQueries(['all-notifications']);
        queryClient.invalidateQueries(['notifications']);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });

  const { mutateAsync: updateMutation } = useMutation(
    (request: { id: string; message: CreateNotification }) =>
      updateNotificationAPI(token as string, request.id, request.message),
  );

  const updateInformationMessage = (id: string, message: CreateNotification) =>
    updateMutation(
      { id, message },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['all-notifications']);
          queryClient.invalidateQueries(['notifications']);
        },
        onError: (error) => {
          setError({ error: (error as AxiosError).response?.data });
        },
      },
    );

  const { mutateAsync: deleteMutation } = useMutation((id: string) =>
    deleteNotificationAPI(token as string, id),
  );

  const deleteInformationMessage = (id: string) =>
    deleteMutation(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['all-notifications']);
        queryClient.invalidateQueries(['notifications']);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });

  return {
    informationMessages: notifications,
    informationMessagesLoading: notificationsLoading,
    createInformationMessage,
    updateInformationMessage,
    deleteInformationMessage,
  };
};
