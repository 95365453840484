// Should be replaced with Radix Tooltip
import { Root, SwitchProps, Thumb } from '@radix-ui/react-switch';
import styled from 'styled-components';

interface IProps extends SwitchProps {
  name: string;
  tooltip?: string;
}

export const Switch = ({ name, tooltip, ...rest }: IProps) => {
  return (
    <Container className="container">
      <StyledSwitch name={name} className="switch" {...rest}>
        <StyledThumb className="thumb" />
      </StyledSwitch>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xsmall};
  align-items: center;

  label {
    top: 1px;
    position: relative;
  }
`;

const StyledSwitch = styled(Root)<SwitchProps>`
  width: 44px;
  height: 24px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.colors.background.disabled};
  border: none;
  transition: background-color ${({ theme }) => theme.transitions.medium};

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.colors.background.cta};
  }

  &:focus {
    outline: none;
  }
`;

const StyledThumb = styled(Thumb)`
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.background.body};
  border-radius: 9999px;
  display: block;
  transition: ${({ theme }) => theme.transitions.medium};

  &[data-state='checked'] {
    transform: translateX(18px);
  }
`;
