import { useParams } from 'react-router-dom';

import { ProposedWorkDetailedView } from '../../components/DetailView/ProposedWorkDetailedView';
import { InvalidPage } from '../../pages/InvalidPage/InvalidPage';

export const ProposedWorkOrder = () => {
  const { id } = useParams();
  const workOrderId = Number(id);

  if (!id) {
    return <InvalidPage header="Missing workorder" text={`No workorder with id: ${id} found.`} />;
  }

  if (isNaN(workOrderId)) {
    return <InvalidPage header="Wrong format" text={`Wrong format on workorder id`} />;
  }
  return <ProposedWorkDetailedView />;
};
