import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Select, ISelectItem } from '../..';
import { useCompanyService } from '../../../hooks';

export const LANGUAGES: ISelectItem[] = [
  { title: 'Dansk', value: 'da-DK' },
  { title: 'English', value: 'en-US' },
  { title: 'Norsk', value: 'no-NO' },
  { title: 'Suomi', value: 'fi-FI' },
  { title: 'Svenska', value: 'sv-SE' },
];

interface IProps {
  defaultValue?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const LanguageSelect = ({ defaultValue, onChange, disabled }: IProps) => {
  const { t } = useTranslation();
  const { companies, isCompaniesLoading } = useCompanyService();

  const defaultSelectedItem = useMemo(() => {
    return LANGUAGES.find((item) => item.value === defaultValue ?? LANGUAGES[0].value);
  }, [defaultValue]);

  return (
    <SelectContainer>
      <Select
        label={t('userSettings.selectedLanguage') ?? ''}
        defaultTitle={t('userSettings.languages') ?? ''}
        selectItems={LANGUAGES}
        disabled={disabled || isCompaniesLoading || companies?.length === 0}
        onChange={onChange}
        initialSelectedItem={defaultSelectedItem}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.small};
`;
