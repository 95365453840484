import { ExtendButtonBase, Tab as MuiTab, TabTypeMap } from '@mui/material';
import { AxiosResponse } from 'axios';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';

import { WorkOrder } from '../../../typescript/types';
import { TabIcon } from './TabIcon';
import { TabLabel } from './TabLabel';

interface IProps {
  text: string;
  path: string;
  mobileText: string;
  isSelected: boolean;
  query: (companyId: string) => UseQueryResult<AxiosResponse<WorkOrder[]>, unknown>;
  onClick?: () => void;
}

export const Tab = ({ text, mobileText, path, isSelected, query, onClick, ...rest }: IProps) => {
  const handleClick = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <StyledTab
      label={<TabLabel text={text} mobileText={mobileText} />}
      disableRipple
      icon={<TabIcon isSelected={isSelected} query={query} />}
      attr-path={path}
      onClick={() => handleClick()}
      $isSelected={isSelected}
      {...rest}
    />
  );
};

interface ITab extends ExtendButtonBase<TabTypeMap<{}, 'div'>> {
  $isSelected: boolean;
}

const StyledTab = styled(MuiTab)<ITab>`
  &.Mui-disabled {
    display: 'none';
  }

  &.MuiButtonBase-root {
    display: flex;
    flex-flow: row-reverse;
    gap: 1rem;

    span {
      transition: color 0.25s ease-in-out;
      &:last-of-type {
        color: ${({ $isSelected, theme }) =>
          $isSelected ? theme.colors.text.link : theme.colors.text.body};
      }
    }

    @media screen and (max-width: 462px) {
      span:last-child {
        width: 90%;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      min-width: unset;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      gap: 0.7rem;
      padding: 12px 12px;
    }
  }
`;
