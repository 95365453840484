import { createContext, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Pages } from '../../typescript/enums';
import { IState } from '../../typescript/interfaces';

export enum Paths {
  HISTORY = 'history',
  COMPANIES = 'companies',
  COMPLETED = 'completed-work',
  PROPOSED = 'proposed-work',
  WELCOME = 'welcome',
}

interface IContext {
  appState: IState;
  setAppState: (state: Partial<IState>) => void;
}

const initialState: IContext = {
  appState: {
    path: '',
    hasBeenOnOnboardingPage: false,
    currentWorkLogPage: Pages.PROPOSED,
    currentPage: Pages.PROPOSED,
    HISTORY: {
      tablePage: 0,
      filter: { query: '' },
      SYSTEM: { tablePage: 0, filter: { query: '' } },
      WORKORDER: { tablePage: 0, filter: { query: '' } },
    },
    COMPANIES: { tablePage: 0, filter: { query: '' } },
    COMPLETED: { tablePage: 0, filter: { query: '' } },
    PROPOSED: { tablePage: 0, filter: { query: '' } },
    WELCOME: {},
    WORKORDER_HISTORY: { details: new Map() },
  },
  setAppState: () => null,
};

const getPageByPath = (pathname: string) => {
  if (pathname.includes(Paths.HISTORY)) return Pages.HISTORY;
  if (pathname.includes(Paths.COMPANIES)) return Pages.COMPANIES;
  if (pathname.includes(Paths.COMPLETED)) return Pages.COMPLETED;
  if (pathname.includes(Paths.PROPOSED)) return Pages.PROPOSED;
  if (pathname.includes(Paths.WELCOME)) return Pages.WELCOME;

  return Pages.PROPOSED;
};

export const AppStateContext = createContext<IContext>(initialState);
const { Provider } = AppStateContext;

export const AppStateProvider: FC = ({ children }) => {
  const location = useLocation();

  const [appState, setInternalAppState] = useState<IState>(initialState.appState);
  const setAppState = (state: Partial<IState>) => {
    setInternalAppState({ ...appState, ...state });
  };

  // Update application when the url changes
  useEffect(() => {
    const page = getPageByPath(location.pathname);

    if (page !== appState.currentPage) {
      setInternalAppState({ ...appState, currentPage: page });
    }
  }, [appState, location]);

  return <Provider value={{ appState, setAppState }}>{children}</Provider>;
};
