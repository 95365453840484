import { Pages } from '../../typescript/enums';
import { IDetailView } from '../../typescript/interfaces';
import { Page, WorkOrder } from '../../typescript/types';
import { getCompletedWorkDetailView } from './CompletedWork/getCompletedWorkDetailView';
import { getProposedWorkDetailView } from './ProposedWork/getProposedWorkDetailView';

export const getDetailViewModel = (
  workOrder: WorkOrder,
  application: Page,
  companyId: string,
): IDetailView => {
  const detailView: IDetailView =
    application === Pages.COMPLETED
      ? getCompletedWorkDetailView(workOrder, application, companyId)
      : getProposedWorkDetailView(workOrder, application, companyId);
  return detailView;
};
