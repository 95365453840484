import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TableWithFilter, Spinner, Button } from '../../components';
import { useGetSystemHistory, pageSize } from '../../hooks';
import { IHistoryDTO } from '../../typescript/interfaces';
import { getServiceHistoryTableData } from './getServiceHistoryTableData';

export interface IWorkorderHistory {
  wonum: string;
  action: string;
  date: string;
  comment: string;
}

export const SystemHistoryPage = () => {
  const { setNextPage, history } = useGetSystemHistory();
  const { data = [] as IHistoryDTO[], isLoading } = history;
  const { t } = useTranslation();

  const loadMore = () => {
    setNextPage();
  };

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <TableWithFilter
        data={data}
        getTableData={getServiceHistoryTableData}
        filterPlaceholder={`${t('history.system.filterPlaceholder')}`}
        subPage="SYSTEM"
        enableSorting={true}
        defaultOrder="asc"
        csvExportConfig={{
          buildFilename: () => 'system-history.csv',
        }}
      />
      {data.length > pageSize - 1 && (
        <ButtonContainer>
          <Button onClick={() => loadMore()}>{t('history.system.loadMore')}</Button>
        </ButtonContainer>
      )}
    </>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
