import { TableCellProps } from '@mui/material';

import { ITableCell } from '../../../typescript/interfaces';

export const cell = (cell: ITableCell, options?: TableCellProps): ITableCell => {
  // Contains default values for the cell
  const defaultOptions: TableCellProps = {
    ...options,
  };

  return {
    ...cell,
    ...defaultOptions,
  };
};
