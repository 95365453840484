import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  addAgreement as addAgreementAPI,
  deleteCompany as deleteCompanyAPI,
  deleteOrganization as deleteOrganizationAPI,
  getCompanies as getCompaniesAPI,
  getCompany as getCompanyAPI,
  updateAgreement as updateAgreementAPI,
  updateCompany as updateCompanyAPI,
  addCompany as addCompanyAPI,
  updateLocation as updateLocationAPI,
  deleteLocation as deleteLocationAPI,
  addLocation as addLocationAPI,
  updateOrganization as updateOrganizationAPI,
} from '../api';
import { IAgreementDTO, ILocationDTO } from '../typescript/interfaces';
import { useModal } from '../utils/context';
import { ICompanyDTO } from './../typescript/interfaces/ICompanyDTO';
import { useError } from './useError';
import { usePermissions } from './usePermissions';
import { useSuccess } from './useSuccess';
import { useTokenQuery } from './useTokenQuery';

export const useCompanyService = (id?: string, ids?: string[]) => {
  const [setSuccess] = useSuccess();
  const [setError] = useError();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { isAuthenticated, isLoading: authIsLoading } = useAuth0();
  const { data: token, isLoading: isTokenLoading } = useTokenQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reloadAllPermissions } = usePermissions();

  //****************************/
  // Company
  //****************************/

  // Get companies
  const { data: companies, isLoading: isCompaniesLoading } = useQuery(
    ['companies'],
    () => getCompaniesAPI(token as string),
    {
      enabled: !!token && !!isAuthenticated && !authIsLoading,
    },
  );

  // Get company
  const {
    data: company,
    isLoading: isCompanyLoading,
    error: getCompanyError,
  } = useQuery<ICompanyDTO, AxiosError>(
    ['company', id],
    () => getCompanyAPI(token as string, id ?? ''),
    {
      enabled: !!token && !!isAuthenticated && !authIsLoading,
      onError: (error) => {
        const optionalMessage =
          error.response?.status === 404 ? `${t('admin.company.companyNotFound')}` : '';
        setError({ optionalMessage, error });
        console.error(`${t('admin.company.companyNotFound')} ${error.message}`);
        navigate(-1);
      },
    },
  );

  // Add company
  const { mutate: mutateAddCompany, isLoading: isAddCompanyLoading } = useMutation(addCompanyAPI);
  const addCompany = (
    name: string,
    hasQuickResponse: boolean,
    successText = t('admin.company.companyAdded'),
  ) => {
    const data = {
      token,
      company: {
        name,
        hasQuickResponse,
      } as ICompanyDTO,
    };
    mutateAddCompany(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['companies']);
        queryClient.invalidateQueries(['permissions']);
        reloadAllPermissions();
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Update company
  const { mutate: mutateCompany, isLoading: isUpdateCompanyLoading } =
    useMutation(updateCompanyAPI);
  const updateCompany = (company: ICompanyDTO) => {
    const data = {
      token,
      company,
    };
    mutateCompany(data, {
      onSuccess: () => {
        setSuccess(t('admin.company.companyUpdated'));
        closeModal();
        queryClient.invalidateQueries(['company', company.id]);
        queryClient.invalidateQueries(['companies']);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Delete company
  const { mutate: removeCompany, isLoading: isDeleteCompanyLoading } =
    useMutation(deleteCompanyAPI);
  const deleteCompany = (companyId: string) => {
    const data = {
      token,
      companyId,
    };
    removeCompany(data, {
      onSuccess: () => {
        setSuccess(t('admin.company.companyDeleted'));
        localStorage.removeItem('selectedCompany');
        closeModal();
        queryClient.invalidateQueries(['companies']);
        navigate('/company');
        reloadAllPermissions();
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  //****************************/
  // Organization
  //****************************/

  // Delete organization
  const { mutate: mutateOrganization, isLoading: isDeleteOrganizationLoading } =
    useMutation(deleteOrganizationAPI);
  const deleteOrganization = (ids: string[], companyId: string) => {
    const data = {
      token,
      ids,
      companyId,
    };
    mutateOrganization(data, {
      onSuccess: () => {
        setSuccess(t('admin.company.organization.organizationDeleted'));
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Update organization
  const { mutate: mutateUpdateOrganization, isLoading: isUpdateOrganizationLoading } =
    useMutation(updateOrganizationAPI);
  const updateOrganization = (
    agreements: IAgreementDTO[],
    companyId: string,
    successText = t('admin.company.organization.organizationUpdated'),
  ) => {
    const data = {
      token,
      agreements,
      companyId,
    };
    mutateUpdateOrganization(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
        queryClient.invalidateQueries(['companies']);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  //****************************/
  // Location
  //****************************/

  // Update location
  const { mutate: mutateLocation, isLoading: isUpdateLocationLoading } =
    useMutation(updateLocationAPI);
  const updateLocation = (
    location: ILocationDTO,
    companyId: string,
    successText = t('admin.company.locations.locationUpdated'),
  ) => {
    const data = {
      token,
      location,
      companyId,
    };
    mutateLocation(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Add location
  const { mutate: mutateAddLocation, isLoading: isAddLocationLoading } =
    useMutation(addLocationAPI);
  const addLocation = (
    companyId: string,
    name: string,
    externalId: string,
    successText = t('admin.company.locations.locationAdded'),
  ) => {
    const data = {
      token,
      location: {
        name,
        externalId,
      } as ILocationDTO,
      companyId,
    };
    mutateAddLocation(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Delete location
  const { mutate: mutateDeleteLocation, isLoading: isDeleteLocationLoading } =
    useMutation(deleteLocationAPI);
  const deleteLocation = (
    id: string,
    companyId: string,
    successText = t('admin.company.locations.locationDeleted'),
  ) => {
    const data = {
      token,
      id,
      companyId,
    };
    mutateDeleteLocation(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  //****************************/
  // Agreement
  //****************************/

  // Update agreement
  const { mutate: mutateAgreement, isLoading: isUpdateAgreementLoading } =
    useMutation(updateAgreementAPI);
  const updateAgreement = (
    companyId: string,
    agreement: IAgreementDTO,
    successText = t('admin.company.organization.agreementUpdated'),
  ) => {
    const data = {
      token,
      agreement,
      companyId,
    };
    mutateAgreement(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  // Add agreement
  const { mutate: mutateAddAgreement, isLoading: isAddAgreementLoading } =
    useMutation(addAgreementAPI);
  const addAgreement = (
    companyId: string,
    agreement: IAgreementDTO,
    successText = t('admin.company.organization.agreementAdded'),
  ) => {
    const data = {
      token,
      agreement,
      companyId,
    };
    mutateAddAgreement(data, {
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
      onError: (error) => {
        setError({ error: (error as AxiosError).response?.data });
      },
    });
  };

  const error = getCompanyError;

  return {
    addAgreement,
    addCompany,
    addLocation,
    companies,
    company,
    deleteCompany,
    deleteLocation,
    deleteOrganization,
    error,
    isAddAgreementLoading,
    isAddCompanyLoading,
    isAddLocationLoading,
    isCompaniesLoading,
    isCompanyLoading,
    isDeleteCompanyLoading,
    isDeleteLocationLoading,
    isDeleteOrganizationLoading,
    isTokenLoading,
    isUpdateAgreementLoading,
    isUpdateCompanyLoading,
    isUpdateLocationLoading,
    isUpdateOrganizationLoading,
    updateAgreement,
    updateCompany,
    updateLocation,
    updateOrganization,
  };
};
