import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CsvExportConfig, useCsvExport } from '../../../hooks/useCsvExport';
import { ITableData } from '../../../typescript/interfaces';
import { Order, SubPage } from '../../../typescript/types';
import { filterTableRows, getNumber } from '../../../utils';
import { Filter } from '../Filter';
import { Table } from './Table';
import { TableToolbar } from './TableToolbar';

export type PartialCsvExportConfig<TExport> = Pick<
  CsvExportConfig<TExport>,
  'buildFilename' | 'separator'
>;

interface IProps<T> {
  data: T[];
  getTableData: (data: T[]) => ITableData;
  handleRowClick?: (id: string) => void;
  csvExportConfig?: PartialCsvExportConfig<string[]>;
  filterPlaceholder?: string;
  subPage?: SubPage; // Is used for setting the correct page in appState
  tableHeading?: string;
  hideLabelInCell?: boolean; // If true, the label will be hidden in the table cells
  enableSorting?: boolean;
  optionalHeaderComponent?: JSX.Element;
  hasExpandableRows?: boolean;
  inputContainerTooltip?: string;
  defaultSortProperty?: string;
  defaultOrder?: Order;
}

export const TableWithFilter = <T,>({
  data,
  getTableData,
  handleRowClick,
  csvExportConfig,
  filterPlaceholder = 'Filter',
  tableHeading = '',
  hideLabelInCell = false,
  enableSorting = false,
  optionalHeaderComponent,
  hasExpandableRows = false,
  inputContainerTooltip,
  defaultSortProperty = '',
  defaultOrder = 'desc',
}: IProps<T>) => {
  const exportToCsv = useCsvExport<string[]>({
    buildFilename: csvExportConfig?.buildFilename ?? (() => ''),
    separator: csvExportConfig?.separator,
    headers: [],
    buildRow: () => [],
  });

  const [query, setQuery] = useState<string>('');
  const [tablePage, setTablePage] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tooltip =
    inputContainerTooltip ??
    `Filter based on given words.
  
  You can filter multiple words by separating them with a space, and filter sentences by enclosing them in quotes, e.g. "cleaning service".
    `;

  const filterWords = useMemo(() => (query ? query.match(/"[^"]+"|[^ ]+/g) ?? [] : []), [query]);

  const tableData = useMemo(() => {
    const tableData = getTableData(data);
    const filteredRows = filterTableRows(tableData.rows, filterWords, hasExpandableRows);
    return { ...tableData, rows: filteredRows };
  }, [data, filterWords, getTableData]);

  const rowCount = useMemo(
    () => tableData?.rows.filter((x) => !x.id?.includes('_details')).length,
    [tableData.rows],
  );

  const handleIsOnEmptyPage = (isOnEmptyPage: boolean) => {
    if (!isOnEmptyPage) return;
    setTablePage(0);
  };

  const performExportToCsv = () => {
    // Deconstruct table rows into headers and csv rows
    if (!tableData?.rows?.length) {
      exportToCsv([]);
    } else {
      const headers = tableData.rows[0].cells.map((x) => x.id ?? '');

      const rows = tableData.rows
        .map((x) => x.cells.map((c) => c.value?.toString() ?? ''))
        .filter((x) => x.length === headers.length);

      exportToCsv(rows, { headers, buildRow: (x) => x });
    }
  };

  return (
    <>
      <div>
        {csvExportConfig && (
          <ExportRow>
            <Tooltip title="Export to CSV file">
              <IconButton onClick={performExportToCsv}>
                <OpenInNewIcon color="primary" />
              </IconButton>
            </Tooltip>
          </ExportRow>
        )}
        <Filter
          filterWords={filterWords}
          handleChange={setQuery}
          inputContainerTooltip={tooltip}
          inputPlaceholder={filterPlaceholder}
          value={query}
          optionalHeaderComponent={optionalHeaderComponent}
        />
      </div>
      <Table
        tableData={tableData}
        heading={tableHeading}
        rowsPerPage={rowsPerPage}
        tablePage={tablePage}
        handleRowClick={handleRowClick}
        hideLabelInCell={hideLabelInCell}
        enableSorting={enableSorting}
        hasExpandableRows={hasExpandableRows}
        defaultSortProperty={defaultSortProperty}
        defaultOrder={defaultOrder}
      />
      <TableToolbar
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={tablePage}
        onPageChange={(_, newPage: number) => setTablePage(newPage)}
        onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement>) =>
          setRowsPerPage(getNumber(e.target.value))
        }
        handleIsOnEmptyPage={(isOnEmptyPage) => handleIsOnEmptyPage(isOnEmptyPage)}
      />
    </>
  );
};

const ExportRow = styled.div`
  display: flex;
  justify-content: end;
`;
