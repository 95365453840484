import { FC } from 'react';
import { ErrorInfo } from 'react';

import { AuthProvider } from '../';
import { ErrorBoundary, ErrorFallback } from '../';
import { ModalProvider, AppStateProvider, PermissionsProvider } from '../../utils/context/';
import { ReactQueryClientProvider } from './ReactQueryClientProvider';
import { ThemeProviders } from './ThemeProviders';

export const Providers: FC = ({ children }) => {
  return (
    <AuthProvider>
      <AppStateProvider>
        <ThemeProviders>
          <ReactQueryClientProvider>
            <PermissionsProvider>
              <ErrorBoundary
                onError={(error: Error, errorInfo: ErrorInfo) => (
                  <ErrorFallback error={error} errorInfo={errorInfo} />
                )}>
                <ModalProvider>{children}</ModalProvider>
              </ErrorBoundary>
            </PermissionsProvider>
          </ReactQueryClientProvider>
        </ThemeProviders>
      </AppStateProvider>
    </AuthProvider>
  );
};
