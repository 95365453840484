const env = process.env.REACT_APP_ENV ?? '';

// Workorders that are not in the list below can be updated
const blackListedWorkOrderIds = [14235788, 14235786, 14235790, 14235789];
const blackListedEnv = ['development', 'test', 'stage'];

/** Check if changes is allowed */
export const getIsChangesAllowed = (workorderId: number) => {
  // if env is not in the blacklisted envs, then changes are allowed
  if (!blackListedEnv.includes(env)) {
    return { isChangesAllowed: true, message: '' };
  }

  // if workorder id is in the blacklisted workorder ids, then changes are not allowed
  if (env === 'development' || env === 'test') {
    const isChangesAllowed = !blackListedWorkOrderIds.includes(workorderId);
    return {
      isChangesAllowed,
      message: isChangesAllowed ? '' : 'Changes are not allowed for this workorder',
    };
  }

  // if env is stage, then changes are not allowed
  return {
    isChangesAllowed: env !== 'stage',
    message: `Changes are not allowed in environment: ${env}`,
  };
};
