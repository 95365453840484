import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IUserDTO } from 'typescript/interfaces';

interface IProps {
  source: string;
  user: IUserDTO;
}

type WorkorderType = 'completed' | 'proposed';

export const WorkorderTypeIndicator = ({ source, user }: IProps) => {
  const { t } = useTranslation();
  const name = user ? `${user.givenName ?? ''} ${user.familyName ?? ''}` : '';
  const workorderType = source.toLowerCase().includes('completed') ? 'completed' : 'proposed';
  const workorderTypeTooltip = t(`history.workorder.${workorderType}Workorder`);

  return (
    <Container>
      <Tooltip title={workorderTypeTooltip}>
        <Indicator $workorderType={workorderType} />
      </Tooltip>
      <Tooltip title={`${name}`}>
        <Name>{name}</Name>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Indicator = styled.div<{ $workorderType: WorkorderType }>`
  position: absolute;
  left: -${({ theme }) => theme.spacings.medium};
  top: -${({ theme }) => theme.spacings.medium};
  display: block;
  width: 10px;
  height: 58px;
  background: ${({ theme, $workorderType }) =>
    $workorderType === 'completed' ? theme.colors.status.completed : theme.colors.status.proposed};
`;

const Name = styled.p`
  margin-left: ${({ theme }) => theme.spacings.medium};
`;
