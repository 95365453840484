import { useContext } from 'react';
import { IState } from 'typescript/interfaces';

import { AppStateContext } from '../utils/context/AppStateContext';

export const useAppState = () => {
  const { appState, setAppState } = useContext(AppStateContext);

  const updateState = (newState: Partial<IState>) => {
    setAppState({ ...appState, ...newState });
  };

  return { appState, setAppState, updateState };
};
