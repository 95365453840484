import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

import {
  updateAgreement as updateAgreementAPI,
  deleteAgreement as deleteAgreementAPI,
} from '../../../../api';
import { useSuccess, useTokenQuery } from '../../../../hooks';
import { useModal } from '../../../../utils/context';
import { Button, Icon, Input, Spinner } from './../../../../components';
import { IAgreementDTO } from './../../../../typescript/interfaces/';

interface IFormData {
  agreement: string;
  agreementIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  companyId: string;
  org: string;
  agreement: IAgreementDTO;
}

export const EditAgreement = ({ companyId, org, agreement }: IProps) => {
  const { mutate: updateAgreement, isLoading } = useMutation(updateAgreementAPI); // TODO: Use useCompanyService hook instead
  const { mutate: deleteAgreement } = useMutation(deleteAgreementAPI); // TODO: Use useCompanyService hook instead
  const queryClient = useQueryClient();
  const [setSuccess] = useSuccess();
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [isFormValid, setIsFormValid] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    agreement: agreement?.externalId ?? '',
    agreementIsValid: undefined,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid = formData.agreementIsValid === true && formData.isFormChanged === true;
    setIsFormValid(isFormValid);
  }, [formData]);

  const { data: token, isLoading: tokenIsLoading } = useTokenQuery();

  const removeAgreement = (agreement: IAgreementDTO) => {
    const isConfirmed = window.confirm(`${t('general.confirmAction')}`);

    if (!isConfirmed) {
      return;
    }

    const data = {
      token,
      id: agreement.id,
      companyId,
    };
    deleteAgreement(data, {
      onSuccess: () => {
        setSuccess('Agreement deleted');
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, agreement: IAgreementDTO) => {
    e.preventDefault();
    const value = e.currentTarget.agreement.value;
    const externalId = `${org}_${value}`.toUpperCase();

    const data = {
      token,
      agreement: {
        ...agreement,
        externalId,
      },
      companyId,
    };

    updateAgreement(data, {
      onSuccess: () => {
        setSuccess('Agreement updated');
        closeModal();
        queryClient.invalidateQueries(['company', companyId]);
      },
    });
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = !!value;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}IsValid`]: isValid,
      isFormChanged: true,
    });
  };

  if (tokenIsLoading || isLoading) {
    return <Spinner size={20} />;
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e, agreement)} onChange={(e) => handleFormChange(e)}>
      <Input
        name="agreement"
        value={agreement.externalId}
        warning={formData.agreementIsValid === false}
        placeholder={
          formData.agreementIsValid === false
            ? t('admin.company.organization.agreementIsRequired') ?? ''
            : ''
        }
      />
      <ButtonContainer>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          onClick={() => removeAgreement(agreement)}>
          <Icon icon="delete" />
          {`${t('admin.company.organization.removeAgreement')}`}
        </Button>
        <Button size="small" type="submit" disabled={!isFormValid}>
          Save changes
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
