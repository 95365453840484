import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import { AccordionProps, default as MuiAccordion } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface IProps extends Omit<AccordionProps, 'children'> {
  summary?: JSX.Element;
  details: JSX.Element;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  expanded?: boolean;
}

export const Accordion = ({ summary, details, expanded = false, ...rest }: IProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const { TransitionProps, ...props } = rest;

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      TransitionProps={{ unmountOnExit: true }}
      {...props}>
      {summary && (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          {summary}
        </AccordionSummary>
      )}
      <Collapse in={isExpanded} {...TransitionProps}>
        <AccordionDetails>{details}</AccordionDetails>
      </Collapse>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(MuiAccordion)`
  &.MuiPaper-root {
    background: transparent;
    max-width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.border.default};
    box-shadow: ${({ theme }) => theme.shadows.small};
    border-radius: ${({ theme }) => theme.radius.default};

    &::before {
      background: transparent;
    }
  }

  .MuiAccordionSummary-content {
    gap: 0.5rem;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;
