import styled from 'styled-components';

import { IFieldItem } from '../../typescript/interfaces';
import { FieldItem } from '../UI/FieldItem/FieldItem';

interface IProps {
  formFields: IFieldItem[];
}

export const FormFields = ({ formFields, ...rest }: IProps) => {
  if (!formFields || formFields.length < 1) return null;
  const secondColumn = [...formFields];
  const firstColumn = secondColumn.splice(0, 2);

  const getFieldItem = (fieldItem: IFieldItem, index: number) => {
    let { style } = fieldItem;
    style = { padding: '0', ...style };
    return <FieldItem fieldItem={fieldItem} key={index} style={style} {...rest} />;
  };

  return (
    <Container>
      <div>{firstColumn.map((field: IFieldItem, index: number) => getFieldItem(field, index))}</div>
      <div>
        {secondColumn.map((field: IFieldItem, index: number) => getFieldItem(field, index))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  grid-area: form;
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.xlarge};
  padding-top: ${({ theme }) => theme.spacings.xlarge};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ theme }) => theme.spacings.medium};
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }

  > div {
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacings.large};

    &:first-child {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-flow: row;
        flex: 1;

        > div {
          flex: 1;
        }
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex-flow: column;
      }
    }

    &:last-child {
      flex: 1;
    }
  }
`;
