import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, Spinner, UserSettings } from '../../components';
import { useUserService } from '../../hooks';

export const OnboardPage = () => {
  const { t } = useTranslation();
  const { me, isMeLoading } = useUserService();
  const [isLoading, setIsLoading] = useState(isMeLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (me?.onboard) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        navigate('/');
        window.location.reload();
      }, 5000);
    }
  }, [me?.onboard, navigate]);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Heading>👋 {t('onboardPage.title')}</Heading>
        <Preamble>{t('onboardPage.description')}</Preamble>
        <p>{t('onboardPage.paragraph')}</p>
        <UserSettings
          buttonText={t('onboardPage.looksGood') ?? 'Looks good'}
          isButtonDisabled={isLoading}
        />
        <p>{t('onboardPage.paragraph2')}</p>
        <p>{t('onboardPage.paragraph3')}</p>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 75%;

  form {
    margin: ${({ theme }) => theme.spacings.xlarge} 0;
  }

  p {
    line-height: 1.5;
  }
`;

const Preamble = styled.p`
  font-size: ${({ theme }) => theme.fonts.size.preamble};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
