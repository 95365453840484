import { TableCell as MuiTableCell, Tooltip } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { ITableCell } from '../../../typescript/interfaces';

export const TableCell: FC<ITableCell> = ({
  children,
  hideOverflow,
  dense,
  showTooltip = false,
  ...rest
}) => {
  // If tooltip is true and children is a string, then show tooltip
  if (showTooltip && typeof children === 'string') {
    return (
      <StyledTableCell {...rest} $hideOverflow={hideOverflow} $dense={dense}>
        <Tooltip title={children}>
          <p>{children}</p>
        </Tooltip>
      </StyledTableCell>
    );
  }

  // if tooltip is false or children is not a string, then don't show tooltip and render children
  if (!showTooltip || typeof children !== 'string') {
    return (
      <StyledTableCell {...rest} $hideOverflow={hideOverflow} $dense={dense}>
        {children}
      </StyledTableCell>
    );
  }

  // if tooltip is false and children is a string, then don't show tooltip and render children
  return (
    <StyledTableCell {...rest} $hideOverflow={hideOverflow} $dense={dense}>
      <p>{children}</p>
    </StyledTableCell>
  );
};

const StyledTableCell = styled(MuiTableCell)<{ $hideOverflow?: boolean; $dense?: boolean }>`
  &.MuiTableCell-root {
    padding: ${({ theme, $dense }) =>
      $dense
        ? `${theme.spacings.xxsmall} ${theme.spacings.xsmall}`
        : `${theme.spacings.medium} ${theme.spacings.medium}`};
    white-space: nowrap;
    max-width: 300px;
    border-bottom: none;
    border-top: none;
    font-size: ${({ theme }) => theme.fonts.size.body};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
      color: ${({ theme }) => theme.colors.text.navigation};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding: 0;
      display: flex;
      flex-flow: column;
      text-align: left;
      font-size: ${({ theme }) => theme.fonts.size.body};

      p {
        white-space: break-spaces;
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
        ${({ $hideOverflow }) =>
          $hideOverflow &&
          css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
      }
    }

    &.details {
      padding: 0;
    }
  }
`;
