import { css } from 'styled-components';

import MuseoSansRounded300Ttf from '../../assets/fonts/MuseoSansRounded-300.ttf';
import MuseoSansRounded500Ttf from '../../assets/fonts/MuseoSansRounded-500.ttf';

export const Fonts = css`
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: local('Museo Sans Rounded'), url(${MuseoSansRounded300Ttf}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    src: local('Museo Sans Rounded'), url(${MuseoSansRounded500Ttf}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    src: local('Museo Sans Rounded'), url(${MuseoSansRounded500Ttf}) format('truetype');
    font-weight: 600;
  }
`;
