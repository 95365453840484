import styled from 'styled-components';

import { useAppState, useGetCommunicationLog } from '../../hooks';
import { Spinner } from '../Spinner/Spinner';
import { Table } from '../UI/Table/Table';

interface IProps {
  wonum: string;
  orgid: string;
  companyId: string;
}

export const WorkOrderHistory = ({ wonum, orgid, companyId }: IProps) => {
  const {
    appState: { currentPage },
  } = useAppState();
  const { data: result, isLoading } = useGetCommunicationLog({
    wonum,
    orgid,
    companyId,
    currentPage,
  });
  const tableData = result?.data;
  const details =
    tableData && tableData.rows.length > 0 ? (
      <Table tableData={tableData} />
    ) : (
      <div>No history found for this work order</div>
    );

  return isLoading ? (
    <SpinnerContainer>
      <Spinner size={60} />
    </SpinnerContainer>
  ) : (
    details
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
