import { DefaultTheme } from 'styled-components';

import { IBaseTheme, IThemeVariant } from '../../typescript/interfaces';
import { breakpoints } from './tokens/breakpoints';
import { colors } from './tokens/colors';
import { spacings } from './tokens/spacings';
import { typeScale } from './tokens/typeScale';

const baseTheme: IBaseTheme = {
  breakpoints,
  spacings,
  radius: {
    default: '5px',
  },
  transitions: {
    fast: '0.1s ease-in-out',
    medium: '0.15s ease-in-out',
    slow: '0.2s ease-in-out',
    slower: '0.25s ease-in-out',
  },
  shadows: {
    small: '0px 4px 10px -3px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  },
  fonts: {
    family: `"Museo Sans Rounded", Arial, Helvetica, sans-serif`,
    size: typeScale,
    weight: {
      regular: 300,
      medium: 400,
      bold: 600,
    },
  },
};

/** Proposed work */
export const OrangeTheme: IThemeVariant = {
  colors: {
    status: {
      completed: colors.blue[900],
      proposed: colors.orange[600],
    },
    background: {
      primary: colors.orange[600],
      onPrimary: colors.gray[50],
      body: colors.gray[50],
      cta: colors.orange[600],
      ctaHover: colors.orange[500],
      ctaActive: colors.orange[700],
      ctaDisabled: colors.gray[400],
      field: colors.orange[50],
      error: colors.levels.error,
      warning: colors.levels.warning,
      info: colors.levels.info,
      success: colors.green[500],
      disabled: colors.gray[400],
      even: colors.orange[50],
      tableRow: colors.gray[100],
      tableRowHover: colors.orange[50],
      tag: colors.gray[200],
      tagHover: colors.gray[100],
      log: colors.gray[55],
      informationBanner: '#f8f7f5',
    },
    text: {
      onPrimary: colors.gray[50],
      body: colors.gray[1000],
      onCta: colors.gray[50],
      cta: colors.orange[600],
      ctaHover: colors.gray[50],
      ctaActive: colors.gray[50],
      ctaDisabled: colors.gray[50],
      ctaError: colors.gray[50],
      label: colors.gray[400],
      field: colors.orange[700],
      error: colors.red[500],
      warning: colors.gray[1000],
      success: colors.gray[1000],
      onError: colors.gray[50],
      onWarning: colors.gray[1000],
      onSuccess: colors.gray[1000],
      disabled: colors.gray[400],
      onDisabled: colors.gray[50],
      link: colors.orange[600],
      tableRowHover: colors.orange[900],
      tag: colors.orange[900],
      tagIcon: colors.orange[900],
      navigation: colors.gray[500],
      informationBanner: colors.gray[1000],
    },
    border: {
      default: colors.gray[300],
      input: colors.gray[400],
      cta: colors.orange[600],
      ctaError: colors.red[500],
      field: colors.orange[600],
      fieldError: colors.red[500],
      disabled: colors.gray[400],
    },
  },
};

export const ProposedTheme: DefaultTheme = { ...baseTheme, ...OrangeTheme };

/** Completed work */
export const BlueTheme: IThemeVariant = {
  colors: {
    status: {
      completed: colors.blue[900],
      proposed: colors.orange[600],
    },
    background: {
      primary: colors.blue[900],
      onPrimary: colors.gray[50],
      body: colors.gray[50],
      cta: colors.blue[900],
      ctaHover: colors.blue[800],
      ctaActive: colors.blue[800],
      ctaDisabled: colors.gray[400],
      field: colors.blue[50],
      error: colors.levels.error,
      warning: colors.levels.warning,
      info: colors.levels.info,
      success: colors.green[500],
      disabled: colors.gray[400],
      even: colors.blue[100],
      tableRow: colors.gray[100],
      tableRowHover: colors.blue[50],
      tag: colors.gray[200],
      tagHover: colors.gray[100],
      log: colors.gray[55],
      informationBanner: '#f8f7f5',
    },
    text: {
      onPrimary: colors.gray[50],
      body: colors.gray[1000],
      onCta: colors.gray[50],
      cta: colors.blue[900],
      ctaHover: colors.gray[50],
      ctaActive: colors.gray[50],
      ctaDisabled: colors.gray[50],
      ctaError: colors.gray[50],
      label: colors.gray[400],
      field: colors.blue[900],
      error: colors.red[500],
      warning: colors.gray[1000],
      success: colors.gray[1000],
      onError: colors.gray[50],
      onWarning: colors.gray[1000],
      onSuccess: colors.gray[1000],
      disabled: colors.gray[400],
      onDisabled: colors.gray[50],
      link: colors.blue[900],
      tableRowHover: colors.blue[900],
      tag: colors.blue[900],
      tagIcon: colors.blue[900],
      navigation: colors.gray[500],
      informationBanner: colors.gray[1000],
    },
    border: {
      default: colors.gray[300],
      input: colors.gray[400],
      cta: colors.blue[900],
      ctaError: colors.red[500],
      field: colors.blue[900],
      fieldError: colors.red[500],
      disabled: colors.gray[400],
    },
  },
};

export const CompletedTheme: DefaultTheme = { ...baseTheme, ...BlueTheme };
