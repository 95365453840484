import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Icon, Input, Spinner } from '../../../../components';
import { useCompanyService } from '../../../../hooks';
import { IAgreementDTO } from '../../../../typescript/interfaces';
import { IOrganizationGroup } from './Organizations';

const getAgreements = (
  organizationgroup: IOrganizationGroup,
  organization: string,
): IAgreementDTO[] => {
  const agreements = organizationgroup.agreements.map((agreement: IAgreementDTO) => {
    return {
      ...agreement,
      externalId: `${organization}_${agreement.externalId}`.toUpperCase(),
    };
  });

  const orgAgreement: IAgreementDTO = {
    id: organizationgroup.organizationId,
    externalId: organization.toUpperCase(),
  };

  return [...agreements, orgAgreement];
};

interface IFormData {
  organization: string;
  organizationIsValid: boolean | undefined;
  isFormChanged: boolean;
}

interface IProps {
  organizationGroup: IOrganizationGroup;
  companyId: string;
}

export const EditOrganization = ({ organizationGroup, companyId }: IProps) => {
  const { t } = useTranslation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    organization: organizationGroup.organization,
    organizationIsValid: undefined,
    isFormChanged: false,
  });

  useEffect(() => {
    const isFormValid = formData.organizationIsValid === true && formData.isFormChanged === true;
    setIsFormValid(isFormValid);
  }, [formData]);

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = !!value;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}IsValid`]: isValid,
      isFormChanged: true,
    });
  };

  const {
    deleteOrganization,
    updateOrganization,
    isDeleteOrganizationLoading,
    isUpdateOrganizationLoading,
  } = useCompanyService();
  const isLoading = isDeleteOrganizationLoading || isUpdateOrganizationLoading;

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    organizationGroup: IOrganizationGroup,
    companyId: string,
  ) => {
    e.preventDefault();
    const organization = e.currentTarget.organization.value;
    const agreements = getAgreements(organizationGroup, organization);
    updateOrganization(agreements, companyId);
  };

  const removeOrganization = (organizationGroup: IOrganizationGroup) => {
    const isConfirmed = window.confirm(`${t('general.confirmAction')}`);

    if (!isConfirmed) {
      return;
    }

    const ids = organizationGroup.agreements.map((agreement) => agreement.id);
    ids.push(organizationGroup.organizationId); // Because we want to remove the whole organization we need to add the organization id to the array
    deleteOrganization(ids, companyId);
  };

  if (isLoading) {
    return <Spinner size={20} />;
  }

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, organizationGroup, companyId)}
      onChange={(e) => handleFormChange(e)}>
      <Input
        name="organization"
        value={formData.organization}
        warning={formData.organizationIsValid === false}
        placeholder={
          formData.organizationIsValid === false
            ? t('admin.company.organization.organizationIsRequired') ?? ''
            : ''
        }
      />
      <ButtonContainer>
        <Button
          variant="secondary"
          size="small"
          onClick={() => removeOrganization(organizationGroup)}>
          <Icon icon="delete" />
          {`${t('admin.company.organization.removeOrganization')}`}
        </Button>
        <Button size="small" type="submit" disabled={!isFormValid}>
          Save changes
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
