export const colors = {
  blue: {
    50: '#e7fffb',
    100: '#c2fff6',
    200: '#8cffee',
    300: '#3dffe2',
    400: '#00ffda',
    500: '#00fff8',
    600: '#00d1e3',
    700: '#00a4b5',
    800: '#008190',
    900: '#004e59', // primary
    1000: '#001214',
  },
  orange: {
    50: '#fff9ec',
    100: '#fff1d4',
    200: '#ffdfa7',
    300: '#ffc76f',
    400: '#ffa335',
    500: '#ff880e',
    600: '#e96704', // primary
    700: '#c84f06',
    800: '#9f3f0d',
    900: '#80350e',
    1000: '#140900',
  },
  red: {
    500: '#EA4D3E',
    1000: '#4B0C07',
  },
  yellow: {
    500: '#FFCD00',
    1000: '#74440f',
  },
  green: {
    500: '#77D672',
    1000: '#1b4d1a',
  },
  gray: {
    50: '#ffffff', // White
    55: '#f7f8f8', // Not in design system or figma
    100: '#F8F7F5', // Gray 100
    200: '#E7E6E4', // Header
    300: '#DAD9D8', // Lines
    400: '#B6AFA9', // In figma but not in design system
    500: '#645F5C', // Navigation
    1000: '#221B16', // Gray 1000
  },
  levels: {
    info: 'rgb(51, 122, 183)',
    warning: 'rgb(233, 103, 4)',
    error: '#df2935',
  },
};
