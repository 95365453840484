import { WorkOrder } from '../typescript/types';
import { getErrorMessage } from './getErrorMessage';

const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (error) {
    console.error(getErrorMessage(error), 'trying to parse', str);
    return false;
  }
  return true;
};

/**
 * @desctiption Washes response from invalid tab char (U+0009)
 * @param response
 */
export const washJSON = (response: string): WorkOrder[] => {
  // '\n' gets replaced with '<br>' in the API. We need to replace it back.
  const trimmed = response.replace(/ {4}/g, '\\n').replace(/<br\s*[/]?>/gi, '\\n');

  if (isJsonString(trimmed)) {
    return JSON.parse(trimmed ?? 'null');
  }

  return [];
};
