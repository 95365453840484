import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';

import { getServices as getServicesAPI } from '../api';
import { useError } from './useError';
import { useTokenQuery } from './useTokenQuery';

export const useServiceService = () => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { data: token, isLoading: isTokenLoading } = useTokenQuery();

  const [setError] = useError();

  const getServices = useQuery(['service'], () => getServicesAPI(token as string), {
    enabled: !!token && !!isAuthenticated && !isAuthLoading,
    onError: (error) => {
      setError(error);
    },
  });

  const { data: services, isLoading: isServicesLoading } = getServices;

  return { services, isAuthLoading, isTokenLoading, isServicesLoading };
};
