import { ISvg } from '../../../../../typescript/interfaces';
import { Svg } from '../../Svg';

export const Number = ({ ...rest }: ISvg) => (
  <Svg {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm1 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3-1a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm5.5-15a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm1 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3-1a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm4.5-14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3-1a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3 8.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3-1a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 7.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill="currentColor"></path>
  </Svg>
);
