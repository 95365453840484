import { Chat } from './Communication/Chat';
import { Number } from './Communication/Number';
import { Users } from './Communication/Users';
import { Check } from './Controls/Check';
import { DownChevron } from './Controls/DownChevron';
import { Multiply } from './Controls/Multiply';
import { UpChevron } from './Controls/UpChevron';
import { Clock } from './General/Clock';
import { Company } from './General/Company';
import { Delete } from './General/Delete';
import { Edit } from './General/Edit';
import { Settings } from './General/Settings';
import { Wrench } from './General/Wrench';

export const IconTypes = {
  chat: Chat,
  number: Number,
  users: Users,
  check: Check,
  downChevron: DownChevron,
  upChevron: UpChevron,
  multiply: Multiply,
  clock: Clock,
  company: Company,
  delete: Delete,
  edit: Edit,
  settings: Settings,
  wrench: Wrench,
};
