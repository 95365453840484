import { useProposedWorkOrdersQuery } from '../../hooks';
import { WorkOrders } from '../WorkOrders/WorkOrders';

export const ProposedWorkOrders = () => {
  return (
    <WorkOrders
      getWorkOrdersQuery={useProposedWorkOrdersQuery}
      csvExportConfig={{
        buildFilename: () => `proposed-workorders.csv`,
      }}
    />
  );
};
