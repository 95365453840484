/**
import { tryParseValue } from './tryParseValue';
 * @description Tries to parse uknown values to a number
 * @param value
 * @returns Parsed value or 0
 */
export const tryParseValue = (value: unknown): number => {
  const parsed = parseValue(value);

  if (isNaN(parsed)) {
    const cleaned = tryCleanString(value as string);
    const parsedCleaned = parseValue(cleaned);
    if (isNaN(parsedCleaned)) {
      console.error('Could not parse value', value);
      return 0;
    }
    return parsedCleaned;
  }

  return parsed;
};

export const parseValue = (value: any) => {
  if (typeof value === 'string') {
    try {
      const trimmed = value.replace(/\s/g, '').replace(',', '.'); // Remove all whitespace and replace comma with dot to be able to parse correctly
      const parsed = parseFloat(trimmed);
      return parsed;
    } catch (error) {
      console.error(error);
    }
  }
  if (typeof value === 'number') {
    return value;
  }

  return 0;
};

const tryCleanString = (value: string) => {
  const theForbiddenSign = '−'; // Character U+2212 will not be parsed to a number
  if (value.includes(theForbiddenSign)) {
    return value.replace(theForbiddenSign, '-');
  }
};
