import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../images/coor-logo-black.svg';

interface IProps {
  url?: string;
}

export const Logo = ({ url = '/' }: IProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };

  return (
    <Container id="Logo" onClick={() => handleClick()}>
      <CoorLogo src={logo} alt="COOR" />
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CoorLogo = styled.img`
  height: auto;
  width: 174px;
`;
