import { useContext } from 'react';

import { PermissionsContext } from '../utils/context/PermissionsContext';

/**
 * A custom hook that provides access to the user's permissions.
 * @returns An object containing the user's permissions and related information.
 */
export const usePermissions = () => {
  const {
    allPermissions,
    reloadAllPermissions,
    hasPermissionToUpdateCompany,
    hasPermissionToViewCustomerAdminForCompany,
    hasPermissionsToViewCompanyWorklog,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
    hasPermissionsToViewWorkorderForCompany,
    hasAdminPermission,
    isPermissionsLoading,
    permissionsContextIsLoading,
    permissionsError,
    permissionsForSelectedCompany,
    selectedCompany,
    updateSelectedCompany,
  } = useContext(PermissionsContext);

  return {
    allPermissions,
    reloadAllPermissions,
    hasPermissionToUpdateCompany,
    hasPermissionToViewCustomerAdminForCompany,
    hasPermissionsToViewCompanyWorklog,
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
    hasPermissionsToViewWorkorderForCompany,
    hasAdminPermission,
    isPermissionsLoading,
    permissionsContextIsLoading,
    permissionsError,
    permissionsForSelectedCompany,
    selectedCompany,
    updateSelectedCompany,
  };
};
