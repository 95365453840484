import { createGlobalStyle } from 'styled-components';

import { Fonts } from './fonts';

export const GlobalStyles = createGlobalStyle`
    ${Fonts}

    :root {
        font-size: ${({ theme }) => theme.fonts.size.baseFontSize};
        font-weight: ${({ theme }) => theme.fonts.weight.regular};
        color: ${({ theme }) => theme.colors.text.body};
    }

    html { 
        box-sizing: border-box;
        height: 100%;
        background: ${({ theme }) => theme.colors.background.body};
        overflow-x: hidden;
    }

    body { 
        height: 100%;
        padding:0;
        margin:0;
        overflow-x: hidden;
        font-family: ${({ theme }) => theme.fonts.family};
    }

    #root {
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    *, *::after, *::before {
        box-sizing: inherit;
    }


    *:focus {
        outline: 1px solid ${({ theme }) => theme.colors.background.primary};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            outline: none;
        }
    }


    img {
        width: 100%;
        height: auto;
    }

    a {
        color: ${({ theme }) => theme.colors.text.link};
        &:active {color: ${({ theme }) => theme.colors.text.link};}
        &:visited {color: ${({ theme }) => theme.colors.text.link}; }
    }

    p {
        margin: 0;
    }

    ::selection {
        color: ${({ theme }) => theme.colors.text.onPrimary};
        background: ${({ theme }) => theme.colors.background.primary};
    }

    .MuiTooltip-popper {
        white-space: break-spaces;
    }

    div[data-radix-content-popper-wrapper] {
        z-index: 9999;
    }
`;
