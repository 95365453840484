import { ITableRow } from '../../typescript/interfaces';
import { cell } from '../UI';

export const getFillerRows = (numberOfRows: number, minRows = 6, value = '--'): ITableRow[] => {
  const diff = minRows - numberOfRows;
  const fillerRows: ITableRow[] = new Array(diff).fill({
    cells: [
      cell({ value }),
      cell({ value }),
      cell({ value }),
      cell({ value }, { align: 'right' }),
      cell({ value }, { align: 'right' }),
    ],
    classes: { root: 'filler' },
  });
  return fillerRows;
};
