import { useTranslation } from 'react-i18next';

import { IWizardAction, IWizardState, Wizard, WizardProvider } from '../../../../../components';
import {
  IAgreementDTO,
  ICompanyDTO,
  ILocationDTO,
  IRoleDTO,
  IStep,
  IUpdateUserDTO,
  IUserDTO,
} from '../../../../../typescript/interfaces';
import { useUserService } from './../../../../../hooks/useUserService';
import { AddAgreements } from './AddAgreements';
import { AddLocations } from './AddLocations';
import { AddProfile } from './AddProfile';
import { AddRoles } from './AddRoles';
import { AddServices } from './AddServices';

interface IProps {
  company: ICompanyDTO;
}

interface IWizardUser
  extends Omit<
    IUpdateUserDTO,
    'roles' | 'assignedAgreements' | 'assignedLocations' | 'assignedServices'
  > {
  roles: IRoleDTO[];
  assignedAgreements: IAgreementDTO[];
  assignedLocations: ILocationDTO[];
  assignedServices: ILocationDTO[];
}

export const AddUser = ({ company }: IProps) => {
  const { t } = useTranslation();
  const { addUser, isAddUserLoading } = useUserService();

  const STEPS: IStep[] = [
    {
      label: t('admin.user.addUserModal.userProfile'),
      content: <AddProfile company={company} />,
    },
    {
      label: t('admin.user.addUserModal.userRoles'),
      content: <AddRoles companyId={company.id} />,
    },
    {
      label: t('admin.user.addUserModal.userAgreements'),
      content: <AddAgreements />,
    },
    {
      label: t('admin.user.addUserModal.userServices'),
      content: <AddServices />,
    },
    {
      label: t('admin.user.addUserModal.userLocations'),
      content: <AddLocations />,
    },
  ];

  const handleFinish = (dataFromWizard: any) => {
    const stepsState = Array.from(dataFromWizard.values());
    const user: IWizardUser = stepsState.reduce((acc: any, curr: any) => {
      return (acc = { ...acc, ...curr });
    }, {}) as IWizardUser;

    const userToAdd: IUpdateUserDTO = {
      id: user.id,
      email: user.email,
      givenName: user.givenName,
      familyName: user.familyName,
      companyId: user.companyId,
      language: user.language,
      notify: user.notify,
      roles: user?.roles?.map((role) => role?.id),
      assignedAgreements: user?.assignedAgreements?.map((agreement) => agreement?.id),
      assignedLocations: user?.assignedLocations?.map((location) => location?.id),
      assignedServices: user?.assignedServices?.map((service) => service?.id),
    };

    addUser(userToAdd);
  };

  const reducer = (state: IWizardState, action: IWizardAction) => {
    switch (action.type) {
      case 'PREFILL_USER':
        const user = action.payload as IUserDTO;
        const steps = state.steps
          .set(-1, { id: user?.id })
          .set(1, { roles: user?.memberships ?? [] })
          .set(2, { assignedAgreements: user?.assignedAgreements ?? [] })
          .set(3, { assignedServices: user?.assignedServices ?? [] })
          .set(4, { assignedLocations: user?.assignedLocations ?? [] });

        const validSteps = state.validSteps.set(1, true).set(2, true).set(3, true).set(4, true);

        return {
          ...state,
          steps,
          validSteps,
        };
      default:
        return state;
    }
  };

  return (
    <WizardProvider steps={STEPS} customReducer={reducer}>
      <Wizard steps={STEPS} handleFinish={handleFinish} loading={isAddUserLoading} />
    </WizardProvider>
  );
};
