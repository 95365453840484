import { rest } from 'msw';

import { mockCompletedWorkOrders } from './mockCompletedWorkOrders';
import { mockProposedWorkOrders } from './mockProposedWorkOrders';

const API_AGREEMENTS_COMPLETEDWORK_URL =
  process.env.REACT_APP_API_AGREEMENTS_COMPLETEDWORK_URL ?? '';
const API_COMPLETEDWORK_URL = process.env.REACT_APP_API_COMPLETEDWORK_URL ?? '';
const REACT_APP_API_WORKORDER_ATTACHMENT_URL =
  process.env.REACT_APP_API_WORKORDER_ATTACHMENT_URL ?? '';
const API_AGREEMENTS_PROPOSEDWORK_URL = process.env.REACT_APP_API_AGREEMENTS_PROPOSEDWORK_URL ?? '';
const API_PROPOSEDWORK_URL = process.env.REACT_APP_API_PROPOSEDWORK_URL ?? '';

const API_APP_API_URL = process.env.REACT_APP_API_URL ?? '';



export const handlers = [

  //****************************/
  // Completed work
  //****************************/

  // Get all completed work orders
  rest.get(API_AGREEMENTS_COMPLETEDWORK_URL, (_, res, ctx) => {
    return res(ctx.json(mockCompletedWorkOrders));
  }),
  
  // Get a completed work order by id
  rest.get(`${API_COMPLETEDWORK_URL}/:id`, (req, res, ctx) => {
    const { id } = req.params;
    const workOrder = mockCompletedWorkOrders.find(({ workorderid }) => workorderid === id);
    return res(ctx.json(workOrder));
  }),

  //****************************/
  // Proposed work
  //****************************/

  
  // Get all proposed work orders
  rest.get(API_AGREEMENTS_PROPOSEDWORK_URL, (_, res, ctx) => {
    return res(ctx.json(mockProposedWorkOrders));
  }),
  
  // Get a proposed work order by id
  rest.get(`${API_PROPOSEDWORK_URL}/:id`, (req, res, ctx) => {
    const { id } = req.params;
    const workOrder = mockProposedWorkOrders.find(({ workorderid }) => workorderid === id);
    return res(ctx.json(workOrder));
  }),


  //****************************/
  // Attachments
  //****************************/
  
  // Get attachment by id and doclinksid
  rest.get(
    `${REACT_APP_API_WORKORDER_ATTACHMENT_URL}/:id/doclinksId/:doclinksId`,
    (req, res, ctx) => {
      const { id, doclinksId } = req.params;
      console.warn('Missing feature in mock: ', { id, doclinksId });
      //TODO: Get the attachment from WorkOrders
      const attachemnt = '';
      return res(ctx.json(attachemnt));
    },
  ),
];
