import { Key } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '../../../hooks';
import { useModal } from '../../../utils/context/ModalContext';
import { UserPermissions } from './UserPermissions';

interface IProps {
  text?: string;
  onClick: () => void;
}

export const Permissions = ({ text, onClick }: IProps) => {
  const { t } = useTranslation();
  const { setModalContent } = useModal();
  const { selectedCompany } = usePermissions();
  const modalTitle = t('userArea.permissions.title');
  const title = useMemo(() => {
    if (selectedCompany?.name) {
      return `${modalTitle} - ${selectedCompany?.name}`;
    }
    return modalTitle;
  }, [modalTitle, selectedCompany?.name]);

  let modalContent = {
    title,
    content: <UserPermissions />,
  };

  const handleClick = () => {
    onClick();
    setModalContent(modalContent);
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <Key fontSize="small" />
      </ListItemIcon>
      {text ?? t('userArea.permissions.title')}
    </MenuItem>
  );
};
