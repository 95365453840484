import i18n from 'i18next';

import { Checkbox } from '../../../../../components';
import { ILocationDTO, ITableData, ITableRow } from '../../../../../typescript/interfaces';

const getHeaderCells = (
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
) => {
  const { t } = i18n;
  return [
    {
      id: 'select',
      label: <Checkbox value="selectAll" checked={isAllSelected} onClick={handleIsAllSelected} />,
      sx: { width: '30px' },
      sortable: false,
    },
    {
      id: 'externalId',
      label: t('admin.user.locations.externalId'),
    },
    {
      id: 'name',
      label: t('admin.user.locations.name'),
    },
  ];
};
const getRows = (
  userLocations: ILocationDTO[],
  selectableLocations: ILocationDTO[],
  handleLocationClick: (checked: boolean, value?: string) => void,
): ITableRow[] => {
  const { t } = i18n;
  return selectableLocations.map((selectableLocation: ILocationDTO) => {
    const { id, name, externalId } = selectableLocation;

    // Check if user has this location assigned
    const isChecked = userLocations.some((userLocation) => userLocation.id === id);

    return {
      id: selectableLocation.id,
      hover: false,
      cells: [
        {
          id: 'id',
          value: <Checkbox value={id} checked={isChecked} onClick={handleLocationClick} />,
          sx: { width: '30px' },
        },
        {
          id: 'location',
          value: !!externalId ? externalId : t('general.unspecified'),
        },
        {
          id: 'name',
          value: name,
        },
      ],
      selected: isChecked,
    };
  });
};
export const getTableData = (
  userLocations: ILocationDTO[],
  companyLocations: ILocationDTO[],
  isAllSelected: boolean,
  handleIsAllSelected: (checked: boolean) => void,
  handleLocationClick: (checked: boolean, value?: string) => void,
): ITableData => {
  return {
    headerCells: getHeaderCells(isAllSelected, handleIsAllSelected),
    rows: getRows(userLocations, companyLocations, handleLocationClick),
  };
};
