import { useContext } from 'react';

import { Pages } from '../../../typescript/enums';
import { Paths } from '../../../utils/context';
import { Icon } from '../../UI';
import { HeaderLinkRenderContext } from '../Header';
import { useAppState, usePermissions } from './../../../hooks/';
import { Link } from './Link';

export const WorkOrder = () => {
  const { renderItem } = useContext(HeaderLinkRenderContext);

  const {
    appState: { currentPage },
  } = useAppState();
  const {
    hasPermissionsToViewCompletedWorkorderForCompany,
    hasPermissionsToViewProposedWorkorderForCompany,
    selectedCompany,
  } = usePermissions();

  if (
    !hasPermissionsToViewCompletedWorkorderForCompany &&
    !hasPermissionsToViewProposedWorkorderForCompany
  ) {
    return null;
  }

  const path =
    currentPage === Pages.PROPOSED && hasPermissionsToViewProposedWorkorderForCompany
      ? `company/${selectedCompany?.id}/${Paths.PROPOSED}`
      : `company/${selectedCompany?.id}/${Paths.COMPLETED}`;

  const navigateOptions = {
    state: currentPage === Pages.PROPOSED ? Pages.PROPOSED : Pages.COMPLETED,
  };

  return renderItem({
    icon: <Icon icon="wrench" />,
    text: 'Workorders',
    Link: ({ children }) => (
      <Link path={path} navigateOptions={navigateOptions}>
        {children}
      </Link>
    ),
  });
};
