import { ErrorInfo } from 'react';
import styled from 'styled-components';

interface IProps {
  error: Error;
  errorInfo: ErrorInfo;
}

export const ErrorFallback = ({ error, errorInfo }: IProps) => {
  return (
    <Container>
      <h1>An error occured. 😱</h1>
      <hr />
      {error && (
        <div>
          <strong>Error message: </strong>
          {error.message}
        </div>
      )}
      <hr />
      <p>Something went wrong. Please try to reload the page</p>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
`;
