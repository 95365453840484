export interface CsvExportConfig<Tin> {
  headers: string[];
  buildRow: (row: Tin) => string[];
  buildFilename: (rows: Tin[]) => string;
  separator?: string;
}

const downloadFile = (data: string, filename: string) => {
  const blob = new Blob([data]);

  if (blob === null) {
    throw new Error('Unable to download file, blob not supported?');
  }

  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = filename;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    document.body.removeChild(link);
  }, 0);
};

export const useCsvExport = <Tin>(baseConfig: CsvExportConfig<Tin>) => {
  return (rows: Tin[], config?: Partial<CsvExportConfig<Tin>>) => {
    const headers = config?.headers ?? baseConfig.headers;
    const buildRow = config?.buildRow ?? baseConfig.buildRow;
    const buildFilename = config?.buildFilename ?? baseConfig.buildFilename;

    const separator = config?.separator ?? baseConfig?.separator ?? ';';
    const separatorReplacement = { ',': '.', ';': ' ' }[separator] ?? '';

    const csvRows = [headers.join(separator)];

    rows.map(buildRow).forEach((row) => {
      const washedColumns = row.map((x) =>
        (x ?? '')
          .replaceAll(separator, separatorReplacement)
          .replace(/[\r\n]+/g, ' ')
          .replace(/ /g, ''),
      );
      csvRows.push(washedColumns.join(separator));
    });

    downloadFile(csvRows.join('\n'), buildFilename(rows));
  };
};
