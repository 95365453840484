import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ICompanyDTO } from 'typescript/interfaces';

import { usePermissions } from '../../../hooks';
import { IUserPermission } from '../../../utils/context/PermissionsContext';
import { ISelectItem, Select } from '../Select/Select';

/**
 * Returns an array of select items for a company select component.
 * @param companyDefaultName - The default name for the company.
 * @param allPermissions - An array of user permissions.
 * @returns An array of select items or null if there are no permissions or less than 2 select items.
 */
const getSelectItems = (
  companyDefaultName: string,
  allPermissions: IUserPermission[] | null,
): ISelectItem[] | null => {
  if (!allPermissions || allPermissions.length === 0) {
    return null;
  }

  const selectItems = allPermissions.map((permission) => ({
    title: permission.companyName ?? companyDefaultName,
    value: permission.companyId,
  }));

  return selectItems.length > 1 ? selectItems : null;
};

export const CompanySelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allPermissions, selectedCompany, updateSelectedCompany } = usePermissions();

  // Get selectItems from permissions
  const selectItems = getSelectItems(t('general.unspecified'), allPermissions);

  // Handle select change
  const onChange = (companyId: string) => {
    if (!companyId) {
      return;
    }

    // Get selected company details from permissions
    const details =
      allPermissions?.find((permission) => permission.companyId === companyId) ?? null;
    const selectedCompany: Partial<ICompanyDTO> = {
      id: details?.companyId,
      name: details?.companyName,
    };
    // Update selected company in local storage
    updateSelectedCompany(selectedCompany);

    navigate('/');
  };

  if (!selectItems || selectedCompany === undefined) {
    return null;
  }

  // If the user has a selected company in their settings, use that as the initial selected item
  const initialSelectedItem = selectedCompany
    ? selectItems.find((item) => item.value === selectedCompany.id)
    : selectItems[0];

  return (
    <SelectContainer>
      <Select
        defaultTitle={t('admin.user.companies') ?? ''}
        selectItems={selectItems}
        disabled={!allPermissions || allPermissions.length === 0}
        onChange={onChange}
        initialSelectedItem={initialSelectedItem}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacings.small};
`;
