import {
  createContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import { Modal } from '../../components';

interface IModalProps {
  overflow?: string;
  displayCloseButton?: boolean;
  width?: string;
}

interface IModalContent {
  title: string;
  content: ReactNode | null;
  props?: IModalProps;
}

interface IContext {
  modalContent: IModalContent | null;
  setModalContent: Dispatch<SetStateAction<IModalContent | null>>;
  closeModal: () => void;
}

const initialState: IContext = {
  modalContent: null,
  setModalContent: () => null,
  closeModal: () => null,
};

export const ModalContext = createContext<IContext>(initialState);
const { Provider } = ModalContext;

export const ModalProvider: FC = ({ children }) => {
  const [modalContent, setModalContent] = useState<IModalContent | null>(null);

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <Provider value={{ modalContent, setModalContent, closeModal }}>
      {modalContent && <Modal />}
      {children}
    </Provider>
  );
};

export const useModal = () => {
  const { modalContent, setModalContent, closeModal } = useContext(ModalContext);
  return { modalContent, setModalContent, closeModal };
};
