import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { IEnhancedTableData } from 'typescript/interfaces';
import { WorkOrder } from 'typescript/types';

import { Button } from '../../components/UI';
import { useQuickResponse } from '../../utils/context/QuickResponseContext';

interface IProps {
  visibleRows: IEnhancedTableData[];
  workOrders: WorkOrder[];
  type: 'checkbox' | 'button';
}

export const QuickResponseIncludeAll = ({ visibleRows, workOrders, type }: IProps) => {
  const { includeWorkOrders, unIncludeAllWorkOrders, includedWorkOrders } = useQuickResponse();

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const allIsIncluded = !visibleRows.some(
      (x) => !includedWorkOrders.find((y) => x.workOrder === y.wonum),
    );
    setIsChecked(allIsIncluded);
  }, [includedWorkOrders, visibleRows]);

  const onIncludeAllChanged = () => {
    if (isChecked) {
      unIncludeAllWorkOrders();
      setIsChecked(false);
    } else {
      includeWorkOrders(
        visibleRows
          .map((x) => workOrders.find((w) => w.wonum === x.workOrder))
          .filter((x) => x) as WorkOrder[],
      );
      setIsChecked(true);
    }
  };

  return type === 'checkbox' ? (
    <Checkbox checked={isChecked} onChange={onIncludeAllChanged} />
  ) : (
    <Button id="quickResponseSelectAllButton" size="small" onClick={onIncludeAllChanged}>
      Select {isChecked ? 'none' : 'all'}
    </Button>
  );
};
