import { Header, Text, FlexContainer, LinkContainer } from './styles';

interface IProps {
  header?: string;
  text?: string;
  link?: React.ReactNode;
}

export const InvalidPage = ({
  header = 'This page does not exist',
  text = "There's nothing here 🤷‍♂️",
  link,
}: IProps) => {
  return (
    <FlexContainer>
      <Header>{header}</Header>
      <Text>{text}</Text>
      {link && <LinkContainer>{link}</LinkContainer>}
    </FlexContainer>
  );
};
