import { IDetailView, IFieldItem, IRadioButton } from '../../../typescript/interfaces';
import { Page, WorkOrder } from '../../../typescript/types';
import { PLUSPNEXTBILLSTAT } from './../../../typescript/types/Pluspnextbillstat';
import { getCompletedWorkDetailTable } from './getCompletedWorkDetailTable';
import { getCompletedWorkFields } from './getCompletedWorkFields';

const radioButtons: IRadioButton[] = [
  {
    value: PLUSPNEXTBILLSTAT.REJECT,
    label: 'Reject',
    name: 'pluspnextbillstat',
  },
  {
    value: PLUSPNEXTBILLSTAT.DISPUTE,
    label: 'Dispute',
    name: 'pluspnextbillstat',
  },
  {
    value: PLUSPNEXTBILLSTAT.APPROVE,
    label: 'Approve',
    name: 'pluspnextbillstat',
  },
];

const getCompletedWorkForm = (workOrder: WorkOrder): IFieldItem[] => {
  return [
    {
      placeholder: '',
      content: workOrder.pluspcustponum ?? 'Purchase order missing',
      fieldVariant: 'inputField',
      name: 'pluspcustponum',
      title: 'Purchase order',
      maxLength: 25,
    },
    {
      title: 'Reference',
      placeholder: '',
      content: workOrder.pluspcostcenter ?? 'Reference missing',
      fieldVariant: 'inputField',
      name: 'pluspcostcenter',
      maxLength: 37,
    },
    {
      title: 'Comment',
      placeholder: '',
      content: '',
      fieldVariant: 'textAreaField',
      name: 'comment',
      warning: false,
      warningMessage: 'Please add a comment',
      style: { gridRow: 'span 2' },
      textAreaProps: {
        rows: 13,
      },
    },
  ];
};

export const getCompletedWorkDetailView = (
  workOrder: WorkOrder,
  application: Page,
  companyId: string,
): IDetailView => {
  return {
    workorderid: workOrder.workorderid,
    wonum: workOrder.wonum,
    orgid: workOrder.siteid.substring(0, 4),
    fields: getCompletedWorkFields(workOrder),
    docLinks: workOrder.doclinks,
    formFields: getCompletedWorkForm(workOrder),
    tableData: getCompletedWorkDetailTable(workOrder, 6),
    radioButtons,
    companyId,
  };
};
