import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ITableHeaderCell, IListUserDTO, ITableRow, ITableData } from 'typescript/interfaces';

import { getAllUsers } from '../../../api';
import { Loader } from '../../../components/Loader/Loader';
import { TableWithFilter } from '../../../components/UI';
import { useAppState, useTokenQuery } from '../../../hooks';
import { useModal } from '../../../utils/context';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'company',
    label: 'Company',
  },
  {
    id: 'agreements',
    label: 'Agreements',
  },
];

const getRows = (data: IListUserDTO[]): ITableRow[] => {
  return data.map((row: IListUserDTO) => {
    const name = `${row.givenName ?? ''} ${row.familyName ?? ''}`;
    return {
      id: row.userId,
      cells: [
        {
          id: 'name',
          value: name,
        },
        {
          id: 'email',
          value: row.email,
        },
        {
          id: 'company',
          value: row.companyName,
        },
        {
          id: 'agreements',
          value: row.assignedAgreements?.map((agreement) => agreement).join(', ') ?? '',
        },
      ],
      hover: true,
    };
  });
};

const getUserListData = (data: IListUserDTO[], admin = false): ITableData => {
  return {
    headerCells,
    rows: getRows(data),
  };
};

export const ListAllUsers = () => {
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();
  const { data: token } = useTokenQuery();

  const { data: allUsers, isLoading: isAllUsersLoading } = useQuery(
    ['all-users'],
    () => getAllUsers(token as string),
    {
      enabled: !!token,
    },
  );

  const handleRowClick = (id: string) => {
    const user = allUsers?.find((user) => user.userId === id);
    const { currentPage } = appState;
    setAppState({
      ...appState,
      [currentPage]: {
        ...appState[currentPage],
        selectedUser: { id, name: `${user?.givenName ?? ''} ${user?.familyName ?? ''}` },
      },
    });
    navigate(`/company/${user?.companyId}/users/${id}`);
    closeModal();
  };

  return (
    <OuterContainer>
      {isAllUsersLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <TableWithFilter
        data={allUsers ?? []}
        getTableData={getUserListData}
        handleRowClick={handleRowClick}
        filterPlaceholder="Filter users"
        enableSorting={true}
        defaultSortProperty="name"
      />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
  min-height: 500px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
