import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import { usePermissions } from '../../../hooks';
import { AddCompany } from '../../../pages/AdminPage/CompanyAdminPage/CompanyListPage/AddCompany';
import { useModal } from '../../../utils/context';
import { Icon } from '../../UI';
import { ListAllUsers } from './ListAllUsers';
import { NonLinkHeaderIcon } from './NonLinkHeaderIcon';
import { AdminNotifications } from './Notifications';
import { ViewAllHistory } from './ViewAllHistory';

export const SystemAdminDropdown = () => {
  const { hasAdminPermission } = usePermissions();

  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!hasAdminPermission) {
    return null;
  }

  return (
    <OuterAnchor>
      <NonLinkHeaderIcon className="icon" icon="settings" onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        id="admin-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <SystemAdminMenu withIcons={false} />
      </Menu>
    </OuterAnchor>
  );
};

export const SystemAdminMenu = ({ withIcons }: { withIcons: boolean }) => {
  const { setModalContent } = useModal();

  const openAddCompanyModal = () => {
    setModalContent({
      title: 'Add new company',
      content: <AddCompany />,
      props: {
        width: '800px',
      },
    });
  };

  const openListAllUsersModal = () => {
    setModalContent({
      title: 'Listing all users',
      content: <ListAllUsers />,
    });
  };

  const openViewAllHistoryModal = () => {
    setModalContent({
      title: 'Viewing all history',
      content: <ViewAllHistory />,
    });
  };

  const openInformationMessageAdmin = () => {
    setModalContent({
      title: 'Admin notifications',
      content: <AdminNotifications />,
    });
  };

  return (
    <>
      <MenuItem onClick={openAddCompanyModal}>
        {withIcons && (
          <ListItemIcon>
            <Icon icon="company" />
          </ListItemIcon>
        )}
        Add new company
      </MenuItem>
      <MenuItem onClick={openListAllUsersModal}>
        {withIcons && (
          <ListItemIcon>
            <Icon icon="users" />
          </ListItemIcon>
        )}
        List all users
      </MenuItem>
      <MenuItem onClick={openViewAllHistoryModal}>
        {withIcons && (
          <ListItemIcon>
            <Icon icon="clock" />
          </ListItemIcon>
        )}
        View all history
      </MenuItem>
      <MenuItem onClick={openInformationMessageAdmin}>
        {withIcons && (
          <ListItemIcon>
            <Icon icon="wrench" />
          </ListItemIcon>
        )}
        Admin notifications
      </MenuItem>
    </>
  );
};

const OuterAnchor = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`;
