import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';

import { CompletedTheme, ProposedTheme } from '../utils/theme/theme';

export const useTheme = () => {
  const { pathname } = useLocation();
  const paths = pathname.split('/').filter((path) => path !== '');

  const [theme, setTheme] = useState<DefaultTheme>(ProposedTheme);

  useEffect(() => {
    const isOnProposed = paths.includes('proposed-work');
    const theme = isOnProposed ? ProposedTheme : CompletedTheme;

    setTheme(theme);
  }, [paths, setTheme]);

  return { theme };
};
