import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ButtonBase,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IHeadCell } from 'typescript/interfaces';

import { TableRow, TableCell, HeaderLabelCell, Status, TableToolbar, Button } from '..';
import { useLocalStorage, useAppState } from '../../hooks';
import { CsvExportConfig, useCsvExport } from '../../hooks/useCsvExport';
import { Pages } from '../../typescript/enums';
import { IEnhancedTableData } from '../../typescript/interfaces/IEnhancedTableData';
import { Order, Position, WorkOrder } from '../../typescript/types';
import { getNumber, getStatusText, getStringOrNumber } from '../../utils';
import { useModal } from '../../utils/context';
import { useQuickResponse } from '../../utils/context/QuickResponseContext';
import { getCompletedWorkheadCells } from './completedWorkHeadCells';
import { EnhancedFilter } from './EnhancedFilter';
import { EnhancedTableHead } from './EnhancedTableHead';
import { getTableRows } from './getTableRows';
import { getProposedWorkheadCells } from './proposedWorkHeadCells';
import { QuickResponseApprove } from './QuickResponseApprove';
import { QuickResponseIncludeAll } from './QuickResponseIncludeAll';

export type PartialCsvExportConfig = Pick<CsvExportConfig<unknown>, 'buildFilename' | 'separator'>;

const getLowerCase = (val: string | number) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }

  return val;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valA = getLowerCase(getStringOrNumber(a[orderBy]));
  const valB = getLowerCase(getStringOrNumber(b[orderBy]));

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof IEnhancedTableData>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => any) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IEnhancedTableProps {
  workOrders: WorkOrder[];
  optionalHeaderComponent?: JSX.Element;
  optionalHeaderComponentPosition?: Position;
  csvExportConfig: PartialCsvExportConfig;
}

export const EnhancedTable = ({
  workOrders,
  optionalHeaderComponent,
  optionalHeaderComponentPosition,
  csvExportConfig,
}: IEnhancedTableProps) => {
  const { appState, setAppState } = useAppState();
  const { companyId = '' } = useParams();
  const { currentPage } = appState;
  const { tablePage } = appState[currentPage];
  const [filteredRows, setFilteredRows] = useState<IEnhancedTableData[]>([]);
  const [rows, setRows] = useState<IEnhancedTableData[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IEnhancedTableData>('workOrder');
  const initialRowsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useLocalStorage('rows-per-page', initialRowsPerPage);
  const navigate = useNavigate();
  const { setModalContent } = useModal();
  const exportToCsv = useCsvExport<{ headCells: IHeadCell[]; workOrder: IEnhancedTableData }>({
    buildFilename: csvExportConfig.buildFilename,
    separator: csvExportConfig.separator,
    headers: [],
    buildRow: () => [],
  });

  const quickResponseContext = useQuickResponse();
  const {
    isEnabled: quickResponseIsEnabled,
    includedWorkOrders,
    toggleIncludeWorkorder,
  } = quickResponseContext;

  const setTablePage = useCallback(
    (tablePage: number) => {
      setAppState({
        ...appState,
        [currentPage]: {
          ...appState[currentPage],
          tablePage,
        },
      });
    },
    [appState, currentPage, setAppState],
  );

  const handleQuickResponseIncludeChecked = (row: IEnhancedTableData) => {
    const workOrder = workOrders.find((x) => x.wonum === row.workOrder);
    if (workOrder) toggleIncludeWorkorder(workOrder);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IEnhancedTableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (companyId: string, id: number, org: string) => {
    if (currentPage === Pages.COMPLETED) {
      navigate(`/company/${companyId}/completed-work/${id}/${org}`);
    }
    if (currentPage === Pages.PROPOSED) {
      navigate(`/company/${companyId}/proposed-work/${id}/${org}`);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = getNumber(event.target.value);
    if (rowsPerPage !== targetValue) {
      setRowsPerPage(targetValue);
    }

    setTablePage(0);
  };

  const handleFilterChange = <T,>(filtered: T[]) => {
    setFilteredRows(filtered as IEnhancedTableData[]);
  };

  const handleQuickResponseApprove = () => {
    setModalContent({
      title: 'Quick Response',
      content: <QuickResponseApprove quickResponseContext={quickResponseContext} />,
      props: {
        displayCloseButton: false,
        width: '1200px',
      },
    });
  };

  const performExportToCsv = () => {
    // Deconstruct table rows into headers and csv rows
    if (!filteredRows?.length) {
      exportToCsv([]);
    } else {
      const headCells =
        currentPage === Pages.PROPOSED
          ? getProposedWorkheadCells([])
          : getCompletedWorkheadCells([]);

      const rows = filteredRows.map((x) => ({ headCells, workOrder: x }));
      console.log(rows);

      exportToCsv(rows, {
        headers: headCells.map((x) => x.label?.toString() ?? ''),
        buildRow: (x) => x.headCells.map((c) => x.workOrder[c.id]?.toString() ?? ''),
      });
    }
  };

  useEffect(() => {
    setRows(getTableRows(workOrders, currentPage));
  }, [workOrders, currentPage]);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const sortedPageRows = useMemo(
    () =>
      // if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy))
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        tablePage * rowsPerPage,
        tablePage * rowsPerPage + rowsPerPage,
      ),
    [filteredRows, order, orderBy, tablePage, rowsPerPage],
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = tablePage > 0 ? Math.max(0, (1 + tablePage) * rowsPerPage - rows.length) : 0;

  const handleIsOnEmptyPage = (isOnEmptyPage: boolean) => {
    if (!isOnEmptyPage) return;
    setTablePage(0);
  };

  const extraCells: IHeadCell[] = quickResponseIsEnabled
    ? [
        {
          id: 'quickResponse',
          numeric: false,
          disablePadding: false,
          disableSorting: true,
          label: (
            <QuickResponseIncludeAll
              type="checkbox"
              visibleRows={sortedPageRows}
              workOrders={workOrders}
            />
          ),
        },
      ]
    : [];

  const headcells =
    currentPage === Pages.PROPOSED
      ? getProposedWorkheadCells(extraCells)
      : getCompletedWorkheadCells(extraCells);

  const columnOffset = quickResponseIsEnabled ? 1 : 0;

  return (
    <Container>
      <ExportRow>
        <Tooltip title="Export to CSV file">
          <IconButton onClick={performExportToCsv}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Tooltip>
      </ExportRow>

      <EnhancedFilter
        page={currentPage}
        rows={rows}
        handleFilterChange={(filtered) => handleFilterChange(filtered)}
        optionalHeaderComponent={optionalHeaderComponent}
        optionalHeaderComponentPosition={optionalHeaderComponentPosition}
      />
      <div>
        <TableContainer>
          <StyledTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              headCells={headcells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortedPageRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <EnhancedTableRow
                    hover
                    onClick={() => handleClick(companyId, row.id, row.organization)}
                    tabIndex={-1}
                    key={row.workOrder}>
                    {quickResponseIsEnabled && (
                      <>
                        <HeaderLabelCell headerCells={headcells} index={0} hiddenInXs={true} />
                        <TableCell hideOverflow={false}>
                          <IncludeInQuickResponseCheckbox
                            checked={!!includedWorkOrders.find((x) => x.wonum === row.workOrder)}
                            onClick={() => handleQuickResponseIncludeChecked(row)}
                          />
                        </TableCell>
                      </>
                    )}
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 0} />
                    <TableCell id={labelId} scope="row" align="left">
                      <Status status={getStatusText(row.response)}>{row.workOrder}</Status>
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 1} />
                    <TableCell align="left" hideOverflow={true}>
                      {row.description}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 2} />
                    <TableCell align="left" hideOverflow={true}>
                      {row.location}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 3} />
                    <TableCell align="left" hideOverflow={true}>
                      {row.actionReporting}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 4} />
                    <TableCell align="right" hideOverflow={true}>
                      {row.cost}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 5} />
                    <TableCell align="left" hideOverflow={true}>
                      {row.purchaseOrder}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 6} />
                    <TableCell align="left" hideOverflow={true}>
                      {row.reference}
                    </TableCell>
                    <HeaderLabelCell headerCells={headcells} index={columnOffset + 7} />
                    <TableCell align="left" hideOverflow={true}>
                      {getStatusText(row.response)}
                    </TableCell>
                  </EnhancedTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TableToolbar
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={filteredRows.length === 0 && tablePage !== 0 ? 0 : tablePage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          handleIsOnEmptyPage={(isOnEmptyPage) => handleIsOnEmptyPage(isOnEmptyPage)}
        />
      </div>

      {quickResponseIsEnabled && (
        <OuterContainer $isOpen={includedWorkOrders.length > 0}>
          <PanelContainer>
            <Button
              size="small"
              disabled={includedWorkOrders.length === 0}
              onClick={handleQuickResponseApprove}>
              Approve all selected
            </Button>
            <QuickResponseIncludeAll
              type="button"
              visibleRows={sortedPageRows}
              workOrders={workOrders}
            />
          </PanelContainer>
        </OuterContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  &.MuiTable-root {
    min-width: unset;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    margin-bottom: ${({ theme }) => theme.spacings.medium};
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: flex;
      flex-flow: column;
    }
  }
`;

const EnhancedTableRow = styled((props) => <TableRow {...props} />)`
  &.MuiTableRow-root {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      display: grid;
      grid-template-columns: 120px 1fr;
    }
  }
`;

const OuterContainer = styled.div<{ $isOpen: boolean }>`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};

    #quickResponseSelectAllButton {
      display: none;
    }
  }

  position: sticky;
  z-index: 200;
  bottom: 0;
  width: 100%;
  min-height: 70px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  background-color: ${({ theme }) => theme.colors.background.body};
  margin-top: ${({ theme }) => theme.spacings.small};
`;

const PanelContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  padding: ${({ theme }) => theme.spacings.small} ${({ theme }) => theme.spacings.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const IncludeInQuickResponseCheckbox = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <>
      <CheckboxHiddenInXs
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClick();
        }}
        checked={checked}
      />

      <IncludeButtonInXs
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClick();
        }}>
        Include for QuickResponse
        {checked ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
      </IncludeButtonInXs>
    </>
  );
};

const CheckboxHiddenInXs = styled(Checkbox)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none !important;
  }
`;

const IncludeButtonInXs = styled(ButtonBase)`
  gap: ${({ theme }) => theme.spacings.xsmall};
  font-size: 0.8em;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none !important;
  }
`;

const ExportRow = styled.div`
  display: flex;
  justify-content: end;
`;
