import { ITableData, ITableHeaderCell, ITableRow, IUserDTO } from 'typescript/interfaces';

const headerCells: ITableHeaderCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'agreements',
    label: 'Agreements',
  },
];

const getRows = (data: Partial<IUserDTO>[]): ITableRow[] => {
  return data.map((row: Partial<IUserDTO>) => {
    const name = `${row.givenName ?? ''} ${row.familyName ?? ''}`;
    return {
      id: row.id,
      cells: [
        {
          id: 'name',
          value: name,
        },
        {
          id: 'email',
          value: row.email,
        },
        {
          id: 'agreements',
          value: row.assignedAgreements?.map((agreement) => agreement.name).join(', ') ?? '',
        },
      ],
      hover: true,
    };
  });
};

export const getUserListData = (data: Partial<IUserDTO>[], admin = false): ITableData => {
  return {
    headerCells,
    rows: getRows(data),
  };
};
