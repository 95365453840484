import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../../components';
import { IAgreementDTO, ICompanyDTO } from '../../../../typescript/interfaces';
import { useModal } from '../../../../utils/context';
import { AddOrganization } from './AddOrganization';
import { getOrganizationGroups } from './getOrganizationGroups';
import { OrganizationGroups } from './OrganizationGroups';

export interface IOrganizationGroup {
  organization: string;
  organizationId: string;
  agreements: IAgreementDTO[];
}

interface IProps {
  company: ICompanyDTO;
  setOptionalComponent?: Dispatch<SetStateAction<React.ReactNode>>;
}

export const Organizations = ({ company, setOptionalComponent }: IProps) => {
  const agreements = company?.agreements || []; // organizations is missing in ICompanyDTO
  const organizationGroups = getOrganizationGroups(agreements);
  const { t } = useTranslation();
  const { setModalContent } = useModal();

  const AddOrganizationButton = () => (
    <Button onClick={() => addAnOrganization(company.id)}>
      {t('admin.company.organization.addOrganization')}
    </Button>
  );

  useEffect(() => {
    if (!setOptionalComponent) {
      return;
    }

    setOptionalComponent(AddOrganizationButton);
  }, [setOptionalComponent]);

  const addAnOrganization = (companyId: string) => {
    setModalContent({
      title: `${t('admin.company.organization.addOrganization')}`,
      content: <AddOrganization companyId={companyId} />,
    });
  };

  return (
    <Container>
      {organizationGroups.length > 0 ? (
        <OrganizationGroups groups={organizationGroups} companyId={company.id} />
      ) : (
        <p>{t('admin.company.organization.noOrganizationsFound')}</p>
      )}
    </Container>
  );
};

const Container = styled.div``;
