import * as RadixProgress from '@radix-ui/react-progress';
import styled from 'styled-components';

interface IProps {
  progress: number;
}

export const Progress = ({ progress }: IProps) => {
  return (
    <Progressbar value={progress}>
      <ProgressIndicator $progress={progress} />
    </Progressbar>
  );
};

const Progressbar = styled(RadixProgress.Root)`
  width: 100%;
  height: 12px;
  border-radius: 6px;
  margin: 0 ${({ theme }) => theme.spacings.large};
  background: ${({ theme }) => theme.colors.background.primary};
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
`;

const ProgressIndicator = styled(RadixProgress.Indicator)<{ $progress: number }>`
  height: 12px;
  border-radius: 6px;
  transition: width 0.15s ease-in;
  width: ${({ $progress }) => `${$progress}%`};
  /* transform: ${({ $progress }) => `translateX(-5${100 - $progress}%)`}; */
  background: ${({ theme }) => theme.colors.background.primary};
`;
