import { Tooltip } from '@mui/material';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Loader } from '../..//Loader/Loader';

const Sizes = {
  small: css`
    padding: ${({ theme }) => ` ${theme.spacings.small} ${theme.spacings.medium}`};
    font-size: ${({ theme }) => theme.fonts.size.body};
  `,
  medium: css`
    padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xlarge}`};
    font-size: ${({ theme }) => theme.fonts.size.preamble};
  `,
};

type Size = keyof typeof Sizes;

const getSize = (size: Size) => {
  return Sizes[size];
};

const DefaultButton = styled.button<{ $size: Size }>`
  position: relative;
  ${({ $size }) => getSize($size)}
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-family: ${({ theme }) => theme.fonts.family};
  border-radius: 40px;
  border: 1px solid transparent;
  cursor: pointer;
  letter-spacing: -0.015rem;
  transition: background-color 0.1s ease-in-out;
  transition: box-shadow 0.25s ease-in-out, background 0.15s ease-in-out, color 0.15s ease-in-out,
    fill 0.15s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  white-space: nowrap;
  max-height: 56px;

  .loader-container {
    border: 1px solid transparent;
    border-radius: 40px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 80%;

    svg:last-child {
      margin: 0;
    }
  }

  svg {
    font-size: ${({ theme }) => theme.fonts.size.h4};
    path {
      fill: ${({ theme }) => theme.colors.text.onPrimary};
    }

    &:first-child {
      margin-right: ${({ theme }) => theme.spacings.xsmall};
    }

    &:last-child {
      margin-left: ${({ theme }) => theme.spacings.xsmall};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.ctaHover};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(DefaultButton)`
  background: ${({ theme }) => theme.colors.background.cta};
  color: ${({ theme }) => theme.colors.text.onCta};

  .loader-container {
    background: ${({ theme }) => theme.colors.background.cta};
  }

  svg {
    color: inherit;
    path {
      fill: ${({ theme }) => theme.colors.text.onCta};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.ctaHover};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.ctaDisabled};
    color: ${({ theme }) => theme.colors.text.ctaDisabled};
  }
`;

const SecondaryButton = styled(DefaultButton)`
  background: ${({ theme }) => theme.colors.background.body};
  color: ${({ theme }) => theme.colors.text.cta};
  border-color: ${({ theme }) => theme.colors.border.cta};

  .loader-container {
    background: ${({ theme }) => theme.colors.background.body};
    border-color: ${({ theme }) => theme.colors.border.cta};
  }

  svg {
    color: inherit;
    path {
      fill: ${({ theme }) => theme.colors.text.cta};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.ctaHover};
    border-color: ${({ theme }) => theme.colors.background.ctaHover};
    color: ${({ theme }) => theme.colors.text.onCta};

    path {
      fill: ${({ theme }) => theme.colors.text.onCta};
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.ctaDisabled};
    color: ${({ theme }) => theme.colors.text.ctaDisabled};
  }
`;

const TertiaryButton = styled(DefaultButton)`
  background: transparent;
  color: ${({ theme }) => theme.colors.text.cta};
  border-color: transparent;

  .loader-container {
    background: ${({ theme }) => theme.colors.background.body};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.text.cta};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.ctaHover};
    border-color: ${({ theme }) => theme.colors.background.ctaHover};
    color: ${({ theme }) => theme.colors.text.onCta};

    path {
      fill: ${({ theme }) => theme.colors.text.onCta};
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.ctaDisabled};
    color: ${({ theme }) => theme.colors.text.ctaDisabled};
  }

  &:focus {
    outline: none;
  }
`;

const NoBackground = styled(DefaultButton)`
  background: transparent;
  color: ${({ theme }) => theme.colors.text.cta};
  border-color: transparent;

  .loader-container {
    background: ${({ theme }) => theme.colors.background.body};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.text.cta};
    }
  }

  &:hover {
    text-shadow: 2px 2px 2px ${({ theme }) => theme.colors.background.ctaHover};
    color: ${({ theme }) => theme.colors.text.cta};
    background: transparent;

    path {
      fill: ${({ theme }) => theme.colors.text.cta};
      text-shadow: 2px 2px 2px ${({ theme }) => theme.colors.background.ctaHover};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.ctaDisabled};
    background: transparent;
  }

  &:focus {
    outline: none;
  }
`;

const Variants = {
  default: DefaultButton,
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  noBackground: NoBackground,
};

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: keyof typeof Variants;
  size?: Size;
  tooltip?: string;
  loading?: boolean;
}

export const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  tooltip,
  loading,
  ...rest
}: IButtonProps) => {
  const ButtonVariant = Variants[variant];

  const renderButton = () => (
    <ButtonVariant type="button" $size={size} {...rest}>
      {loading && (
        <span
          className="loader-container"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <Loader size="24px" />
        </span>
      )}
      {children ? children : ''}
    </ButtonVariant>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{renderButton()}</Tooltip>;
  }

  return renderButton();
};
