import styled from 'styled-components';

import { Loader, Logo } from '../';

export const CheckingAuth = () => {
  return (
    <Container>
      <div>
        <Logo />
        <h1>Checking authentication..</h1>
        <Loader />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    white-space: nowrap;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      white-space: break-spaces;
    }
  }

  div {
    display: flex;
    flex: 0;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;
